import * as React from 'react';
const SvgSun = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.045 9a2.25 2.25 0 104.5 0 2.25 2.25 0 00-4.5 0zM9.295 3.75v-1.5M13.045 5.25l1.05-1.05M14.545 9h1.5M13.045 12.75l1.05 1.05M9.295 14.25v1.5M5.546 12.75l-1.05 1.05M4.795 9h-1.5M5.546 5.25L4.496 4.2"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const MemoSvgSun = React.memo(SvgSun);
export default MemoSvgSun;
