import * as React from 'react';

const SvgMicrophone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.25c-2.1 0-3.806 1.694-3.806 3.786V12.5c0 2.092 1.706 3.786 3.806 3.786s3.806-1.694 3.806-3.786V7.036c0-2.092-1.705-3.786-3.806-3.786zm0 1.5a2.296 2.296 0 012.306 2.286V12.5a2.296 2.296 0 01-2.306 2.286 2.296 2.296 0 01-2.306-2.286V7.036A2.296 2.296 0 0112.5 4.75z"
        fill="currentColor"
      />
      <path
        d="M18 8.714a.75.75 0 01.743.648l.007.102V12.5c0 3.18-2.403 5.802-5.5 6.17V21a.75.75 0 01-1.493.102L11.75 21v-2.33c-3.023-.36-5.384-2.865-5.496-5.942L6.25 12.5V9.464a.75.75 0 011.493-.102l.007.102V12.5c0 2.53 2.009 4.596 4.532 4.71l.218.004c2.552 0 4.631-1.996 4.745-4.498l.005-.216V9.464a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMicrophone = React.memo(SvgMicrophone);
export default MemoSvgMicrophone;
