import { HtmlHTMLAttributes, useRef, useState, PropsWithChildren } from 'react';
import { Popover } from '../popover';
import { CategoryLink } from '../category-link';
import { BackButton } from '../popover/back-button';
import { MenuCategory } from '../menu-categories-data';
import { SubCategoryLink } from '../sub-category-link';
import { LinkBehaviorProps } from '../helpers';
import styles from './menu-item.module.scss';

type Props = {
  item: MenuCategory;
  disableAnalyticsEvents?: boolean;
} & LinkBehaviorProps;

type LiItem = HtmlHTMLAttributes<HTMLLIElement>;

export const ListItem = (props: LiItem) => {
  const ref = useRef(null);

  return (
    <li
      {...props}
      ref={ref}
      className={styles.listItem}
      onClick={(event) => {
        if (event.target === ref.current && props.onClick) {
          props.onClick(event);
        }
      }}
      data-nagish="header-menu-item"
    >
      {props.children}
    </li>
  );
};

export const ItemBackButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => <BackButton className={styles.stickyTop} text={text} onClick={onClick} />;

export const MenuItemContentWrapper = ({
  rowsInDesktop,
  children,
}: PropsWithChildren<{
  rowsInDesktop?: number;
}>) => (
  <ul
    className={styles.content}
    style={
      {
        '--desktop-max-rows': rowsInDesktop,
      } as React.CSSProperties
    }
  >
    {children}
  </ul>
);

export const MenuItem = ({
  item,
  disableAnalyticsEvents,
  ...linkBehaviorProps
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const subCategories = item.subCategories || [];
  const hasItems = !!subCategories.length;

  return (
    <ListItem
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(true)}
      data-testid="header-item"
    >
      <CategoryLink
        url={item.url}
        text={item.categoryText}
        icon={item.icon}
        isNewTab={item.newTab}
        disableAnalyticsEvents={disableAnalyticsEvents}
        hasLinkBehaviourInMobile={!hasItems}
        {...linkBehaviorProps}
      />
      {hasItems ? (
        <Popover open={open}>
          <ItemBackButton
            text={item.categoryText}
            onClick={() => setOpen(false)}
          />
          <MenuItemContentWrapper rowsInDesktop={item.rowsInDesktop}>
            {subCategories.map((item, idx) => (
              <SubCategoryLink
                key={`${item.text}_${idx}`}
                text={item.text}
                url={item.url}
                icon={item.icon}
                isNewTab={item.newTab}
                desktopColumnPlacement={item.desktopColumnPlacement}
                disableAnalyticsEvents={disableAnalyticsEvents}
                isNewTag={item.isNewTag}
                {...linkBehaviorProps}
              />
            ))}
          </MenuItemContentWrapper>
        </Popover>
      ) : null}
    </ListItem>
  );
};
