import * as React from 'react';
const SvgOffice = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12.97a.75.75 0 01.75-.75h18a.75.75 0 110 1.5H3a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12.22a.75.75 0 01.75.75v9.667a.75.75 0 01-1.5 0V12.97a.75.75 0 01.75-.75zM4.25 3.363A2.75 2.75 0 017 .613h10a2.75 2.75 0 012.75 2.75V7.97A2.75 2.75 0 0117 10.72H7a2.75 2.75 0 01-2.75-2.75V3.363zM7 2.113c-.69 0-1.25.56-1.25 1.25V7.97c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V3.363c0-.69-.56-1.25-1.25-1.25H7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 9.22h4.5v4.5h-4.5v-4.5zm1.5 1.5v1.5h1.5v-1.5h-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 13.656h-5.27v8.23h5.27v-8.23zm-6.77-.69v9.42a1 1 0 001 1h6.27a1 1 0 001-1v-9.42h-8.27z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.625 15.328h1.98a.75.75 0 010 1.5h-1.98a.75.75 0 010-1.5zM16.625 18.679h1.98a.75.75 0 010 1.5h-1.98a.75.75 0 010-1.5z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgOffice = React.memo(SvgOffice);
export default MemoSvgOffice;
