import * as React from 'react';

const SvgKids = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2c5.523 0 10 4.477 10 10 0 2.006-.59 3.874-1.608 5.44a.747.747 0 01-.146.218A9.99 9.99 0 0112 22a9.998 9.998 0 01-8.829-5.3.752.752 0 01-.214-.425A9.961 9.961 0 012 12C2 6.477 6.477 2 12 2zm7.686 13.636A8.468 8.468 0 0020.5 12a8.48 8.48 0 00-2.875-6.373.756.756 0 01-.139.08c-1.182.518-2.15 1.105-2.91 1.756.27.45.424.975.424 1.537 0 .53-.137 1.027-.378 1.46.953.464 1.877 1.174 2.776 2.123.758.802 1.52 1.82 2.288 3.053zm-6.179-9.23a2.982 2.982 0 00-.763-.313A.752.752 0 0012.75 6V3.533a8.446 8.446 0 013.49 1.098c-1.074.524-1.985 1.115-2.733 1.775zm.02 5.177c.054.043.113.079.18.107.865.369 1.734 1.008 2.6 1.924.812.857 1.648 2.015 2.503 3.473a8.49 8.49 0 01-6.18 3.39c-.52-1.702-.779-3.247-.779-4.634 0-1.396.098-2.678.292-3.846a2.983 2.983 0 001.384-.414zm-2.47 8.865a8.504 8.504 0 01-6.504-4.347c.817-1.765 1.658-3.039 2.507-3.82.904-.83 1.744-1.333 2.51-1.52.286.393.664.714 1.103.93-.215 1.27-.322 2.653-.322 4.152 0 1.413.237 2.948.707 4.605zM9 9c0 .12.007.239.02.355-.976.283-1.966.894-2.975 1.822-.787.724-1.533 1.747-2.244 3.071A8.51 8.51 0 013.5 12a8.483 8.483 0 013.131-6.59c.853.476 1.797 1.14 2.829 1.993A2.986 2.986 0 009 9zm-.983-4.511a23.393 23.393 0 012.544 1.878 2.98 2.98 0 01.695-.274L11.25 6V3.533a8.446 8.446 0 00-3.233.956zM12 7.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgKids = React.memo(SvgKids);
export default MemoSvgKids;
