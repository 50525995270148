import * as React from 'react';
const SvgNotification = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.84 22.24c-.79 0-1.57-.25-2.21-.72-.63-.46-1.1-1.13-1.32-1.87a.645.645 0 01-.03-.2v-.2H3.79c-.3 0-.58-.17-.71-.43s-.1-.56.08-.79c.04-.07.08-.13.14-.23.1-.2.25-.54.41-1.05.29-.96.64-2.7.64-5.46 0-1.95.78-3.79 2.19-5.17a7.609 7.609 0 014.21-2.07l.17-.02V2.77c0-.42.35-.77.79-.77.44 0 .79.34.79.77v1.22l.18.02c1.68.19 3.26.93 4.46 2.1a7.195 7.195 0 012.19 5.17c0 3.08.34 4.79.63 5.68.16.48.3.74.38.86l.07.09c.24.23.32.55.22.83-.11.31-.41.51-.75.51h-4.46v.2c0 .07-.02.14-.04.2-.22.74-.69 1.4-1.32 1.87-.63.46-1.42.72-2.21.72h-.01zm-1.89-2.69c.15.29.36.55.63.75.36.26.81.41 1.26.41.45 0 .89-.14 1.26-.41.27-.2.48-.45.63-.75l.14-.29H9.81l.14.29zM11.83 5.5c-1.58 0-3.06.6-4.18 1.69a5.657 5.657 0 00-1.73 4.09c0 2.37-.24 4.35-.7 5.89-.03.1-.18.54-.18.54h13.51l-.1-.3c-.47-1.44-.71-3.51-.71-6.14 0-1.55-.62-3-1.73-4.09a5.95 5.95 0 00-4.18-1.69v.01z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgNotification = React.memo(SvgNotification);
export default MemoSvgNotification;
