import * as React from 'react';

const SvgShow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.15 7.538C15.674 6.615 13.996 5.973 12.294 6c-1.703-.028-3.38.614-4.858 1.537-1.487.929-2.821 2.173-3.849 3.427l-.002.002c-.22.272-.584.779-.584 1.4 0 .62.363 1.127.584 1.399l.002.002c1.005 1.227 2.326 2.472 3.81 3.408 1.477.93 3.166 1.587 4.897 1.559 1.73.028 3.42-.628 4.896-1.559 1.485-.936 2.806-2.18 3.812-3.408l.002-.002.023-.028c.095-.117.239-.293.356-.486.127-.21.273-.517.273-.886 0-.368-.146-.676-.273-.884-.117-.194-.26-.37-.356-.487l-.023-.028-.002-.003c-1.029-1.253-2.364-2.497-3.85-3.426zM4.553 12.205c.044-.09.112-.188.195-.29.94-1.148 2.155-2.276 3.483-3.105 1.333-.833 2.729-1.334 4.048-1.31h.029c1.32-.024 2.715.477 4.049 1.31 1.328.83 2.544 1.958 3.485 3.106l.005.005c.11.136.192.237.254.338a.554.554 0 01.053.107.548.548 0 01-.053.108 3.209 3.209 0 01-.254.338l-.005.005v.002c-.922 1.123-2.125 2.252-3.452 3.088-1.332.84-2.739 1.353-4.082 1.328h-.028c-1.344.025-2.75-.488-4.082-1.328-1.327-.836-2.53-1.965-3.45-3.088a1.634 1.634 0 01-.195-.29.4.4 0 01-.052-.162.4.4 0 01.052-.162zm9.02.163c0 .684-.556 1.241-1.244 1.241a1.243 1.243 0 01-1.245-1.241c0-.684.556-1.242 1.245-1.242.688 0 1.244.558 1.244 1.242zm1.5 0a2.743 2.743 0 01-2.744 2.741 2.743 2.743 0 01-2.745-2.741 2.743 2.743 0 012.745-2.742 2.743 2.743 0 012.744 2.742z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgShow = React.memo(SvgShow);
export default MemoSvgShow;
