import * as React from 'react';

const SvgWanted = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 7H14V5.5h-3.5V7zm5 0V4.75a.75.75 0 00-.75-.75h-5a.75.75 0 00-.75.75V7H4.75a.75.75 0 00-.75.75v11c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-11a.75.75 0 00-.75-.75H15.5zm-5.076 5.487L5.5 11.174V8.5H19v2.674l-4.924 1.313a.999.999 0 00-.826-.437h-2a.999.999 0 00-.826.437zm-.174 1.506L5.5 12.726V18H19v-5.274l-4.75 1.267v.057a1 1 0 01-1 1h-2a1 1 0 01-1-1v-.057z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgWanted = React.memo(SvgWanted);
export default MemoSvgWanted;
