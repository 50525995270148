import * as React from 'react';
const SvgSwap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.043 12.632a.75.75 0 01-1.053-.126l-2.38-3.03a.75.75 0 01.005-.931l2.38-2.971a.75.75 0 111.17.937L13.74 8.293h7.063a.75.75 0 010 1.5h-7.036l1.404 1.786a.75.75 0 01-.127 1.053zm-6.085-2.18a.75.75 0 011.053.127l2.38 3.03a.75.75 0 01-.004.931l-2.38 2.971a.75.75 0 11-1.17-.937l1.426-1.782H3.2a.75.75 0 010-1.5h7.036l-1.404-1.786a.75.75 0 01.126-1.053z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgSwap = React.memo(SvgSwap);
export default MemoSvgSwap;
