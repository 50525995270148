import * as React from 'react';
const SvgNewAsset = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 19.98v-9.393c0-.376.105-.719.316-1.027.21-.308.488-.53.834-.666l9.74-3.734c.302-.12.581-.09.839.09.257.182.386.431.386.748V13.5h1.347c0-.486.173-.903.519-1.25.346-.346.763-.52 1.249-.52.486 0 .903.174 1.25.52.347.347.52.764.52 1.25v6.48c0 .423-.148.781-.443 1.077a1.465 1.465 0 01-1.076.443H5.019c-.422 0-.78-.148-1.076-.443A1.465 1.465 0 013.5 19.98zM5 20h4.5v-4.692c0-.497.177-.923.531-1.277a1.74 1.74 0 011.277-.53h2.808V6.851l-8.924 3.446a.327.327 0 00-.139.116.298.298 0 00-.053.173V20zm6 0h3.116v-1.77c0-.244.086-.453.258-.625a.853.853 0 01.626-.259c.245 0 .454.087.626.259a.853.853 0 01.258.626V20H19v-5h-7.692a.3.3 0 00-.222.086.3.3 0 00-.086.22V20z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.745 1.17a.75.75 0 01.75.75v1.05h1.05a.75.75 0 110 1.5h-1.05v1.05a.75.75 0 01-1.5 0V4.47h-1.05a.75.75 0 010-1.5h1.05V1.92a.75.75 0 01.75-.75zm17 3a.75.75 0 01.75.75v1.05h1.05a.75.75 0 010 1.5h-1.05v1.05a.75.75 0 01-1.5 0V7.47h-1.05a.75.75 0 010-1.5h1.05V4.92a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgNewAsset = React.memo(SvgNewAsset);
export default MemoSvgNewAsset;
