/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';

const SvgGooglePlay = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30 32"
      {...props}
    >
      <path
        fill="#ffd400"
        d="M22.093 20.929c3.789-2.081 6.687-3.666 6.935-3.789.793-.421 1.61-1.536 0-2.402-.52-.272-3.319-1.808-6.935-3.789l-4.978 5.003 4.978 4.978z"
      />
      <path
        fill="#f33"
        d="M17.115 15.95L1.214 31.975c.372.05.793-.05 1.288-.322 1.04-.57 12.087-6.613 19.591-10.724l-4.978-4.978z"
      />
      <path
        fill="#48ff48"
        d="M17.115 15.95l4.978-5.003S3.616.866 2.502.272C2.081.024 1.61-.05 1.189.024L17.115 15.95z"
      />
      <path
        fill="#3bccff"
        d="M17.115 15.95L1.189.024C.545.173 0 .742 0 1.906v28.186c0 1.065.421 1.833 1.214 1.907l15.901-16.05z"
      />
    </svg>
  );
};

const MemoSvgGooglePlay = React.memo(SvgGooglePlay);
export default MemoSvgGooglePlay;
