import * as React from 'react';

const SvgUnderground = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.366 3.805a.75.75 0 00-.929 1.174l5.833 4.928-2.26.394-.107.027a.75.75 0 00.365 1.451l3.75-.654.111-.028a.75.75 0 00.51-.668l.218-3.845-.001-.112a.75.75 0 00-1.496.026l-.13 2.256-5.784-4.885-.08-.064zM2 6.25a.75.75 0 100 1.5h3.75v2.45c0 .58.47 1.05 1.05 1.05h1.95v1.95c0 .58.47 1.05 1.05 1.05h2.45v1.95c0 .58.47 1.05 1.05 1.05h1.95v1.95c0 .58.47 1.05 1.05 1.05H20a.75.75 0 000-1.5h-3.25V16.8c0-.58-.47-1.05-1.05-1.05h-1.95V13.8c0-.58-.47-1.05-1.05-1.05h-2.45V10.8c0-.58-.47-1.05-1.05-1.05H7.25V7.3c0-.58-.47-1.05-1.05-1.05H2z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgUnderground = React.memo(SvgUnderground);
export default MemoSvgUnderground;
