import * as React from 'react';

const SvgBack = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M22.276 7.609a1.333 1.333 0 10-1.886-1.886l-9.333 9.333a1.333 1.333 0 000 1.886l9.333 9.333a1.333 1.333 0 101.886-1.886l-8.391-8.391 8.391-8.39z" />
    </svg>
  );
};

const MemoSvgBack = React.memo(SvgBack);
export default MemoSvgBack;
