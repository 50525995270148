import * as React from 'react';

const SvgSort = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.944 4.71a.75.75 0 00-1.495.08l-.19 13.28-1.79-1.434-.092-.063a.75.75 0 00-.846 1.234l2.971 2.38.095.064a.75.75 0 00.837-.06l3.03-2.38.082-.075a.75.75 0 00-1.01-1.104L8.76 18.027l.19-13.216-.005-.101zm7.554-.495l-.095-.064-.1-.049a.75.75 0 00-.737.109l-3.03 2.38-.081.075a.75.75 0 00-.045.978l.075.082a.75.75 0 00.978.044l1.777-1.396-.19 13.217.006.101a.75.75 0 001.495-.08l.189-13.281 1.791 1.435.092.063a.75.75 0 00.846-1.234l-2.971-2.38z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgSort = React.memo(SvgSort);
export default MemoSvgSort;
