import * as React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.935 5.278a.75.75 0 01.085.982l-.066.078-4.903 5.077 5.146 5.328.066.078a.75.75 0 01-1.145.964l-5.11-5.29-5.109 5.29a.75.75 0 01-1.144-.964l.065-.078 5.146-5.328-4.903-5.077-.065-.078a.75.75 0 011.144-.964l4.866 5.04 4.867-5.04a.75.75 0 011.06-.018z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;
