import * as React from 'react';

const SvgCold = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.108 3.736a.736.736 0 00-1.472 0v1.023l-.685-.685-.068-.06a.67.67 0 00-.88 1.008l1.633 1.632v2.94a.736.736 0 001.473 0v-2.94l1.632-1.632.06-.069a.67.67 0 00-.06-.879l-.069-.06a.67.67 0 00-.879.06l-.684.685V3.735zm6.977 4.736a.735.735 0 10-.736-1.274l-.805.464.19-.824a.67.67 0 00-.415-.777l-.087-.026a.67.67 0 00-.777.415l-.026.087-.471 2.04-2.688 1.55a.735.735 0 10.736 1.274l2.458-1.418 2.475.572a.67.67 0 00.389-1.28l-.088-.026-1.073-.248.918-.53zm-5.079 3.995l2.077 1.197 2.245-.601a.67.67 0 01.433 1.264l-.086.03-.95.254 1.36.785a.735.735 0 11-.736 1.274l-1.343-.775.247.924a.67.67 0 01-.387.79l-.086.03a.67.67 0 01-.791-.387l-.03-.086-.593-2.217-2.096-1.208a.735.735 0 11.736-1.274zm-1.898 1.806a.736.736 0 00-1.472 0v3.084L9.94 19.053a.67.67 0 00.879 1.008l.069-.06.748-.748v.879a.736.736 0 001.473 0v-.752l.62.621c.24.24.617.26.88.06l.068-.06a.67.67 0 00.06-.879l-.06-.069-1.569-1.568v-3.212zm-2.364-1.537a.735.735 0 01-.27 1.005l-2.353 1.357-.355 2.01a.67.67 0 01-1.329-.142l.01-.09.159-.904-1.21.698a.737.737 0 01-.737-1.274l1.164-.671-1.006-.178a.67.67 0 01-.554-.685l.01-.091a.67.67 0 01.686-.553l.09.01 2.58.455 2.11-1.216a.737.737 0 011.005.269zm-4.59-5.1l-.758-.438a.737.737 0 00-.737 1.274l.824.474-.986.265-.087.03a.67.67 0 00.433 1.265l2.283-.612 2.612 1.507a.737.737 0 00.737-1.274l-2.68-1.545-.583-2.18-.03-.086a.67.67 0 00-.79-.387l-.087.03a.67.67 0 00-.387.79l.237.886z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCold = React.memo(SvgCold);
export default MemoSvgCold;
