import * as React from 'react';

const SvgTruck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1001 4C8.66416 4 7.5001 5.16406 7.5001 6.6V7H4.49427C3.93688 7 3.41966 7.29007 3.12901 7.76568L1.76357 10H1.6C0.716344 10 0 10.7164 0 11.6V15.6C0 16.4837 0.716344 17.2 1.6 17.2H3.56967C3.84054 18.3467 4.87067 19.2 6.1001 19.2C7.32954 19.2 8.35968 18.3467 8.63053 17.2H15.5697C15.8405 18.3467 16.8707 19.2 18.1001 19.2C19.3295 19.2 20.3597 18.3467 20.6305 17.2H21.1001C22.536 17.2 23.7001 16.0359 23.7001 14.6V6.6C23.7001 5.16406 22.536 4 21.1001 4H10.1001ZM20.6305 16H21.1001C21.8733 16 22.5001 15.3732 22.5001 14.6V6.6C22.5001 5.8268 21.8733 5.2 21.1001 5.2H10.1001C9.3269 5.2 8.7001 5.8268 8.7001 6.6L8.7001 7V7.6V10.6V11.2L8.7001 16H15.5697C15.8406 14.8533 16.8707 14 18.1001 14C19.3295 14 20.3596 14.8533 20.6305 16ZM7.5 16.5833V16.6H7.5001C7.5001 17.3732 6.8733 18 6.1001 18C5.3269 18 4.7001 17.3732 4.7001 16.6C4.7001 15.8268 5.3269 15.2 6.1001 15.2C6.86772 15.2 7.49104 15.8178 7.5 16.5833ZM7.5 14.4087V11.2H1.6C1.37909 11.2 1.2 11.3791 1.2 11.6V15.6C1.2 15.821 1.37909 16 1.6 16H3.56967C3.84054 14.8534 4.87067 14 6.1001 14C6.61551 14 7.0959 14.15 7.5 14.4087ZM4.15295 8.39142C4.22562 8.27252 4.35492 8.2 4.49427 8.2H7.5001V10H3.16993L4.15295 8.39142ZM16.7001 16.6C16.7001 15.8268 17.3269 15.2 18.1001 15.2C18.8733 15.2 19.5001 15.8268 19.5001 16.6C19.5001 17.3732 18.8733 18 18.1001 18C17.3269 18 16.7001 17.3732 16.7001 16.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgTruck = React.memo(SvgTruck);
export default MemoSvgTruck;
