import * as React from 'react';

const SvgRegister = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.25A1.75 1.75 0 0012.25 5v2c0 .966.784 1.75 1.75 1.75h1v1.5H4.111A1.75 1.75 0 002.361 12v1.4a.25.25 0 01-.005.05l-.85 4.204a1.75 1.75 0 001.716 2.096h17.556a1.75 1.75 0 001.715-2.096l-.85-4.204a.25.25 0 01-.004-.05V12a1.75 1.75 0 00-1.75-1.75H17v-1.5h1A1.75 1.75 0 0019.75 7V5A1.75 1.75 0 0018 3.25h-4zM13.75 5a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25V5zm-9.889 7a.25.25 0 01.25-.25H19.89a.25.25 0 01.25.25v1.4c0 .116.012.232.035.346l.849 4.205a.25.25 0 01-.245.299H3.222a.25.25 0 01-.245-.3l.85-4.204c.023-.114.034-.23.034-.346V12zM6.5 13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1A.5.5 0 008 14v-.5a.5.5 0 00-.5-.5h-1zm2.5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1A.5.5 0 019 14v-.5zm-2.5 2a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V16a.5.5 0 00-.5-.5h-1zM9 16a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V16zm5-3.25a1.75 1.75 0 00-1.75 1.75v1c0 .966.784 1.75 1.75 1.75h3a1.75 1.75 0 001.75-1.75v-1A1.75 1.75 0 0017 12.75h-3zm-.25 1.75a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-1z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRegister = React.memo(SvgRegister);
export default MemoSvgRegister;
