import React, { FC } from 'react';
import cn from 'classnames';
import NextLink from 'next/link';
import { variants, sizes, targets } from './consts';
import styles from './link.module.scss';

export interface LinkProps
  extends Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'target'
  > {
  href: string;
  target?: (typeof targets)[number];
  variant?: (typeof variants)[number];
  textsize?: (typeof sizes)[number];
  children?: React.ReactNode;
  className?: string;
  nextRoute?: boolean;
}

const [NEW_TAB_TARGET] = targets;

export const Link: FC<LinkProps> = ({
  children,
  href,
  target = '_self',
  variant = 'primary',
  textsize = 'small',
  className,
  nextRoute = false,
  ...restOfProps
}) => {
  const linkProps = {
    'data-testid': 'link',
    className: cn(styles.y2Link, styles[variant], styles[textsize], className),
    ...(target === NEW_TAB_TARGET && { rel: 'noopener' }),
    ...restOfProps,
  };
  return nextRoute ? (
    <NextLink href={href} legacyBehavior>
      <a {...linkProps}>{children}</a>
    </NextLink>
  ) : (
    <a {...linkProps} href={href}>
      {children}
    </a>
  );
};
