/* eslint-disable no-magic-numbers */
export const realEstate = {
  title: 'realestate' as const,
  titleHeb: 'נדל"ן',
  id: 2 as const,
  subCategories: {
    forSale: {
      id: 1 as const,
      title: 'forsale' as const,
      titleHeb: 'מכירה',
      commercialSubCat: 5,
    },
    rent: {
      id: 2 as const,
      title: 'rent' as const,
      titleHeb: 'השכרה',
      commercialSubCat: 6,
    },
    roommates: {
      id: 3 as const,
      title: 'roommates' as const,
      titleHeb: 'שותפים',
    },
    commercial: {
      id: 4 as const,
      title: 'commercial' as const,
      titleHeb: 'מסחרי',
      commercialSubCat: 7,
    },
    brokerageSales: {
      id: 5 as const,
      title: 'brokerage-sales' as const,
      titleHeb: 'תיווך מכירה',
    },
    realtyRent: {
      id: 6 as const,
      title: 'realty-rent' as const,
      titleHeb: 'תיווך השכרה',
    },
    tradingBrokerage: {
      id: 7 as const,
      title: 'trading-brokerage' as const,
      titleHeb: 'תיווך מסחר',
    },
    kones: {
      id: 8 as const,
      title: 'kones' as const,
      titleHeb: 'כונס נכסים',
    },
    yad1Listings: {
      id: 98 as const,
      title: 'newprojects' as const,
      titleHeb: 'דירות חדשות',
    },
    yad1: {
      id: 99 as const,
      title: 'yad1' as const,
      titleHeb: 'יד 1',
    },
  },
};
