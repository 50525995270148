import { IconButton } from '../with-icon';
import { ButtonProps, Size } from '../../base';
import styles from './with-loader.module.scss';
import { Spinner } from '../../../animation';
import cn from 'classnames';

export type ButtonWithLoaderProps = ButtonProps & {
  loading: boolean;
  side?: 'right' | 'left';
};

const defaultSize: Size = 'small';

const getIconSizeClass = (buttonProps: ButtonProps) => {
  const { mobileOnlySize, desktopOnlySize, height } = buttonProps;
  const noStylePerDevice = !mobileOnlySize && !desktopOnlySize;
  const size = height || defaultSize;

  return [
    mobileOnlySize && styles[`mobile-loader-${mobileOnlySize}`],
    desktopOnlySize && styles[`desktop-loader-${desktopOnlySize}`],
    noStylePerDevice && styles[`default-loader-${size}`],
  ];
};

const getIconColorClass = (buttonProps: ButtonProps) => {
  if (buttonProps.disabled) {
    return [styles.alwaysOrange];
  }
  const { mobileOnlyVariant, desktopOnlyVariant, variant } = buttonProps;
  const noStylePerDevice = !mobileOnlyVariant && !desktopOnlyVariant;

  return [
    mobileOnlyVariant && styles[`mobile-loader-color-${mobileOnlyVariant}`],
    desktopOnlyVariant && styles[`desktop-loader-color-${desktopOnlyVariant}`],
    noStylePerDevice && styles[`default-loader-color-${variant}`],
  ];
};

export const ButtonWithLoader = ({
  loading,
  side = 'right',
  children,
  ...buttonProps
}: ButtonWithLoaderProps): JSX.Element => {
  return (
    <IconButton
      {...buttonProps}
      iconPosition={side}
      hasAdjustedPadding={loading}
      iconSlot={
        loading && (
          <Spinner
            className={cn(
              styles.spinner,
              ...getIconColorClass(buttonProps),
              ...getIconSizeClass(buttonProps),
            )}
            direction={'loop'}
            data-testid="spinner"
          />
        )
      }
    >
      {children}
    </IconButton>
  );
};
