import styles from './sub-category-list.module.scss';
import { getCollectionLink } from '../../find-collection';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';

type Props = {
  url: string;
  title: string;
  subItems?: MenuItem[];
};

export const SubCategoryList = ({ url, title, subItems }: Props) => {
  const items = Array.isArray(subItems) ? subItems : [];

  return (
    <ul className={styles.list} data-testid="sub-category-list">
      <li>
        <a href={url} className={styles.header}>
          {title}
        </a>
      </li>
      {items.map((subItem) => (
        <li key={subItem.id} className={styles.item}>
          <a href={getCollectionLink(subItem)} className={styles.item}>
            {subItem.title}
          </a>
        </li>
      ))}
    </ul>
  );
};
