import * as React from 'react';

const SvgAlertinactive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55 15.542c.043-.14.086-.298.128-.475a2.223 2.223 0 001.473.428 9.46 9.46 0 01-.2.694h10.04l-.009-.027c-.29-.898-.555-2.377-.555-4.81a4.28 4.28 0 00-1.297-3.06A4.479 4.479 0 0012 7.023c-.49 0-.97.079-1.425.23a1.533 1.533 0 01-1.281-1.095 6.059 6.059 0 011.842-.574V4.75c0-.414.344-.75.767-.75.424 0 .767.336.767.75v.81a6.016 6.016 0 013.545 1.67 5.764 5.764 0 011.746 4.122c0 2.339.255 3.653.484 4.356.113.35.217.54.274.629.02.03.034.048.04.056a.738.738 0 01.197.795.766.766 0 01-.723.5h-3.31a.731.731 0 01-.03.197 2.944 2.944 0 01-1.083 1.527 3.05 3.05 0 01-1.803.588 3.05 3.05 0 01-1.802-.588c-.52-.381-.9-.917-1.083-1.527a.736.736 0 01-.031-.196H5.767a.769.769 0 01-.69-.422.736.736 0 01.087-.793c.015-.023.043-.07.08-.143.076-.146.19-.4.307-.79zm5.133 2.147c.103.205.254.385.442.523a1.493 1.493 0 001.764 0c.189-.138.34-.318.442-.523h-2.648zM7 13.5a.75.75 0 01-.75-.75v-2h-2a.75.75 0 010-1.5h2v-2a.75.75 0 011.5 0v2h2a.75.75 0 010 1.5h-2v2a.75.75 0 01-.75.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAlertinactive = React.memo(SvgAlertinactive);
export default MemoSvgAlertinactive;
