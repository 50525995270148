import * as React from 'react';
const SvgNadlanIndex = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 2.25A.75.75 0 003 3v16c0 .414.336.75.75.75h16a.75.75 0 000-1.5H4.5V3a.75.75 0 00-.75-.75zm15.919 3.09a.75.75 0 01-.007.013l-3.863 7.242a1.05 1.05 0 01-1.396.445l-3.16-1.58-3.129 4.47a.75.75 0 11-1.228-.86l3.351-4.788a1.05 1.05 0 011.33-.337l3.115 1.557 3.624-6.794-1.585.608a.75.75 0 01-.538-1.4l3.2-1.228a.752.752 0 01.394-.074.75.75 0 01.671.571l1.22 3.175a.75.75 0 01-1.401.538l-.598-1.559z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgNadlanIndex = React.memo(SvgNadlanIndex);
export default MemoSvgNadlanIndex;
