import * as React from 'react';
const SvgSparks = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path d="M5.878 7.595c-.196-.083-.306-.232-.384-.433-.31-.808-.87-1.367-1.681-1.668-.228-.083-.39-.218-.402-.476-.013-.231.118-.406.371-.498A2.819 2.819 0 005.31 3.236c.088-.148.153-.31.214-.476.088-.231.258-.358.476-.358.219 0 .38.123.472.358.319.852.895 1.442 1.747 1.752a.638.638 0 01.37.358v.262c-.047.196-.178.31-.366.38-.825.31-1.397.877-1.707 1.707-.075.197-.197.332-.41.367-.075.009-.15.009-.228.009zM11.737 17.597c-.424-.179-.66-.5-.83-.933-.668-1.744-1.875-2.95-3.628-3.6-.49-.18-.839-.472-.867-1.028-.028-.5.255-.876.801-1.074 1.433-.519 2.536-1.442 3.299-2.771.188-.32.33-.67.462-1.028.188-.5.556-.772 1.027-.772.471 0 .82.264 1.018.772.688 1.838 1.932 3.11 3.77 3.78.396.141.631.415.801.773v.565c-.104.424-.386.67-.791.82-1.782.67-3.016 1.895-3.686 3.685-.16.425-.424.717-.886.792-.16.019-.32.019-.49.019z" />
      </g>
    </svg>
  );
};
const MemoSvgSparks = React.memo(SvgSparks);
export default MemoSvgSparks;
