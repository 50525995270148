import * as React from 'react';

const SvgPlay = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 6.822v10.356a1 1 0 001.537.844l8.137-5.178a1 1 0 000-1.688L8.537 5.978A1 1 0 007 6.822z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPlay = React.memo(SvgPlay);
export default MemoSvgPlay;
