import * as React from 'react';

const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 11.25a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.102-1.493l.102-.007h13zm0 6a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.102-1.493l.102-.007h13zm0-12a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.102-1.493L5.5 5.25h13z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMenu = React.memo(SvgMenu);
export default MemoSvgMenu;
