import * as React from 'react';

const SvgDiamondBlue = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        d="M9.704 0a1.3 1.3 0 011.082.579l1.996 2.993a1.3 1.3 0 01.218.722v.473a1.3 1.3 0 01-.26.78L7.537 12.48a1.298 1.298 0 01-2.076 0L.26 5.547a1.3 1.3 0 01-.26-.78v-.473a1.3 1.3 0 01.218-.722L2.214.58A1.3 1.3 0 013.296 0h6.408zM7.76 5.2H5.24l1.26 5.036L7.76 5.2zm-3.85 0H1.624l3.413 4.55-1.098-4.395a1.3 1.3 0 01-.03-.155zm7.464 0H9.09a1.3 1.3 0 01-.029.155l-1.1 4.393L11.374 5.2zM5.2 1.299L3.296 1.3 1.56 3.9h2.376a1.3 1.3 0 01.1-.274L5.2 1.299zm1.3.307L5.353 3.9h2.294L6.5 1.606zM9.704 1.3L7.8 1.299l1.164 2.327a1.3 1.3 0 01.1.274h2.375L9.704 1.3z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDiamondBlue = React.memo(SvgDiamondBlue);
export default MemoSvgDiamondBlue;
