import * as React from 'react';

const SvgSecurityCam = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.808 10.217l11.236-4.678a.5.5 0 01.653.267l.784 1.852a.5.5 0 01-.266.656L6.848 12.7a.5.5 0 01-.538-.097l-1.653-1.562a.5.5 0 01.151-.825zm-1.181 1.915a2 2 0 01.605-3.3l11.235-4.678a2 2 0 012.611 1.067l.784 1.853a2 2 0 01-1.062 2.62l-4.749 2.01a3.21 3.21 0 00.061.79c.154.759.892 1.56 2.002 2.14 1.094.57 2.33.806 3.209.628a.772.772 0 01.065-.01v-1.986a.75.75 0 111.5 0v5.5a.75.75 0 01-1.5 0v-1.994c-1.267.184-2.749-.172-3.968-.808-1.28-.668-2.493-1.766-2.778-3.172a4.747 4.747 0 01-.07-.461l-4.14 1.751a2 2 0 01-2.152-.388l-1.653-1.562z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgSecurityCam = React.memo(SvgSecurityCam);
export default MemoSvgSecurityCam;
