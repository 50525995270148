import * as React from 'react';
const SvgElevator = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.98a2.75 2.75 0 00-2.75 2.75v11c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-13a.75.75 0 00-.75-.75H8zM6.75 9.73c0-.691.56-1.25 1.25-1.25h3.25v11.5h-4.5V9.73z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.98a.75.75 0 00-.75.75v13c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-11A2.75 2.75 0 0016 6.98h-4zm.75 1.5H16c.69 0 1.25.559 1.25 1.25v10.25h-4.5V8.48zM17.088 3.793l-.007-.009-.037-.038-1.802-1.613a.536.536 0 00-.65-.043l-.01.006-.041.032-1.78 1.615a.483.483 0 00.003.724.536.536 0 00.652.043l.008-.006.042-.033 1.425-1.293 1.438 1.286c.2.179.51.178.71-.002a.482.482 0 00.049-.67z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.158 3.72l.007.009a.582.582 0 01-.06.807.635.635 0 01-.843.003l-1.37-1.227-1.364 1.238-.048.037-.009.006a.636.636 0 01-.773-.052.583.583 0 01-.004-.872l1.785-1.62.047-.036.01-.006a.636.636 0 01.773.052l1.807 1.618.042.043zm-2.267-.542l1.438 1.286c.2.179.51.178.71-.002a.482.482 0 00.049-.67l-.007-.008-.037-.038-1.802-1.613a.536.536 0 00-.65-.043l-.01.006-.041.032-1.78 1.615a.483.483 0 00.003.724.536.536 0 00.652.043l.008-.006.042-.033 1.425-1.293zM10.488 2.807l-.007.009-.037.038-1.802 1.613a.536.536 0 01-.65.043l-.01-.006-.041-.033-1.78-1.614a.483.483 0 01.003-.724.536.536 0 01.651-.043l.01.006.04.033 1.426 1.293L9.73 2.136c.2-.18.51-.178.71.002a.482.482 0 01.049.67z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.558 2.88l.007-.009a.582.582 0 00-.06-.807.635.635 0 00-.843-.003l-1.37 1.227L6.928 2.05l-.048-.037-.009-.006a.636.636 0 00-.773.052.583.583 0 00-.004.872L7.88 4.55l.047.036.01.006a.636.636 0 00.773-.052l1.807-1.618.042-.043zm-2.267.542L9.73 2.136c.2-.18.51-.178.71.002a.482.482 0 01.049.67l-.007.008-.037.038-1.802 1.613a.536.536 0 01-.65.043l-.01-.006-.041-.033-1.78-1.614a.483.483 0 01.003-.724.536.536 0 01.651-.043l.01.006.04.033 1.426 1.293z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgElevator = React.memo(SvgElevator);
export default MemoSvgElevator;
