import * as React from 'react';

const SvgTime = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25 2.25c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm-.25 3a.75.75 0 01.743.648l.007.102v4.711l2.441 2.698a.75.75 0 01-1.112 1.006L11.25 12.79V7.5a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgTime = React.memo(SvgTime);
export default MemoSvgTime;
