import texts from './texts.json';
import { ErrorSection, Props as TemplateProps } from '../template';
import {
  ErrorHeroIllustration,
  NoConnectionHeroIllustration,
  AbroadHeroIllustration,
  PageFailedHeroIllustration,
  AntibotHeroIllustration,
  PageUnauthorizedHeroIllustration,
  PageForbiddenHeroIllustration,
} from '@y2/mango/components/illustrations/hero';

type Props = {
  title?: TemplateProps['title'];
  subTitle?: TemplateProps['subTitle'];
  buttonText?: TemplateProps['buttonText'];
  info?: TemplateProps['info'];
  customAction?: TemplateProps['customAction'];
};

export const STATUS = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  gone: 410,
  internalError: 500,
};

export const UnauthorizedErrorSection = (props?: Props) => {
  const text = { ...texts['401'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      info={text.info}
      illustration={<PageUnauthorizedHeroIllustration />}
      buttonText={text.buttonText}
      action="loginOnClick"
      customAction={props?.customAction}
    />
  );
};

export const BadRequestErrorSection = (props?: Props) => {
  const text = { ...texts['400'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      illustration={<PageFailedHeroIllustration />}
      buttonText={text.buttonText}
      customAction={props?.customAction}
    />
  );
};

export const ForbiddenErrorSection = (props?: Props) => {
  const text = { ...texts['403'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      illustration={<PageForbiddenHeroIllustration />}
      buttonText={text.buttonText}
      action="redirectOnClick"
      customAction={props?.customAction}
    />
  );
};

export const NotFoundErrorSection = (props?: Props) => {
  const text = { ...texts['404'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      buttonText={text.buttonText}
      illustration={<NoConnectionHeroIllustration />}
      customAction={props?.customAction}
    />
  );
};

export const RemovedPageErrorSection = (props?: Props) => {
  const text = { ...texts['410'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle || ''}
      buttonText={text.buttonText}
      illustration={<NoConnectionHeroIllustration />}
      customAction={props?.customAction}
    />
  );
};

export const ServerErrorSection = (props?: Props) => {
  const text = { ...texts['500'], ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      buttonText={text.buttonText}
      illustration={<ErrorHeroIllustration />}
      action="refreshOnClick"
      customAction={props?.customAction}
    />
  );
};

export const OldBrowserErrorSection = (props?: Props) => {
  const text = { ...texts.oldBrowser, ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      illustration={<PageFailedHeroIllustration />}
      customAction={props?.customAction}
    />
  );
};

export const AbroadErrorSection = (props?: Props) => {
  const text = { ...texts.Abroad, ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      info={text.info}
      illustration={<AbroadHeroIllustration />}
      customAction={props?.customAction}
    />
  );
};

export const RobotErrorSection = (props?: Props) => {
  const text = { ...texts.Robot, ...(props || {}) };
  return (
    <ErrorSection
      title={text.title}
      subTitle={text.subTitle}
      illustration={<AntibotHeroIllustration />}
      customAction={props?.customAction}
    />
  );
};
