/* eslint-disable custom-rules/use-current-color */
import { SVGProps, VFC, memo } from 'react';
import * as React from 'react';

const SvgBounceBlue: VFC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="136"
    height="136"
    viewBox="0 0 136 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.05"
      d="M68.001 121.267C88.0306 121.267 104.268 117.715 104.268 113.333C104.268 108.952 88.0306 105.4 68.001 105.4C47.9715 105.4 31.7344 108.952 31.7344 113.333C31.7344 117.715 47.9715 121.267 68.001 121.267Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.9251 114.467C47.3471 114.467 43.6016 110.754 43.6016 106.216V29.7835C43.6016 25.2466 47.3471 21.5333 51.9251 21.5333H84.068C88.646 21.5333 92.3916 25.2466 92.3916 29.7835V106.216C92.3916 110.754 88.646 114.467 84.068 114.467H51.9251Z"
      fill="#EEEEEE"
      stroke="#363636"
      strokeWidth="1.13333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9409 21.5333C51.3683 21.5333 47.6271 25.2466 47.6271 29.7835V106.216C47.6271 110.754 51.3683 114.467 55.9409 114.467H51.4076C46.835 114.467 43.0938 110.754 43.0938 106.216V29.7835C43.0938 25.2466 46.835 21.5333 51.4076 21.5333H55.9409Z"
      fill="currentColor"
      fillOpacity="0.1"
    />
    <path
      d="M84.4445 31.9883H51.5576C49.4116 31.9883 47.6719 33.728 47.6719 35.874V96.6409C47.6719 98.7869 49.4116 100.527 51.5576 100.527H84.4445C86.5905 100.527 88.3302 98.7869 88.3302 96.6409V35.874C88.3302 33.728 86.5905 31.9883 84.4445 31.9883Z"
      fill="white"
      stroke="#363636"
      strokeWidth="1.13333"
    />
    <path
      d="M54.4016 31.7333C52.0296 31.7333 50.1068 33.502 50.1068 35.6838V96.9162C50.1068 99.0979 52.0296 100.867 54.4016 100.867H51.8963C49.5244 100.867 47.6016 99.0979 47.6016 96.9162V35.6838C47.6016 33.502 49.5244 31.7333 51.8963 31.7333H54.4016Z"
      fill="currentColor"
      fillOpacity="0.1"
    />
    <path
      d="M68.0013 109.933C69.5661 109.933 70.8346 108.665 70.8346 107.1C70.8346 105.535 69.5661 104.267 68.0013 104.267C66.4365 104.267 65.168 105.535 65.168 107.1C65.168 108.665 66.4365 109.933 68.0013 109.933Z"
      fill="#DADADA"
      stroke="#363636"
      strokeWidth="1.13333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0312 21.5333H77.9412V22.9596C77.9412 24.7381 76.4994 26.18 74.7209 26.18H60.2516C58.4731 26.18 57.0312 24.7381 57.0312 22.9596V21.5333Z"
      fill="#DADADA"
      stroke="#363636"
      strokeWidth="1.13333"
    />
    <rect
      x="28.5667"
      y="46.5667"
      width="54.8667"
      height="18.8667"
      rx="3.96667"
      fill="#EBF7FF"
    />
    <path
      d="M62.2157 51C62.4918 51 62.7157 51.2239 62.7157 51.5C62.7157 51.7455 62.5388 51.9496 62.3056 51.9919L62.2157 52H33.5C33.2239 52 33 51.7761 33 51.5C33 51.2545 33.1769 51.0504 33.4101 51.0081L33.5 51H62.2157Z"
      fill="url(#paint0_linear_574_6090)"
    />
    <path
      d="M62.2629 55.4445C62.5391 55.4445 62.7629 55.6683 62.7629 55.9445C62.7629 56.1899 62.5861 56.3941 62.3528 56.4364L62.2629 56.4445H43.4492C43.1731 56.4445 42.9492 56.2206 42.9492 55.9445C42.9492 55.699 43.1261 55.4948 43.3593 55.4525L43.4492 55.4445H62.2629Z"
      fill="url(#paint1_linear_574_6090)"
    />
    <path
      d="M62.2662 59.8889C62.5424 59.8889 62.7662 60.1128 62.7662 60.3889C62.7662 60.6344 62.5894 60.8385 62.3561 60.8809L62.2662 60.8889H38.8594C38.5832 60.8889 38.3594 60.6651 38.3594 60.3889C38.3594 60.1435 38.5363 59.9393 38.7695 59.897L38.8594 59.8889H62.2662Z"
      fill="url(#paint2_linear_574_6090)"
    />
    <rect
      x="65"
      y="50"
      width="15"
      height="12"
      rx="2"
      fill="url(#paint3_linear_574_6090)"
    />
    <rect
      x="28.5667"
      y="46.5667"
      width="54.8667"
      height="18.8667"
      rx="3.96667"
      stroke="#0495FF"
      strokeWidth="1.13333"
    />
    <path
      d="M29.7942 35.4955C29.592 35.3693 29.3463 35.3476 29.1236 35.3965C28.9015 35.4452 28.6926 35.5661 28.5735 35.7621C28.4902 35.8993 28.4594 36.0713 28.4882 36.2683L30.2297 43.0476C30.2961 43.3024 30.4248 43.4887 30.598 43.5968C30.8003 43.7231 31.0459 43.745 31.2685 43.6961C31.4904 43.6474 31.699 43.5265 31.8178 43.3307C31.9012 43.1933 31.9319 43.0212 31.903 42.8239L30.1613 36.0438C30.0953 35.7894 29.967 35.6035 29.7942 35.4955Z"
      fill="#DCDCDC"
    />
    <path
      d="M24.1326 48.9912L17.1322 48.925C16.8752 48.9229 16.6714 48.9992 16.5343 49.1383C16.3717 49.3033 16.3033 49.5378 16.3099 49.7675C16.3163 49.9929 16.3955 50.2233 16.566 50.3869C16.688 50.504 16.8557 50.5787 17.0609 50.6022L24.0625 50.6682C24.3204 50.6704 24.5245 50.5956 24.6614 50.4574C24.8227 50.2946 24.8893 50.0626 24.8828 49.8353C24.8763 49.6072 24.7956 49.3739 24.624 49.2079C24.5025 49.0905 24.336 49.0149 24.1326 48.9912Z"
      fill="#DCDCDC"
    />
    <path
      d="M21.7894 40.4661L21.6864 40.4656C21.4477 40.4825 21.2373 40.6132 21.089 40.7899C20.9415 40.9657 20.8495 41.1941 20.8746 41.431C20.8925 41.6 20.9747 41.7666 21.122 41.9183L25.9634 45.9893C26.1705 46.1631 26.3866 46.2406 26.5908 46.2262C26.8295 46.2093 27.0399 46.0786 27.1882 45.9019C27.3357 45.7261 27.4277 45.4977 27.4026 45.2608C27.3847 45.0919 27.3025 44.9252 27.1552 44.7735L22.3138 40.7025C22.1067 40.5288 21.8906 40.4512 21.6864 40.4656L21.7894 40.4661Z"
      fill="#DCDCDC"
    />
    <rect
      x="57.2346"
      y="69.5667"
      width="54.8667"
      height="18.8667"
      rx="3.96667"
      fill="#F8F8F8"
    />
    <rect
      x="57.2346"
      y="69.5667"
      width="54.8667"
      height="18.8667"
      rx="3.96667"
      stroke="#363636"
      strokeWidth="1.13333"
    />
    <defs>
      <linearGradient
        id="paint0_linear_574_6090"
        x1="47.8578"
        y1="50.5"
        x2="47.7906"
        y2="52.4977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CD9E5" />
        <stop offset="1" stopColor="#0495FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_574_6090"
        x1="52.8561"
        y1="54.9445"
        x2="52.7554"
        y2="56.9394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CD9E5" />
        <stop offset="1" stopColor="#0495FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_574_6090"
        x1="50.5628"
        y1="59.3889"
        x2="50.481"
        y2="61.3856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CD9E5" />
        <stop offset="1" stopColor="#0495FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_574_6090"
        x1="72.5"
        y1="44"
        x2="60.7927"
        y2="58.6341"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CD9E5" />
        <stop offset="1" stopColor="#0495FF" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgBounceBlue);
export default Memo;
