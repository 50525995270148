import * as React from 'react';
const SvgInstagram = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.622c2.403 0 2.688.01 3.637.053 2.44.11 3.579 1.268 3.69 3.689.043.949.051 1.234.051 3.637 0 2.404-.009 2.688-.051 3.636-.112 2.42-1.248 3.579-3.69 3.69-.949.043-1.232.052-3.637.052-2.403 0-2.688-.009-3.637-.052-2.445-.112-3.578-1.275-3.689-3.69-.043-.949-.052-1.233-.052-3.637 0-2.403.01-2.687.052-3.637.112-2.42 1.248-3.578 3.69-3.689.949-.043 1.233-.052 3.636-.052zM9 0C6.556 0 6.25.01 5.29.054 2.02.204.205 2.018.055 5.289.01 6.249 0 6.556 0 9s.01 2.751.054 3.711c.15 3.268 1.964 5.085 5.235 5.235.96.043 1.267.054 3.711.054s2.751-.01 3.711-.054c3.265-.15 5.086-1.964 5.234-5.235.044-.96.055-1.267.055-3.711s-.01-2.75-.054-3.71C17.799 2.024 15.983.205 12.712.055 11.75.01 11.444 0 9 0zm0 4.378a4.622 4.622 0 100 9.244 4.622 4.622 0 000-9.244zM9 12a3 3 0 110-6 3 3 0 010 6zm4.805-8.884a1.08 1.08 0 10-.001 2.161 1.08 1.08 0 000-2.16z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgInstagram = React.memo(SvgInstagram);
export default MemoSvgInstagram;
