const Sentry = require('@sentry/nextjs');

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const COMMIT = process.env.NEXT_PUBLIC_COMMIT_HASH;
const MSG_MAX_LENGTH = 10000;
const MAX_TITLE_CHARS = 70;
const CLOSING_DELAY = 2000;
const RUN_FOR_ENVS = ['production', 'prod', 'staging'];
const IS_BROWSER = typeof window !== 'undefined';
const LOG_LIB_PREFIX = '(@y2/log report) - ';

const OWNERS = {
  AMERICA: 'america',
  CODE_OF_DUTY: 'code_of_duty',
  STORM: 'storm',
  VENOM: 'venom',
  DEADPOOL: 'deadpool',
  MAGNETO: 'magneto',
  PHOENIX: 'phoenix',
  ROCKET: 'rocket',
  MIPO: 'mipo',
};

function validateOwner(owner) {
  const ownerNames = Object.values(OWNERS);
  if (!ownerNames.includes(owner)) {
    throw Error(
      'owner name given to sentry is not in the OWNERS array list, check sentry.config',
    );
  }
}

if (IS_BROWSER) {
  window.COMMIT_HASH = process.env.NEXT_PUBLIC_COMMIT_HASH;
}

function addEventBeforeExit(event) {
  if (IS_BROWSER) {
    window.addEventListener('beforeunload', event);
  } else {
    process.on('exit', event);
  }
}

function getLogErrorPrefix(chunks) {
  if (!chunks || !chunks.length) return null;

  for (const chunk of chunks) {
    const errMessage = String(chunk.value);
    if (errMessage.includes(LOG_LIB_PREFIX)) {
      return errMessage;
    }
  }

  return null;
}

function getTransactionTitle(appName, prefixMessage) {
  let message = appName + ' - ' + prefixMessage.replace(LOG_LIB_PREFIX, '');
  if (message.length > MAX_TITLE_CHARS) {
    message = message.substring(0, MAX_TITLE_CHARS) + '...';
  }

  return message.trim();
}

/**
 * @param {string} environment - 'production' | 'prod' | 'staging' | 'dev' ...
 * @param {string} appName - 'personal' | 'price-list' | 'kones' ...
 * @param {string} owner - one from the exported OWNERS object..
 */
function initSentry(environment, appName, owner) {
  validateOwner(owner);
  const enabled = RUN_FOR_ENVS.includes(environment);
  if (!enabled) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    enabled,
    environment,
    tracesSampleRate: 1.0,
    maxValueLength: MSG_MAX_LENGTH,
    release: `${appName}-${COMMIT}`,
    beforeSend(event) {
      const exception = (event && event.exception) || {};
      const chunks = exception.values || [exception];
      const logErrorPrefix = getLogErrorPrefix(chunks);
      if (IS_BROWSER && !logErrorPrefix) {
        // avoid sending errors outside the log.error method, in the browser they could be from extensions or 3rd party scripts
        return null;
      }
      event.transaction = getTransactionTitle(appName, logErrorPrefix);

      return event;
    },
  });

  const ownerFullName = `y2_${owner}`;
  Sentry.configureScope((scope) => {
    scope.setTag('app', appName);
    scope.setTag('environment', environment);
    scope.setTag('owner', ownerFullName);
    scope.setTag('commit_hash', COMMIT);
    scope.setTag('platform', IS_BROWSER ? 'browser' : 'server');
  });

  addEventBeforeExit(async () => {
    await Sentry.close(CLOSING_DELAY);
  });

  if (IS_BROWSER) {
    window.__LOG_MONITOR = true;
  } else {
    console.log(
      '---- Sentry is running for ' + appName + ' on the server side ----',
    );
  }
}

module.exports = {
  initSentry,
  OWNERS,
};
