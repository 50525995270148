import * as React from 'react';

const SvgEmail = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6a.75.75 0 00-.75.75v10c0 .414.336.75.75.75h14a.75.75 0 00.75-.75v-10A.75.75 0 0019 6H5zm.75 2.466V16h12.5V8.466l-4.448 3.421a2.955 2.955 0 01-3.604 0L5.75 8.466zM17.046 7.5H6.955l4.158 3.198a1.455 1.455 0 001.774 0L17.046 7.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgEmail = React.memo(SvgEmail);
export default MemoSvgEmail;
