import * as React from 'react';

const SvgRented = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 2.69a5.75 5.75 0 00-7.782 7.98L5.12 18.42a1.75 1.75 0 00.64 2.39l1.732 1a1.75 1.75 0 002.39-.64l.126-.217 1.082.625a1.75 1.75 0 002.39-.64l1-1.733a1.75 1.75 0 00-.64-2.39l-1.082-.625 1.6-2.77A5.75 5.75 0 0017.375 2.69zM10.82 5.545a4.25 4.25 0 113.205 6.35l-.488-.055-.245.425-2.208 3.824-.375.65.65.375 1.731 1a.25.25 0 01.092.341l-1 1.732a.25.25 0 01-.342.092l-1.732-1-.65-.375-.374.65-.5.865a.25.25 0 01-.342.092l-1.732-1a.25.25 0 01-.091-.342l4.708-8.154.245-.425-.291-.395a4.251 4.251 0 01-.26-4.65zM13.5 9.402a2 2 0 102-3.464 2 2 0 00-2 3.464z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRented = React.memo(SvgRented);
export default MemoSvgRented;
