import * as React from 'react';
const SvgFacebook = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.32 20.5H9.773v-8.501H8v-2.93h1.773V7.312c0-2.39 1-3.811 3.844-3.811h2.367v2.93h-1.48c-1.107 0-1.18.41-1.18 1.174L13.32 9.07H16l-.314 2.929H13.32V20.5z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgFacebook = React.memo(SvgFacebook);
export default MemoSvgFacebook;
