import { useEffect } from 'react';
import {
  cohortKey,
  domain,
  cohortGroups,
  groupKeys,
  cohortGroupCookieKey,
  CohortGroupKey,
} from './consts';
import { cookie } from '@y2/packages-utils/cookies';
import { getCohortGroupValue } from './helpers';

const expiresInMonths = 6;
const range = { from: 1, to: 100 };
const millisecToSec = 1000;
const defaultCohortGroupKey: CohortGroupKey = 'D';

const createCohortValue = () =>
  Math.floor(Math.random() * range.to) + range.from;

const getCohortGroupKey = (cohort: number) => {
  const cohortGroupValue = getCohortGroupValue(cohort);

  return (
    groupKeys.find((key) => cohortGroups[key] === cohortGroupValue) ||
    defaultCohortGroupKey
  );
};

const getExpirationDate = () => {
  const expiresDate = new Date();
  expiresDate.setMonth(expiresDate.getMonth() + expiresInMonths);

  return expiresDate;
};

const setCohortGroupCookie = (cohortGroupKey: CohortGroupKey) => {
  const expiresDate = getExpirationDate();
  cookie.set(cohortGroupCookieKey, cohortGroupKey, {
    maxAge: (expiresDate.getTime() - Date.now()) / millisecToSec,
    domain: domain,
    path: '/',
  });
};

const setCohortCookie = () => {
  const newCohort = createCohortValue();
  const expiresDate = getExpirationDate();
  const cohortGroupKey = getCohortGroupKey(newCohort);

  cookie.set(cohortKey, newCohort, {
    maxAge: (expiresDate.getTime() - Date.now()) / millisecToSec,
    domain: domain,
    path: '/',
  });

  setCohortGroupCookie(cohortGroupKey);
};

const updateCohortGroupCookie = (cohort: number) => {
  const currentCohortGroup = cookie.get(cohortGroupCookieKey);
  const cohortGroupKey = getCohortGroupKey(cohort);
  if (currentCohortGroup !== cohortGroupKey) {
    setCohortGroupCookie(cohortGroupKey);
  }
};

/**
 * requires for every app, will setup a cohort cookie for the user's browser if there isn't one
 */
export const CohortSetup = () => {
  useEffect(() => {
    const cohort = cookie.get(cohortKey);
    if (!cohort) {
      setCohortCookie();
    } else {
      updateCohortGroupCookie(parseInt(cohort));
    }
  }, []);

  return null;
};
