import {
  LazyLoadLottie,
  Props as LazyLottieProps,
} from '../conditional-lazy-loader';

type Props = Omit<LazyLottieProps, 'fetchAnimationData'>;

const fetchAnimationData = () => import('./calculator.json');

export const Calculator = (props: Props) => (
  <LazyLoadLottie fetchAnimationData={fetchAnimationData} {...props} />
);
