import * as React from 'react';
const SvgNoteBoard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 4.5H17V5a1 1 0 01-1 1H8a1 1 0 01-1-1v-.5H5.5v16h13v-16zM19 3h-2a1 1 0 00-1-1H8a1 1 0 00-1 1H5a1 1 0 00-1 1v17a1 1 0 001 1h14a1 1 0 001-1V4a1 1 0 00-1-1zM8.25 9a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zm2.813 3.5a.75.75 0 000 1.5h4.687a.75.75 0 000-1.5h-4.688zM7.5 16.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM15.5 3h-7v2h7V3z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgNoteBoard = React.memo(SvgNoteBoard);
export default MemoSvgNoteBoard;
