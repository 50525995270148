import * as React from 'react';
const SvgLinkedin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35 5.675H.87v11.198h3.48V5.675zm.277-3.548a2.017 2.017 0 10-4.033-.003 2.017 2.017 0 004.033.003zm5.245 3.548H6.534v11.198h3.479v-5.54c0-1.46.276-2.875 2.087-2.875 1.786 0 1.809 1.67 1.809 2.97v5.445h3.478V10.73c0-3.015-.65-5.334-4.174-5.334-1.693 0-2.829.928-3.293 1.809h-.048V5.675z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgLinkedin = React.memo(SvgLinkedin);
export default MemoSvgLinkedin;
