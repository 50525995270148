import * as React from 'react';
const SvgDateCalender = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 3.223a.75.75 0 00-.75.75v.604h-.8a2.7 2.7 0 00-2.7 2.7v10.8a2.7 2.7 0 002.7 2.7h12.6a2.7 2.7 0 002.7-2.7v-10.8a2.7 2.7 0 00-2.7-2.7h-.8v-.604a.75.75 0 00-1.5 0v.604h-3.25v-.604a.75.75 0 00-1.5 0v.604H8v-.604a.75.75 0 00-.75-.75zM16 6.077h-3.25v.446a.75.75 0 01-1.5 0v-.446H8v.446a.75.75 0 01-1.5 0v-.446h-.8a1.2 1.2 0 00-1.2 1.2v1.807h15V7.277a1.2 1.2 0 00-1.2-1.2h-.8v.446a.75.75 0 01-1.5 0v-.446zm-11.5 12v-7.493h15v7.493a1.2 1.2 0 01-1.2 1.2H5.7a1.2 1.2 0 01-1.2-1.2z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDateCalender = React.memo(SvgDateCalender);
export default MemoSvgDateCalender;
