import * as React from 'react';
const SvgCenteredImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.295 6V4.5a1.5 1.5 0 011.5-1.5h1.5M3.295 12v1.5a1.5 1.5 0 001.5 1.5h1.5M12.295 3h1.5a1.5 1.5 0 011.5 1.5V6M12.295 15h1.5a1.5 1.5 0 001.5-1.5V12M6.295 7.5a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5h-3a1.5 1.5 0 01-1.5-1.5v-3z"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const MemoSvgCenteredImage = React.memo(SvgCenteredImage);
export default MemoSvgCenteredImage;
