import * as React from 'react';

const SvgUnit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.695 4.014c0-.969-.769-1.764-1.73-1.764h-8.038c-.962 0-1.732.795-1.732 1.764V8.25H4A2.75 2.75 0 001.25 11v10.5a.75.75 0 001.5 0v-2.069h7.445v1.319h-.75a.75.75 0 000 1.5h13a.75.75 0 000-1.5h-.75V4.014zm-9.768-.264h8.037c.122 0 .231.113.231.264V20.75h-8.5V4.014c0-.151.11-.264.232-.264zm-1.732 14.181v-1.5H2.75v1.5h7.445zM4 9.75h6.195l.001 2.674a2.74 2.74 0 00-1.803-.674H7a2.75 2.75 0 00-2.75 2.75v.431h-1.5V11c0-.69.56-1.25 1.25-1.25zm4.393 3.5H7c-.69 0-1.25.56-1.25 1.25v.431h3.892V14.5c0-.69-.559-1.25-1.25-1.25zM18.896 13a1 1 0 10-2 0 1 1 0 002 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgUnit = React.memo(SvgUnit);
export default MemoSvgUnit;
