import * as React from 'react';
const SvgWaze = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.01 19.008h-2.044a2.502 2.502 0 00-4.552-.864v.016a7.861 7.861 0 01-2.02-1.493c-.704-.71-1.086-1.361-1.26-1.772A2.441 2.441 0 004.33 14.2c.431-.464.67-1.075.667-1.709v-1.486c-.001-1.774.59-3.498 1.679-4.898 1.557-1.996 3.857-3.113 6.333-3.113a8 8 0 015.653 13.655 8.01 8.01 0 01-5.653 2.359z"
        // eslint-disable-next-line custom-rules/use-current-color
        fill="transparent"
      />
      <path
        d="M22 10.993a8.934 8.934 0 00-2.637-6.355A8.926 8.926 0 0013.011 2C10.25 2 7.678 3.238 5.928 5.452a9.013 9.013 0 00-1.922 5.589v1.452c0 .744-.526 1.446-1.545 1.508a.477.477 0 00-.46.436c-.037.678.688 1.924 1.683 2.93a8.78 8.78 0 002.366 1.706A2.5 2.5 0 008.512 22a2.486 2.486 0 002.453-2h2.097c.28 1.41 1.744 2.378 3.313 1.852 1.371-.46 1.961-2.015 1.467-3.274a9.067 9.067 0 003.478-4.142 8.938 8.938 0 00.68-3.443zm-8.99 8.015h-2.044a2.502 2.502 0 00-4.552-.864v.016a7.861 7.861 0 01-2.02-1.493c-.704-.71-1.086-1.361-1.26-1.772A2.441 2.441 0 004.33 14.2c.431-.464.67-1.075.667-1.709v-1.486c-.001-1.774.59-3.498 1.679-4.898 1.557-1.996 3.857-3.113 6.333-3.113a8 8 0 015.653 13.655 8.01 8.01 0 01-5.653 2.359z"
        fill="currentColor"
      />
      <path
        d="M17.004 9.998a1 1 0 100-2 1 1 0 000 2zM11.002 9.998a1 1 0 100-2 1 1 0 000 2zM11.307 12.273a.503.503 0 00-.874-.054.498.498 0 00-.033.476 4 4 0 007.236 0 .498.498 0 00-.452-.706h-.013a.492.492 0 00-.443.284 2.998 2.998 0 01-5.42 0h-.001z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgWaze = React.memo(SvgWaze);
export default MemoSvgWaze;
