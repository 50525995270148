import * as React from 'react';
const SvgBank = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83 1.802a.833.833 0 01.934 0l6.721 4.546c.68.46.355 1.523-.467 1.523H3.578c-.823 0-1.148-1.063-.468-1.523l6.72-4.546zm-4.893 4.82l5.36-3.627 5.361 3.626H4.938zM15.87 9.584c.034.133.053.272.053.416v6.667c0 .144-.019.283-.053.416h1.303a.625.625 0 110 1.25H3.422a.625.625 0 010-1.25h1.302a1.67 1.67 0 01-.052-.416V10c0-.144.018-.283.052-.416H3.423a.625.625 0 010-1.25h13.75a.625.625 0 110 1.25H15.87zm-9.947 7.083V10c0-.23.186-.416.416-.416h1.25c.23 0 .417.186.417.416v6.667c0 .23-.187.416-.417.416H6.34a.417.417 0 01-.416-.416zm3.75-7.084a.417.417 0 00-.417.417v6.667c0 .23.186.416.417.416h1.25c.23 0 .416-.186.416-.416V10a.417.417 0 00-.417-.416h-1.25zm3.333 0a.417.417 0 00-.417.417v6.667c0 .23.187.416.417.416h1.25c.23 0 .416-.186.416-.416V10a.417.417 0 00-.416-.416h-1.25z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBank = React.memo(SvgBank);
export default MemoSvgBank;
