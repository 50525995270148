import * as React from 'react';

const SvgSettings = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.105 5.5a.392.392 0 00-.365.25l-.501 1.275a.75.75 0 01-.323.375l-1.657.958a.75.75 0 01-.488.092l-1.353-.205a.394.394 0 00-.4.193l-.001.003-.464.8a.393.393 0 00.033.442l.855 1.071a.75.75 0 01.164.468v1.913a.75.75 0 01-.163.467l-.855 1.074a.394.394 0 00-.034.441l.465.801a.393.393 0 00.399.192l1.354-.205a.75.75 0 01.488.092l1.657.957a.75.75 0 01.323.376l.5 1.275a.393.393 0 00.366.25h.928a.393.393 0 00.366-.25l.5-1.275a.75.75 0 01.324-.376l1.657-.957a.75.75 0 01.488-.092l1.354.205a.393.393 0 00.399-.192v-.001l.465-.8a.393.393 0 00-.033-.443l-.855-1.07a.75.75 0 01-.164-.469v-1.913a.75.75 0 01.163-.467l.855-1.076a.393.393 0 00.033-.441l-.464-.801a.393.393 0 00-.4-.192l-1.353.205a.75.75 0 01-.488-.092L13.223 7.4a.75.75 0 01-.323-.375l-.5-1.275a.395.395 0 00-.367-.25h-.928zm.928-1.5a1.893 1.893 0 011.763 1.202l.404 1.031 1.203.695 1.094-.166a1.894 1.894 0 011.922.924l.001.001.464.8a1.892 1.892 0 01-.159 2.127l-.691.87v1.389l.69.865a1.893 1.893 0 01.16 2.131l-.464.799a1.892 1.892 0 01-1.923.925l-1.095-.166-1.202.695-.404 1.03a1.894 1.894 0 01-1.763 1.203h-.928a1.893 1.893 0 01-1.762-1.202l-.405-1.031-1.202-.695-1.094.166a1.893 1.893 0 01-1.923-.924l-.464-.8a1.893 1.893 0 01.159-2.128l.691-.868v-1.388l-.69-.866a1.893 1.893 0 01-.16-2.13l.464-.8a1.893 1.893 0 011.922-.928l1.095.167 1.202-.695.405-1.031A1.893 1.893 0 0111.105 4h.928zm-.464 5.143a3.036 3.036 0 100 6.071 3.036 3.036 0 000-6.071zm0 1.5a1.535 1.535 0 110 3.07 1.535 1.535 0 010-3.07z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgSettings = React.memo(SvgSettings);
export default MemoSvgSettings;
