import React, { ReactNode } from 'react';
import styles from './modal.module.scss';
import cn from 'classnames';
import { useBrowserBack } from './use-browser-back';
import text from './modal.text.json';
import { CloseButton } from './close-button';
import { ReactModal } from './react-modal-module';

export const MOBILE_BEHAVIORS = [
  'regular',
  'fullScreen',
  'drawer',
  'fullScreenDrawer',
  'leftSide',
  'rightSide',
] as const;

const [DEFAULT_MOBILE_BEHAVIOR] = MOBILE_BEHAVIORS;

export type ModalProps = {
  open: boolean;
  onClose: (event?: any) => void;
  mobileBehavior?: (typeof MOBILE_BEHAVIORS)[number];
  className?: string;
  label?: string;
  description?: string;
  useBrowserBackButton?: boolean;
  hasDefaultFrame?: boolean;
  hasCloseButton?: boolean;
  closeButtonClassName?: string;
  children?: ReactNode;
  id?: string;
  'data-nagish': string;
  'data-testid'?: string;
};

export const Modal = ({
  open,
  onClose,
  mobileBehavior = DEFAULT_MOBILE_BEHAVIOR,
  className = '',
  label = text.DEFAULT_ARIA_LABELS,
  description = text.DEFAULT_ARIA_LABELS,
  useBrowserBackButton = false,
  hasDefaultFrame = true,
  hasCloseButton = true,
  closeButtonClassName = '',
  id,
  children,
  ...otherAttributes
}: ModalProps) => {
  const { handleClose } = useBrowserBack(onClose, open, useBrowserBackButton);

  return (
    <ReactModal
      isOpen={open}
      shouldFocusAfterRender={true}
      shouldCloseOnEsc={true}
      bodyOpenClassName={'disableScroll'}
      overlayClassName={cn(styles.box, styles[mobileBehavior])}
      className={cn(styles.window, hasDefaultFrame && styles.frame, className)}
      onRequestClose={handleClose}
      id={id}
      aria={{
        labelledby: label,
        describedby: description,
      }}
      data={{
        nagish: otherAttributes['data-nagish'],
      }}
      data-testId={otherAttributes['data-testid']}
    >
      <>
        {hasCloseButton && (
          <CloseButton onClick={handleClose} className={closeButtonClassName} />
        )}
        {children}
      </>
    </ReactModal>
  );
};

export default Modal;
