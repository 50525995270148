import * as React from 'react';
const SvgApproval = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.523 4.727a6.75 6.75 0 0110.827 7.759l3.192 5.528a.75.75 0 01-.698 1.124l-3.099-.2-1.376 2.785a.75.75 0 01-1.322.042L12.296 17l-2.751 4.765a.75.75 0 01-1.322-.043l-1.376-2.783-3.1.2a.75.75 0 01-.697-1.123l3.192-5.53a6.75 6.75 0 011.281-7.758zm-.358 9.159L5.05 17.551l2.199-.142a.75.75 0 01.72.416l.977 1.976 2.116-3.665a6.75 6.75 0 01-3.897-2.25zm5.132.864a5.25 5.25 0 004.54-2.616l.012-.02a5.25 5.25 0 10-4.554 2.636h.002zm4.772-.477a6.75 6.75 0 01-3.54 1.863l2.116 3.665.977-1.976a.75.75 0 01.72-.416l2.2.142-2.115-3.665a6.746 6.746 0 01-.358.387z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgApproval = React.memo(SvgApproval);
export default MemoSvgApproval;
