import * as React from 'react';

const SvgPanorama = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.925 5.254A.75.75 0 0121.75 6v12a.75.75 0 01-.822.747l.019-.196-.02.196h-.003l-.01-.002-.044-.004-.168-.016a148.437 148.437 0 00-2.76-.227c-1.706-.125-3.808-.248-5.442-.248-1.634 0-3.736.123-5.442.248a155.095 155.095 0 00-2.76.227l-.168.016-.044.004-.01.001h-.003l-.029-.29.028.29A.75.75 0 013.25 18V6a.75.75 0 01.825-.746h.003l.01.001.042.004.163.016.61.056c.52.047 1.25.109 2.09.171 1.683.125 3.785.248 5.507.248 1.722 0 3.824-.123 5.507-.248a138.056 138.056 0 002.7-.227l.163-.016.042-.004h.01l.003-.001.002.013-.002-.013zm-.675 1.57v10.355l-.045-.004c-.54-.047-1.294-.11-2.153-.173-1.713-.125-3.86-.252-5.552-.252-1.691 0-3.839.127-5.552.252-.859.063-1.613.126-2.153.173l-.045.004V6.823l.019.002c.526.047 1.265.11 2.113.173 1.692.125 3.84.252 5.618.252 1.778 0 3.926-.127 5.618-.252.848-.063 1.587-.126 2.113-.173l.019-.002zm-3.244 7.164l.01-.007.037-.037 1.613-1.802a.536.536 0 00.043-.65l-.006-.01-.032-.041-1.615-1.78a.483.483 0 00-.723.003.536.536 0 00-.044.651l.006.01.033.04 1.294 1.426-1.287 1.438c-.179.2-.178.51.002.71a.482.482 0 00.67.049zm-.452-.554a.235.235 0 00.006.303c.07.077.178.083.255.02l.022-.021-.022.02a.182.182 0 01-.255-.02.235.235 0 01-.006-.302zm1.472-1.644l-1.468-1.618-.02-.024.02.024 1.468 1.618zM7.983 9.62l.009-.008a.482.482 0 01.67.05c.18.198.18.51.002.71l-1.287 1.437 1.294 1.425.032.042.006.009a.536.536 0 01-.043.65.483.483 0 01-.723.003L6.328 12.16l-.033-.041-.006-.009a.536.536 0 01.044-.651l1.612-1.802.038-.037zm.456.243a.182.182 0 00-.256-.02l-.022.022-1.605 1.793 1.606-1.794.021-.02a.182.182 0 01.256.02zM6.973 11.81l1.468 1.618.02.024-.02-.024-1.468-1.618z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPanorama = React.memo(SvgPanorama);
export default MemoSvgPanorama;
