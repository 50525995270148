import * as React from 'react';
const SvgBox = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.821 8.786a.75.75 0 01.75-.75H18.43a.75.75 0 01.75.75v8.571a2.893 2.893 0 01-2.893 2.893H7.714a2.893 2.893 0 01-2.893-2.893V8.786zm1.5.75v7.821c0 .77.624 1.393 1.393 1.393h8.572c.769 0 1.393-.624 1.393-1.393V9.536H6.32z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.107 12a.75.75 0 01.75-.75h4.286a.75.75 0 010 1.5H9.857a.75.75 0 01-.75-.75zM3.75 5.571c0-1.006.815-1.821 1.821-1.821H18.43c1.006 0 1.821.815 1.821 1.821v2.143a1.821 1.821 0 01-1.821 1.822H5.57A1.821 1.821 0 013.75 7.714V5.571zm1.821-.321a.321.321 0 00-.321.321v2.143c0 .178.144.322.321.322H18.43a.321.321 0 00.321-.322V5.571a.321.321 0 00-.321-.321H5.57z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBox = React.memo(SvgBox);
export default MemoSvgBox;
