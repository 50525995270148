import * as React from 'react';

const SvgHeart = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1 8.81c0-3.197 2.582-5.56 5.446-5.56 1.286 0 2.502.443 3.451 1.244l.007.005.995.877.995-.877.006-.005a5.336 5.336 0 013.451-1.244c1.37 0 2.745.404 3.788 1.336 1.058.946 1.694 2.364 1.694 4.224 0 1.918-1.03 4.195-2.677 6.37-1.665 2.198-4.03 4.384-6.858 6.125l-.393.242-.393-.242c-2.831-1.742-5.19-3.951-6.848-6.159C3.124 12.963 2.1 10.686 2.1 8.81zm5.446-4.06C5.492 4.75 3.6 6.46 3.6 8.81c0 1.4.801 3.356 2.363 5.435 1.467 1.954 3.544 3.931 6.042 5.536 2.501-1.604 4.584-3.564 6.056-5.506 1.565-2.067 2.372-4.024 2.372-5.465 0-1.498-.5-2.485-1.193-3.105-.709-.633-1.7-.955-2.789-.955-.936 0-1.805.32-2.478.886l-1.974 1.739-1.975-1.74a3.837 3.837 0 00-2.478-.885z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgHeart = React.memo(SvgHeart);
export default MemoSvgHeart;
