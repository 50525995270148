import * as React from 'react';

const SvgArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.933 8.4c-.533-.533-1.333-.533-1.867 0s-.533 1.333 0 1.867L15.199 23.6c.533.533 1.467.533 2 0l11.867-13.2c.533-.533.4-1.467-.133-1.867C28.4 8 27.6 8 27.066 8.666L16.133 20.799l-11.2-12.4z"
      />
    </svg>
  );
};

const MemoSvgArrow = React.memo(SvgArrow);
export default MemoSvgArrow;
