import * as React from 'react';

const SvgGame = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.75 8.5a.75.75 0 100 1.5.75.75 0 000-1.5zM15 12.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM17.25 10a.75.75 0 100 1.5.75.75 0 000-1.5zM13.5 10.75a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM8.5 13.1a.6.6 0 01-.6-.6v-1.15H6.75a.6.6 0 110-1.2H7.9V9a.6.6 0 011.2 0v1.15h1.15a.6.6 0 110 1.2H9.1v1.15a.6.6 0 01-.6.6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.201 12.548c-.125-.816-.482-2.17-1.109-3.52-.502-1.083-1.362-2.548-2.605-3.374C17.833 5.22 17.14 5 16.424 5c-.297 0-.6.039-.904.115-.52.13-.96.322-1.347.58a3.608 3.608 0 01-2.025.632h-.169a3.614 3.614 0 01-2.026-.632 4.242 4.242 0 00-1.347-.58A3.702 3.702 0 007.703 5c-.715 0-1.409.22-2.063.654-1.243.826-2.103 2.29-2.605 3.374-.627 1.352-.984 2.705-1.109 3.52-.453 2.963-.225 4.996.674 6.043.442.516 1.047.788 1.747.788.638 0 1.391-.32 2.238-.948.7-.518 1.45-1.236 2.172-2.074a.828.828 0 01.631-.291h5.352a.83.83 0 01.63.29c.722.839 1.474 1.557 2.172 2.075.847.629 1.6.948 2.238.948.7 0 1.305-.272 1.747-.788.9-1.047 1.127-3.08.674-6.043zM4.347 17.84c-.25 0-.43-.077-.58-.252-.56-.653-.68-2.451-.32-4.81.108-.71.424-1.9.983-3.105.42-.903 1.118-2.112 2.061-2.74.403-.266.798-.396 1.21-.396.17 0 .35.023.53.069.346.088.63.207.865.365a5.155 5.155 0 002.883.893h.17a5.16 5.16 0 002.883-.893 2.73 2.73 0 01.865-.365 2.15 2.15 0 01.53-.07c.411 0 .806.13 1.21.398.943.627 1.641 1.836 2.061 2.739.56 1.206.874 2.396.982 3.106.361 2.358.242 4.156-.32 4.809-.149.175-.328.252-.58.252-.106 0-.515-.046-1.322-.645-.608-.452-1.273-1.088-1.923-1.842a2.364 2.364 0 00-1.795-.826H9.388c-.69 0-1.345.3-1.796.826-.65.755-1.315 1.39-1.923 1.842-.807.599-1.216.645-1.322.645z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgGame = React.memo(SvgGame);
export default MemoSvgGame;
