import * as React from 'react';

const SvgDocument = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.75 10.5a.75.75 0 00-.75-.75H8.437l-.101.007a.75.75 0 00.101 1.493H15l.102-.007a.75.75 0 00.648-.743zM15.75 13.5a.75.75 0 00-.75-.75H9.429l-.102.007a.75.75 0 00.102 1.493H15l.102-.007a.75.75 0 00.648-.743zM15.75 16.5a.75.75 0 00-.75-.75h-3.6l-.102.007a.75.75 0 00.102 1.493H15l.102-.007a.75.75 0 00.648-.743zM15.75 7.5a.75.75 0 00-.75-.75h-3.5l-.102.007A.75.75 0 0011.5 8.25H15l.102-.007a.75.75 0 00.648-.743z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.629L14.31 2H5a1 1 0 00-1 1v17a1 1 0 001 1h14a1 1 0 001-1V6.629zM5.5 3.5h8.277L18.5 7.342V19.5h-13v-16z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDocument = React.memo(SvgDocument);
export default MemoSvgDocument;
