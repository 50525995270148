import React, { useState } from 'react';
import { NotSupportedModal } from '../not-supported-modal/not-supported-modal';
import { NotSupportedIndicator } from '../not-supported-strip/not-supported-strip';
import { useBrowserSupport } from '../../use-browser-support';

export const BrowserSupport = () => {
  const [isStripOpen, setIsStripOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { isUnsupported } = useBrowserSupport();

  return isUnsupported ? (
    <>
      <NotSupportedModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsStripOpen(true);
        }}
      />
      <NotSupportedIndicator
        isOpen={isStripOpen}
        onClose={() => setIsStripOpen(false)}
        onDetailsClick={() => {
          setIsStripOpen(false);
          setIsModalOpen(true);
        }}
      />
    </>
  ) : null;
};
