type Map = {
  [key: string]: number;
};

export const minBrowserVersions: Map = {
  Chrome: 84,
  Edge: 84,
  Safari: 14.1,
  Firefox: 63,
  Opera: 70,
  'Chrome Android': 121,
  'Samsung Internet': 14.0,
  'Opera Mobile': 73,
  'UC Browser Android': 15.5,
  'Android Browser': 121,
  'Firefox Android': 122,
  'QQ Browser': 13.1,
  'Baidu Browser': 13.18,
};

export const excludedBrowsers = ['IE', 'IEMobile'];

export const minOperatingSystems: Map = {
  'Safari iOS': 14.5,
  iOS: 14.5,
  KAIOS: 2.5,
};
