import * as React from 'react';

const SvgAttention = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.05 1.75c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm-.1 12.25a.75.75 0 110 1.5.75.75 0 010-1.5zM12 6.75a.75.75 0 01.743.648l.007.102v6a.75.75 0 01-1.493.102l-.007-.102v-6a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAttention = React.memo(SvgAttention);
export default MemoSvgAttention;
