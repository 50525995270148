import * as React from 'react';

const SvgFence = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.562 3.504a.75.75 0 00-1.124 0l-2 2.265-.066.087a.75.75 0 00-.122.41V8h-1.5V6.265a.75.75 0 00-.188-.496l-2-2.265a.75.75 0 00-1.124 0l-2 2.265-.066.087a.75.75 0 00-.122.41V8h-1.5V6.265a.75.75 0 00-.188-.496l-2-2.265a.75.75 0 00-1.124 0l-2 2.265a.75.75 0 00-.188.496V21c0 .414.336.75.75.75h4l.102-.007A.75.75 0 007.75 21v-2.5h1.5V21c0 .414.336.75.75.75h4l.102-.007A.75.75 0 0014.75 21v-2.5h1.5V21c0 .414.336.75.75.75h4a.75.75 0 00.75-.75V6.265a.75.75 0 00-.188-.496l-2-2.265zM3.75 6.548l1.249-1.415L6.25 6.549V20.25h-2.5V6.548zM12 5.133l-1.25 1.415V20.25h2.5V6.549L12 5.133zm5.75 1.415L19 5.133l1.25 1.416V20.25h-2.5V6.548zm-1.5 8.952h-1.5V17h1.5v-1.5zm-8.5 0h1.5V17h-1.5v-1.5zm8.5-3h-1.5V14h1.5v-1.5zm-8.5 0h1.5V14h-1.5v-1.5zm8.5-3h-1.5V11h1.5V9.5zm-8.5 0h1.5V11h-1.5V9.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgFence = React.memo(SvgFence);
export default MemoSvgFence;
