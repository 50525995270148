import * as React from 'react';
const SvgFloors = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.547 20.423h14.846a1 1 0 001-.987l.047-3.578a1 1 0 00-1-1.014h-3.635a1 1 0 01-1-1v-3.597a1 1 0 00-1-1h-3.608a1 1 0 01-1-1v-3.67a1 1 0 00-1-1h-3.65a1 1 0 00-1 1v14.846a1 1 0 001 1z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
};
const MemoSvgFloors = React.memo(SvgFloors);
export default MemoSvgFloors;
