import * as React from 'react';

const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9 8a.75.75 0 100-1.5.75.75 0 000 1.5zM11.95 16.75a.75.75 0 00.743-.648L12.7 16v-6a.75.75 0 00-1.493-.102L11.2 10v6c0 .414.336.75.75.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.75c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm0-1.5a8.5 8.5 0 110-17 8.5 8.5 0 010 17z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgInfo = React.memo(SvgInfo);
export default MemoSvgInfo;
