import * as React from 'react';

const SvgMeetingRoom = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.361 21.218v-1l-.005-.193h1.419l-.005.193v1c0 .355.288.643.643.643h6a.643.643 0 00.643-.643v-1c0-.075-.003-.15-.007-.225l1.763-.023a.75.75 0 00.732-.86l-1.34-9.002a.75.75 0 00-.741-.64h-.408v-.25a3.637 3.637 0 00-1.893-3.194 2.47 2.47 0 10-3.498-.002 3.642 3.642 0 00-1.894 3.196v.25h-1.41l.001-.25a3.637 3.637 0 00-1.893-3.194 2.47 2.47 0 10-3.498-.002 3.642 3.642 0 00-1.894 3.196l-.001.25h-.538a.75.75 0 00-.741.64l-1.34 9.002a.75.75 0 00.733.86l1.893.022c-.004.075-.006.15-.006.226v1c0 .355.287.643.642.643h6a.643.643 0 00.643-.643zm2.695-1a2.357 2.357 0 012.357-2.357l.161.006a2.357 2.357 0 012.196 2.351v.357h-4.715v-.357zm-6.338-2.357a2.357 2.357 0 00-2.357 2.357v.357h4.714v-.357c0-1.247-.969-2.268-2.195-2.351l-.162-.006zm-3.534-6.894h15.63l1.12 7.513-1.31.017a3.652 3.652 0 00-1.462-1.473 2.47 2.47 0 10-3.498-.002 3.659 3.659 0 00-1.477 1.503h-2.243a3.65 3.65 0 00-1.476-1.501 2.47 2.47 0 10-3.498-.002 3.659 3.659 0 00-1.463 1.474l-1.441-.016 1.118-7.513zm12.229 3.345a.97.97 0 100 1.939.97.97 0 000-1.939zm-9.664.97a.97.97 0 111.939 0 .97.97 0 01-1.939 0zm.97-8.42A2.357 2.357 0 005.36 9.217l-.001.25h4.715v-.25c0-1.247-.969-2.268-2.195-2.351l-.162-.006zm6.337 2.356a2.357 2.357 0 012.357-2.357l.161.006a2.357 2.357 0 012.196 2.351v.25h-4.715v-.25zm2.357-5.905a.97.97 0 100 1.938.97.97 0 000-1.939zm-9.664.969a.97.97 0 111.938 0 .97.97 0 01-1.938 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMeetingRoom = React.memo(SvgMeetingRoom);
export default MemoSvgMeetingRoom;
