import * as React from 'react';
const SvgKosher = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zm0 1.5a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM9.771 6.836v-.294h-.613c-.053.212-.1.405-.138.58l-.07.324c-.05.25-.087.477-.11.681a5.802 5.802 0 00-.036.665c0 .554.103.95.31 1.186.207.236.538.355.992.355h3.463c.387 0 .696.036.926.11.23.072.399.195.506.368.106.173.16.412.16.715v1.283c0 .41-.13.71-.39.896-.26.186-.694.279-1.304.279H9.21l-.73 2.91h5.352c.483 0 .9-.122 1.252-.365.35-.244.621-.607.812-1.09.19-.485.285-1.075.285-1.772v-3.45c0-.896-.214-1.586-.644-2.07-.43-.483-1.052-.725-1.867-.725h-3.167c-.251 0-.436-.05-.554-.153-.118-.101-.177-.246-.177-.433z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgKosher = React.memo(SvgKosher);
export default MemoSvgKosher;
