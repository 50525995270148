import * as React from 'react';
const SvgThumbUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.23 4.98a1.353 1.353 0 011.045-.567h.003a1.36 1.36 0 011.117.502l.002.003a1.236 1.236 0 01.19 1.085l-1.159 3.958a.68.68 0 00.116.607.715.715 0 00.566.275h4.762a.717.717 0 01.582.299.68.68 0 01.106.56l-.004.016-1.968 7.047a1.154 1.154 0 01-.397.593 1.201 1.201 0 01-.743.257H8.2v-8.342l4.03-6.293zm-1.204-.731a2.911 2.911 0 01.053-.08 2.737 2.737 0 012.135-1.166h.003a2.75 2.75 0 012.289 1.054c.515.663.679 1.525.442 2.325l-.9 3.076h3.827a2.146 2.146 0 011.688.816c.403.515.536 1.183.362 1.808l-1.968 7.047-.005.017v.001c-.153.53-.478.997-.926 1.331l-.026.02a2.648 2.648 0 01-1.551.502H4.655c-.439 0-.86-.17-1.17-.474A1.6 1.6 0 013 19.384v-7.386c0-.429.175-.84.485-1.143.31-.302.73-.473 1.17-.473h2.443l3.928-6.133zm-6.371 7.518a.238.238 0 00-.19.092.227.227 0 00-.047.139v7.386a.227.227 0 00.047.139.239.239 0 00.19.092h2.127v-7.848H4.655z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgThumbUp = React.memo(SvgThumbUp);
export default MemoSvgThumbUp;
