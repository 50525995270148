import * as React from 'react';
const SvgLongTerm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.683 1.995A10.084 10.084 0 003.17 8.617a.54.54 0 00.359.697.592.592 0 00.721-.37 9.037 9.037 0 11-.462 4.547l.76.68a.563.563 0 00.75-.84l-1.81-1.62a.564.564 0 00-.844.103l-1.365 2.012a.563.563 0 00.932.632l.478-.704a10.13 10.13 0 109.994-11.76z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.752 8.146H12.3V7.094a.464.464 0 11.93 0v1.052h1.547V7.094a.464.464 0 11.929 0v1.052h1.393c.684 0 1.239.555 1.239 1.239v6.811c0 .684-.555 1.238-1.239 1.238H8.43a1.238 1.238 0 01-1.238-1.238V9.385c0-.684.554-1.239 1.238-1.239h1.394V7.094a.464.464 0 11.928 0v1.052zM12.3 9.57a.464.464 0 10.93 0v-.495h1.547v.495a.464.464 0 10.929 0v-.495h1.393l.056.005a.31.31 0 01.254.305v1.703H8.12V9.385l.005-.056a.31.31 0 01.304-.254h1.394v.495a.464.464 0 10.928 0v-.495H12.3v.495zm-4.18 2.446h9.289v4.18l-.005.056a.31.31 0 01-.305.254H8.43l-.055-.005a.31.31 0 01-.254-.305v-4.18z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.682 2.177a9.902 9.902 0 00-9.34 6.503.357.357 0 00.24.46c.2.061.42-.05.497-.259a9.22 9.22 0 11-.471 4.638l-.078-.502 1.14 1.017a.381.381 0 00.508-.568l-1.811-1.62a.38.38 0 00-.572.07L1.43 13.928a.381.381 0 00.63.428l.735-1.083.074.452a9.948 9.948 0 109.813-11.548zm4.5 17.574a8.854 8.854 0 00-7.115-16.097 8.855 8.855 0 00-5.646 5.354.774.774 0 01-.946.48A.722.722 0 013 8.555a10.266 10.266 0 019.684-6.743 10.311 10.311 0 017.863 16.991 10.313 10.313 0 01-17.955-4.58l-.229.337a.745.745 0 01-1.233-.836l1.364-2.011a.746.746 0 011.117-.137l1.81 1.618a.745.745 0 01-.992 1.112l-.364-.324a8.856 8.856 0 0013.117 5.769zM13.046 8.893h1.912v.677a.282.282 0 00.565 0v-.677h1.583l.072.006.008.002c.23.042.404.242.404.484v1.885H7.939V9.377l.006-.072.001-.008a.492.492 0 01.484-.404h1.576v.677a.282.282 0 00.564 0v-.677h1.912v.677a.282.282 0 00.565 0v-.677zm-.565-.565H10.57V7.094a.282.282 0 00-.564 0v1.234H8.43c-.583 0-1.056.473-1.056 1.057v6.811c0 .583.473 1.056 1.056 1.056h8.67c.583 0 1.056-.473 1.056-1.056V9.385c0-.584-.473-1.057-1.057-1.057h-1.575V7.094a.282.282 0 00-.565 0v1.234h-1.912V7.094a.282.282 0 00-.565 0v1.234zm5.109 3.506v4.37l-.007.072-.001.008a.492.492 0 01-.484.404H8.422l-.072-.007-.008-.001a.492.492 0 01-.403-.484v-4.362h9.652zm-4.826-1.617a.647.647 0 01-.647-.647v-.313h-1.184v.313a.647.647 0 01-1.293 0v-.313h-1.21c-.06 0-.112.042-.125.098l-.003.038v1.512h8.924v-1.52c0-.06-.042-.111-.098-.124l-.038-.004h-1.203v.313a.647.647 0 01-1.293 0v-.313h-1.184v.313c0 .358-.29.647-.646.647zm-.647-2.253v-.87a.647.647 0 011.293 0v.87h1.184v-.87a.647.647 0 011.293 0v.87H17.1c.785 0 1.42.636 1.42 1.42v6.812a1.42 1.42 0 01-1.42 1.42H8.43a1.42 1.42 0 01-1.42-1.42V9.385c0-.785.636-1.42 1.42-1.42h1.211v-.871a.647.647 0 111.293 0v.87h1.184zm-3.815 4.234h8.924v3.99l-.004.038a.128.128 0 01-.124.098H8.44L8.4 16.32a.128.128 0 01-.098-.124v-3.998z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgLongTerm = React.memo(SvgLongTerm);
export default MemoSvgLongTerm;
