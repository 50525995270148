import * as React from 'react';

const SvgSearch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.409 3.25c3.949 0 7.158 3.124 7.158 6.988a6.88 6.88 0 01-2.066 4.91l3.311 3.323a.75.75 0 01-.986 1.126l-.076-.068-3.428-3.44a7.253 7.253 0 01-3.913 1.137c-3.95 0-7.159-3.124-7.159-6.988 0-3.864 3.21-6.988 7.159-6.988zm0 1.5c-3.13 0-5.659 2.462-5.659 5.488 0 3.026 2.529 5.488 5.659 5.488s5.658-2.462 5.658-5.488c0-3.026-2.529-5.488-5.658-5.488z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgSearch = React.memo(SvgSearch);
export default MemoSvgSearch;
