import * as React from 'react';

const SvgRefresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.882 8.766a6.712 6.712 0 00-12.073 5.822 6.711 6.711 0 0012.697-.936.644.644 0 111.248.318 8 8 0 11-1.045-6.33l.003-1.506A.644.644 0 0120 6.137l-.008 3.279c0 .355-.29.643-.646.642l-3.279-.008a.644.644 0 11.003-1.288l1.812.004z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRefresh = React.memo(SvgRefresh);
export default MemoSvgRefresh;
