import * as React from 'react';
const SvgHappy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#happy_svg__clip0_6943_85601)" fill="currentColor">
        <path d="M10 20C4.488 20 0 15.514 0 10S4.487 0 10 0c5.515 0 10.001 4.486 10.001 10s-4.486 10-10 10zm0-18.868C5.112 1.132 1.134 5.11 1.134 10c0 4.89 3.978 8.867 8.868 8.867 4.89 0 8.868-3.978 8.868-8.867 0-4.89-3.979-8.868-8.868-8.868z" />
        <path d="M10.001 16.593h-.04c-2.472-.02-3.913-1.743-4.176-2.088a.566.566 0 01.9-.688c.127.167 1.3 1.629 3.284 1.644H10c2.005 0 3.188-1.474 3.317-1.643a.566.566 0 01.9.687c-.267.349-1.722 2.088-4.216 2.088zM7.992 8.432a.565.565 0 01-.45-.223c-.056-.073-.568-.71-1.434-.71h-.013c-.857.007-1.365.639-1.42.71a.566.566 0 01-.9-.687c.147-.191.944-1.144 2.311-1.155h.023c1.38 0 2.185.962 2.333 1.156a.566.566 0 01-.45.91zM15.816 8.432a.565.565 0 01-.45-.223c-.056-.073-.568-.71-1.433-.71h-.014c-.857.007-1.364.639-1.42.71a.566.566 0 01-.899-.687c.146-.191.944-1.144 2.31-1.155h.023c1.38 0 2.185.962 2.333 1.156a.566.566 0 01-.45.91z" />
      </g>
      <defs>
        <clipPath id="happy_svg__clip0_6943_85601">
          <path fill="currentColor" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgHappy = React.memo(SvgHappy);
export default MemoSvgHappy;
