import * as React from 'react';

const SvgQuestion = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 2.05c-5.633 0-10.2 4.567-10.2 10.2 0 5.633 4.567 10.2 10.2 10.2 5.633 0 10.2-4.567 10.2-10.2 0-5.633-4.567-10.2-10.2-10.2zm-8.3 10.2a8.3 8.3 0 1116.6 0 8.3 8.3 0 01-16.6 0zm8.197-5.45c-1.06 0-1.815.286-2.364.774a3.25 3.25 0 00-.94 1.512.929.929 0 00-.043.27c0 .408.322.607.58.68l.01.002.016.003c.066.012.151.027.24.027a.665.665 0 00.412-.135.815.815 0 00.271-.416c.127-.396.325-.717.605-.939.278-.22.652-.356 1.154-.356.524 0 .92.138 1.18.36.258.22.405.54.405.95 0 .283-.056.48-.2.682-.154.214-.412.44-.846.764-.345.249-.559.41-.716.553-.162.146-.262.27-.383.438-.351.47-.474.928-.474 1.74v.348c0 .215.08.395.227.518a.79.79 0 00.513.169c.182 0 .363-.05.5-.171a.663.663 0 00.217-.516v-.336c0-.454.077-.716.248-.95.182-.25.48-.48.975-.848.457-.34.827-.669 1.08-1.056.256-.393.386-.834.386-1.386 0-.813-.3-1.49-.844-1.962-.541-.469-1.305-.719-2.21-.719zm-.376 9.28a.99.99 0 00-.72.294c-.186.19-.29.458-.29.766 0 .307.104.574.29.765a.99.99 0 00.72.295c.56 0 1.01-.43 1.01-1.06 0-.63-.45-1.06-1.01-1.06z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgQuestion = React.memo(SvgQuestion);
export default MemoSvgQuestion;
