import * as React from 'react';
const SvgSquaremeter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 21.5h13a3 3 0 003-3v-13a3 3 0 00-3-3H5a3 3 0 00-3 3v13a3 3 0 003 3zM5 20a1.5 1.5 0 01-1.5-1.5v-13A1.5 1.5 0 015 4h13a1.5 1.5 0 011.5 1.5v13A1.5 1.5 0 0118 20H5z"
        fill="currentColor"
      />
      <path d="M3.5 10h17v1.5h-17V10z" fill="currentColor" />
      <path
        d="M8.5 21V11H10v10H8.5zM13.5 11V4H15v7h-1.5z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgSquaremeter = React.memo(SvgSquaremeter);
export default MemoSvgSquaremeter;
