import { commercial } from './commercial';
import { pets } from './pets';
import { privateTeachers } from './private-teachers';
import { products } from './products';
import { realEstate } from './realestate';
import { recreation } from './recreation';
import { vehicles } from './vehicles';
import { recommerce } from './recommerce';

export const categories = {
  vehicles,
  realEstate,
  products,
  pets,
  privateTeachers,
  recreation,
  commercial,
  recommerce,
};
