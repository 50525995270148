import cn from 'classnames';
import styles from './sub-category-list.module.scss';
import React from 'react';
import { SubCategory } from '../types';
import SubCategoryItem from '../sub-category-item/sub-category-item';

export interface Props {
  subCategories: SubCategory[];
  isCollapsed: boolean;
}
const LINKS_IN_COLUMNS = 8;

const SubCategoryList: React.VFC<Props> = ({ subCategories, isCollapsed }) => {
  const hasTwoColumns = subCategories.length > LINKS_IN_COLUMNS;
  return (
    <ul
      className={cn(
        styles.subCategoryList,
        hasTwoColumns && styles.twoColumns,
        isCollapsed && styles.isCollapsed,
      )}
      data-nagish="footer-sub-category-list"
    >
      {subCategories.map(
        ({ title, url, isTargetBlank, analyticsProperties }) => (
          <SubCategoryItem
            key={title}
            title={title}
            url={url}
            isTargetBlank={isTargetBlank}
            analyticsProperties={analyticsProperties}
          />
        ),
      )}
    </ul>
  );
};

export default SubCategoryList;
