import { Environment } from '../schema';

const commit = process.env['NEXT_PUBLIC_COMMIT_HASH'] || 'undefined';

const isJestRun = Boolean(process.env['JEST_RUN']);

const clusterPath = process.env['NEXT_PUBLIC_CLUSTER_GW'] || '';

const config: Environment = {
  name: 'prod',
  analyticsEnv: 'production',
  routes: {
    mainSite: 'https://www.yad2.co.il',
    yadataSite: 'https://yadata.yad2.co.il',
    marketYad2: 'https://market.yad2.co.il',
    myYad2: 'https://my.yad2.co.il',
    plusYad2: 'https://plus.yad2.co.il',
    personal: 'https://personal.yad2.co.il',
    oktaLogin: 'https://admin-aws.yad2.co.il/okta-login',
    generalPages: 'https://www.yad2.co.il/general-pages',
    contactUs: 'https://service.yad2.co.il',
    treedis: 'https://realestate.yad2.co.il',
    api: {
      gw: 'https://gw.yad2.co.il',
      gwInClusterForSSR: clusterPath,
      gwDrushim: 'https://gw.drushim.co.il',
      old: 'https://www.yad2.co.il/api',
      campaigns: 'https://campaigns.yad2.co.il/api',
      mipoCampaigns: 'https://yad2campaign.mipo.co.il/api',
      analyticsData: 'https://analytics.data.yad2.co.il',
      rentPlus: 'https://www.yad2.co.il/rent/api',
    },
    websocket: {
      gw: {
        host: 'https://gw-websocket.yad2.co.il',
      },
    },
  },
  assets: {
    basePath: 'https://assets.yad2.co.il',
    path: 'https://visuals.yad2.co.il',
    drushimPath: 'https://assets.drushim.co.il',
    imagesPath: 'https://img.yad2.co.il/Pic',
    userPublishImages: 'https://images.yad2.co.il/',
  },
  commit,
  isJestRun,
  amplitudeAnalyticsId: 'acf309e91c511c392febbc4168c81b58',
};

export default config;
