/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
export const privateTeachers = {
  title: 'private-teachers' as const,
  titleHeb: 'לימודים',
  id: 6 as const,
  subCategories: {
    internet: {
      id: 1 as const,
      title: 'Internet' as const,
      titleHeb: 'אינטרנט',
    },
    architecture: {
      id: 2 as const,
      title: 'architecture' as const,
      titleHeb: 'אדריכלות',
    },
    optics: {
      id: 3 as const,
      title: 'optics' as const,
      titleHeb: 'אופטיקה',
    },
    fashion: {
      id: 4 as const,
      title: 'fashion' as const,
      titleHeb: 'אופנה',
    },
    makeup: {
      id: 5 as const,
      title: 'makeup' as const,
      titleHeb: 'איפור',
    },
    art: {
      id: 6 as const,
      title: 'art' as const,
      titleHeb: 'אומנות',
    },
    biologyAndChemistry: {
      id: 7 as const,
      title: 'biology-and-chemistry' as const,
      titleHeb: 'ביולוגיה וכימיה',
    },
    insurance: {
      id: 8 as const,
      title: 'insurance' as const,
      titleHeb: 'ביטוח',
    },
    securityAndInvestigations: {
      id: 9 as const,
      title: 'security-and-investigations' as const,
      titleHeb: 'ביטחון וחקירות',
    },
    construction: {
      id: 10 as const,
      title: 'construction' as const,
      titleHeb: 'בנייה',
    },
    bartenders: {
      id: 11 as const,
      title: 'Bartenders' as const,
      titleHeb: 'ברמנים',
    },
    geography: {
      id: 12 as const,
      title: 'Geography' as const,
      titleHeb: 'גיאוגרפיה',
    },
    topiary: {
      id: 13 as const,
      title: 'topiary' as const,
      titleHeb: 'גננות נוי',
    },
    graphic: {
      id: 14 as const,
      title: 'graphic' as const,
      titleHeb: 'גרפיקה',
    },
    history: {
      id: 15 as const,
      title: 'history' as const,
      titleHeb: 'היסטוריה',
    },
    engineer: {
      id: 16 as const,
      title: 'Engineer' as const,
      titleHeb: 'הנדסאי',
    },
    accountancy: {
      id: 17 as const,
      title: 'accountancy' as const,
      titleHeb: 'הנהלת חשבונות',
    },
    video: {
      id: 18 as const,
      title: 'video' as const,
      titleHeb: 'וידאו',
    },
    physicalEducation: {
      id: 19 as const,
      title: 'Physical-Education' as const,
      titleHeb: 'חינוך גופני',
    },
    educationAndTeaching: {
      id: 20 as const,
      title: 'Education-and-Teaching' as const,
      titleHeb: 'חינוך והוראה',
    },
    electricityAndElectronics: {
      id: 21 as const,
      title: 'electricity-and-electronics' as const,
      titleHeb: 'חשמל ואלקטרוניקה',
    },
    agriculture: {
      id: 22 as const,
      title: 'agriculture' as const,
      titleHeb: 'חקלאות',
    },
    educationalConsultants: {
      id: 23 as const,
      title: 'Educational-Consultants' as const,
      titleHeb: 'יעוץ חינוכי',
    },
    landOfIsraelStudies: {
      id: 24 as const,
      title: 'Land-of-Israel-Studies' as const,
      titleHeb: "לימודי א''י",
    },
    engineeringStudies: {
      id: 25 as const,
      title: 'Engineering-Studies' as const,
      titleHeb: 'לימודי הנדסה',
    },
    multimedia: {
      id: 26 as const,
      title: 'Multimedia' as const,
      titleHeb: 'מולטימדיה',
    },
    music: {
      id: 27 as const,
      title: 'music' as const,
      titleHeb: 'מוסיקה',
    },
    secretaryship: {
      id: 28 as const,
      title: 'secretaryship' as const,
      titleHeb: 'מזכירות',
    },
    optometry: {
      id: 29 as const,
      title: 'Optometry' as const,
      titleHeb: 'מדעי האופטומטריה',
    },
    socialSciences: {
      id: 30 as const,
      title: 'Social-Sciences' as const,
      titleHeb: 'מדעי החברה',
    },
    scienceOfNature: {
      id: 31 as const,
      title: 'Science-of-nature' as const,
      titleHeb: 'מדעי הטבע',
    },
    marineScience: {
      id: 32 as const,
      title: 'Marine-Science' as const,
      titleHeb: 'מדעי הים',
    },
    computerScience: {
      id: 33 as const,
      title: 'Computer-Science' as const,
      titleHeb: 'מדעי המחשב',
    },
    computers: {
      id: 34 as const,
      title: 'Computers' as const,
      titleHeb: 'מחשבים',
    },
    businessManagementAndEconomics: {
      id: 35 as const,
      title: 'Business-Management-and-Economics' as const,
      titleHeb: 'מנהל עסקים וכלכלה',
    },
    publicManager: {
      id: 36 as const,
      title: 'Public-Manager' as const,
      titleHeb: 'מנהל ציבורי',
    },
    restaurateur: {
      id: 37 as const,
      title: 'Restaurateur' as const,
      titleHeb: 'מסעדנות',
    },
    medicalLaboratoryScience: {
      id: 38 as const,
      title: 'Medical-laboratory-science' as const,
      titleHeb: 'מעבדנות רפואית',
    },
    sentences: {
      id: 39 as const,
      title: 'Sentences' as const,
      titleHeb: 'משפטים',
    },
    math: {
      id: 40 as const,
      title: 'Math' as const,
      titleHeb: 'מתמטיקה',
    },
    healthServicesManagement: {
      id: 41 as const,
      title: 'Health-Services-Management' as const,
      titleHeb: 'ניהול שרותי בריאות',
    },
    nursing: {
      id: 42 as const,
      title: 'Nursing' as const,
      titleHeb: 'סיעוד',
    },
    literature: {
      id: 43 as const,
      title: 'Literature' as const,
      titleHeb: 'ספרות',
    },
    socialWork: {
      id: 44 as const,
      title: 'social-work' as const,
      titleHeb: 'עבודה סוציאלית',
    },
    philosophy: {
      id: 45 as const,
      title: 'philosophy' as const,
      titleHeb: 'פילוסופיה',
    },
    physics: {
      id: 46 as const,
      title: 'physics' as const,
      titleHeb: 'פיסיקה',
    },
    psychology: {
      id: 47 as const,
      title: 'psychology' as const,
      titleHeb: 'פסיכולוגיה',
    },
    advertising: {
      id: 48 as const,
      title: 'advertising' as const,
      titleHeb: 'פרסום',
    },
    photo: {
      id: 49 as const,
      title: 'Photo' as const,
      titleHeb: 'צילום',
    },
    diving: {
      id: 50 as const,
      title: 'Diving' as const,
      titleHeb: 'צלילה',
    },
    moviesAndTv: {
      id: 51 as const,
      title: 'Movies-and-TV' as const,
      titleHeb: 'קולנוע וטלויזיה',
    },
    cosmetics: {
      id: 52 as const,
      title: 'cosmetics' as const,
      titleHeb: 'קוסמטיקה',
    },
    criminology: {
      id: 53 as const,
      title: 'criminology' as const,
      titleHeb: 'קרימינולוגיה',
    },
    accounting: {
      id: 54 as const,
      title: 'Accounting' as const,
      titleHeb: 'ראיית חשבון',
    },
    pharmacy: {
      id: 55 as const,
      title: 'pharmacy' as const,
      titleHeb: 'רוקחות',
    },
    radiologyAndImaging: {
      id: 56 as const,
      title: 'Radiology-and-Imaging' as const,
      titleHeb: 'רנטגנאות והדמייה',
    },
    medicine: {
      id: 57 as const,
      title: 'medicine' as const,
      titleHeb: 'רפואה',
    },
    alternativeMedicine: {
      id: 58 as const,
      title: 'Alternative-Medicine' as const,
      titleHeb: 'רפואה משלימה',
    },
    dentistry: {
      id: 59 as const,
      title: 'dentistry' as const,
      titleHeb: 'רפואת שיניים',
    },
    salesAndMarketing: {
      id: 60 as const,
      title: 'Sales-and-Marketing' as const,
      titleHeb: 'שיווק ומכירות',
    },
    cruises: {
      id: 61 as const,
      title: 'Cruises' as const,
      titleHeb: 'שייט',
    },
    appraisal: {
      id: 62 as const,
      title: 'Appraisal' as const,
      titleHeb: 'שמאות',
    },
    languages: {
      id: 63 as const,
      title: 'Languages' as const,
      titleHeb: 'שפות',
    },
    theaterAndDrama: {
      id: 64 as const,
      title: 'Theater-and-Drama' as const,
      titleHeb: 'תאטרון ודרמה',
    },
    tourist: {
      id: 65 as const,
      title: 'Tourist' as const,
      titleHeb: 'תיירות',
    },
    bible: {
      id: 66 as const,
      title: 'Bible' as const,
      titleHeb: "תנ''ך",
    },
    communication: {
      id: 67 as const,
      title: 'communication' as const,
      titleHeb: 'תקשורת',
    },
    other: {
      id: 68 as const,
      title: 'Other' as const,
      titleHeb: 'אחר',
    },
    privateLessons: {
      id: 69 as const,
      title: 'private-lessons' as const,
      titleHeb: 'שיעורים פרטיים',
    },
    english: {
      id: 70 as const,
      title: 'English' as const,
      titleHeb: 'אנגלית',
    },
    tongue: {
      id: 71 as const,
      title: 'Tongue' as const,
      titleHeb: 'לשון',
    },
    dance: {
      id: 72 as const,
      title: 'Dance' as const,
      titleHeb: 'ריקוד',
    },
    citizenship: {
      id: 73 as const,
      title: 'citizenship' as const,
      titleHeb: 'אזרחות',
    },
  },
};
