/* eslint-disable id-length */
import env from '@y2/environments';

const host = env.name === 'local' ? 'localhost' : env.routes.mainSite;

export const domain = host.replace(/https?:\/\/www/, '');
export const cohortKey = 'y2018-2-cohort';
export const cohortGroupCookieKey = 'cohortGroup';
export const cohortGroups = {
  A: 5,
  B: 20,
  C: 50,
  // default (release to 100%)
  D: 100,
};
export type CohortGroupKey = keyof typeof cohortGroups;
export const groupKeys = Object.keys(cohortGroups) as CohortGroupKey[];
export const groupValues = Object.values(cohortGroups);
