export const urlKey = 'utm_campaign';
export const sessionKey = 'brazeSessionCampaign';

declare global {
  interface Window {
    brazeCampaign?: string;
  }
}

const getBrazeCampaignFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(urlKey);
};

const getBrazeCampaignFromSession = () => {
  const { sessionStorage, brazeCampaign } = window;

  return brazeCampaign || sessionStorage.getItem(sessionKey);
};

const setBrazeCampaignInSession = (campaign: string) => {
  const { sessionStorage } = window;

  sessionStorage.setItem(sessionKey, campaign);
  window.brazeCampaign = campaign;
};

export const addBrazeCampaignIndication = () => {
  const campaign = getBrazeCampaignFromUrl() || getBrazeCampaignFromSession();

  const shouldSetInSession =
    campaign && typeof window.brazeCampaign === 'undefined';
  if (shouldSetInSession) {
    setBrazeCampaignInSession(campaign);
  }

  // eslint-disable-next-line camelcase
  return campaign ? { brz_cmp: campaign } : {};
};
