import * as React from 'react';

const SvgCrown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.012 8.299A.283.283 0 002 8.312V8.7l.015.026c.01.018.021.036.027.056a35048.086 35048.086 0 002.928 9.574c.14.455.392.643.87.643h12.32c.477 0 .73-.188.87-.645l.46-1.508c.508-1.658 1.016-3.316 1.522-4.974l.135-.441c.276-.903.551-1.806.82-2.71a.769.769 0 00-.567-.976c-.234-.057-.446.016-.655.118a1596.455 1596.455 0 01-4.082 1.977l-.06.03-.023-.025a21374.732 21374.732 0 01-2.206-2.53l-1.725-1.98c-.393-.45-.912-.447-1.305.005-1.06 1.22-2.121 2.437-3.182 3.655l-.627.716-.14.158a2128.72 2128.72 0 01-2.674-1.295c-.514-.25-1.028-.499-1.543-.745-.49-.236-.928-.064-1.143.438-.005.011-.014.021-.023.031zm2.072 1.773a24.012 24.012 0 01-.023-.077l1.945.943.571.277.567.276c.47.227.784.16 1.138-.245l2.062-2.366 1.52-1.743c.029-.034.06-.066.094-.102l.046-.05.147.17a2851.34 2851.34 0 011.867 2.14l1.7 1.952c.358.41.667.471 1.157.234l1.26-.611 1.575-.764c.04-.02.082-.038.133-.06l.093-.042-.364 1.193-.032.106-.376 1.232c-.48 1.57-.96 3.142-1.436 4.714-.044.145-.098.202-.263.201a3831.42 3831.42 0 00-10.933-.001c-.146 0-.212-.034-.256-.183-.633-2.088-1.27-4.176-1.908-6.264l-.271-.887a2.99 2.99 0 00-.013-.043z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCrown = React.memo(SvgCrown);
export default MemoSvgCrown;
