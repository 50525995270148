import React from 'react';
import styles from './mobile-strip.module.scss';
import { BackIcon, MenuIcon } from '@y2/deprecated/ui/icons';
import cn from 'classnames';
import texts from './mobile-strip.text';

export type MobileInlineButtons = {
  right?: React.ReactNode;
  left?: React.ReactNode;
};

type Props = {
  logoSlot: React.ReactNode;
  isSticky: boolean;
  hasShadow: boolean;
  onOpenMenuClick: () => void;
  inlineButtons?: MobileInlineButtons;
  hideBackButton?: boolean;
  hideMobileMenu?: boolean;
  color?: 'orange' | 'white';
  className?: string;
};

const onBackButtonClick = () => window.history.back();

const MobileStrip: React.VFC<Props> = ({
  logoSlot,
  isSticky,
  onOpenMenuClick,
  hasShadow,
  hideBackButton = false,
  color = 'orange',
  hideMobileMenu = false,
  inlineButtons = {
    right: null,
    left: null,
  },
  className,
}) => {
  return (
    <div
      className={cn(
        styles.box,
        isSticky && styles.isSticky,
        hasShadow && styles.hasShadow,
        styles[color],
        className,
      )}
      data-testid="mobile-header-strip"
    >
      <div className={styles.container}>
        <div className={styles.buttonsColumn}>
          {!hideMobileMenu && (
            <button
              className={styles.iconButtonWrapper}
              onClick={onOpenMenuClick}
              aria-label={texts.MENU_BTN_ARIA_LABEL}
              aria-hidden={hideMobileMenu}
              data-nagish="header-mobile-strip-button"
              data-testid="open-menu-button"
            >
              <MenuIcon className={styles.icon} />
            </button>
          )}
          {inlineButtons.right}
        </div>
        <div className={styles.middleFloat}>{logoSlot}</div>
        <div className={styles.buttonsColumn}>
          {inlineButtons.left}
          {!hideBackButton && (
            <button
              className={cn(styles.iconButtonWrapper)}
              onClick={onBackButtonClick}
              aria-label={texts.BACK_BTN_ARIA_LABEL}
              data-testid="back-button"
              data-nagish="header-mobile-strip-button"
            >
              <BackIcon className={styles.icon} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileStrip;
