import { AnalyticsEventBaseData } from './types';
import {
  getEnvironmentEventData,
  getPageBreakPoint,
  getRversionEventData,
  getPVersionCommit,
  getReactPageIndicator,
  getIsBotIndication,
} from './helpers';
import { addBrazeCampaignIndication } from './campaigns/braze';
import { DEFAULT_EVENT_NAME, DEFAULT_PAGE_EVENT_NAME } from './constants';
import { getAdBlockStatus } from './ad-block-status-script';
import { triggerPostPageEvent } from './triggers';

type EventDataAdditions = {
  [key: string]: boolean | number | string | object | null;
};

type EventData = AnalyticsEventBaseData & EventDataAdditions;

type EventOptions = {
  includeEnv?: boolean;
  includeCommit?: boolean;
  includePageBreakPoint?: boolean;
  includeRversion?: boolean;
  includeAdBlockStatus?: boolean;
  customEventName?: string;
  disableReactPageIndicator?: boolean;
};

const hasEventOption = (
  eventOptions: EventOptions,
  key: keyof EventOptions,
  hasEventOptions = false,
) =>
  typeof eventOptions[key] !== 'undefined'
    ? eventOptions[key]
    : hasEventOptions;

const sendReactPageIndictor = (
  isPageEvent: boolean,
  eventOptions: EventOptions,
) => isPageEvent && (!eventOptions || !eventOptions.disableReactPageIndicator);

const createEventPusher =
  (eventName: string, isPageEvent = false) =>
  (eventData: EventData, eventOptions: EventOptions = {}) => {
    if (!isClientSide()) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push({
      event: eventName,
      ...(hasEventOption(eventOptions, 'includeEnv', isPageEvent)
        ? getEnvironmentEventData()
        : {}),
      ...(hasEventOption(eventOptions, 'includePageBreakPoint', isPageEvent)
        ? getPageBreakPoint()
        : {}),
      ...(hasEventOption(eventOptions, 'includeRversion', isPageEvent)
        ? getRversionEventData()
        : {}),
      ...(hasEventOption(eventOptions, 'includeCommit', isPageEvent)
        ? getPVersionCommit()
        : {}),
      ...(hasEventOption(eventOptions, 'includeAdBlockStatus', isPageEvent)
        ? getAdBlockStatus()
        : {}),
      ...(sendReactPageIndictor(isPageEvent, eventOptions)
        ? getReactPageIndicator()
        : {}),
      ...getIsBotIndication(),
      ...addBrazeCampaignIndication(),
      ...eventData,
    });

    if (isPageEvent) triggerPostPageEvent();
  };

const isClientSide = () => typeof window !== 'undefined';

export const analytics = {
  pushEvent: createEventPusher(DEFAULT_EVENT_NAME),
  pushPageEvent: createEventPusher(DEFAULT_PAGE_EVENT_NAME, true),
};
