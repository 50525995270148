/* eslint-disable custom-rules/client-width */
import { isWebView } from '@y2/react-native-utils';
import {
  BREAKS,
  LEGACY_APP_RVERSION_PREFIX,
  NATIVE_APP_RVERSION_PREFIX,
  ANDROID_UA_PREFIX,
  IOS_UA_PREFIX,
  isBrowser,
} from './constants';
import { isPossibleBot } from './is-bot';

export const envState = {
  environment: '',
  setEnvironment: (env: string) => {
    envState.environment = env;
  },
};

export const getEnvironmentEventData = () =>
  envState.environment
    ? {
        Environment: envState.environment,
      }
    : {};

const getDesktopBreakPoint = (
  width: number,
  ignoreSpecialDesktops: boolean,
) => {
  const isSmallDesktop = width > BREAKS.tablet && width <= BREAKS.smallDesk;
  const isRegularDesktop =
    width > BREAKS.smallDesk && width <= BREAKS.largeDesk;
  if (ignoreSpecialDesktops || isRegularDesktop) {
    return 'desktop';
  } else if (isSmallDesktop) {
    return 'desktop-small';
  }

  return 'desktop-wide';
};

const getDeviceNameForBreakPoint = (
  width: number,
  ignoreSpecialDesktops: boolean,
) => {
  if (width <= BREAKS.mobile) {
    return 'mobile';
  } else if (width > BREAKS.mobile && width <= BREAKS.tablet) {
    return 'tablet';
  }

  return getDesktopBreakPoint(width, ignoreSpecialDesktops);
};

export const getPageBreakPoint = () => ({
  PageBreakPoint: getDeviceNameForBreakPoint(window.innerWidth, false),
});

const getMobileAppRversion = () => {
  const { userAgent } = window.navigator;
  const isNative = isWebView('REACT_NATIVE_APP');
  const appUserAgentPrefix = isNative
    ? NATIVE_APP_RVERSION_PREFIX
    : LEGACY_APP_RVERSION_PREFIX;

  let device;
  if (userAgent.includes(ANDROID_UA_PREFIX)) {
    device = 'ANDROID';
  }
  if (userAgent.includes(IOS_UA_PREFIX)) {
    device = 'IOS';
  }

  return `${appUserAgentPrefix}${device}`;
};

const hasWebView = () =>
  isWebView('REACT_NATIVE_APP') || isWebView('LEGACY_APP');

const getRversionStr = () => {
  const isInMobileApp = hasWebView();

  return isInMobileApp
    ? getMobileAppRversion()
    : `RESPONSIVE_${getDeviceNameForBreakPoint(
        Number(window.top?.innerWidth),
        true,
      ).toUpperCase()}_WEB_3.0`;
};

export const getRversionEventData = () => ({
  Rversion: getRversionStr(),
});

export const getPVersionCommit = () => ({
  pVersion: process.env.NEXT_PUBLIC_COMMIT_HASH || 'no-commit',
});

export const getReactPageIndicator = () => ({
  SiteType: 'React',
});

const isBot = isBrowser ? isPossibleBot() : false;

export const getIsBotIndication = () => ({
  IsBot: isBot,
});
