import * as React from 'react';
const SvgFilterEco2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72973 7.19595H13.8034C14.1211 6.07289 15.1536 5.25 16.3784 5.25C17.6031 5.25 18.6357 6.07289 18.9533 7.19595H20.2703C20.6733 7.19595 21 7.52266 21 7.92568C21 8.32869 20.6733 8.65541 20.2703 8.65541H18.9533C18.6357 9.77846 17.6031 10.6014 16.3784 10.6014C15.1536 10.6014 14.1211 9.77846 13.8034 8.65541H3.72973C3.32671 8.65541 3 8.32869 3 7.92568C3 7.52266 3.32671 7.19595 3.72973 7.19595ZM16.3784 6.70946C15.7067 6.70946 15.1622 7.25398 15.1622 7.92568C15.1622 8.59737 15.7067 9.14189 16.3784 9.14189C17.0501 9.14189 17.5946 8.59737 17.5946 7.92568C17.5946 7.25398 17.0501 6.70946 16.3784 6.70946Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72973 14.9797C3.32671 14.9797 3 15.3064 3 15.7095C3 16.1125 3.32671 16.4392 3.72973 16.4392H6.01964C6.33728 17.5622 7.36984 18.3851 8.59459 18.3851C9.81935 18.3851 10.8519 17.5622 11.1696 16.4392H20.2703C20.6733 16.4392 21 16.1125 21 15.7095C21 15.3064 20.6733 14.9797 20.2703 14.9797H11.1696C10.8519 13.8567 9.81935 13.0338 8.59459 13.0338C7.36984 13.0338 6.33728 13.8567 6.01964 14.9797H3.72973ZM9.81081 15.7095C9.81081 15.0378 9.26629 14.4932 8.59459 14.4932C7.9229 14.4932 7.37838 15.0378 7.37838 15.7095C7.37838 16.3812 7.9229 16.9257 8.59459 16.9257C9.26629 16.9257 9.81081 16.3812 9.81081 15.7095Z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgFilterEco2 = React.memo(SvgFilterEco2);
export default MemoSvgFilterEco2;
