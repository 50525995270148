import * as React from 'react';
const SvgDetailedMicrophone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46 6.264A4.54 4.54 0 0112 1.723a4.54 4.54 0 014.541 4.54v5.213a4.54 4.54 0 01-4.54 4.541 4.54 4.54 0 01-4.542-4.54V6.263zM12 3.223a3.04 3.04 0 00-3.04 3.04v5.213A3.04 3.04 0 0012 14.517a3.04 3.04 0 003.041-3.04V6.263a3.04 3.04 0 00-3.04-3.041zm-7.25 7.361a.75.75 0 01.75.75c0 3.585 2.915 6.5 6.5 6.5 3.586 0 6.5-2.915 6.5-6.5a.75.75 0 011.5 0c0 4.16-3.187 7.587-7.25 7.966v2.128a.75.75 0 11-1.5 0V19.3C7.187 18.92 4 15.495 4 11.334a.75.75 0 01.75-.75zm6.191-3.209a3.066 3.066 0 012.119 0 .75.75 0 00.516-1.408 4.566 4.566 0 00-3.151 0 .75.75 0 10.516 1.408zm1.636 2.03c-.377-.1-.766-.1-1.143 0a.75.75 0 01-.383-1.45 3.726 3.726 0 011.91 0 .75.75 0 11-.384 1.45z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDetailedMicrophone = React.memo(SvgDetailedMicrophone);
export default MemoSvgDetailedMicrophone;
