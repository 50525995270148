import * as React from 'react';

const SvgStarFull = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.754 3.543a1.75 1.75 0 00-2.333.825L8.677 8.023a.25.25 0 01-.193.14l-4.015.53a1.75 1.75 0 00-.976 3.004l2.937 2.788a.25.25 0 01.073.226l-.737 3.982a1.75 1.75 0 002.556 1.857l3.559-1.932a.25.25 0 01.238 0l3.56 1.932a1.75 1.75 0 002.555-1.857l-.738-3.982a.25.25 0 01.074-.226l2.937-2.788a1.75 1.75 0 00-.976-3.005l-4.015-.529a.25.25 0 01-.193-.14L13.58 4.368a1.75 1.75 0 00-.825-.825z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgStarFull = React.memo(SvgStarFull);
export default MemoSvgStarFull;
