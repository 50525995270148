import * as React from 'react';
const SvgWinner = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.837 13.199l-2.973.94a.569.569 0 01-.558-.08.595.595 0 01-.254-.503v-4.08a4.918 4.918 0 01-1.016-1.58 5.068 5.068 0 01-.361-1.912c0-1.441.5-2.662 1.5-3.662s2.22-1.5 3.661-1.5c1.441 0 2.662.5 3.663 1.5 1 1 1.501 2.221 1.501 3.662 0 .676-.12 1.313-.361 1.912a4.917 4.917 0 01-1.016 1.58v4.08c0 .21-.084.377-.254.503a.569.569 0 01-.558.08l-2.974-.94zm0-3.085c1.148 0 2.123-.401 2.926-1.204.803-.803 1.205-1.779 1.205-2.926 0-1.147-.402-2.122-1.205-2.925-.803-.803-1.778-1.205-2.926-1.205-1.147 0-2.122.402-2.925 1.205-.803.803-1.205 1.778-1.205 2.925 0 1.147.402 2.123 1.205 2.926.803.803 1.778 1.204 2.925 1.204zm-2.753 2.777l2.753-.738 2.754.739v-2.558a5.055 5.055 0 01-2.755.813 5.061 5.061 0 01-2.752-.813v2.558z"
        fill="currentColor"
      />
      <path
        d="M5.053 7.795L3.578 6.256a.528.528 0 010-.725c.192-.2.503-.2.695 0l1.128 1.183 2.001-2.11c.192-.2.503-.2.695 0 .192.2.192.526 0 .726L5.748 7.795c-.192.2-.503.2-.695 0z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgWinner = React.memo(SvgWinner);
export default MemoSvgWinner;
