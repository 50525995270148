import { cookie } from '@y2/packages-utils/cookies';
import type { NextApiRequest } from 'next';
import { cohortGroups, cohortKey, groupValues } from './consts';
import { NextRequest } from 'next/server';

export const getCohortGroupValue = (
  cohortValue: string | number | undefined,
) => {
  const isUnset = !cohortValue && cohortValue !== 0;
  const parsedValue = parseInt(String(cohortValue));
  if (isUnset || isNaN(parsedValue)) {
    return cohortGroups.D;
  }

  for (const group of groupValues) {
    if (parsedValue <= group) {
      return group;
    }
  }

  return cohortGroups.D;
};

// in SSR
export const getCohortGroupFromReq = (req: NextApiRequest) => {
  const cookies = req.cookies || {};

  return getCohortGroupValue(cookies[cohortKey]);
};

// in Browser
export const getCohortGroup = () => {
  const cohort = cookie.get(cohortKey);
  return getCohortGroupValue(cohort);
};

// in middleware
export const getCohortFromMiddleware = (cookies: NextRequest['cookies']) => {
  const cohort = cookies.get(cohortKey)?.value;
  return getCohortGroupValue(cohort);
};
