import * as React from 'react';
const SvgBaseball = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.119 20.789a8.82 8.82 0 01-.667-.18 8.455 8.455 0 01-3.95-2.628 11.454 11.454 0 004.083-6.129 11.455 11.455 0 00-.471-7.348 8.457 8.457 0 015.322-.148 8.457 8.457 0 014.061 2.662 11.455 11.455 0 00-4.082 6.128 11.455 11.455 0 00.471 7.35 8.456 8.456 0 01-4.767.293zm6.129-.927a9.956 9.956 0 01-.384-6.327 9.956 9.956 0 013.496-5.288 8.474 8.474 0 01.85 6.452 8.474 8.474 0 01-3.962 5.163zM7.752 5.137a9.956 9.956 0 01.384 6.327 9.955 9.955 0 01-3.496 5.288 8.473 8.473 0 01-.85-6.453 8.473 8.473 0 013.962-5.162zM3.39 17.586A9.97 9.97 0 012.34 9.91 9.97 9.97 0 017.088 3.79l.017-.01a10.052 10.052 0 011.642-.737 9.954 9.954 0 015.842-.203 9.955 9.955 0 016.022 4.573 9.97 9.97 0 011.05 7.675 9.97 9.97 0 01-4.747 6.121l-.017.01a10.043 10.043 0 01-1.642.737 9.954 9.954 0 01-5.842.203A9.955 9.955 0 014.25 18.82l-.03-.036c-.306-.38-.583-.78-.83-1.2z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDogChewToy = React.memo(SvgBaseball);
export default MemoSvgDogChewToy;
