import { categories } from '../data/index';

export const getCategoryEngNameById = (categoryId: number) => {
  return Object.values(categories).find(
    (category) => category.id === categoryId,
  )?.title;
};

export const getCategoryHebNameById = (categoryId: number) => {
  return Object.values(categories).find(
    (category) => category.id === categoryId,
  )?.titleHeb;
};

type CategoryName =
  | 'vehicles'
  | 'realEstate'
  | 'commercial'
  | 'products'
  | 'pets'
  | 'privateTeachers'
  | 'recreation'
  | 'recommerce';

const categoryMapping = {
  realestate: 'realEstate',
  'private-teachers': 'privateTeachers',
  vehicles: 'vehicles',
  commercial: 'commercial',
  products: 'products',
  pets: 'pets',
  recreation: 'recreation',
  recommerce: 'recommerce',
};

export const getCategoryByEngName = (
  category: keyof typeof categoryMapping,
) => {
  const categoryName = categoryMapping[category] as keyof typeof categories;
  return categories[categoryName];
};

type Category = keyof typeof categoryMapping;

export const getSubcategoryByEngName = (
  category: Category,
  subcategory: string,
) => {
  const categoryData = getCategoryByEngName(category);
  return Object.values(categoryData.subCategories).find(
    (subcategoryData) => subcategoryData.title === subcategory,
  );
};

export const getSubcategoryEngNameById = (
  categoryId: number,
  subcategoryId: number,
) => {
  const categoryName = getCategoryEngNameById(categoryId);

  if (categoryName) {
    const categoryMap = categoryMapping[categoryName] as CategoryName;

    return Object.values(categories[categoryMap]?.subCategories).find(
      (subcategory) => subcategory.id === subcategoryId,
    )?.title as string;
  }
  return;
};

export const getSubcategoryHebNameById = (
  categoryId: number,
  subcategoryId: number,
) => {
  const categoryName = getCategoryEngNameById(categoryId);

  if (categoryName) {
    const categoryMap = categoryMapping[categoryName] as CategoryName;

    return Object.values(categories[categoryMap]?.subCategories).find(
      (subcategory) => subcategory.id === subcategoryId,
    )?.titleHeb as string;
  }
  return;
};
