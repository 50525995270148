import * as React from 'react';

const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 11.25a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.102-1.493l.102-.007h13z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMinus = React.memo(SvgMinus);
export default MemoSvgMinus;
