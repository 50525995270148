import {
  LazyLoadLottie,
  Props as LazyLottieProps,
} from '../conditional-lazy-loader';

type Props = Omit<LazyLottieProps, 'fetchAnimationData'>;

const fetchAnimationData = () => import('./yad2-v-check-white.json');

export const Yad2VCheckWhite = (props: Props) => (
  <LazyLoadLottie fetchAnimationData={fetchAnimationData} {...props} />
);
