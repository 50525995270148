import * as React from 'react';
const SvgKm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.226 15.868c.385.385.943.564 1.672.539.73-.025 1.263-.296 1.598-.814l4.503-7.01c.117-.184.104-.347-.038-.49-.143-.141-.306-.154-.49-.037l-6.969 4.532c-.503.334-.771.868-.805 1.603-.033.734.143 1.294.529 1.677zM4.817 20c-.285 0-.566-.07-.842-.213a1.367 1.367 0 01-.617-.613 11.915 11.915 0 01-1.006-2.442A9.624 9.624 0 012 14.091c0-1.386.264-2.692.792-3.919a10.225 10.225 0 012.151-3.205 10.272 10.272 0 013.182-2.166A9.555 9.555 0 0112.012 4c.754 0 1.543.096 2.364.288a9.337 9.337 0 012.44.964c.336.184.52.405.554.663a.67.67 0 01-.252.639 1 1 0 01-.465.188.765.765 0 01-.49-.088 9.4 9.4 0 00-2.19-.864c-.754-.192-1.408-.288-1.961-.288-2.348 0-4.352.835-6.012 2.504-1.66 1.67-2.49 3.698-2.49 6.085 0 .75.104 1.51.314 2.278.21.768.507 1.478.893 2.129h14.565c.369-.601.662-1.302.88-2.104a8.939 8.939 0 00.327-2.353c0-.618-.08-1.277-.239-1.978a6.685 6.685 0 00-.818-2.004.71.71 0 01-.1-.513.753.753 0 01.251-.438.708.708 0 01.604-.163.72.72 0 01.503.363c.42.768.738 1.532.956 2.291.218.76.335 1.524.352 2.291a10.656 10.656 0 01-.314 2.83 9.088 9.088 0 01-1.019 2.454c-.201.384-.415.617-.641.7a2.455 2.455 0 01-.843.126H4.817z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgKm = React.memo(SvgKm);
export default MemoSvgKm;
