import * as React from 'react';

const SvgParking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20.75a.75.75 0 01-.75-.75V4A.75.75 0 014 3.25h16a.75.75 0 01.75.75v16a.75.75 0 01-.75.75H4zm.75-16v14.5h14.5V4.75H4.75zM9.25 7l.06.003A.891.891 0 019.383 7h3.97A2.647 2.647 0 0116 9.647v.882a2.647 2.647 0 01-2.647 2.648H10v3.529c0 .487-.336.882-.75.882s-.75-.395-.75-.882V7.882c0-.487.336-.882.75-.882zM10 8.324v3.529h3.706c.498 0 .908-.454.964-1.04l.007-.136V9.5c0-.603-.375-1.1-.858-1.169l-.113-.007H10z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgParking = React.memo(SvgParking);
export default MemoSvgParking;
