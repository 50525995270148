import * as React from 'react';
const SvgSad = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#sad_svg__clip0_6943_85606)" fill="currentColor">
        <path d="M10 20C4.488 20 0 15.514 0 10S4.487 0 10 0c5.515 0 10.001 4.486 10.001 10s-4.486 10-10 10zm0-18.868C5.112 1.132 1.134 5.11 1.134 10c0 4.89 3.978 8.867 8.868 8.867 4.89 0 8.868-3.978 8.868-8.867 0-4.89-3.979-8.868-8.868-8.868z" />
        <path d="M6.108 8.62a1.238 1.238 0 100-2.475 1.238 1.238 0 000 2.475zM13.892 8.638a1.238 1.238 0 100-2.476 1.238 1.238 0 000 2.476zM13.766 15.086a.567.567 0 01-.281-.075 7.022 7.022 0 00-3.551-.93 7.022 7.022 0 00-3.418.93.566.566 0 01-.564-.983 8.16 8.16 0 013.971-1.08 8.14 8.14 0 014.126 1.08.566.566 0 01-.283 1.058z" />
      </g>
      <defs>
        <clipPath id="sad_svg__clip0_6943_85606">
          <path fill="currentColor" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgSad = React.memo(SvgSad);
export default MemoSvgSad;
