import { useState } from 'react';
import { Popover } from '../../popover';
import { CategoryLink } from '../../category-link';
import { MenuCategory } from '../../menu-categories-data';
import { SubCategoryLink } from '../../sub-category-link';
import { LinkBehaviorProps } from '../../helpers';
import { ListItem, ItemBackButton, MenuItemContentWrapper } from '../../item';
import { Yad1SubCategoryLink } from '../yad1-link/yad1-link';
import {
  MortgageIcon,
  PropertyIcon,
  RealEstateIcon,
  RisingGraphIcon,
} from '@y2/mango/components/icons';
import texts from './main-realestate-item.texts.json';

type Props = {
  item: MenuCategory;
  disableAnalyticsEvents?: boolean;
} & LinkBehaviorProps;

export const RealestateItem = ({
  item,
  disableAnalyticsEvents,
  ...linkBehaviorProps
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ListItem
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(true)}
      data-testid="header-item"
    >
      <CategoryLink
        url={item.url}
        text={item.categoryText}
        icon={item.icon}
        isNewTab={item.newTab}
        disableAnalyticsEvents={disableAnalyticsEvents}
        {...linkBehaviorProps}
      />
      <Popover open={open}>
        <ItemBackButton
          text={item.categoryText}
          onClick={() => setOpen(false)}
        />
        <MenuItemContentWrapper rowsInDesktop={5}>
          <SubCategoryLink
            text={texts.forsale}
            url="/realestate/forsale"
            desktopColumnPlacement="1"
          />
          <SubCategoryLink
            text={texts.rent}
            url="/realestate/rent"
            desktopColumnPlacement="1"
          />
          <SubCategoryLink
            text={texts.commercial}
            url="/realestate/commercial"
            desktopColumnPlacement="1"
          />
          <SubCategoryLink
            text={texts.businessesForSale}
            url="/products/businesses-for-sale?category=37"
            desktopColumnPlacement="1"
          />
          <Yad1SubCategoryLink desktopColumnPlacement="2" />
          <SubCategoryLink
            text={texts.foreclosure}
            url="/kones/realestate?yad2_source=SubMenu_Nadlan_Link"
            icon={PropertyIcon}
            isNewTab
            desktopColumnPlacement="2"
          />
          <SubCategoryLink
            text={texts.agencies}
            url="/realestate/agencies"
            icon={RealEstateIcon}
            isNewTab
            desktopColumnPlacement="2"
          />
          <SubCategoryLink
            text={texts.mortgageConsulting}
            url="https://sc.mizrahi-tefahot.co.il/TofesMashkantaClient/?cID=116&sID=20&pID=592&oID=7&lID=5&trg=TFH&src=7&utm_source=yad2&utm_medium=link&utm_campaign=yad2_1124"
            icon={MortgageIcon}
            isNewTab
            desktopColumnPlacement="2"
          />
          <SubCategoryLink
            text={texts.yadata}
            url="https://yadata.yad2.co.il/?yad2_source=HeaderSubmenu"
            icon={RisingGraphIcon}
            isNewTab
            desktopColumnPlacement="2"
            isNewTag
          />
        </MenuItemContentWrapper>
      </Popover>
    </ListItem>
  );
};
