import * as React from 'react';

const SvgCreate = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.64 3a1.48 1.48 0 00-1.046.433l-6.774 6.781-.02.022a.643.643 0 00-.145.26L9.62 14.18l-.007.029a.644.644 0 00.803.767l3.69-1.035.03-.008a.645.645 0 00.253-.157l6.774-6.782.027-.028a1.477 1.477 0 00-.028-2.061l-1.474-1.472-.028-.028A1.48 1.48 0 0018.64 3zm-6.787 8.006l5.167-5.173 1.74 1.74-5.167 5.172-2.42.679.68-2.418zm5.874-5.88l.78-.78.04-.03a.189.189 0 01.215.018l.012.011 1.474 1.472a.187.187 0 01.01.254l-.01.012-.782.782-1.739-1.739z"
        fill="currentColor"
      />
      <path
        d="M14.107 4.294a.75.75 0 00-.75-.75H5.054l-.143.006a1.75 1.75 0 00-1.607 1.744v14l.006.143a1.75 1.75 0 001.744 1.607h14l.144-.006a1.75 1.75 0 001.606-1.744v-8.082l-.007-.102a.75.75 0 00-1.493.102v8.082l-.006.057a.25.25 0 01-.244.193h-14l-.057-.007a.25.25 0 01-.193-.243v-14l.007-.058a.25.25 0 01.243-.192h8.303l.102-.007a.75.75 0 00.648-.743z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCreate = React.memo(SvgCreate);
export default MemoSvgCreate;
