import * as React from 'react';
const SvgMortgage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.127 20.602a.236.236 0 01-.087.02.236.236 0 01-.086-.02L4.07 18.781a.866.866 0 01.317-.522.88.88 0 01.545-.182H9.83c.436 0 .807-.017 1.112-.05.305-.033.619-.106.94-.22l1.57-.513a.689.689 0 00.435-.384.785.785 0 00.036-.588.717.717 0 00-.372-.438.743.743 0 00-.58-.043l-1.3.455a5.196 5.196 0 01-1.015.205c-.375.04-.86.066-1.456.076h-.267c0-.248.055-.461.167-.64a.83.83 0 01.44-.364l5.802-2.13a.455.455 0 01.053-.015.262.262 0 01.053-.005h1.85v5.154l-7.171 2.025zm.39 1.47l6.781-1.945c.114.414.346.746.696.997.35.25.72.376 1.112.376h1.384a1.74 1.74 0 001.277-.531 1.74 1.74 0 00.531-1.277v-5.961a1.74 1.74 0 00-.531-1.277 1.74 1.74 0 00-1.277-.53h-5.03c-.106 0-.212.01-.32.034-.107.023-.207.05-.3.08l-5.827 2.147c-.453.168-.83.465-1.13.891-.3.426-.45.927-.45 1.501h-2.5c-.718 0-1.303.232-1.755.695-.452.464-.678 1.065-.678 1.805 0 .283.075.511.225.684.15.174.377.31.68.412l6.068 1.883c.164.054.337.082.517.085.181.004.357-.02.527-.07zm10.281-2.38a.3.3 0 01-.086.221.3.3 0 01-.222.087h-1.384a.335.335 0 01-.222-.072c-.057-.048-.086-.127-.086-.236v-6.269h1.692c.09 0 .164.029.222.087a.3.3 0 01.086.22v5.962z"
        fill="currentColor"
      />
      <path
        d="M15.12 9.85v-.87a.2.2 0 01.194-.199l2.017-.052a.2.2 0 00.13-.347l-6.88-6.334a1 1 0 00-1.375.02L2.578 8.512a.2.2 0 00.14.343h1.801c.11 0 .2.09.2.2v5.476"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};
const MemoSvgMortgage = React.memo(SvgMortgage);
export default MemoSvgMortgage;
