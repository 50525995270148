import React from 'react';

const SvgLayersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0725 2.69569C11.64 2.34102 12.36 2.34102 12.9275 2.69569L19.0407 6.51644C20.1374 7.20186 20.1374 8.79902 19.0407 9.48444L18.2151 10.0005L19.0406 10.5164C20.1373 11.2018 20.1373 12.799 19.0406 13.4844L18.215 14.0004L19.0406 14.5164C20.1373 15.2018 20.1373 16.799 19.0406 17.4844L12.9274 21.3052C12.3599 21.6598 11.6399 21.6598 11.0724 21.3052L4.95922 17.4844C3.86255 16.799 3.86255 15.2018 4.95922 14.5164L5.78483 14.0004L4.95922 13.4844C3.86255 12.799 3.86255 11.2018 4.95922 10.5164L5.78485 10.0004L4.9593 9.48444C3.86263 8.79902 3.86263 7.20186 4.9593 6.51644L11.0725 2.69569ZM7.19992 14.8849L5.75422 15.7884L5.35672 15.1524L5.75422 15.7884C5.59755 15.8863 5.59755 16.1145 5.75422 16.2124L11.8674 20.0332C11.9485 20.0838 12.0514 20.0838 12.1324 20.0332L18.2456 16.2124C18.4023 16.1145 18.4023 15.8863 18.2456 15.7884L16.7999 14.8849L12.9274 17.3052C12.3599 17.6598 11.6399 17.6598 11.0724 17.3052L7.19992 14.8849ZM12.9275 13.3052C12.5584 13.5359 12.1248 13.6165 11.7106 13.5471C11.4879 13.5098 11.2708 13.4292 11.0724 13.3052L7.19992 10.8849L5.75422 11.7884L5.35672 11.1524L5.75422 11.7884C5.59755 11.8863 5.59755 12.1145 5.75422 12.2124L7.19992 13.116L7.59742 13.3644L11.8674 16.0332C11.9485 16.0838 12.0514 16.0838 12.1324 16.0332L16.4024 13.3644L16.7999 13.116L18.2456 12.2124C18.4023 12.1145 18.4023 11.8863 18.2456 11.7884L16.8 10.8849L12.9275 13.3052ZM18.2457 8.21244L16.8 9.11602L16.7999 9.11599L16.4024 9.36443L12.1324 12.0332C12.0868 12.0617 12.0344 12.0741 11.9828 12.0706C11.9669 12.0695 11.9511 12.0669 11.9356 12.0628C11.9119 12.0565 11.889 12.0466 11.8675 12.0332L5.7543 8.21244C5.59763 8.11452 5.59763 7.88636 5.7543 7.78844L11.8675 3.96769C11.9486 3.91702 12.0514 3.91702 12.1325 3.96769L18.2457 7.78844C18.4024 7.88636 18.4024 8.11452 18.2457 8.21244Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLayersIcon = React.memo(SvgLayersIcon);
export default MemoSvgLayersIcon;
