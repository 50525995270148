/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';

const SvgVerifiedYad2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="currentColor"
      {...props}
    >
      <path
        d="M22.693 1.78219L24.6189 3.70804C25.5843 4.67348 26.8902 5.21289 28.2535 5.21289H30.9786C33.0805 5.21289 34.7871 6.91753 34.7871 9.02143V11.7465C34.7871 13.11 35.3291 14.4183 36.292 15.3811L38.2178 17.307C39.7052 18.7943 39.7052 21.2057 38.2178 22.693L36.292 24.6189C35.3265 25.5843 34.7871 26.8902 34.7871 28.2535V30.9786C34.7871 33.0805 33.0825 34.7871 30.9786 34.7871H28.2535C26.89 34.7871 25.5817 35.3291 24.6189 36.292L22.693 38.2178C21.2057 39.7052 18.7943 39.7052 17.307 38.2178L15.3811 36.292C14.4157 35.3265 13.1098 34.7871 11.7465 34.7871H9.02143C6.91947 34.7871 5.21289 33.0825 5.21289 30.9786V28.2535C5.21289 26.89 4.67088 25.5817 3.70804 24.6189L1.78219 22.693C0.294826 21.2057 0.294826 18.7943 1.78219 17.307L3.70804 15.3811C4.67348 14.4157 5.21289 13.1098 5.21289 11.7465V9.02143C5.21289 6.91947 6.91753 5.21289 9.02143 5.21289H11.7465C13.11 5.21289 14.4183 4.67088 15.3811 3.70804L17.307 1.78219C18.7943 0.294826 21.2057 0.294826 22.693 1.78219Z"
        fill="currentColor"
        stroke="white"
        stroke-width="1.33333"
      />
      <path
        d="M16.1904 27.7862C15.7956 27.7862 15.4032 27.637 15.1023 27.336L10.2347 22.4684C9.6353 21.869 9.6353 20.8941 10.2347 20.2922C10.8341 19.6928 11.8091 19.6928 12.4085 20.2922L16.188 24.0717L27.5963 12.6634C28.1957 12.064 29.1707 12.064 29.7701 12.6634C30.3695 13.2629 30.3695 14.2378 29.7701 14.8396L17.2737 27.336C16.9728 27.637 16.5804 27.7862 16.1856 27.7862H16.1904Z"
        fill="white"
      />
    </svg>
  );
};

const MemoSvgVerifiedYad2 = React.memo(SvgVerifiedYad2);
export default MemoSvgVerifiedYad2;
