import * as React from 'react';

const SvgStar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.42 4.368L8.678 8.023a.25.25 0 01-.193.14l-4.015.53a1.75 1.75 0 00-.976 3.004l2.937 2.788a.25.25 0 01.073.226l-.737 3.982a1.75 1.75 0 002.556 1.857l3.559-1.932a.25.25 0 01.238 0l3.56 1.932a1.75 1.75 0 002.555-1.857l-.738-3.982a.25.25 0 01.074-.226l2.937-2.788a1.75 1.75 0 00-.976-3.005l-4.015-.529a.25.25 0 01-.193-.14L13.58 4.368a1.75 1.75 0 00-3.159 0zm1.688.528a.25.25 0 01.118.118l1.743 3.655a1.75 1.75 0 001.351.981l4.015.53a.25.25 0 01.14.429l-2.938 2.788a1.75 1.75 0 00-.515 1.587l.737 3.982a.25.25 0 01-.365.265l-3.56-1.931a1.75 1.75 0 00-1.669 0L7.606 19.23a.25.25 0 01-.365-.265l.737-3.982a1.75 1.75 0 00-.516-1.587l-2.936-2.788a.25.25 0 01.14-.43L8.68 9.65a1.75 1.75 0 001.35-.981l1.744-3.655a.25.25 0 01.334-.118z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

const MemoSvgStar = React.memo(SvgStar);
export default MemoSvgStar;
