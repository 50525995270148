import { MenuItem } from '../item';
import { RecommerceMenuItem } from '../recommerce-item';
import { OpenStateProvider } from '../recommerce-item/mobile-menu/open-context';
import type { MenuCategory } from '../menu-categories-data';
import styles from './menu-list-items.module.scss';
import { LinkBehaviorProps } from '../helpers';
import { RealestateItem } from '../realestate-item/main/main-realestate-item';

type Props = {
  list: MenuCategory[];
  disableAnalyticsEvents?: boolean;
} & LinkBehaviorProps;

export const MenuListNavbar = ({ children }: { children: React.ReactNode }) => (
  <nav className={styles.root}>
    <ul className={styles.list}>{children}</ul>
  </nav>
);

export const MenuListItems = ({
  list,
  disableAnalyticsEvents,
  ...linkBehaviorProps
}: Props) => (
  <OpenStateProvider>
    <MenuListNavbar>
      {list.map((item, idx) => {
        if (item.isRecommerce) {
          return (
            <RecommerceMenuItem
              key={`${item.categoryText}_${idx}`}
              disableAnalyticsEvents={disableAnalyticsEvents}
              categoryMenuItem={item}
              {...linkBehaviorProps}
            />
          );
        }

        if (item.isRealestate) {
          return (
            <RealestateItem
              key={`${item.categoryText}_${idx}`}
              disableAnalyticsEvents={disableAnalyticsEvents}
              item={item}
              {...linkBehaviorProps}
            />
          );
        }
        return (
          <MenuItem
            key={`${item.categoryText}_${idx}`}
            disableAnalyticsEvents={disableAnalyticsEvents}
            item={item}
            {...linkBehaviorProps}
          />
        );
      })}
    </MenuListNavbar>
  </OpenStateProvider>
);
