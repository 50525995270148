import * as React from 'react';
const SvgWatertap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.081 2.777A1.96 1.96 0 007.915 4.16a2.19 2.19 0 001.013 2.52l4.01 2.295.05.038c.043.044.069.104.069.168v2.342l-7.456-1.73-.145-.027a1.658 1.658 0 00-1.683.843l-.597 1.084-.064.128a1.658 1.658 0 00.977 2.24l-.076.235-.038.14a1.658 1.658 0 001.017 1.917l1.569.608.135.046a1.658 1.658 0 001.896-.757l.379-.649 4.086 1.29v.603a1.896 1.896 0 00-1.503 1.854v.948c0 .523.425.948.948.948h7.58a.948.948 0 00.948-.948v-.948c0-.873-.591-1.609-1.395-1.828V7l-.005-.165a2.606 2.606 0 00-2.03-2.378l-7.375-1.655-.144-.026zm8.133 14.677V7.001c0-.515-.332-.965-.812-1.124l-.114-.032L9.914 4.19a.54.54 0 00-.636.374c-.09.307.02.634.268.825l.088.059 4.01 2.294c.473.271.78.755.828 1.292l.006.148v8.272h3.736zM5.278 11.178l7.778 1.806V15.4l-8.5-2.682-.042-.018a.237.237 0 01-.094-.322l.597-1.084.037-.05a.237.237 0 01.224-.067zm.086 3.557l.08-.245 2.136.674-.217.37-.034.046a.237.237 0 01-.256.056l-1.569-.607-.053-.03a.237.237 0 01-.087-.264zm13.77 4.14h-5.686a.474.474 0 00-.466.39l-.007.084-.001.474h6.633v-.474a.474.474 0 00-.388-.466l-.085-.007z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgWatertap = React.memo(SvgWatertap);
export default MemoSvgWatertap;
