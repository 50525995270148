export const yad2Sitekey = '84edd4de146581a0440e9f0c58c32066';

export const yad2Integritykey =
  'sha512-gKQdQe59Hw864B9Pbxo+0aPeJJQdKODsfg0Vwf+zIQcRS7uR+kd3oEX3ClAdDERLQbO5LmliXmzjNjrxkP5wgA==';

export const ACCESSIBILITY_ID = 'INDmenu-btn';

export const CLICK_EVENT = {
  GA_event_category: 'Accessibility',
  GA_event_action: 'accessibility button clicked',
  GA_event_label: 'accessibility button clicked',
};
