import cn from 'classnames';
import { Button, ButtonProps, Size } from '../base';
import styles from './button-with-icon.module.scss';
import { ReactNode } from 'react';

export type IconButtonProps = ButtonProps & {
  iconPosition: 'left' | 'right';
  iconSlot: ReactNode;
  hasAdjustedPadding?: boolean;
};

const defaultSize: Size = 'small';

const IconContainer = ({ icon }: { icon: ReactNode }) => (
  <div className={styles.icon}>{icon}</div>
);

const getIconSizeClass = (buttonProps: ButtonProps) => {
  const { mobileOnlySize, desktopOnlySize, height } = buttonProps;
  const noStylePerDevice = !mobileOnlySize && !desktopOnlySize;
  const size = height || defaultSize;

  return [
    mobileOnlySize && styles[`mobile-icon-${mobileOnlySize}`],
    desktopOnlySize && styles[`desktop-icon-${desktopOnlySize}`],
    noStylePerDevice && styles[`default-icon-${size}`],
  ];
};

const getIconColorClass = (buttonProps: ButtonProps) => {
  const { mobileOnlyVariant, desktopOnlyVariant, variant } = buttonProps;
  const noStylePerDevice = !mobileOnlyVariant && !desktopOnlyVariant;

  return [
    mobileOnlyVariant && styles[`mobile-icon-color-${mobileOnlyVariant}`],
    desktopOnlyVariant && styles[`desktop-icon-color-${desktopOnlyVariant}`],
    noStylePerDevice && styles[`default-icon-color-${variant}`],
  ];
};

export const IconButton = ({
  iconPosition,
  iconSlot,
  className,
  children,
  hasAdjustedPadding = true,
  ...buttonProps
}: IconButtonProps): JSX.Element => {
  return (
    <Button
      className={cn(
        styles.withIcon,
        ...getIconSizeClass(buttonProps),
        ...getIconColorClass(buttonProps),
        hasAdjustedPadding && styles[iconPosition],
        className,
      )}
      {...buttonProps}
    >
      {iconPosition === 'right' && <IconContainer icon={iconSlot} />}
      {children}
      {iconPosition === 'left' && <IconContainer icon={iconSlot} />}
    </Button>
  );
};
