import * as React from 'react';
const SvgSend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.518 2.603l17.879 8.451a1.043 1.043 0 010 1.892L3.518 21.397a1.066 1.066 0 01-1.414-.493 1.037 1.037 0 01-.066-.728l1.788-6.481a.423.423 0 01.352-.306l8.663-1.163a.212.212 0 00.166-.125l.014-.053a.21.21 0 00-.132-.225l-.048-.012-8.653-1.163a.423.423 0 01-.352-.305L2.038 3.824a1.047 1.047 0 01.744-1.287c.245-.066.507-.043.736.066z" />
    </svg>
  );
};
const MemoSvgSend = React.memo(SvgSend);
export default MemoSvgSend;
