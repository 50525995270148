import * as React from 'react';

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.972 3.712a.75.75 0 00-1.493.102l-.001 11.933-3.823-3.061-.092-.063a.75.75 0 00-.846 1.234l4.994 4 .095.064a.75.75 0 00.837-.06l5.091-4 .083-.075a.75.75 0 00.044-.978l-.075-.082a.75.75 0 00-.978-.045l-3.83 3.007V3.814l-.006-.102zM18.021 20a.75.75 0 00-.75-.75H7.186l-.102.007a.75.75 0 00.102 1.493h10.085l.102-.007A.75.75 0 0018.02 20z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDownload = React.memo(SvgDownload);
export default MemoSvgDownload;
