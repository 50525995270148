import * as React from 'react';

const SvgRoommies = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.334 7.85a4.051 4.051 0 10-5.854 3.627 5.824 5.824 0 00-3.837 5.475.75.75 0 001.5 0 4.322 4.322 0 017.803-2.562l1.086-1.05a5.81 5.81 0 00-2.806-1.935 4.05 4.05 0 002.108-3.556zm9.071 2.5a4.051 4.051 0 10-5.853 3.627 5.824 5.824 0 00-3.838 5.475.75.75 0 001.5 0 4.322 4.322 0 018.643 0 .75.75 0 001.5 0 5.82 5.82 0 00-4.058-5.548 4.05 4.05 0 002.106-3.555zm-4.05-2.552a2.55 2.55 0 100 5.102 2.55 2.55 0 000-5.102zM4.731 7.85a2.551 2.551 0 115.102 0 2.551 2.551 0 01-5.102 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRoommies = React.memo(SvgRoommies);
export default MemoSvgRoommies;
