import { Cookies } from 'react-cookie';
import { CookieSetOptions, CookieGetOptions } from 'universal-cookie';
import env from '@y2/environments';

const host = env.name === 'local' ? 'localhost' : env.routes.mainSite;
export const domain = host.replace(/https?:\/\/www/, '');

const cookies = new Cookies();
export * from 'react-cookie';
export const cookie = {
  get: (key: string, options?: CookieGetOptions) => cookies.get(key, options),
  set: (key: string, value: unknown, options?: CookieSetOptions) =>
    cookies.set(key, value, options),
  remove: (key: string, options?: CookieSetOptions) =>
    cookies.remove(key, options),
};
