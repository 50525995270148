import * as React from 'react';

const SvgMediaRoom = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .617c1.259 0 2.32.846 2.646 2h4.857a2.75 2.75 0 012.75 2.75v11.531a2.75 2.75 0 01-2.75 2.75H12.75v1.734H17a.75.75 0 010 1.5H7a.75.75 0 010-1.5h4.25v-1.735l-6.753.001a2.75 2.75 0 01-2.75-2.75V5.368a2.75 2.75 0 012.75-2.75h4.856a2.751 2.751 0 012.647-2zM9.354 4.118H4.497c-.69 0-1.25.56-1.25 1.25v11.53c0 .69.56 1.25 1.25 1.25h2.552a5.01 5.01 0 013.003-3.881 3.519 3.519 0 113.712-.072 5.012 5.012 0 013.185 3.952l2.554.001c.69 0 1.25-.56 1.25-1.25V5.368c0-.69-.56-1.25-1.25-1.25h-4.857a2.751 2.751 0 01-5.238.17l-.054-.17zm-.778 14.009A3.507 3.507 0 0112 15.373l.192.006a3.507 3.507 0 013.191 2.583l.039.165H8.576zm3.272-8.903a2.019 2.019 0 100 4.037 2.019 2.019 0 000-4.037zM10.75 3.367a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMediaRoom = React.memo(SvgMediaRoom);
export default MemoSvgMediaRoom;
