import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import styles from './close-button.module.scss';
import text from './close-button.text.json';
import { CloseIcon } from '../../icons';

type AllButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

type CloseButtonProps = AllButtonAttributes & {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

export const CloseButton: React.VFC<CloseButtonProps> = ({
  onClick,
  className,
  ...otherHtmlAttributes
}) => (
  <button
    aria-label={text.CLOSE}
    className={cn(styles.button, className)}
    onClick={onClick}
    data-testid="modal-close-button"
    {...otherHtmlAttributes}
  >
    <CloseIcon className={styles.icon} />
  </button>
);
