import * as React from 'react';

const SvgMultimedia = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3 4.25c-.58 0-1.05.47-1.05 1.05v9.327a3.5 3.5 0 101.483 2.53A.752.752 0 009.75 17V9.5h9.5v3.163a3.5 3.5 0 101.444 2.623.747.747 0 00.056-.286V5.3c0-.58-.47-1.05-1.05-1.05H9.3zM19.25 8V5.75h-9.5V8h9.5zm-2.05 9.5a2 2 0 100-4 2 2 0 000 4zm-8.95 0a2 2 0 11-4 0 2 2 0 014 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMultimedia = React.memo(SvgMultimedia);
export default MemoSvgMultimedia;
