import * as React from 'react';

const SvgCalculator = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 20c0 .414.336.75.75.75h13a.75.75 0 00.75-.75V4a.75.75 0 00-.75-.75h-13a.75.75 0 00-.75.75v16zm1.5-.75V4.75h11.5v14.5H6.25zM8 9.8a.3.3 0 01.3-.3h1.4a.3.3 0 01.3.3v1.4a.3.3 0 01-.3.3H8.3a.3.3 0 01-.3-.3V9.8zm.3-3.3a.3.3 0 00-.3.3v1.4a.3.3 0 00.3.3h7.4a.3.3 0 00.3-.3V6.8a.3.3 0 00-.3-.3H8.3zM11 9.8a.3.3 0 01.3-.3h1.4a.3.3 0 01.3.3v1.4a.3.3 0 01-.3.3h-1.4a.3.3 0 01-.3-.3V9.8zm3.3-.3a.3.3 0 00-.3.3v1.4a.3.3 0 00.3.3h1.4a.3.3 0 00.3-.3V9.8a.3.3 0 00-.3-.3h-1.4zM8 12.8a.3.3 0 01.3-.3h1.4a.3.3 0 01.3.3v1.4a.3.3 0 01-.3.3H8.3a.3.3 0 01-.3-.3v-1.4zm3.3-.3a.3.3 0 00-.3.3v1.4a.3.3 0 00.3.3h1.4a.3.3 0 00.3-.3v-1.4a.3.3 0 00-.3-.3h-1.4zm2.7.3a.3.3 0 01.3-.3h1.4a.3.3 0 01.3.3v1.4a.3.3 0 01-.3.3h-1.4a.3.3 0 01-.3-.3v-1.4zm-5.7 2.7a.3.3 0 00-.3.3v1.4a.3.3 0 00.3.3h1.4a.3.3 0 00.3-.3v-1.4a.3.3 0 00-.3-.3H8.3zm2.7.3a.3.3 0 01.3-.3h1.4a.3.3 0 01.3.3v1.4a.3.3 0 01-.3.3h-1.4a.3.3 0 01-.3-.3v-1.4zm3.3-.3a.3.3 0 00-.3.3v1.4a.3.3 0 00.3.3h1.4a.3.3 0 00.3-.3v-1.4a.3.3 0 00-.3-.3h-1.4z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCalculator = React.memo(SvgCalculator);
export default MemoSvgCalculator;
