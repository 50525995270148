import * as React from 'react';

const SvgChevronup = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.695 15.397a.75.75 0 001.13-.98l-.073-.084-6.18-6.136a.75.75 0 00-.976-.07l-.084.073-6.102 6.145a.75.75 0 00.98 1.13l.084-.073 5.574-5.612 5.647 5.607z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgChevronup = React.memo(SvgChevronup);
export default MemoSvgChevronup;
