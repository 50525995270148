import * as React from 'react';

const SvgWhatsapp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.523 5.47A8.43 8.43 0 0012.536 3C7.87 3 4.073 6.779 4.07 11.423c0 1.485.39 2.934 1.13 4.212L4 20l4.487-1.171a8.487 8.487 0 004.045 1.025h.004c4.665 0 8.462-3.78 8.464-8.424a8.352 8.352 0 00-2.477-5.96zm-5.987 12.961h-.003c-1.263 0-2.5-.338-3.581-.976l-.257-.151-2.663.695.711-2.584-.167-.265A6.96 6.96 0 015.5 11.424c.002-3.86 3.158-7.001 7.038-7.001 1.88 0 3.646.73 4.974 2.053a6.944 6.944 0 012.059 4.954c-.002 3.86-3.158 7.001-7.036 7.001zm2.414-5.929c.194.07 1.233.58 1.445.685l.116.057c.147.07.247.119.289.19.053.087.053.508-.123 1-.177.492-1.021.94-1.428 1.001a2.914 2.914 0 01-1.332-.083 12.178 12.178 0 01-1.206-.444c-1.982-.852-3.322-2.764-3.576-3.126a2.429 2.429 0 00-.037-.052l-.002-.003c-.112-.15-.861-1.144-.861-2.174 0-.97.478-1.478.698-1.712l.042-.044a.779.779 0 01.564-.264c.141 0 .282.001.405.008h.048c.123 0 .276-.002.428.36l.232.561c.183.442.385.932.42 1.003.053.105.089.228.018.369-.07.14-.3.437-.529.72l-.03.035c-.087.103-.173.205-.063.394.124.21.548.9 1.177 1.458.676.6 1.264.854 1.561.982.058.025.105.046.14.063.211.105.335.088.458-.053.123-.14.529-.614.67-.825.14-.21.282-.176.476-.106z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgWhatsapp = React.memo(SvgWhatsapp);
export default MemoSvgWhatsapp;
