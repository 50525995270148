import * as React from 'react';

const SvgPhoneFull = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.593 15.288c2.397 2.758 5.332 5.064 9.026 5.276a.752.752 0 00.277-.036c.756-.249 1.37-.54 1.89-.991.52-.452.892-1.02 1.243-1.735a.75.75 0 00.012-.635 7.747 7.747 0 00-4.098-3.999.75.75 0 00-.854.205c-.28.327-.416.474-.557.597-.1.087-.213.17-.399.292-.237-.092-.578-.287-1.005-.62-.556-.434-1.162-1.023-1.723-1.668-.56-.646-1.06-1.328-1.412-1.939-.27-.469-.417-.834-.475-1.082.148-.167.245-.266.345-.353.142-.123.307-.237.668-.469a.75.75 0 00.323-.817 7.758 7.758 0 00-3.39-4.616.75.75 0 00-.631-.077c-.756.25-1.37.54-1.89.991-.52.452-.892 1.019-1.244 1.733a.75.75 0 00-.075.27c-.304 3.688 1.571 6.915 3.969 9.673z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPhoneFull = React.memo(SvgPhoneFull);
export default MemoSvgPhoneFull;
