/* istanbul ignore file */
import Script from 'next/script';
import './accessibility';
import { log } from '@y2/log';
import { yad2Integritykey, yad2Sitekey } from './constants';
import { getInlineScriptSnippet } from './helpers';

const onScriptFailed = () => {
  log.info('failed to load accessibility script');
};

type Props = {
  sitekey?: string;
  integrityKey?: string;
};

export const AccessibilityScript = ({
  sitekey = yad2Sitekey,
  integrityKey = yad2Integritykey,
}: Props) => (
  <Script
    id="accessibility-script"
    strategy="afterInteractive"
    onError={onScriptFailed}
    dangerouslySetInnerHTML={{
      __html: getInlineScriptSnippet(sitekey, integrityKey),
    }}
  />
);
