import * as React from 'react';
const SvgDirectionsBike = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#directions-bike_svg__clip0_4675_622)">
        <path
          d="M8.792 6.168c-1.009 0-1.834-.84-1.834-1.867 0-1.026.825-1.866 1.834-1.866 1.008 0 1.833.84 1.833 1.866 0 1.027-.825 1.867-1.833 1.867zm9.625 6.065c2.566 0 4.583 2.053 4.583 4.666 0 2.613-2.017 4.666-4.583 4.666-2.567 0-4.584-2.053-4.584-4.666 0-2.613 2.017-4.666 4.584-4.666zm0 7.933c1.741 0 3.208-1.494 3.208-3.267s-1.467-3.266-3.208-3.266c-1.742 0-3.209 1.493-3.209 3.266s1.467 3.267 3.209 3.267zM13.1 10.834l-2.2-2.24-.733.746c-1.192 1.214-2.75 1.96-4.675 1.96V9.434c1.375 0 2.475-.56 3.3-1.4l1.741-1.773c.459-.373.917-.56 1.467-.56s1.008.187 1.283.56l2.567 2.613c.367.373.55.84.55 1.306 0 .56-.183 1.027-.55 1.307L12.917 14.1v4.666h-1.834V12.98l2.017-2.146zm-7.517 1.4c2.567 0 4.584 2.052 4.584 4.665s-2.017 4.666-4.584 4.666C3.017 21.565 1 19.512 1 16.9c0-2.613 2.017-4.666 4.583-4.666zm0 7.932c1.742 0 3.209-1.494 3.209-3.267s-1.467-3.266-3.209-3.266c-1.741 0-3.208 1.493-3.208 3.266s1.467 3.267 3.208 3.267z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="directions-bike_svg__clip0_4675_622">
          {/* eslint-disable-next-line custom-rules/use-current-color */}
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgDirectionsBike = React.memo(SvgDirectionsBike);
export default MemoSvgDirectionsBike;
