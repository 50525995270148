import * as React from 'react';

const SvgMorevertical = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.967 16.111a1.556 1.556 0 100 3.111 1.556 1.556 0 000-3.11zm0-6.111a1.556 1.556 0 100 3.111 1.556 1.556 0 000-3.111zm0-6.111a1.556 1.556 0 100 3.111 1.556 1.556 0 000-3.111z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMorevertical = React.memo(SvgMorevertical);
export default MemoSvgMorevertical;
