import * as React from 'react';

const SvgSeats = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 2.75v2.5H7a.75.75 0 00-.75.75v10H4a.75.75 0 00-.75.75v4c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-4A.75.75 0 0019 16h-2.25V6a.75.75 0 00-.75-.75h-1.25v-2.5A.75.75 0 0014 2H9a.75.75 0 00-.75.75zm1.5.75V5h3.5V3.5h-3.5zm-5 14V20h13.5v-2.5H4.75zm3-9.326v7.576h6.61L7.75 8.174zm7.5 6.314V6.75H8.498l6.752 7.738z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgSeats = React.memo(SvgSeats);
export default MemoSvgSeats;
