import React from 'react';

const SvgRisingGraph = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 2.25C3.33579 2.25 3 2.58579 3 3V19C3 19.4142 3.33579 19.75 3.75 19.75H19.75C20.1642 19.75 20.5 19.4142 20.5 19C20.5 18.5858 20.1642 18.25 19.75 18.25L4.5 18.25L4.5 3C4.5 2.58579 4.16421 2.25 3.75 2.25ZM19.6688 5.33946C19.6665 5.34397 19.6642 5.34846 19.6618 5.35294L15.7993 12.5951C15.5314 13.0973 14.9123 13.2947 14.4032 13.0401L11.2434 11.4602L8.11442 15.9301C7.87689 16.2694 7.40924 16.352 7.0699 16.1144C6.73057 15.8769 6.64804 15.4092 6.88558 15.0699L10.2371 10.2819C10.5356 9.85556 11.1014 9.71217 11.5669 9.94493L14.682 11.5025L18.3058 4.70789L16.7208 5.31631C16.3341 5.46475 15.9003 5.27161 15.7518 4.8849C15.6034 4.4982 15.7965 4.06438 16.1832 3.91594L19.3827 2.6878C19.4025 2.67809 19.423 2.66917 19.444 2.6611C19.5538 2.61896 19.6674 2.60435 19.7771 2.61388C20.0567 2.6356 20.3126 2.81389 20.4197 3.09306C20.4314 3.12347 20.441 3.15417 20.4485 3.185L21.6673 6.36004C21.8157 6.74674 21.6226 7.18056 21.2359 7.329C20.8492 7.47744 20.4153 7.28429 20.2669 6.89759L19.6688 5.33946Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRisingGraph = React.memo(SvgRisingGraph);
export default MemoSvgRisingGraph;
