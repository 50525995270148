import * as React from 'react';

const SvgTrashdelete = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.416 3.47a1.84 1.84 0 00-1.353.602c-.346.375-.53.872-.53 1.378v.481H4.75a.75.75 0 100 1.5h1.516V17.76c0 .507.185 1.003.53 1.379.348.377.833.602 1.353.602h7.932c.52 0 1.006-.225 1.353-.602.346-.376.53-.872.53-1.379V7.431h1.516a.75.75 0 000-1.5h-3.782v-.48c0-.507-.185-1.004-.53-1.379a1.84 1.84 0 00-1.353-.603h-3.4zM9.28 7.43h7.184V17.76a.537.537 0 01-.133.363.34.34 0 01-.25.118H8.15a.34.34 0 01-.25-.118.537.537 0 01-.133-.363V7.431H9.28zm4.918-1.5v-.48a.537.537 0 00-.134-.363.34.34 0 00-.25-.119h-3.398a.34.34 0 00-.25.119.538.538 0 00-.133.362v.481h4.165zM10.15 9.576a.75.75 0 01.743.648l.007.102v5a.75.75 0 01-1.493.102l-.007-.102v-5a.75.75 0 01.75-.75zm4.743.648a.75.75 0 00-1.493.102v5l.007.102a.75.75 0 001.493-.102v-5l-.007-.102z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgTrashdelete = React.memo(SvgTrashdelete);
export default MemoSvgTrashdelete;
