import * as React from 'react';

const SvgBulletedlist = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.45 6.8a.75.75 0 01.648-.743L3.2 6.05h13a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.75-.75zm18-1.3a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-18 6.5a.75.75 0 01.648-.743l.102-.007h13a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.75-.75zm18-1.3a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-18 6.3a.75.75 0 01.648-.743l.102-.007h13a.75.75 0 01.102 1.493l-.102.007h-13a.75.75 0 01-.75-.75zm18-1.3a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgBulletedlist = React.memo(SvgBulletedlist);
export default MemoSvgBulletedlist;
