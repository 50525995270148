import {
  LazyLoadLottie,
  Props as LazyLottieProps,
} from '../conditional-lazy-loader';

type Props = Omit<LazyLottieProps, 'fetchAnimationData'>;

const fetchAnimationData = () => import('./chat-loader-data.json');

export const ChatLoader = (props: Props) => (
  <LazyLoadLottie fetchAnimationData={fetchAnimationData} {...props} />
);
