import { MenuItem } from '@y2/api-clients/recommerce-feed.types';
import { recommerceFeedClient } from '@y2/api-clients/recommerce-feed';
import { useEffect, useState } from 'react';
import { log } from '@y2/log';
import { recommerceTitle } from './const';

declare global {
  interface Window {
    isRecommerceMenuFetchInProcess?: boolean;
  }
}

const client = recommerceFeedClient();

const sessionKey = 'market-header-menu-categories';

const eventKey = 'market-header-menu-categories-fetch';

const getCategoriesFromSession = () => {
  const collection = sessionStorage.getItem(sessionKey);

  return collection ? (JSON.parse(collection) as MenuItem) : null;
};

export const getRecursiveCollection = (
  collectionsArr: MenuItem[],
): MenuItem => ({
  id: '0',
  collectionId: '0',
  route: '',
  title: recommerceTitle,
  englishHandler: '',
  hebrewHandler: '',
  verified: false,
  items: collectionsArr,
});

const waitForMenuFetch = (callback: (collectionBase: MenuItem) => void) => {
  window.addEventListener(eventKey, (event) => {
    callback((event as CustomEvent).detail as MenuItem);
  });
};

const resolveMenuWaiters = (collectionBase: MenuItem) => {
  window.dispatchEvent(
    new CustomEvent(eventKey, {
      detail: collectionBase,
    }),
  );
};

export const fetchCategoriesWithCombinedQueue = () =>
  new Promise<MenuItem>((resolve, reject) => {
    if (window.isRecommerceMenuFetchInProcess) {
      return waitForMenuFetch(resolve);
    }

    window.isRecommerceMenuFetchInProcess = true;
    // eslint-disable-next-line custom-rules/only-short-promise-then-usages
    client
      .getMenuItems()
      .then((categories) => {
        const baseCollectionItem = getRecursiveCollection(categories);
        sessionStorage.setItem(sessionKey, JSON.stringify(baseCollectionItem));
        resolve(baseCollectionItem);
        resolveMenuWaiters(baseCollectionItem);
      })
      .catch((error) => {
        log.error(error, 'failed to fetch header market/recommerce categories');
        reject(error);
      })
      .finally(() => {
        window.isRecommerceMenuFetchInProcess = false;
      });
  });

export const useSessionCategories = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<MenuItem>();

  useEffect(() => {
    const collection = getCategoriesFromSession();

    if (collection) {
      setCollection(collection);
    } else if (!isFetching) {
      const handleFetch = async () => {
        const collection = await fetchCategoriesWithCombinedQueue();
        setCollection(collection);
        setIsFetching(false);
      };

      setIsFetching(true);
      handleFetch();
    }
  }, []);

  return {
    collection,
    isFetching,
  };
};
