import * as React from 'react';

const SvgMute = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.098 3.367A.75.75 0 0115.25 4v16a.75.75 0 01-1.153.633l-7-4.455a.75.75 0 01-.32-.436l-.024.002-.141.006H3.388a1.638 1.638 0 01-1.638-1.638V8.888c0-.905.733-1.638 1.638-1.638h3.224c.34 0 .657.104.92.282l6.566-4.165zm-.348 1.996l-5.5 3.489v6.281l5.5 3.501V5.363zM6.612 8.75H3.388a.138.138 0 00-.138.138v5.224c0 .076.062.138.138.138h3.224a.138.138 0 00.138-.138V8.888a.138.138 0 00-.138-.138zm10.17 6.857a.772.772 0 01-.66-.364.77.77 0 01-.1-.585.768.768 0 01.346-.488 2.783 2.783 0 001.3-2.36c0-.963-.488-1.846-1.304-2.36a.78.78 0 01.828-1.321 4.33 4.33 0 012.034 3.68 4.33 4.33 0 01-2.027 3.679.775.775 0 01-.416.119zM18.5 17.53a.777.777 0 001.073.249 6.983 6.983 0 003.323-5.969 7.002 7.002 0 00-3.765-6.22.78.78 0 00-1.106.922.775.775 0 00.383.457 5.452 5.452 0 012.931 4.841 5.435 5.435 0 01-2.588 4.646.783.783 0 00-.25 1.074z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMute = React.memo(SvgMute);
export default MemoSvgMute;
