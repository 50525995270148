import env from '@y2/environments';

export const useTestEnvPrevention = () => {
  if (env.isJestRun) {
    throw Error(`
Avoid running lottie animation during tests!
put this mock: jest.mock('@y2/mango/components/animation'); // under the import lines
note* if its a new animation add it to the mock file: libs/mango/src/components/animation/__mocks__/index.ts`);
  }
};
