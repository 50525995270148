import styles from './social-buttons.module.scss';
import {
  YoutubeWhiteIcon,
  AppleLogoIcon,
  FacebookIcon,
  GooglePlayIcon,
} from '@y2/mango/components/icons';
import texts from './social-buttons.texts.json';
export const ICON_LISTS = [
  {
    url: 'https://itunes.apple.com/us/app/yad2-%D7%99%D7%932/id406318295?mt=8',
    ariaLabel: texts.ios,
    getIcon: () => <AppleLogoIcon className={styles.icon} />,
  },
  {
    url: 'https://play.google.com/store/apps/details?id=com.goldtouch.ct.yad2',
    ariaLabel: texts.android,
    getIcon: () => <GooglePlayIcon />,
  },
  {
    url: 'https://www.facebook.com/yad2page',
    ariaLabel: texts.facebook,
    getIcon: () => <FacebookIcon className={styles.icon} />,
  },
  {
    url: 'https://www.youtube.com/user/MeYad2',
    ariaLabel: texts.youtube,
    getIcon: () => <YoutubeWhiteIcon className={styles.icon} />,
  },
];
