import * as React from 'react';
const SvgThreeDimensionsBox = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1 21.083v-8.566L3.6 8.174v8.358c0 .062.015.12.046.174.031.053.077.1.139.138l7.315 4.24zm1.8 0l7.315-4.24a.397.397 0 00.139-.137.344.344 0 00.046-.174V8.174l-7.5 4.343v8.566zM12 10.966l7.41-4.283-7.226-4.18A.345.345 0 0012 2.447a.345.345 0 00-.184.058L4.59 6.684 12 10.965zm-9.115 7.459A2.136 2.136 0 011.8 16.55V7.449a2.126 2.126 0 011.085-1.873l8.03-4.623a2.137 2.137 0 012.17 0l8.03 4.623A2.136 2.136 0 0122.2 7.449v9.102a2.126 2.126 0 01-1.085 1.874l-8.03 4.622a2.137 2.137 0 01-2.17 0l-8.03-4.622z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.963 2.113a.9.9 0 01.9.9v.371a.9.9 0 11-1.8 0v-.37a.9.9 0 01.9-.9zm0 3.339a.9.9 0 01.9.9v.742a.9.9 0 11-1.8 0v-.742a.9.9 0 01.9-.9zm0 3.71a.9.9 0 01.9.9v.37a.9.9 0 11-1.8 0v-.37a.9.9 0 01.9-.9zM12.188 12.062a.9.9 0 01-.35 1.224l-.257.143a.9.9 0 01-.874-1.574l.258-.143a.9.9 0 011.223.35zM9.87 13.35a.9.9 0 01-.35 1.223l-.515.287a.9.9 0 11-.874-1.574L8.646 13a.9.9 0 011.224.35zm-2.576 1.43a.9.9 0 01-.35 1.224l-.515.286a.9.9 0 11-.874-1.573l.515-.286a.9.9 0 011.224.35zm-2.576 1.431a.9.9 0 01-.35 1.224l-.257.143a.9.9 0 01-.874-1.573l.257-.143a.9.9 0 011.224.35z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.062a.9.9 0 00.35 1.224l.258.143a.9.9 0 00.874-1.574l-.258-.143a.9.9 0 00-1.224.35zm2.319 1.288a.9.9 0 00.35 1.223l.514.287a.9.9 0 10.875-1.574L15.542 13a.9.9 0 00-1.223.35zm2.576 1.43a.9.9 0 00.35 1.224l.514.286a.9.9 0 10.875-1.573l-.516-.286a.9.9 0 00-1.223.35zm2.576 1.431a.9.9 0 00.35 1.224l.257.143a.9.9 0 00.874-1.573l-.258-.143a.9.9 0 00-1.223.35z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgThreeDimensionsBox = React.memo(SvgThreeDimensionsBox);
export default MemoSvgThreeDimensionsBox;
