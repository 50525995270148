import { cookie } from '@y2/packages-utils/cookies';
import env from '@y2/environments';
import { recommerceCollectionUrl } from './const';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';

// eslint-disable-next-line max-lines-per-function
export const findCollection = ({
  collection,
  findBy: { id, collectionId, title, englishHandler, hebrewHandler },
}: {
  collection?: MenuItem;
  findBy: {
    id?: string;
    collectionId?: string;
    title?: string;
    englishHandler?: string;
    hebrewHandler?: string;
  };
}): MenuItem | undefined => {
  if (!collection) {
    return;
  }

  if (collection.id === id) {
    return collection;
  }

  if (collection.title === title) {
    return collection;
  }

  if (collection.collectionId === collectionId) {
    return collection;
  }

  if (collection.englishHandler === englishHandler) {
    return collection;
  }

  if (collection.hebrewHandler === hebrewHandler) {
    return collection;
  }

  const childItems = collection.items || [];

  for (const item of childItems) {
    const matchingItem = findCollection({
      collection: item,
      findBy: {
        id,
        collectionId,
        title,
        englishHandler,
        hebrewHandler,
      },
    });

    if (matchingItem) {
      return matchingItem;
    }
  }
};

export const getCollectionLink = (collection: MenuItem): string => {
  const hasTemporaryTrialCookie = cookie.get('new-headless-recommerce');

  if (hasTemporaryTrialCookie) {
    return `${env.routes.mainSite}/market/collections/${collection.englishHandler}`;
  }

  return `${recommerceCollectionUrl}/${collection.hebrewHandler}`;
};
