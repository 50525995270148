import { Icon } from '@y2/deprecated/ui/icons/types';
import styles from './sub-category-link.module.scss';
import {
  sentAnalyticsNavEvent,
  getLinkWithHost,
  LinkBehaviorProps,
} from '../helpers';
import { Link } from '../link/link';
import { NewTag } from '../new-tag/new-tag';

type Props = {
  text: string;
  url: string;
  icon?: Icon;
  isNewTab?: boolean;
  desktopColumnPlacement?: string;
  disableAnalyticsEvents?: boolean;
  isNewTag?: boolean;
} & LinkBehaviorProps;

export const SubCategoryLink = ({
  text,
  icon: Icon,
  url,
  isNewTab,
  disableAnalyticsEvents = false,
  isInSubDomain = false,
  desktopColumnPlacement,
  isNextLink,
  isNewTag,
}: Props) => (
  <li
    className={styles.listItem}
    style={
      {
        '--desktop-column-placement': desktopColumnPlacement,
      } as React.CSSProperties
    }
  >
    <Link
      className={styles.link}
      href={isInSubDomain ? getLinkWithHost(url) : url}
      onClick={() => !disableAnalyticsEvents && sentAnalyticsNavEvent(text)}
      {...(isNewTab ? { target: '_blank' } : {})}
      isNextLink={isNextLink}
      data-nagish="header-menu-subcategory-link"
    >
      {Icon && (
        <span className={styles.iconWrapper}>
          <Icon className="icon" />
        </span>
      )}
      {text}
      {isNewTag && <NewTag />}
    </Link>
  </li>
);
