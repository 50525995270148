import * as React from 'react';
const SvgNoSmoking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.398 7.907c.186.18.48 0 .44-.263a5.885 5.885 0 01-.107-.79c-.014-.207 0-.387.04-.54.026-.124.106-.304.226-.512a3.56 3.56 0 00.293-.79c.08-.304.08-.637.013-.941a2.18 2.18 0 00-.386-.859c-.16-.208-.493-.069-.48.208a4.2 4.2 0 01-.013.72c-.046.352-.175.608-.358.974l-.081.162c-.107.221-.24.512-.293.886-.027.346.04.665.146.942.147.318.333.581.56.803zM6.48 4.832a.75.75 0 011.045.185l9.177 13.106a.75.75 0 01-1.229.86l-2.924-4.176H4.286a1.264 1.264 0 01-1.265-1.264v-3.086c0-.698.567-1.264 1.265-1.264h4.333L6.297 5.877a.75.75 0 01.184-1.045zm3.188 5.86H4.521v2.615H11.5l-1.83-2.614zm6.581 0h-3.22l-1.051-1.5h7.735c.699 0 1.265.567 1.265 1.265v3.086c0 .698-.566 1.264-1.265 1.264H15.91l-1.05-1.5h1.39v-2.614zm1.5 2.615v-2.614h1.729v2.614H17.75zm3.511-5.594c.04.207-.2.346-.346.207a1.658 1.658 0 01-.426-.623 1.582 1.582 0 01-.12-.761c.024-.259.121-.473.198-.644l.029-.063.083-.17c.132-.267.23-.465.263-.73.026-.18.026-.374.013-.581-.013-.208.253-.319.386-.153.147.18.253.416.306.679.054.235.054.512-.013.748-.044.194-.124.37-.196.526-.015.033-.03.065-.043.097l-.047.104c-.059.13-.105.234-.127.311-.04.111-.04.263-.04.43.014.193.04.401.08.623z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgNoSmoking = React.memo(SvgNoSmoking);
export default MemoSvgNoSmoking;
