import * as React from 'react';

const SvgConstructionRights = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.508 1.75l-6.997-.077c-1.922 0-3.465 1.583-3.465 3.527v13.6c0 1.944 1.543 3.527 3.457 3.527h8.902c1.913 0 3.456-1.583 3.456-3.527l.093-11.804a.822.822 0 00-.008-.115.734.734 0 00-.23-.657l-4.485-4.207a.741.741 0 00-.42-.2.748.748 0 00-.303-.067zM5.546 5.2c0-1.124.88-2.027 1.957-2.027l6.465.07v1.593l.005.164c.09 1.416 1.322 2.52 2.809 2.52h1.667l-.088 11.274c0 1.13-.88 2.033-1.956 2.033H7.503c-1.077 0-1.957-.903-1.957-2.027V5.2zm5.158 10.75l.106.002c.377.014.606.112 1.068.447l.44.331c.448.346.684.47.886.47.727 0 1.264-.24 1.674-.73a.75.75 0 111.152.96c-.702.842-1.664 1.27-2.826 1.27-.584 0-1.015-.2-1.616-.641l-.574-.431-.14-.096c-.095-.062-.142-.082-.17-.082l-.176.007c-.479.042-1.043.249-1.689.636a.75.75 0 11-.771-1.287c.941-.564 1.818-.856 2.636-.856zm5.5-2.755a.75.75 0 00-.75-.75h-7a.75.75 0 000 1.5h7a.75.75 0 00.75-.75zm-.75-4.224a.75.75 0 010 1.5h-7a.75.75 0 010-1.5h7zm.014-4.135v-.541l1.839 1.725h-.525l-.136-.005c-.67-.062-1.178-.576-1.178-1.18z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgConstructionRights = React.memo(SvgConstructionRights);
export default MemoSvgConstructionRights;
