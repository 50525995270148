import { categories } from '@y2/categories';
import texts from './recommerce-item.text.json';

export const recommerceBaseUrl = 'https://market.yad2.co.il';

export const recommerceCollectionUrl = `${recommerceBaseUrl}/collections`;

export const recommerceTitle = categories.products.titleHeb;

export const recommerceSubTitle = `${texts.homepage} ${recommerceTitle}`;
