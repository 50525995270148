import React, { createContext, useContext, useState, ReactNode } from 'react';

type BooleanMap = Record<string, boolean>;

type IsOpenContextType = {
  state: BooleanMap;
  setValue: (key: string, value: boolean) => void;
  closeAllMobileSlides: () => void;
};

const BooleanContext = createContext<IsOpenContextType | undefined>(undefined);

export const OpenStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<BooleanMap>({});

  const setValue = (key: string, value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const closeAllMobileSlides = () => {
    setState({});
  };

  return (
    <BooleanContext.Provider value={{ state, setValue, closeAllMobileSlides }}>
      {children}
    </BooleanContext.Provider>
  );
};

export const useIsOpenContext = () => {
  const context = useContext(BooleanContext);
  if (!context) {
    throw new Error('useBooleanContext must be used within a BooleanProvider');
  }

  return context;
};
