import * as React from 'react';

const SvgChair = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.566 3.924a.75.75 0 10-.593-1.378c-1.151.495-1.914 1.341-2.238 2.496-.212.754-.316 2.366-.324 4.892l.002 1.085a2.2 2.2 0 00-.613-.087h-2.55V9.6l-.009-.117h.909l.102-.007a.75.75 0 00-.102-1.493H9.2l-.102.007A.75.75 0 009.2 9.482h2.559a.766.766 0 00-.009.117v1.333H9.2a2.2 2.2 0 000 4.4h2.55l.001 3.679c-.82.1-1.773.414-2.872.934a.75.75 0 00.642 1.356c1.266-.599 2.252-.865 2.93-.82.724.047 1.631.319 2.712.822a.75.75 0 00.633-1.36c-.937-.437-1.785-.727-2.546-.87v-3.74h2.55a2.2 2.2 0 002.133-2.736c-.067-3.928.023-6.354.246-7.15.2-.71.644-1.204 1.387-1.523zM9.2 12.432h6.6l.095.007a.7.7 0 01.545.408l.003.3a.65.65 0 00.029.183.7.7 0 01-.672.502H9.2l-.095-.006a.7.7 0 01.095-1.394z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgChair = React.memo(SvgChair);
export default MemoSvgChair;
