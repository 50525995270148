import * as React from 'react';

const SvgGarden = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.17 2.39a.75.75 0 011.017-.12l3.311 2.436a.75.75 0 01.181 1.018c-1.338 2.02-2.82 2.814-4.261 2.111l-4.737 6.136 1.608 1.223a1.75 1.75 0 01.545 2.093l-.072.145c-.632 1.145-1.395 2.116-2.29 2.908-1.815 1.605-3.705 2.079-6.677 1.56a.75.75 0 01-.584-.506c-.733-2.247-.85-4.19-.327-5.82.452-1.41 1.367-2.67 2.723-3.774a1.744 1.744 0 012.032-.131l.132.091 1.714 1.303 4.767-6.176c-.91-1.193-.55-2.765.918-4.498zM7.558 12.96c-1.144.933-1.887 1.955-2.245 3.072-.343 1.068-.336 2.362.036 3.886l.085.33.07.245.138.02c1.98.263 3.246-.042 4.469-.99l.193-.156.175-.15c.763-.676 1.42-1.51 1.971-2.51a.25.25 0 00-.017-.267l-.05-.052-4.52-3.434a.245.245 0 00-.305.007zm9.326-9.052l-.076.109c-.74 1.075-.7 1.763-.04 2.274.65.503 1.321.341 2.176-.723l.07-.092-2.13-1.568z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgGarden = React.memo(SvgGarden);
export default MemoSvgGarden;
