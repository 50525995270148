export const DEFAULT_EVENT_NAME = 'GTM event to GA';

export const DEFAULT_PAGE_EVENT_NAME = 'GTM page to GA';

export const BREAKS = {
  mobile: 680,
  tablet: 1024,
  smallDesk: 1240,
  largeDesk: 1680,
};

export const LEGACY_APP_RVERSION_PREFIX = 'RESPONSIVE_MOBILE_APP_';
export const NATIVE_APP_RVERSION_PREFIX = 'RESPONSIVE_NATIVE_MOBILE_APP_';
export const ANDROID_UA_PREFIX = 'RESPONSIVE_MOBILE_APP_ANDROID';
export const IOS_UA_PREFIX = 'RESPONSIVE_MOBILE_APP_IOS';

export const TEST_AD_BLOCK_SCRIPT_PATH =
  '//assets.yad2.co.il/yad2site/prebid/ads.js?_cb=';

export const isBrowser = typeof window !== 'undefined';
