import * as React from 'react';

const SvgFullScreen = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.572 3.796l.111.005 6.429.772a.75.75 0 01.635.55l.02.112.704 6.36a.75.75 0 01-1.471.274l-.02-.11-.54-4.869L6.808 18.523l4.835.581a.75.75 0 01.66.723l-.005.111a.75.75 0 01-.723.66l-.11-.005-6.43-.771a.75.75 0 01-.634-.55l-.021-.112-.703-6.36a.75.75 0 011.47-.274l.02.11.54 4.867L17.337 5.871l-4.833-.58a.75.75 0 01-.66-.724l.004-.11a.75.75 0 01.723-.66z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgFullScreen = React.memo(SvgFullScreen);
export default MemoSvgFullScreen;
