import type { NextPage } from 'next';
import { log } from '@y2/log';
import { ServerErrorSection } from '@y2/error-handlers';
import { useEffect } from 'react';
import { ErrorLayout } from '../components/error-layout/error-layout';

type Props = {
  statusCode?: number;
};

const DEFAULT_ERROR_CODE = 500;

type BoundaryFallbackArgs = {
  error: Error;
};

export const BoundaryFallback = ({ error }: BoundaryFallbackArgs) => {
  useEffect(() => {
    if (error) {
      log.error(error, 'failed to render page');
    }
  }, [error]);

  return <ServerErrorSection />;
};

const ErrorPage: NextPage<Props> = () => (
  <ErrorLayout>
    <ServerErrorSection />
  </ErrorLayout>
);

ErrorPage.getInitialProps = ({ res, err }) => {
  const errCode = err?.statusCode;
  const resCode = res?.statusCode;
  const statusCode = errCode || resCode || DEFAULT_ERROR_CODE;

  if (err) {
    log.error(err, 'failed to render page');
  }

  return {
    statusCode,
  };
};

export default ErrorPage;
