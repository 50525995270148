import React from 'react';
import { AppProps } from 'next/app';
import './root.scss';
import Head from 'next/head';
import { ErrorBoundary } from 'react-error-boundary';
import { BoundaryFallback } from './_error';
import { Accessibility } from '@y2/accessibility';
import env from '@y2/environments';
import { CohortSetup } from '@y2/cohort';
import { BrowserSupport } from '@y2/browser-support';

const SystemPagesApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta name="cHash" content={env.commit} />
      </Head>
      <ErrorBoundary FallbackComponent={BoundaryFallback}>
        <Component {...pageProps} />
      </ErrorBoundary>
      <CohortSetup />
      <Accessibility />
      <BrowserSupport />
    </>
  );
};

export default SystemPagesApp;
