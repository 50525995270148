import * as React from 'react';
const SvgAnnouncement = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.35 11.34a.75.75 0 01.75-.75h2.643a.75.75 0 110 1.5H1.1a.75.75 0 01-.75-.75zM1.11 16.339a.75.75 0 01.275-1.025l2.289-1.321a.75.75 0 01.75 1.299l-2.289 1.321a.75.75 0 01-1.024-.274zM1.11 6.34a.75.75 0 00.275 1.025l2.289 1.32a.75.75 0 00.75-1.298L2.135 6.066a.75.75 0 00-1.024.274zM14.883 7.286h5.062c.495 0 .933.219 1.238.549.305.328.485.773.485 1.252v4.505c0 .478-.18.923-.485 1.252-.305.33-.743.548-1.238.548h-5.062V7.286zm1.5 1.5v5.106h3.562a.187.187 0 00.138-.067.342.342 0 00.085-.233V9.087a.342.342 0 00-.085-.233.187.187 0 00-.138-.068h-3.562z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.204 13.892h4.143v4.714a2.071 2.071 0 11-4.143 0v-4.714zm1.5 1.5v3.214a.571.571 0 001.143 0v-3.214h-1.143z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.204 4.827a.124.124 0 01.093.01l.012.007 7.574 3.939v5.453l-7.576 3.6-.012.006a.125.125 0 01-.139-.017.078.078 0 01-.017-.024.053.053 0 01-.005-.022V4.9c0-.009.003-.019.009-.029a.098.098 0 01.061-.044zm.803-1.311A1.625 1.625 0 006.81 3.38a1.597 1.597 0 00-.966.74c-.137.237-.21.505-.21.78V17.78c0 .443.187.84.478 1.123a1.625 1.625 0 001.861.278l8.41-3.997v-7.31L8.007 3.515zM20.168 8.608h1.41c1.145 0 2.072.927 2.072 2.07V12a2.071 2.071 0 01-2.071 2.071h-1.411V8.608zm1.5 1.506v2.45A.571.571 0 0022.15 12v-1.321a.571.571 0 00-.482-.565z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgAnnouncement = React.memo(SvgAnnouncement);
export default MemoSvgAnnouncement;
