import * as React from 'react';

const SvgMinuscircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05 12.25c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10zm18.5 0a8.5 8.5 0 10-17 0 8.5 8.5 0 0017 0zm-3.8.05a.75.75 0 00-.75-.75H8l-.102.007A.75.75 0 008 13.05h8l.102-.007a.75.75 0 00.648-.743z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMinuscircle = React.memo(SvgMinuscircle);
export default MemoSvgMinuscircle;
