/* eslint-disable max-lines */
import { BASE_PATH } from '../constants';
import altTexts from './alt-texts.json';
import Image, { ImageProps } from 'next/image';

type Props = Omit<ImageProps, 'src' | 'alt'>;

const PATH = `${BASE_PATH}/spot/`;

const DEFAULT_SIZE = {
  width: 120,
  height: 120,
};

const ImageComponent = (props: ImageProps) => <Image {...props} />;

export const AccurateOp2SpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Accurate_op2.svg`}
    alt={altTexts.Accurate_op2}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VanCommercialSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Van_Commercial.svg`}
    alt={altTexts.Van_Commercial}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LoginSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}login.svg`}
    alt={altTexts.login}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AllPrices6SpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}All_prices.svg`}
    alt={altTexts.All_prices}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AddressSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}address.svg`}
    alt={altTexts.address}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MailGeneralSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}mail_general.svg`}
    alt={altTexts.mail_general}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AnnouncementSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Announcement.svg`}
    alt={altTexts.Announcement}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AutomaticBounceSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}automatic_bounce.svg`}
    alt={altTexts.automatic_bounce}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MailPasswordSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}mail_password.svg`}
    alt={altTexts.mail_password}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AttentionSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Attention.svg`}
    alt={altTexts.Attention}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BinocularsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}binoculars.svg`}
    alt={altTexts.binoculars}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NadlanSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}nadlan.svg`}
    alt={altTexts.nadlan}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NadlanSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}nadlan-off.svg`}
    alt={`${altTexts.nadlan} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ChatBubblesSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Chat.svg`}
    alt={altTexts.Chat}
    width={149}
    height={97}
    {...args}
  />
);

export const BusinessSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}business.svg`}
    alt={altTexts.business}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BusinessSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}business-off.svg`}
    alt={`${altTexts.business} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NoConnectionSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}no%20connection.svg`}
    alt={altTexts.no_connection}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CommercialSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Commercial.svg`}
    alt={altTexts.Commercial}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CommercialSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}commercial-off.svg`}
    alt={`${altTexts.Commercial} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CallingSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}calling.svg`}
    alt={altTexts.calling}
    width={107.15}
    height={DEFAULT_SIZE.height}
    {...args}
  />
);

export const OutOfFreezeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}out_of_freeze.svg`}
    alt={altTexts.out_of_freeze}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CreateSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Create.svg`}
    alt={altTexts.Create}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ChatSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}chat1.svg`}
    alt={altTexts.chat1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PetsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}pets.svg`}
    alt={altTexts.pets}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PetsSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}pets-off.svg`}
    alt={`${altTexts.pets} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DeniedSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Denied.svg`}
    alt={altTexts.Denied}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BlueDeniedSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}blue-denied.svg`}
    alt={altTexts.Denied}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ChatContactSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}chat_contact.svg`}
    alt={altTexts.chat_contact}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PlaneSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}plane1.svg`}
    alt={altTexts.plane1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FunSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Fun.svg`}
    alt={altTexts.Fun}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ClientsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}clients.svg`}
    alt={altTexts.clients}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PlaneYellowSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}plane_yellow.svg`}
    alt={altTexts.plane_yellow}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const JeepSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Jeep.svg`}
    alt={altTexts.Jeep}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CommercialNadlanSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}commercial_nadlan.svg`}
    alt={altTexts.commercial_nadlan}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ProfessionSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}profession.svg`}
    alt={altTexts.profession}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ProfessionOffSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}profession-off.svg`}
    alt={`${altTexts.profession} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MotorBikeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}MotorBike.svg`}
    alt={altTexts.MotorBike}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const Drushim1SpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}drushim.svg`}
    alt={altTexts.drushim}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const Drushim1SpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}drushim-off.svg`}
    alt={`${altTexts.drushim} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RocketSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}rocket.svg`}
    alt={altTexts.rocket}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NewFeatureSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}New_Feature.svg`}
    alt={altTexts.New_Feature}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DrushimSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}drushim1.svg`}
    alt={altTexts.drushim1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SearchSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}search.svg`}
    alt={altTexts.search}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PrivateSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Private.svg`}
    alt={altTexts.Private}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const EducationSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}education.svg`}
    alt={altTexts.education}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const EducationSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}education-off.svg`}
    alt={`${altTexts.education} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SecondHandSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}second%20hand.svg`}
    alt={altTexts.second_hand}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SecondHandSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}second-hand-off.svg`}
    alt={`${altTexts.second_hand} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PurchaseSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Purchase.svg`}
    alt={altTexts.Purchase}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FashionSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}fashion.svg`}
    alt={altTexts.fashion}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FashionSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}fashion-off.svg`}
    alt={`${altTexts.fashion} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SentSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}sent.svg`}
    alt={altTexts.sent}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BlueSentSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}blue-sent.svg`}
    alt={altTexts.sent}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RentSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Rent.svg`}
    alt={altTexts.Rent}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FreezeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}freeze1.svg`}
    alt={altTexts.freeze1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SlowSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}slow.svg`}
    alt={altTexts.slow}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RoommateSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Roommate.svg`}
    alt={altTexts.Roommate}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FurnitureSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}furniture.svg`}
    alt={altTexts.furniture}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FurnitureSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}furniture-off.svg`}
    alt={`${altTexts.furniture} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TechSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}tech.svg`}
    alt={altTexts.tech}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TechSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}tech-off.svg`}
    alt={`${altTexts.tech} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SignatureSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Signature.svg`}
    alt={altTexts.Signature}
    width={107.15}
    height={DEFAULT_SIZE.height}
    {...args}
  />
);

export const KidsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}kids.svg`}
    alt={altTexts.kids}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const KidsSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}kids-off.svg`}
    alt={`${altTexts.kids} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UsersSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}users.svg`}
    alt={altTexts.users}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SuccessSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Success.svg`}
    alt={altTexts.Success}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const KiteSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}kite1.svg`}
    alt={altTexts.kite1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VehicleSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}vehicle.svg`}
    alt={altTexts.vehicle}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VehicleOffSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}vehicles-off.svg`}
    alt={`${altTexts.vehicle} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UpgradeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Upgrade.svg`}
    alt={altTexts.Upgrade}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LicensePlateSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}license%20plate.svg`}
    alt={altTexts.license_plate}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VacationSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}vication.svg`}
    alt={altTexts.vication}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VacationSpotOffIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}vication-off.svg`}
    alt={`${altTexts.vication} ${altTexts.off}`}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VirtualNumbersSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}virtualnumbers_lock.svg`}
    alt={altTexts.virtual_num_lock}
    width={83}
    height={83}
    {...args}
  />
);

export const SafetySpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}safety.svg`}
    alt={altTexts.safety}
    width={83}
    height={83}
    {...args}
  />
);

export const WeekendCallsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}calling.svg`}
    alt={altTexts.weekendCalls}
    width={83}
    height={83}
    {...args}
  />
);

export const CallsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}calls.svg`}
    alt={altTexts.calls}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const EyeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}eye.svg`}
    alt={altTexts.eye}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LocationSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}location.svg`}
    alt={altTexts.location}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ScooterSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Scooter.svg`}
    alt={altTexts.scooter}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AlarmClockSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}alarm-clock.svg`}
    alt={altTexts.alarmClock}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NoResultsSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}no-results.svg`}
    alt={altTexts.no_results}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CancelOrderSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}cancel.svg`}
    alt={altTexts.cancelOrder}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DangerAttractivenessEmojiSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}danger-emoji.svg`}
    alt={altTexts.dangerAttractivenessEmoji}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MediumAttractivenessEmojiSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}warning-emoji.svg`}
    alt={altTexts.mediumAttractivenessEmoji}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SuccessAttractivenessEmojiSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}success-emoji.svg`}
    alt={altTexts.successAttractivenessEmoji}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SuccessOrderSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}success-order.svg`}
    alt={altTexts.successOrderCompleted}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ShoppingBagSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}shopping-bag.svg`}
    alt={altTexts.shoppingBag}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const OrangePersonSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}orange_person.svg`}
    alt={altTexts.orangePerson}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const WaitSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}wait.svg`}
    alt={altTexts.wait}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const OrderReturnSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}order_return.svg`}
    alt={altTexts.orderReturn}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const OrderDisputeSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}order_dispute.svg`}
    alt={altTexts.orderDispute}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const OrderOnHoldSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}order_on_hold.svg`}
    alt={altTexts.orderOnHold}
    {...DEFAULT_SIZE}
    {...args}
  />
);
