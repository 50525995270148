import * as React from 'react';

const SvgBusiness1 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4 5H3.568l-.262 3.982a2.25 2.25 0 001.644 2.315V17.5h-1.5l-.102.007A.75.75 0 003.45 19h17l.102-.007a.75.75 0 00-.102-1.493h-2v-6.125l.047-.002a2.248 2.248 0 002.096-2.391L20.333 5H6.4zm8.05 12.5h2.5V11c0-.027.001-.055.004-.082a2.355 2.355 0 01-.204-.17c-.42.39-.982.63-1.6.63-.618 0-1.18-.24-1.6-.63-.42.39-.982.63-1.6.63-.618 0-1.18-.24-1.6-.63-.42.39-.982.63-1.6.63-.618 0-1.18-.24-1.6-.63-.203.19-.44.343-.7.451V17.5h3V14a1 1 0 011-1h3a1 1 0 011 1v3.5zm-3.5 0h2v-3h-2v3zm-5.978-11H6.4v2.527c0 .47-.38.85-.85.85a.749.749 0 01-.742-.646l-.006-.127.17-2.604zm11.027 0v2.547a.85.85 0 01-.849.83l-.107-.006a.85.85 0 01-.743-.844V6.5h1.699zM17.5 9.027v.02a.85.85 0 00.743.824l.131.006.126-.015a.749.749 0 00.597-.782l-.17-2.58H17.5v2.527zM7.9 6.5h1.7v2.547a.85.85 0 01-.85.83l-.106-.006a.85.85 0 01-.744-.844V6.5zm3.2 2.527v.02a.85.85 0 00.743.824l.107.006c.47 0 .85-.38.85-.85V6.5h-1.7v2.527z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgBusiness1 = React.memo(SvgBusiness1);
export default MemoSvgBusiness1;
