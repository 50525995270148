import * as React from 'react';

const SvgArchive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.095 14.041a.75.75 0 011.06-1.06l1.153 1.153V5.725a.75.75 0 011.5 0v8.409l1.153-1.153a.75.75 0 011.06 1.06l-2.43 2.432a.747.747 0 01-1.063.002L9.095 14.04zM4.026 18.5a.75.75 0 01-.001-.043v-7.362l.001-.043a1.57 1.57 0 01.607-1.14 1.834 1.834 0 011.19-.385h2.095a.75.75 0 010 1.5H5.78a.337.337 0 00-.218.062.133.133 0 00-.038.045v7.285l.003.005a.135.135 0 00.035.04.337.337 0 00.218.062h12.488a.338.338 0 00.218-.063.135.135 0 00.038-.045v-7.284a.134.134 0 00-.038-.045.337.337 0 00-.218-.063h-2.137a.75.75 0 010-1.5h2.094c.424-.012.852.118 1.19.386a1.571 1.571 0 01.609 1.183v7.362l-.001.044a1.57 1.57 0 01-.608 1.14 1.834 1.834 0 01-1.19.384H5.824a1.833 1.833 0 01-1.19-.384 1.57 1.57 0 01-.608-1.14z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgArchive = React.memo(SvgArchive);
export default MemoSvgArchive;
