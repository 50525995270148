import * as React from 'react';
const SvgGallery = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 3a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h4zm0 1.5H5a.5.5 0 00-.492.41L4.5 5v4a.5.5 0 00.41.492L5 9.5h4a.5.5 0 00.492-.41L9.5 9V5a.5.5 0 00-.41-.492L9 4.5zM9 13a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4a2 2 0 012-2h4zm0 1.5H5a.5.5 0 00-.492.41L4.5 15v4a.5.5 0 00.41.492L5 19.5h4a.5.5 0 00.492-.41L9.5 19v-4a.5.5 0 00-.41-.492L9 14.5zM19 3a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2V5a2 2 0 012-2h4zm0 1.5h-4a.5.5 0 00-.492.41L14.5 5v4a.5.5 0 00.41.492L15 9.5h4a.5.5 0 00.492-.41L19.5 9V5a.5.5 0 00-.41-.492L19 4.5zm0 8.5a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4a2 2 0 012-2h4zm0 1.5h-4a.5.5 0 00-.492.41L14.5 15v4a.5.5 0 00.41.492l.09.008h4a.5.5 0 00.492-.41L19.5 19v-4a.5.5 0 00-.41-.492L19 14.5z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgGallery = React.memo(SvgGallery);
export default MemoSvgGallery;
