import React, { useState, useEffect, useRef } from 'react';
import { useLazyLottieModule } from './use-conditional-load-lottie';
import { Direction, setDirection } from './flow-utils';
import { useTestEnvPrevention } from './prevent-during-test-run';
import type { AnimationConfig, AnimationItem } from 'lottie-web';

type DivAttributes = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onClick'
>;

type LottieProps = Omit<AnimationConfig<'svg'>, 'container'>;

export type Props = {
  fetchAnimationData: () => Promise<any>;
  direction?: Direction;
  speed?: number;
  className?: string;
} & DivAttributes &
  LottieProps;

export const LazyLoadLottie = ({
  fetchAnimationData,
  direction,
  speed,
  className,
  ...lottieProps
}: Props) => {
  const [animationData, setAnimationData] = useState<any>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const lottie = useLazyLottieModule();
  const ref = useRef<HTMLDivElement | null>(null);
  useTestEnvPrevention();

  useEffect(() => {
    const fetchAnimation = async () => {
      const animationData = await fetchAnimationData();

      setAnimationData(animationData);
    };
    fetchAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (animationData && lottie && ref?.current) {
      const animationItem = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        animationData,
        ...lottieProps,
      });

      // eslint-disable-next-line custom-rules/no-nested-if-conditions
      if (direction) {
        setDirection(animationItem, direction);
      }

      setAnimation(animationItem);
    }

    return function dispose() {
      animation?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData, lottie, ref]);

  useEffect(() => {
    if (animation && direction) {
      setDirection(animation, direction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, animation]);

  useEffect(() => {
    if (animation && speed) {
      animation.setSpeed(speed);
    }
  }, [speed, animation]);

  return <div ref={ref} className={className} {...lottieProps} />;
};
