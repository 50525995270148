import * as React from 'react';

const SvgImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.75A.75.75 0 014.75 4h13.857a.75.75 0 01.75.75v13.857a.75.75 0 01-.75.75H4.75a.75.75 0 01-.75-.75V4.75zm13.857.75v6.687l-2.865-2.057a1.283 1.283 0 00-1.634.056L5.5 17.459V5.5h12.357zm0 12.357H7.28l6.947-6.43 3.631 2.606v3.824zM8.747 6.132a2.615 2.615 0 100 5.23 2.615 2.615 0 000-5.23zM7.96 7.959a1.115 1.115 0 111.577 1.577 1.115 1.115 0 01-1.577-1.577z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgImage = React.memo(SvgImage);
export default MemoSvgImage;
