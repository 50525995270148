/* eslint-disable custom-rules/only-return-jsx-declaration */
import React, { useState, MouseEvent } from 'react';
import styles from './responsive-header-grid.module.scss';
import cn from 'classnames';
import text from './responsive-header-grid.text';
import MobileStrip, { MobileInlineButtons } from '../mobile-strip/mobile-strip';
import { CloseIcon } from '@y2/deprecated/ui/icons';
import { useDisableScrollOnMobile } from '@y2/hooks/use-disable-mobile-scroll';
import { FocusTrap } from '@y2/packages-utils/focus-trap';

type HeaderNativeProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'onClick'
>;

type Props = HeaderNativeProps & {
  logoSlot: React.ReactNode;
  mobileLogoSlot: React.ReactNode;
  userDropSlot: React.ReactNode;
  mainMenuSlot: React.ReactNode;
  isTranslucent?: boolean;
  mobileInlineButtons?: MobileInlineButtons;
  leftButtonSlot?: React.ReactNode;
  isSticky?: boolean;
  isStickyMobile?: boolean;
  theme?: 'yad2' | 'yad1' | 'white';
  hideMobileBackButton?: boolean;
  hideMobileMenu?: boolean;
  bottomMenuSlot?: React.ReactNode;
  userDropDownExtension?: React.ReactNode;
  className?: string;
  mobileClassName?: string;
  hasShadow?: {
    mobile: boolean;
    desktop: boolean;
  };
};

const HEADER_ACTION_ATTRIBUTE = 'data-header-action-on-click';

const CLOSE_MOBILE_MENU_ATTRIBUTE = 'close-mobile-menu';

const getMobileStripColor = (theme: Props['theme']) =>
  theme === 'yad2' ? 'orange' : 'white';

function hasMobileMenuCloseAtt(clickedElm: HTMLElement): boolean {
  const headerActionAtt = clickedElm.getAttribute(HEADER_ACTION_ATTRIBUTE);
  if (headerActionAtt === CLOSE_MOBILE_MENU_ATTRIBUTE) {
    return true;
  }

  return clickedElm.parentElement
    ? hasMobileMenuCloseAtt(clickedElm.parentElement)
    : false;
}

const ResponsiveHeaderGrid: React.VFC<Props> = ({
  logoSlot,
  mobileLogoSlot,
  userDropSlot,
  mainMenuSlot,
  leftButtonSlot,
  mobileInlineButtons,
  isTranslucent = false,
  isSticky = false,
  isStickyMobile = false,
  theme = 'yad2',
  hideMobileBackButton = false,
  bottomMenuSlot = null,
  userDropDownExtension = null,
  hideMobileMenu = false,
  hasShadow = { mobile: true, desktop: true },
  className,
  mobileClassName,
  ...otherHtmlAttributes
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  useDisableScrollOnMobile(isMobileMenuOpen);
  const bottomMenuElm = bottomMenuSlot ? (
    <div
      className={cn(
        styles['bottom-menu-container'],
        styles['rows-in-mobile-menu'],
      )}
    >
      {bottomMenuSlot}
    </div>
  ) : null;

  return (
    <>
      <MobileStrip
        logoSlot={mobileLogoSlot}
        color={getMobileStripColor(theme)}
        hideBackButton={hideMobileBackButton}
        onOpenMenuClick={() => setIsMobileMenuOpen(true)}
        isSticky={isSticky || isStickyMobile}
        hideMobileMenu={hideMobileMenu}
        inlineButtons={mobileInlineButtons}
        className={mobileClassName}
        hasShadow={hasShadow.mobile}
      />
      <FocusTrap
        active={isMobileMenuOpen}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
        }}
      >
        <header
          data-testid="responsive-header-grid"
          {...otherHtmlAttributes}
          className={cn(
            styles['common-header'],
            isSticky && styles['is-sticky'],
            isMobileMenuOpen && styles['is-open'],
            isTranslucent && styles.translucent,
            styles[theme],
            hasShadow.desktop && styles.hasShadow,
            className,
          )}
          onClick={(event: MouseEvent) => {
            if (hasMobileMenuCloseAtt(event.target as HTMLElement)) {
              setIsMobileMenuOpen(false);
            }
          }}
        >
          <button
            className={styles['mobile-close-button']}
            aria-label={text.CLOSE_MENU}
            onClick={() => setIsMobileMenuOpen(false)}
            data-testid="mobile-close-button"
            data-nagish="header-mobile-close-button"
          >
            <CloseIcon className={styles['close-icon']} />
          </button>

          <div className={styles['logo-slot']}>{logoSlot}</div>

          <div
            className={cn(
              styles['common-nav-container'],
              styles['rows-in-mobile-menu'],
            )}
          >
            {mainMenuSlot}
          </div>

          <div className={styles['user-menu-extension']}>
            {userDropDownExtension}
          </div>

          {userDropSlot}

          <div
            className={cn(
              styles['common-button-container'],
              styles['rows-in-mobile-menu'],
              styles['grey-middle-split-background'],
            )}
          >
            {leftButtonSlot}
          </div>
          {bottomMenuElm}
        </header>
      </FocusTrap>
      <div
        className={cn(
          styles['common-mask'],
          isMobileMenuOpen && styles['is-visible'],
        )}
        data-testid="header-common-mask"
        onClick={() => setIsMobileMenuOpen(false)}
      ></div>
    </>
  );
};

export default ResponsiveHeaderGrid;
