import * as React from 'react';
const SvgAccessible = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.473 5.818a.884.884 0 100-1.769.884.884 0 000 1.77zm0 1.684a2.569 2.569 0 100-5.137 2.569 2.569 0 000 5.137zM11.777 10.092c0-.465.377-.842.843-.842h3.282a.842.842 0 110 1.684H12.62a.842.842 0 01-.843-.842z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51 14.346l-.914-6.957 1.67-.22.814 6.193 5.403-.229a.842.842 0 01.837.584l1.282 3.988h1.276a.842.842 0 110 1.684h-1.89a.842.842 0 01-.801-.584l-1.274-3.962-5.532.234a.842.842 0 01-.87-.731z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.048 10.606A.842.842 0 017.6 11.71c-.447.188-.636.302-.795.425-.122.094-.22.184-.368.32-.1.092-.223.206-.392.355a4.293 4.293 0 106.584 5.504c.471-.599.556-.863.824-1.703l.006-.017a.842.842 0 111.604.513l-.006.02c-.285.892-.444 1.39-1.104 2.228a5.977 5.977 0 11-9.127-7.708l.031-.035.036-.03c.113-.101.222-.2.326-.296.2-.184.386-.353.56-.486.315-.243.646-.423 1.166-.642a.842.842 0 011.103.448z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgAccessible = React.memo(SvgAccessible);
export default MemoSvgAccessible;
