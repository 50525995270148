import * as React from 'react';
const SvgShare = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5.734a2 2 0 104 0 2 2 0 00-4 0zm2 3.5a3.495 3.495 0 01-2.807-1.409c-.819.557-1.77 1.2-2.719 1.843L9.242 11.18a3.49 3.49 0 01.191 2.004l2.04 1.382h.001c.95.643 1.9 1.286 2.719 1.843a3.5 3.5 0 11-.623 1.39c-.864-.588-1.9-1.289-2.937-1.99v-.001l-1.816-1.23a3.5 3.5 0 11-.425-4.634l2.24-1.517.001-.001c1.037-.702 2.073-1.403 2.937-1.991a3.5 3.5 0 113.43 2.8zm0 7.266a2 2 0 100 4 2 2 0 000-4zm-13-4a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgShare = React.memo(SvgShare);
export default MemoSvgShare;
