import * as React from 'react';

const SvgCorner = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 8A4.75 4.75 0 018 3.25h10A2.75 2.75 0 0120.75 6v10A4.75 4.75 0 0116 20.75H8A4.75 4.75 0 013.25 16V8zM8 4.75A3.25 3.25 0 004.75 8v8A3.25 3.25 0 008 19.25h8A3.25 3.25 0 0019.25 16V6c0-.69-.56-1.25-1.25-1.25H8z"
        fill="currentColor"
      />
      <path
        d="M17.014 7.933l.006.073a.749.749 0 01.011.133v3.794a.75.75 0 01-1.5 0V9.854l-3.786 3.788a.75.75 0 11-1.06-1.061l3.785-3.787h-2.066a.756.756 0 01-.762-.75c0-.414.342-.75.763-.75h3.855c.344 0 .635.225.73.533l.024.106z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCorner = React.memo(SvgCorner);
export default MemoSvgCorner;
