import ReactDOM from 'react-dom';
import styles from './accessibility-button.module.scss';
import cn from 'classnames';
import {
  AccessibilityHandicapIcon,
  ResizeScreenIcon,
} from '@y2/mango/components/icons';
import { ACCESSIBILITY_ID, CLICK_EVENT } from '../constants';
import texts from './accessibility-button.texts.json';
import { analytics } from '@y2/google-tag-manager';

const ButtonPortal = ({ children }: { children: React.ReactNode }) =>
  ReactDOM.createPortal(children, document.body);

type Props = {
  shouldHide?: boolean;
};

export const AccessibilityButton = ({ shouldHide = false }: Props = {}) => {
  return (
    <ButtonPortal>
      <button
        id={ACCESSIBILITY_ID}
        className={cn(styles.button, shouldHide && styles.hide)}
        aria-label={texts.openAccessibilityMenu}
        title={texts.openAccessibilityMenu}
        tabIndex={1}
        aria-expanded="false"
        aria-haspopup="true"
        data-inddrag="true"
        onClick={() => {
          analytics.pushEvent(CLICK_EVENT);
        }}
      >
        <AccessibilityHandicapIcon className={styles.handicap} />
        <div className={styles.info}>
          <ResizeScreenIcon />
          <p>{texts.accessibilityButton}</p>
        </div>
      </button>
    </ButtonPortal>
  );
};
