import * as React from 'react';

const SvgLoadingRamp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.551 4a2.75 2.75 0 012.75-2.75h1a.75.75 0 010 1.5h-1c-.69 0-1.25.56-1.25 1.25l-.001 11.009a3.5 3.5 0 11-3.582 2.42L3.49 21.978a.75.75 0 11-.532-1.401l2.092-.796-2.996-7.569a.75.75 0 01.443-.981l9.406-3.388a.75.75 0 01.951.43l2.941 7.426 1.76-.668L17.55 15V4zm3.25 14.5a2 2 0 10-4 0 2 2 0 004 0zm-9.074-9l2.665 6.733-7.94 3.016-2.719-6.869 7.994-2.88zM7.079 12.64l1.95-.77a.75.75 0 11.551 1.396l-1.95.77a.75.75 0 11-.551-1.396z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLoadingRamp = React.memo(SvgLoadingRamp);
export default MemoSvgLoadingRamp;
