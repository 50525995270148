import * as React from 'react';
const SvgLamp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.957 1.798c2.032-.011 4.015.786 5.217 1.835l.008.006.007.007c2.287 2.102 2.83 4.629 2.852 6.08.03 1.968-1.019 4.292-2.388 5.62-.763.738-1.034 1.494-1.034 2.195h-1.5c0-1.141.461-2.276 1.49-3.273 1.097-1.063 1.955-2.998 1.932-4.52-.017-1.14-.451-3.232-2.36-4.991-.915-.794-2.527-1.462-4.193-1.46V3.3c-1.669-.008-3.238.656-4.16 1.458-1.91 1.759-2.344 3.85-2.361 4.99-.023 1.523.835 3.458 1.932 4.521 1.03.997 1.49 2.132 1.49 3.273h-1.5c0-.701-.271-1.457-1.034-2.196-1.37-1.328-2.418-3.651-2.388-5.62.022-1.45.566-3.977 2.852-6.08l.008-.006.007-.006c1.19-1.038 3.1-1.834 5.123-1.834v-.001z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39 16.791h9.231v3.086a2.75 2.75 0 01-2.75 2.75h-3.73a2.75 2.75 0 01-2.75-2.75V16.79zm1.5 1.5v1.586c0 .69.56 1.25 1.25 1.25h3.731c.69 0 1.25-.56 1.25-1.25V18.29h-6.23z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgLamp = React.memo(SvgLamp);
export default MemoSvgLamp;
