import * as React from 'react';

const SvgDoubleCheck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.426 7.448a.75.75 0 01.113.973l-.069.087-6.58 7.152a1.75 1.75 0 01-2.535.043l-.106-.119-.43-.524a.75.75 0 011.085-1.03l.076.08.43.524a.25.25 0 00.351.035l.026-.024 6.58-7.153a.75.75 0 011.059-.044zm-5.225 0a.75.75 0 01.113.973l-.07.087-6.559 7.13a1.751 1.751 0 01-2.565.013l-.103-.121-2.609-3.349a.75.75 0 011.11-1.004l.074.082L7.2 14.608a.25.25 0 00.35.043l.031-.028 6.56-7.13a.75.75 0 011.06-.045z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDoubleCheck = React.memo(SvgDoubleCheck);
export default MemoSvgDoubleCheck;
