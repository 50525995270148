import * as React from 'react';
const SvgCommercialPark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 20.687V6.29277C3.5 5.91677 3.60525 5.57461 3.81575 5.26627C4.02625 4.95777 4.30433 4.73586 4.65 4.60052L14.3903 1.86602C14.6916 1.74552 14.9711 1.77586 15.2288 1.95702C15.4864 2.13819 15.6152 2.38727 15.6152 2.70427V11.2063H16.9615C16.9615 10.7198 17.1347 10.3033 17.481 9.95677C17.8273 9.61027 18.2437 9.43702 18.73 9.43702C19.2163 9.43702 19.6329 9.61027 19.9797 9.95677C20.3266 10.3033 20.5 10.7198 20.5 11.2063V20.687C20.5 21.1089 20.3523 21.4675 20.0568 21.763C19.7613 22.0585 19.4026 22.2063 18.9808 22.2063H5.01925C4.59742 22.2063 4.23875 22.0585 3.94325 21.763C3.64775 21.4675 3.5 21.1089 3.5 20.687ZM5 20.7063H9.5V13.014C9.5 12.5169 9.677 12.0913 10.031 11.7373C10.385 11.3833 10.8106 11.2063 11.3078 11.2063H14.1155V3.55827L5.19225 6.00427C5.13458 6.02994 5.08817 6.06844 5.053 6.11977C5.01767 6.17111 5 6.22877 5 6.29277V20.7063ZM11 20.7063H14.1155V18.937C14.1155 18.6922 14.2017 18.4835 14.374 18.311C14.5465 18.1387 14.7552 18.0525 15 18.0525C15.2448 18.0525 15.4535 18.1387 15.626 18.311C15.7983 18.4835 15.8845 18.6922 15.8845 18.937V20.7063H19V12.7063H11.3078C11.2179 12.7063 11.1442 12.7351 11.0865 12.7928C11.0288 12.8504 11 12.9242 11 13.014V20.7063Z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgCommercialPark = React.memo(SvgCommercialPark);
export default MemoSvgCommercialPark;
