import * as React from 'react';

const SvgPaint = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10c0 .414.336.75.75.75h3.908a.932.932 0 00-.035.185c-.585 6.852-.042 9.381 1.627 9.382 1.61 0 2.172-2.355 1.684-8.677l-.057-.705a.93.93 0 00-.035-.185h3.908a.75.75 0 00.75-.75V4a.75.75 0 00-.75-.75h-11A.75.75 0 006 4v6zm1.5-.75v-4.5H9v1.284a.5.5 0 101 0V4.75h1.733v1.284a.5.5 0 001 0V4.75h1.7v1.284a.5.5 0 001 0V4.75H17v4.5H7.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPaint = React.memo(SvgPaint);
export default MemoSvgPaint;
