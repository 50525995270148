import * as React from 'react';
const SvgGraph = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.15 1.032c0-.47.38-.85.85-.85h5.924c.47 0 .85.38.85.85v20.634H.15V1.032zM8.624 14.2c0-.47.38-.85.85-.85h5.923c.47 0 .85.38.85.85v7.466H8.624v-7.467zM17.097 4.15c0-.469.38-.85.85-.85h5.923c.47 0 .85.381.85.85v17.516h-7.623V4.15z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgGraph = React.memo(SvgGraph);
export default MemoSvgGraph;
