import * as React from 'react';
const SvgParasol = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.957 20.021l-5.152-5.133a.692.692 0 01-.217-.522c0-.21.073-.387.218-.532a.718.718 0 01.527-.217c.206 0 .381.073.526.217l5.143 5.143a.713.713 0 01.207.527.698.698 0 01-.217.527.686.686 0 01-.517.203.72.72 0 01-.518-.213zM4.871 18.144a9.788 9.788 0 01-.699-8.657c.46-1.172 1.166-2.23 2.114-3.172.95-.948 2.012-1.656 3.19-2.122a9.732 9.732 0 013.61-.699 9.602 9.602 0 015.02 1.406c.407.256.628.622.661 1.098.033.476-.132.895-.496 1.26L7.228 18.3c-.364.364-.786.53-1.267.5-.48-.03-.844-.248-1.09-.656zm1.25-.875l1.58-1.58c-.266-.363-.525-.741-.776-1.133a11.262 11.262 0 01-.677-1.216c-.2-.418-.367-.85-.501-1.297a9.294 9.294 0 01-.3-1.359 7.43 7.43 0 00-.402 3.369 7.943 7.943 0 001.076 3.216zm2.684-2.634l5.8-5.85c-.716-.55-1.442-.99-2.177-1.319-.734-.329-1.426-.55-2.075-.666-.65-.115-1.237-.125-1.761-.029-.524.096-.931.29-1.221.579-.29.3-.483.712-.579 1.236-.096.525-.088 1.114.024 1.769.112.654.333 1.348.661 2.08.33.733.772 1.466 1.328 2.2zM17.29 6.15a7.94 7.94 0 00-3.261-1.098 7.544 7.544 0 00-3.373.406c.475.059.936.158 1.383.299.447.14.88.308 1.297.503.418.194.822.417 1.213.669.39.251.767.518 1.13.802L17.29 6.15z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgParasol = React.memo(SvgParasol);
export default MemoSvgParasol;
