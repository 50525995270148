import * as React from 'react';

const SvgLaptop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.75a.25.25 0 00-.25.25v7.25h12.5V7a.25.25 0 00-.25-.25H6zm13.75 7.5V7A1.75 1.75 0 0018 5.25H6A1.75 1.75 0 004.25 7v7.25H4a1.75 1.75 0 100 3.5h16a1.75 1.75 0 100-3.5h-.25zm0 1.5H4a.25.25 0 100 .5h16a.25.25 0 100-.5h-.25z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLaptop = React.memo(SvgLaptop);
export default MemoSvgLaptop;
