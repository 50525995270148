import * as React from 'react';

const SvgShed = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.792 2.905l-9.5 2.75a.75.75 0 00.417 1.44l1.041-.302v1.439l-1.458.423a.75.75 0 00.417 1.44l1.041-.302v10.582a.75.75 0 001.5 0V9.359l2.5-.724v7.74a.75.75 0 001.5 0V8.201L12 7.405l2.75.796v8.174a.75.75 0 001.5 0v-7.74l2.5.724v11.016a.75.75 0 001.5 0V9.794l1.042.301a.75.75 0 10.417-1.44l-1.459-.423V6.794l1.042.301a.75.75 0 10.417-1.44l-9.536-2.76-.08-.014-.083-.006-.078.003-.082.012-.058.015zM9.25 6.639l2.542-.734.058-.015.082-.012.069-.003.091.006.08.014 2.578.745V5.375a.75.75 0 01.02-.168L12 4.405l-2.769.802c.013.054.02.11.02.168v1.264zm-4.044-.267l2.544-.737v1.438l-2.5.724V6.625a.749.749 0 00-.044-.253zm11.044.702V5.635l2.544.737a.749.749 0 00-.044.253v1.173l-2.5-.724z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgShed = React.memo(SvgShed);
export default MemoSvgShed;
