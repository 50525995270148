/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgChecked = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.307 1.288a.312.312 0 0 1 .489.387l-.029.037-4.682 5.09a.728.728 0 0 1-1.069.005l-.043-.051-1.97-2.529a.313.313 0 0 1 .462-.418l.032.034 1.97 2.529c.035.045.1.053.145.018l.013-.012 4.682-5.09Z"
        fill="#fff"
      />
      <path
        d="m8.307 1.288.368.339-.368-.339Zm.442-.018.338-.368-.338.368Zm.047.405.392.311.011-.015.01-.015-.413-.28Zm-.029.037.368.338.013-.013.011-.015-.392-.31Zm-4.682 5.09.368.338-.368-.338Zm-.089.081.308.395-.308-.395Zm-.98-.076-.38.324.007.009.008.008.365-.341Zm-.043-.051-.394.307.007.01.007.008.38-.325Zm-1.97-2.529-.394.308.394-.308Zm.055-.438.307.394-.307-.394Zm.407.02.371-.335-.012-.014-.013-.013-.346.362Zm.032.034.394-.307-.011-.014-.013-.014-.37.335Zm1.97 2.529-.395.307.394-.307Zm.145.018.308.395.016-.013.015-.014-.339-.368Zm.013-.012.339.368.015-.014.014-.015-.368-.339Zm5.05-4.751a.188.188 0 0 1-.265.011l.677-.736A.813.813 0 0 0 7.94.95l.736.677Zm-.265.011a.187.187 0 0 1-.028-.244l.828.562A.813.813 0 0 0 9.087.902l-.677.736Zm-.006-.273-.029.036.784.621.029-.036-.784-.621Zm-.005.008-4.682 5.09.736.677 4.682-5.09-.736-.677Zm-4.682 5.09a.23.23 0 0 1-.028.026l.615.789a1.23 1.23 0 0 0 .149-.138l-.736-.677Zm-.028.026a.23.23 0 0 1-.308-.024l-.73.683a1.23 1.23 0 0 0 1.653.13l-.615-.79Zm-.292-.007-.044-.05-.76.649.043.05.76-.65Zm-.03-.033L1.398 3.92l-.788.615 1.97 2.528.789-.614ZM1.398 3.92c.065.082.05.2-.032.263l-.614-.789a.812.812 0 0 0-.142 1.14l.789-.614Zm-.032.263a.187.187 0 0 1-.245-.012l.69-.724a.813.813 0 0 0-1.06-.053l.615.79Zm-.27-.039.03.035.742-.67-.03-.035-.742.67Zm.007.007 1.97 2.528.789-.614-1.97-2.529-.789.615Zm1.97 2.528c.205.263.584.31.848.106l-.615-.79a.396.396 0 0 1 .556.07l-.79.614Zm.88.079.012-.012-.678-.735-.012.011.677.736Zm.041-.041 4.682-5.09L7.94.95 3.257 6.04l.736.677Z"
        fill="#fff"
      />
    </svg>
  );
};

const Memo = memo(SvgChecked);
export default Memo;
