import * as React from 'react';

const SvgAddcircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25 2.25c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm.05 3.5a.75.75 0 01.743.648L13.05 8v3.25h3.25a.75.75 0 01.102 1.493l-.102.007h-3.25V16a.75.75 0 01-1.493.102L11.55 16v-3.25H8.3a.75.75 0 01-.102-1.493l.102-.007h3.25V8a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAddcircle = React.memo(SvgAddcircle);
export default MemoSvgAddcircle;
