import { useEffect } from 'react';

export const DISABLE_CLASS = 'disableScrollMobile';

export const useDisableScrollOnMobile = (disableScroll: boolean) => {
  useEffect(() => {
    if (disableScroll) {
      document.body.classList.add(DISABLE_CLASS);
    } else {
      document.body.classList.remove(DISABLE_CLASS);
    }

    return function dispose() {
      document.body.classList.remove(DISABLE_CLASS);
    };
  }, [disableScroll]);
};

// use this only if your modal has the option to open a keyboard, this hook has a side-effect that will reset the scroll position
// if there's no input or a way to open a keyboard use use-disable-scroll-on-mobile
const DISABLE_SCROLL_CLASS = 'disableScrollMobilePopupWithKeyboard';

export const useDisableBodyScrollOnMobileWithKeyboard = (
  shouldDisable: boolean,
) => {
  const lockScroll = () => {
    document.body.classList.add(DISABLE_SCROLL_CLASS);
  };

  const unlockScroll = () => {
    document.body.classList.remove(DISABLE_SCROLL_CLASS);
  };

  useEffect(() => {
    if (shouldDisable) lockScroll();

    return () => {
      unlockScroll();
    };
  }, [shouldDisable]);
};
