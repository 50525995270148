import * as React from 'react';

const SvgHandicap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.941C14 2.871 13.104 2 11.996 2 10.896 2 10 2.871 10 3.941c0 1.076.897 1.948 1.996 1.948C13.103 5.889 14 5.018 14 3.94zm.121 4.224l5.05-.61c.478-.02.848-.41.828-.867-.016-.461-.421-.817-.9-.798l-6.018.474H10.6L4.95 5.9c-.478-.037-.912.305-.947.765a.818.818 0 00.763.895l4.652.606v4.914l-2.117 7.666c-.194.474.105 1.007.595 1.19.495.184 1.046-.034 1.24-.507l2.305-7.259h.772l2.506 7.111c.128.493.65.762 1.16.64.51-.122.82-.615.69-1.108L14.124 13l-.002-4.835z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgHandicap = React.memo(SvgHandicap);
export default MemoSvgHandicap;
