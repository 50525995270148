import type { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

const ANTIBOT_REDIRECT_STATUS = 302;
const ANTIBOT_BASE_PATH = 'validate.perfdrive.com';

const isAntiBotLocation = (response: AxiosResponse) => {
  const { location = '' } = response.headers;

  return location.includes(ANTIBOT_BASE_PATH);
};

const redirectOnAntiBotError = (response: AxiosResponse) => {
  const isAntiBotStatus = response.status === ANTIBOT_REDIRECT_STATUS;

  if (isAntiBotStatus && isAntiBotLocation(response)) {
    const { location } = response.headers;

    window.location.href = location;
  }
};

export default function (client: AxiosInstance) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    client.interceptors.response.use(
      (response: AxiosResponse) => {
        redirectOnAntiBotError(response);

        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          redirectOnAntiBotError(error.response);
        }

        return Promise.reject(error);
      },
    );
  }
}
