import styles from './not-supported-strip.module.scss';
import texts from './not-supported-strip.texts.json';
import cn from 'classnames';
import { AttentionIcon, CloseIcon } from '@y2/mango/components/icons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDetailsClick: () => void;
};

export const NotSupportedIndicator = ({
  isOpen,
  onClose,
  onDetailsClick,
}: Props) => (
  <div
    className={cn(styles.strip, !isOpen && styles.closed)}
    data-testid="unsupported-indicator"
  >
    <div className={styles.right}>
      <AttentionIcon className={styles.noticeIcon} />
      <span className={styles.notice}>{texts.notSupported}</span>
    </div>
    <div className={styles.left}>
      <button className={styles.detailsButton} onClick={onDetailsClick}>
        {texts.forDetails}
      </button>
      <button
        aria-label={texts.close}
        className={cn(styles.closeButton, styles.left)}
        onClick={onClose}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>
    </div>
  </div>
);
