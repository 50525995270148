import * as React from 'react';

const SvgChevronright = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.986 6.66a.75.75 0 01.98-1.13l.084.072 6.137 6.18a.75.75 0 01.069.976l-.073.084-6.145 6.102a.75.75 0 01-1.13-.98l.073-.084 5.613-5.574L8.986 6.66z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgChevronright = React.memo(SvgChevronright);
export default MemoSvgChevronright;
