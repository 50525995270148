/* eslint-disable no-magic-numbers */
export const vehicles = {
  title: 'vehicles' as const,
  titleHeb: 'רכב',
  id: 1 as const,
  subCategories: {
    privateCars: {
      id: 1 as const,
      title: 'private-cars' as const,
      titleHeb: 'פרטי',
      commercialSubCat: 8,
      familySubCat: 21,
    },
    commercialCars: {
      id: 2 as const,
      title: 'commercial-cars' as const,
      titleHeb: 'מסחרי',
      commercialSubCat: 2,
      familySubCat: 21,
    },
    jeeps: {
      id: 3 as const,
      title: '4X4' as const,
      titleHeb: "ג'יפים",
      commercialSubCat: 3,
      familySubCat: 21,
    },
    motorcycles: {
      id: 4 as const,
      title: 'motorcycles' as const,
      titleHeb: 'אופנועים',
      pluralTitle: 'אופנועים',
      singularTitle: 'אופנוע',
      commercialSubCat: 4,
    },
    scooters: {
      id: 5 as const,
      title: 'scooters' as const,
      titleHeb: 'קטנועים',
      pluralTitle: 'קטנועים',
      singularTitle: 'קטנוע',
      commercialSubCat: 5,
    },
    other: {
      id: 6 as const,
      title: 'other' as const,
      titleHeb: 'מיוחדים',
      pluralTitle: 'רכבים מיוחדים',
      singularTitle: 'רכב מיוחד',
      commercialSubCat: 6,
    },
    carAccessories: {
      id: 7 as const,
      title: 'car-accessories' as const,
      titleHeb: 'אביזרים',
      commercialSubCat: 7,
    },
    vehicleAgency: {
      id: 8 as const,
      title: 'vehicle-agency' as const,
      titleHeb: 'פרטי (סוכנויות ועסקים)',
      commercialSubCat: 8,
      familySubCat: 21,
    },
    trucks: {
      id: 9 as const,
      title: 'trucks' as const,
      titleHeb: 'משאיות',
      pluralTitle: 'משאיות',
      singularTitle: 'משאית',
      commercialSubCat: 9,
    },
    newCar: {
      id: 10 as const,
      title: 'new-car' as const,
      titleHeb: 'רכב חדש',
    },
    luxuryCars: {
      id: 11 as const,
      title: 'luxury-cars' as const,
      titleHeb: 'רכבי יוקרה',
      commercialSubCat: 8,
    },
    watercraft: {
      id: 13 as const,
      title: 'watercraft' as const,
      titleHeb: 'כלי שייט',
      pluralTitle: 'כלי שייט',
      singularTitle: 'כלי שייט',
      commercialSubCat: 13,
    },
    familyCars: {
      id: 14 as const,
      title: 'family-cars' as const,
      titleHeb: 'משפחתיות',
      familySubCat: 21,
    },
    crossoverCars: {
      id: 15 as const,
      title: 'crossover-cars' as const,
      titleHeb: 'קרוסאובר',
      familySubCat: 21,
    },
    miniCars: {
      id: 16 as const,
      title: 'mini-cars' as const,
      titleHeb: 'מיני',
      familySubCat: 21,
    },
    kones: {
      id: 17 as const,
      title: 'kones' as const,
      titleHeb: 'כונס רכבים',
    },
    cars: {
      id: 21 as const,
      title: 'cars' as const,
      titleHeb: 'רכב',
      pluralTitle: 'רכבים',
      singularTitle: 'רכב',
    },
  },
};
