import * as React from 'react';
const SvgDirectionsTransit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#directions-transit_svg__clip0_4675_620)"
        fill="currentColor"
      >
        <path d="M12 2.5c-4 0-7.5 0-7.5 3.5v9.5c0 1.93 1.07 3.5 3 3.5l-1.396 1.396a.354.354 0 00.25.604h11.292a.354.354 0 00.25-.604L16.5 19c1.93 0 3-1.57 3-3.5V6c0-3.5-3.08-3.5-7.5-3.5zM17.66 5H6.43c.61-.52 2.06-1 5.57-1 3.71 0 5.12.46 5.66 1zM10 7a1 1 0 011 1v1a1 1 0 01-1 1H7a1 1 0 01-1-1V8a1 1 0 011-1h3zm3 1a1 1 0 011-1h3a1 1 0 011 1v1a1 1 0 01-1 1h-3a1 1 0 01-1-1V8zm3.5 9h-9c-.83 0-1.5-.67-1.5-1.5V13a1 1 0 011-1h10a1 1 0 011 1v2.5c0 .83-.67 1.5-1.5 1.5z" />
        <path d="M8.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
      </g>
      <defs>
        <clipPath id="directions-transit_svg__clip0_4675_620">
          {/* eslint-disable-next-line custom-rules/use-current-color */}
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgDirectionsTransit = React.memo(SvgDirectionsTransit);
export default MemoSvgDirectionsTransit;
