import * as React from 'react';
const SvgRenovated = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.125 3.658c0-.966.783-1.75 1.75-1.75h10.027c.967 0 1.75.784 1.75 1.75v1.29h.973c.69 0 1.25.56 1.25 1.25v4.857c0 .69-.56 1.25-1.25 1.25h-5.75v1.643h.75a.75.75 0 01.75.75v6c0 .23-.104.437-.267.575-.31.492-.858.819-1.482.819h-1a1.749 1.749 0 01-1.482-.819.748.748 0 01-.269-.575v-6a.75.75 0 01.75-.75h.75v-1.893c0-.69.56-1.25 1.25-1.25h5.75V6.449h-.723v1.29a1.75 1.75 0 01-1.75 1.75H5.875a1.75 1.75 0 01-1.75-1.75v-4.08zm1.75-.25a.25.25 0 00-.25.25v4.08c0 .138.112.25.25.25h10.027a.25.25 0 00.25-.25v-4.08a.25.25 0 00-.25-.25H5.875zm5.5 12.29v4.25h1.5v-4.265a.25.25 0 00-.25-.235h-1a.25.25 0 00-.25.25z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgRenovated = React.memo(SvgRenovated);
export default MemoSvgRenovated;
