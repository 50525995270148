import styles from './category-item.module.scss';
import { ChevronLeftIcon } from '@y2/mango/components/icons';

type Props = {
  text: string;
  url: string;
  onMouseEnter: () => void;
  image?: string;
};

export const CategoryItem = ({ url, text, image, onMouseEnter }: Props) => {
  return (
    <li
      onMouseEnter={onMouseEnter}
      className={styles.item}
      data-testid="category-item"
    >
      <a href={url} className={styles.link}>
        {image && (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        )}
        <span className={styles.text}>{text}</span>
        <ChevronLeftIcon className={styles.icon} data-testid="chevron-icon" />
      </a>
    </li>
  );
};
