import * as React from 'react';

const SvgCreditcard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.25A1.75 1.75 0 002.25 6v12c0 .966.784 1.75 1.75 1.75h16A1.75 1.75 0 0021.75 18V6A1.75 1.75 0 0020 4.25H4zm-.25 6.5h16.5V18a.25.25 0 01-.25.25H4a.25.25 0 01-.25-.25v-7.25zm0-2.5h16.5V6a.25.25 0 00-.25-.25H4a.25.25 0 00-.25.25v2.25zm3.25 6a.75.75 0 000 1.5h4a.75.75 0 000-1.5H7z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCreditcard = React.memo(SvgCreditcard);
export default MemoSvgCreditcard;
