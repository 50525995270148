import { ReactNode } from 'react';
import styles from './error-layout.module.scss';
import { Header } from '../header';
import { Footer, CATEGORIES_LIST } from '@y2/footer';
import env from '@y2/environments';
import cn from 'classnames';

type Props = {
  children: ReactNode;
};

export const ErrorLayout = ({ children }: Props) => (
  <div className={cn(styles.grey, 'app')}>
    <Header />
    <main className={styles.baseIndex}>{children}</main>
    <Footer
      className="footer"
      env={env.name}
      categoriesList={CATEGORIES_LIST}
    />
  </div>
);
