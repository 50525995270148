import * as React from 'react';
const SvgEye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.299 8.453C6.233 6.186 8.95 4.75 12 4.75c4.332 0 7.995 2.897 9.691 6.961a.75.75 0 010 .578c-1.696 4.064-5.359 6.96-9.692 6.96-3.05 0-5.77-1.435-7.703-3.704a12.34 12.34 0 01-1.99-3.256.75.75 0 01.001-.578l.01-.024.042-.098a12.342 12.342 0 011.939-3.136zm1.142 6.125c1.683 1.971 4.013 3.172 6.56 3.172 3.52 0 6.627-2.296 8.182-5.75C18.628 8.545 15.52 6.25 12 6.25c-2.547 0-4.878 1.201-6.561 3.174A10.808 10.808 0 003.818 12a10.806 10.806 0 001.623 2.578z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.308 11.71l.01-.023.042-.098a12.342 12.342 0 011.939-3.136C6.233 6.186 8.95 4.75 12 4.75c4.332 0 7.995 2.897 9.691 6.961a.75.75 0 010 .578c-1.696 4.064-5.359 6.96-9.692 6.96-3.05 0-5.77-1.435-7.703-3.704a12.34 12.34 0 01-1.99-3.256.75.75 0 01.001-.578zm-.212.81a12.627 12.627 0 001.987 3.215c1.98 2.32 4.774 3.8 7.917 3.8 4.47 0 8.224-2.987 9.956-7.136a1.035 1.035 0 000-.798C20.224 7.452 16.47 4.464 12 4.464c-3.143 0-5.935 1.479-7.916 3.799a12.628 12.628 0 00-2.03 3.313l-.01.025a1.036 1.036 0 000 .798l.05.121zm3.323 2.03A10.806 10.806 0 013.818 12 10.808 10.808 0 015.44 9.424C7.123 7.451 9.454 6.25 12 6.25c3.52 0 6.628 2.295 8.183 5.75-1.555 3.454-4.663 5.75-8.183 5.75-2.546 0-4.876-1.2-6.559-3.172a.806.806 0 00-.022-.028zm.244-.153A10.498 10.498 0 014.132 12a10.499 10.499 0 011.53-2.395C7.296 7.69 9.55 6.535 12 6.535c3.348 0 6.333 2.16 7.868 5.465-1.535 3.305-4.52 5.464-7.869 5.464-2.45 0-4.702-1.154-6.337-3.067z"
        fill="currentColor"
      />
      <path
        d="M15.572 12a3.571 3.571 0 11-7.143 0 3.571 3.571 0 017.143 0z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgEye = React.memo(SvgEye);
export default MemoSvgEye;
