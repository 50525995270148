import * as React from 'react';

const SvgHistory = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.332 5.107a7.818 7.818 0 00-9.865 3.445l2.11-.006a.75.75 0 11.004 1.5l-3.82.01a.75.75 0 01-.752-.748L3 5.488a.75.75 0 111.5-.003l.004 1.754C6.908 3.533 11.69 1.96 15.91 3.723a9.317 9.317 0 11-12.623 10.89.75.75 0 011.453-.371 7.817 7.817 0 0014.788 1.09 7.815 7.815 0 00-4.196-10.225z"
        fill="currentColor"
      />
      <path
        d="M12.743 9.017a.75.75 0 00-1.493.102V14l.007.104a.75.75 0 00.234.447l2.418 2.231.087.07a.75.75 0 00.973-.113l.069-.087a.75.75 0 00-.112-.972l-2.177-2.01.001-4.551-.007-.102z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgHistory = React.memo(SvgHistory);
export default MemoSvgHistory;
