/* eslint-disable no-magic-numbers */
export const recreation = {
  title: 'recreation' as const,
  titleHeb: 'תיירות ונופש',
  id: 10 as const,
  subCategories: {
    frequentFlyerPoints: {
      id: 1 as const,
      title: 'frequent-flyer-points' as const,
      titleHeb: 'נקודות הנוסע המתמיד',
    },
    unitsAndHolidayApartments: {
      id: 2 as const,
      title: 'units-and-holiday-apartments' as const,
      titleHeb: 'יחידות ודירות נופש',
    },
    accommodation: {
      id: 3 as const,
      title: 'accommodation' as const,
      titleHeb: 'צימרים',
    },
    sailing: {
      id: 4 as const,
      title: 'sailing' as const,
      titleHeb: 'שייט',
    },
    organizedTrips: {
      id: 5 as const,
      title: 'organized-trips' as const,
      titleHeb: 'טיולים מאורגנים',
    },
    flightTickets: {
      id: 6 as const,
      title: 'flight-tickets' as const,
      titleHeb: 'כרטיסי טיסה',
    },
  },
};
