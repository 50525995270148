import * as React from 'react';
const SvgShot = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.545 5.25A2.25 2.25 0 014.795 3h9a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-7.5z"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.545 7.5a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM11.545 6h1.5M11.545 9h1.5M5.545 12h7.5"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const MemoSvgShot = React.memo(SvgShot);
export default MemoSvgShot;
