/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
export const products = {
  title: 'products' as const,
  titleHeb: 'יד שנייה',
  id: 3 as const,
  subCategories: {
    electricalAppliances: {
      id: 1 as const,
      title: 'electrical-appliances' as const,
      titleHeb: 'מוצרי חשמל',
    },
    furniture: {
      id: 2 as const,
      title: 'furniture' as const,
      titleHeb: 'ריהוט',
    },
    musicalInstruments: {
      id: 4 as const,
      title: 'musical-instruments' as const,
      titleHeb: 'כלי נגינה',
    },
    cellular: {
      id: 5 as const,
      title: 'cellular' as const,
      titleHeb: 'סלולרי',
    },
    computersAndAccessories: {
      id: 6 as const,
      title: 'computers-and-accessories' as const,
      titleHeb: 'מחשבים וציוד נלווה',
    },
    stereo: {
      id: 8 as const,
      title: 'stereo' as const,
      titleHeb: 'סטריאו',
    },
    workTools: {
      id: 9 as const,
      title: 'work-tools' as const,
      titleHeb: 'כלי עבודה',
    },
    babyAndChild: {
      id: 11 as const,
      title: 'baby-and-child' as const,
      titleHeb: 'לתינוק ולילד',
    },
    sport: {
      id: 12 as const,
      title: 'sport' as const,
      titleHeb: 'ספורט',
    },
    bicycles: {
      id: 14 as const,
      title: 'bycicles' as const,
      titleHeb: 'אופניים',
    },
    fashionAndGrooming: {
      id: 18 as const,
      title: 'fashion-and-grooming' as const,
      titleHeb: 'אופנה וטיפוח',
    },
    literatureAndArticles: {
      id: 19 as const,
      title: 'literature-and-articles' as const,
      titleHeb: 'ספרות וחומרי לימוד',
    },
    photography: {
      id: 20 as const,
      title: 'photography' as const,
      titleHeb: 'צילום',
    },
    coupons: {
      id: 23 as const,
      title: 'coupons' as const,
      titleHeb: 'שוברי זיכוי \\ תלושים \\ שונות',
    },
    collectibles: {
      id: 24 as const,
      title: 'collectibles' as const,
      titleHeb: 'אספנות',
    },
    officeEquipment: {
      id: 25 as const,
      title: 'office-equipment' as const,
      titleHeb: 'ציוד משרדי',
    },
    weapons: {
      id: 27 as const,
      title: 'weapons' as const,
      titleHeb: 'כלי נשק',
    },
    businessEquipment: {
      id: 28 as const,
      title: 'business-equipment' as const,
      titleHeb: 'ציוד לעסקים',
    },
    musicAndFilm: {
      id: 29 as const,
      title: 'music-and-film' as const,
      titleHeb: 'מוסיקה וסרטים',
    },
    gamingConsoles: {
      id: 30 as const,
      title: 'gaming-consoles' as const,
      titleHeb: 'קונסולות משחק',
    },
    sanitaryTools: {
      id: 32 as const,
      title: 'sanitary-tools' as const,
      titleHeb: 'כלים סניטריים',
    },
    studioEquipment: {
      id: 33 as const,
      title: 'studio-equipment' as const,
      titleHeb: "ציוד לדי.ג'י. ולאולפנים",
    },
    judaica: {
      id: 34 as const,
      title: 'judaica' as const,
      titleHeb: 'יודאיקה',
    },
    communication: {
      id: 36 as const,
      title: 'communication' as const,
      titleHeb: 'תקשורת',
    },
    businessesForSale: {
      id: 37 as const,
      title: 'businesses-for-sale' as const,
      titleHeb: 'עסקים למכירה/מסירה',
    },
    medicalEquipment: {
      id: 38 as const,
      title: 'medical-equipment' as const,
      titleHeb: 'ציוד סיעודי/ רפואי',
    },
    scooter: {
      id: 39 as const,
      title: 'scooter' as const,
      titleHeb: 'קורקינטים',
    },
    stocks: {
      id: 40 as const,
      title: 'stocks' as const,
      titleHeb: 'סטוקים',
    },
    art: {
      id: 42 as const,
      title: 'art' as const,
      titleHeb: 'אומנות',
    },
    homeAndOfficeSecurity: {
      id: 43 as const,
      title: 'home-and-office-security' as const,
      titleHeb: 'מיגון לבית ולעסק',
    },
    homeAppliances: {
      id: 45 as const,
      title: 'home-appliances' as const,
      titleHeb: 'לבית',
    },
    gardening: {
      id: 49 as const,
      title: 'gardening' as const,
      titleHeb: 'לגינה',
    },
    travelAndMilitary: {
      id: 51 as const,
      title: 'travel-and-military' as const,
      titleHeb: 'למטייל ולמתגייס',
    },
    tickets: {
      id: 54 as const,
      title: 'tickets' as const,
      titleHeb: 'מנויים וכרטיסים',
    },
    industrialEquipment: {
      id: 56 as const,
      title: 'industrial-equipment' as const,
      titleHeb: 'ציוד לתעשייה',
    },
    giveaway: {
      id: 59 as const,
      title: 'giveaway' as const,
      titleHeb: 'למסירה - הכל בחינם',
    },
    portableHousing: {
      id: 61 as const,
      title: 'portable-housing' as const,
      titleHeb: 'מבנים ניידים',
    },
    schoolEquipment: {
      id: 67 as const,
      title: 'school-equipment' as const,
      titleHeb: 'ציוד לבית ספר',
    },
    equipmentForEvents: {
      id: 69 as const,
      title: 'equipment-for-events' as const,
      titleHeb: 'ציוד לאירועים',
    },
    lighting: {
      id: 70 as const,
      title: 'lighting' as const,
      titleHeb: 'תאורה ונברשות',
    },
    toysAndGames: {
      id: 71 as const,
      title: 'toys-and-games' as const,
      titleHeb: 'משחקים וצעצועים',
    },
    garageSale: {
      id: 76 as const,
      title: 'garage-sale' as const,
      titleHeb: "גראג' סייל",
    },
    rentalEquipment: {
      id: 77 as const,
      title: 'rental-equipment' as const,
      titleHeb: 'ציוד להשכרה',
    },
    laundryClothesHangers: {
      id: 78 as const,
      title: 'laundry-clothes-hangers' as const,
      titleHeb: 'מתלי בגדים וכביסה',
    },
    massageDevices: {
      id: 82 as const,
      title: 'massage-devices' as const,
      titleHeb: 'מכשירי עיסוי',
    },
  },
};
