import * as React from 'react';
const SvgBars = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 6.643A2.893 2.893 0 016.643 3.75h10.714a2.893 2.893 0 012.893 2.893v10.714a2.893 2.893 0 01-2.893 2.893H6.643a2.893 2.893 0 01-2.893-2.893V6.643zM6.643 5.25c-.77 0-1.393.624-1.393 1.393v10.714c0 .77.624 1.393 1.393 1.393h10.714c.77 0 1.393-.624 1.393-1.393V6.643c0-.77-.624-1.393-1.393-1.393H6.643z"
        fill="currentColor"
      />
      <path d="M11.25 4.813h1.5v14.374h-1.5V4.813z" fill="currentColor" />
      <path d="M19.147 11.25v1.5H4.853v-1.5h14.294z" fill="currentColor" />
    </svg>
  );
};
const MemoSvgBars = React.memo(SvgBars);
export default MemoSvgBars;
