import * as React from 'react';
const SvgBumpAdIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.281a7.719 7.719 0 100 15.438A7.719 7.719 0 0012 4.28zM2.78 12A9.219 9.219 0 1112 21.219 9.219 9.219 0 012.78 12z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.084 11.958a.75.75 0 01-1.06 0l-2.992-2.992-3.04 3.04a.75.75 0 01-1.06-1.06l3.57-3.57a.75.75 0 011.06 0l3.522 3.521a.75.75 0 010 1.06z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.032 7.587a.75.75 0 01.75.75v7.318a.75.75 0 01-1.5 0V8.337a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBumpAdIcon = React.memo(SvgBumpAdIcon);
export default MemoSvgBumpAdIcon;
