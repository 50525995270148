import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  Canceler,
  AxiosResponse,
  AxiosError,
} from 'axios';
import antiBotMiddleware from './middlewares/antibot.middleware';
import { wrapMethodsForTestEnv } from './helpers/test-run-preventions';

export type {
  Canceler,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
};

export const { CancelToken } = axios;

// eslint-disable-next-line prefer-destructuring
export const isAxiosError = axios.isAxiosError;

export default {
  ...axios,
  create: (config?: AxiosRequestConfig): AxiosInstance => {
    const client = axios.create(config);
    // default middlewares
    antiBotMiddleware(client);

    return wrapMethodsForTestEnv(client);
  },
};

export type ResponseBody<T = unknown> = {
  message: string;
  data?: T;
};
