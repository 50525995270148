import styles from './list-header.module.scss';

type Props = {
  title: string;
  route: string;
  baseUrl: string;
};

export const ListHeader = ({ title, route, baseUrl }: Props) => (
  <li className={styles.header} data-testid="list-header">
    <a href={`${baseUrl}/${route}`}>{title}</a>
  </li>
);
