/* istanbul ignore file */
import cn from 'classnames';
import styles from './back-button.module.scss';
import { ChevronRightIcon } from '@y2/mango/components/icons';

type Props = {
  text: string;
  onClick: () => void;
  className?: string;
};

export const BackButton = ({ text, onClick, className }: Props) => (
  <button
    className={cn(styles.root, className)}
    onClick={onClick}
    data-nagish="header-popover-back-button"
    data-testid="menu-slide-back-button"
  >
    <ChevronRightIcon className={styles.rightArrow} />
    <span className={styles.text}>{text}</span>
  </button>
);
