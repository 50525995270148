import type { AxiosInstance } from 'axios';
import env from '@y2/environments';

export const fetchMethods = ['get', 'post', 'put', 'delete', 'patch'] as const;

export const wrapMethodsForTestEnv = (
  axiosClient: AxiosInstance,
): AxiosInstance => {
  if (env.isJestRun) {
    fetchMethods.forEach((method) => {
      const originalMethod = axiosClient[method];
      // eslint-disable-next-line no-param-reassign
      axiosClient[method] = (...args: any) => {
        throw Error(
          `Remove api-clients import/usage during test, Mock your api client, example: jest.mock('@y2/api-client/auth');`,
        );

        return (originalMethod as any)(...args);
      };
    });
  }

  return axiosClient;
};
