import * as React from 'react';
const SvgWeb = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.992 9.317H3.893A8.528 8.528 0 003.463 12c0 .938.151 1.84.43 2.683h4.1A25.417 25.417 0 017.852 12c0-.929.048-1.829.14-2.683zm1.536 10.856a8.56 8.56 0 01-4.992-4.027h3.662c.285 1.61.738 3.005 1.33 4.027zM20.537 12c0 .938-.151 1.84-.43 2.683h-4.1c.092-.854.14-1.754.14-2.683 0-.929-.048-1.829-.14-2.683h4.1c.279.844.43 1.745.43 2.683zm-6.065 8.173c.592-1.022 1.045-2.417 1.33-4.027h3.662a8.56 8.56 0 01-4.992 4.027zm5.27-4.027zM14.682 12c0 .938-.053 1.84-.151 2.683H9.469A23.35 23.35 0 019.317 12c0-.938.054-1.84.152-2.683h5.062c.098.844.152 1.745.152 2.683zM12 20.537c-.184 0-.39-.086-.616-.292-.225-.206-.454-.518-.673-.932-.41-.772-.767-1.861-1.018-3.167h4.614c-.25 1.306-.609 2.395-1.018 3.167-.22.414-.448.727-.673.932-.226.206-.432.292-.616.292zM9.528 3.827c-.592 1.022-1.045 2.417-1.33 4.027H4.536a8.56 8.56 0 014.992-4.027zm-5.27 4.027zm15.61.244zm-4.066-.244c-.285-1.61-.738-3.005-1.33-4.027a8.56 8.56 0 014.992 4.027h-3.662zm-5.091-3.167c-.41.772-.767 1.861-1.018 3.167h4.614c-.25-1.306-.609-2.395-1.018-3.167-.22-.414-.448-.726-.673-.932-.226-.206-.432-.292-.616-.292s-.39.086-.616.292c-.225.206-.454.518-.673.932zM2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm7.445-4.146z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgWeb = React.memo(SvgWeb);
export default MemoSvgWeb;
