import * as React from 'react';

const SvgShowFull = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.164 6.648C15.58 5.658 13.783 4.971 11.958 5c-1.825-.03-3.623.658-5.206 1.647-1.592.995-3.023 2.329-4.124 3.672l-.002.003c-.237.291-.626.835-.626 1.5 0 .664.39 1.208.626 1.499l.002.002c1.077 1.315 2.492 2.65 4.084 3.652 1.582.998 3.392 1.701 5.246 1.67 1.854.031 3.665-.672 5.247-1.67 1.592-1.003 3.008-2.337 4.085-3.651l.003-.003.024-.03a4.67 4.67 0 00.382-.521c.135-.224.292-.554.292-.949 0-.395-.157-.724-.292-.948a4.68 4.68 0 00-.382-.52l-.024-.031-.003-.003c-1.102-1.344-2.533-2.677-4.126-3.672zm-.722 5.175a4.444 4.444 0 01-4.447 4.442 4.444 4.444 0 01-4.446-4.442 4.444 4.444 0 014.446-4.441 4.444 4.444 0 014.447 4.441zm-4.447 2.938a2.94 2.94 0 002.941-2.937 2.94 2.94 0 00-2.94-2.938 2.94 2.94 0 00-2.941 2.938 2.94 2.94 0 002.94 2.937z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgShowFull = React.memo(SvgShowFull);
export default MemoSvgShowFull;
