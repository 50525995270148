import { CategoryLink } from '../category-link';
import { MenuCategory } from '../menu-categories-data';
import { ListItem } from '../item';
import { MobileSlideMenu } from './mobile-menu/main';
import { DesktopDropMenu } from './desktop-menu/main';
import { useIsOpenContext } from './mobile-menu/open-context';
import { recommerceBaseUrl, recommerceSubTitle } from './const';
import { useSessionCategories } from './use-session-collection';
import { useState } from 'react';
import { LinkProps } from './types';

type Props = {
  categoryMenuItem: MenuCategory;
} & LinkProps;

export const RecommerceMenuItem = ({
  categoryMenuItem,
  ...linkProps
}: Props) => {
  const { collection } = useSessionCategories();
  const [isDesktopOpen, setIsDesktopOpen] = useState(false);
  const { setValue, closeAllMobileSlides } = useIsOpenContext();
  const baseMarketId = collection?.id || '';
  const hasDesktopMenu = !categoryMenuItem.isMobileOnly;
  const openMobile = () => setValue(baseMarketId, true);

  return (
    <ListItem
      onMouseEnter={() => setIsDesktopOpen(true)}
      onMouseLeave={() => {
        setIsDesktopOpen(false);
        closeAllMobileSlides();
      }}
      onClick={() => openMobile()}
      data-testid="recommerce-header-item"
    >
      <CategoryLink
        url={categoryMenuItem.url}
        text={categoryMenuItem.categoryText}
        icon={categoryMenuItem.icon}
        isNewTab={categoryMenuItem.newTab}
        hasLinkBehaviourInMobile={false}
        {...linkProps}
      />
      {collection && (
        <>
          <MobileSlideMenu
            item={collection}
            baseUrl={recommerceBaseUrl}
            header={recommerceSubTitle}
            withImages
          />
          {hasDesktopMenu && (
            <DesktopDropMenu
              items={collection.items || []}
              isOpen={isDesktopOpen}
            />
          )}
        </>
      )}
    </ListItem>
  );
};
