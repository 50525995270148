import * as React from 'react';

const SvgHide = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05 17.433a.75.75 0 101.09 1.03l1.214-1.285c.945.33 1.928.52 2.919.506 3.388.05 6.689-2.295 8.664-4.47l.002-.002.024-.025c.094-.104.24-.266.36-.446.128-.19.292-.492.292-.865 0-.372-.163-.674-.291-.864-.121-.18-.267-.342-.361-.446l-.024-.025-.002-.003a16.17 16.17 0 00-2.931-2.526l1.649-1.747a.75.75 0 10-1.09-1.03l-1.882 1.993c-1.386-.718-2.896-1.18-4.41-1.157-3.334-.05-6.643 2.244-8.663 4.467l-.002.003c-.214.237-.608.721-.608 1.335s.394 1.099.608 1.336l.002.002c1.09 1.202 2.587 2.457 4.28 3.33l-.84.89zm3.452-1.472a6.55 6.55 0 001.783.223c2.75.046 5.674-1.922 7.541-3.977l.001-.001.001-.001a2.892 2.892 0 00.269-.329 2.892 2.892 0 00-.268-.328h-.002A14.538 14.538 0 0016.96 9.12l-6.458 6.84zm5.104-7.592c-1.089-.51-2.224-.816-3.32-.798h-.026C9.558 7.526 6.626 9.45 4.72 11.546a1.4 1.4 0 00-.184.246.346.346 0 00-.036.084c.002.01.01.037.036.085a1.4 1.4 0 00.184.246c1.123 1.236 2.627 2.44 4.247 3.194l1.448-1.534a.752.752 0 01-.166-.125 2.766 2.766 0 011.956-4.721l.485 1.596a1.266 1.266 0 00-1.38 2.065c.04.04.074.082.103.128l1.755-1.859a.75.75 0 01-.067-.06 1.265 1.265 0 00-.411-.274l-.485-1.596a2.766 2.766 0 011.988.844l1.412-1.496z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgHide = React.memo(SvgHide);
export default MemoSvgHide;
