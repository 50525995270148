import * as React from 'react';

const SvgMoving = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.642 6.676v-.44l-.384-.215-8.583-4.798a1.745 1.745 0 00-1.705-.001l-8.586 4.8L2 6.235v10.309c0 .614.322 1.177.843 1.493l8.59 5.208.388.236.389-.236 8.59-5.208a1.75 1.75 0 00.842-1.496V6.676zm-9.699-4.144l7.412 4.144-2.489 1.392-7.558-4.199 2.394-1.338a.245.245 0 01.241.002zM7.77 4.73l7.557 4.198-3.505 1.96-.553-.31L8.98 9.3 4.287 6.676 7.769 4.73zm8.48 5.4l-3.678 2.057v9.086l7.45-4.517a.25.25 0 00.121-.214V7.955L17.75 9.292V13a.75.75 0 01-1.5 0v-2.87zm-5.178 2.057v9.086l-7.45-4.517a.245.245 0 01-.121-.211v-8.59l4.748 2.654 2.288 1.279.535.3z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMoving = React.memo(SvgMoving);
export default MemoSvgMoving;
