import * as React from 'react';

const SvgTow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 6a.75.75 0 01.75.75v10.5a.75.75 0 01-1.5 0v-4.5h-3.316c-.377 2.264-2.324 4-4.684 4-2.633 0-4.75-2.16-4.75-4.803 0-1.372.57-2.613 1.486-3.489a.75.75 0 011.037 1.084A3.316 3.316 0 006.5 11.947c0 1.834 1.464 3.303 3.25 3.303S13 13.78 13 11.947h.002a.75.75 0 01.748-.697h4v-4.5A.75.75 0 0118.5 6z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgTow = React.memo(SvgTow);
export default MemoSvgTow;
