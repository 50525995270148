import * as React from 'react';

const SvgCart = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4214 3C21.4214 2.58579 21.0856 2.25 20.6714 2.25H19.4798C18.6546 2.25 17.9415 2.82645 17.7686 3.63332L17.4222 5.25H4.00059C2.83608 5.25 1.99639 6.36614 2.31915 7.48503L4.04992 13.485C4.26602 14.2342 4.95167 14.75 5.73136 14.75H15.3865L15.1074 16.0524C15.0827 16.1677 14.9808 16.25 14.8629 16.25H6.67136C5.15257 16.25 3.92136 17.4812 3.92136 19C3.92136 20.5188 5.15257 21.75 6.67136 21.75C8.19014 21.75 9.42136 20.5188 9.42136 19C9.42136 18.5499 9.31322 18.125 9.1215 17.75H13.2212C13.0295 18.125 12.9214 18.5499 12.9214 19C12.9214 20.5188 14.1526 21.75 15.6714 21.75C17.1901 21.75 18.4214 20.5188 18.4214 19C18.4214 17.7947 17.646 16.7706 16.5668 16.3991C16.5693 16.3883 16.5718 16.3775 16.5741 16.3667L19.2353 3.94762C19.26 3.83235 19.3619 3.75 19.4798 3.75H20.6714C21.0856 3.75 21.4214 3.41421 21.4214 3ZM15.7079 13.25L17.1008 6.75H4.00059C3.83423 6.75 3.71428 6.90945 3.76039 7.06929L5.49116 13.0693C5.52203 13.1763 5.61998 13.25 5.73136 13.25H15.7079ZM5.42136 19C5.42136 18.3096 5.981 17.75 6.67136 17.75C7.36171 17.75 7.92136 18.3096 7.92136 19C7.92136 19.6904 7.36171 20.25 6.67136 20.25C5.981 20.25 5.42136 19.6904 5.42136 19ZM16.9214 19C16.9214 18.3096 16.3617 17.75 15.6714 17.75C14.981 17.75 14.4214 18.3096 14.4214 19C14.4214 19.6904 14.981 20.25 15.6714 20.25C16.3617 20.25 16.9214 19.6904 16.9214 19Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCart = React.memo(SvgCart);
export default MemoSvgCart;
