import * as React from 'react';

const SvgPhonerecall = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 8.295c-2.398-2.757-5.333-5.063-9.027-5.275a.75.75 0 00-.277.036c-.756.249-1.37.539-1.89.99-.52.453-.892 1.02-1.243 1.736a.75.75 0 00-.012.635A7.747 7.747 0 007.9 10.416c.3.123.643.04.854-.205.28-.327.416-.474.557-.597.1-.087.212-.17.399-.293.237.092.578.288 1.005.621.556.434 1.161 1.023 1.722 1.668.561.646 1.06 1.328 1.413 1.939.27.469.416.834.475 1.082a3.708 3.708 0 01-.345.353c-.142.123-.307.237-.669.468a.75.75 0 00-.322.818 7.757 7.757 0 003.389 4.616.75.75 0 00.632.076c.756-.248 1.37-.539 1.89-.99.52-.452.892-1.02 1.244-1.734a.752.752 0 00.075-.27c.304-3.687-1.572-6.915-3.969-9.673z"
        fill="currentColor"
      />
      <rect
        x={20.557}
        y={2.5}
        width={1.5}
        height={22.756}
        rx={0.75}
        transform="rotate(45 20.557 2.5)"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPhonerecall = React.memo(SvgPhonerecall);
export default MemoSvgPhonerecall;
