declare global {
  interface Window {
    isPostPageEvent?: boolean;
  }
}

export const PAGE_EVENT_TRIGGER = 'postPageEventTrigger';

export const triggerPostPageEvent = () => {
  window.dispatchEvent(new Event(PAGE_EVENT_TRIGGER));
  window.isPostPageEvent = true;
};

export const wasPageEventTriggered = () => Boolean(window.isPostPageEvent);
