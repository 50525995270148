import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { isRelativeLink, LinkBehaviorProps } from '../helpers';

type AnchorAttributes = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'ref'
>;

type Props = AnchorAttributes &
  LinkProps & {
    isNextLink?: LinkBehaviorProps['isNextLink'];
  };

export const Link = ({ href, children, isNextLink, ...props }: Props) => {
  const useNextLink = isRelativeLink(href) && isNextLink && isNextLink(href);

  return useNextLink ? (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  ) : (
    <a href={href} {...props}>
      {children}
    </a>
  );
};
