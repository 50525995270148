import * as React from 'react';

const SvgWarning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.135 3.495a1 1 0 011.73 0l9.266 16.004A1 1 0 0121.266 21H2.734a1 1 0 01-.865-1.501l9.266-16.004z"
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
      />
      <path
        d="M11.95 16.95a.75.75 0 110 1.5.75.75 0 010-1.5zM12 8.2a.75.75 0 01.743.648l.007.102v6a.75.75 0 01-1.493.102l-.007-.102v-6A.75.75 0 0112 8.2z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgWarning = React.memo(SvgWarning);
export default MemoSvgWarning;
