/* eslint-disable id-length */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import qs from 'qs';
import env from '@y2/environments';

import {
  AutocompleteResults,
  CartCreateRequestBody,
  CartCreateResponse,
  Filters,
  GetProductResponse,
  GetProductsByShopifyIdsResponse,
  GetSellerProductsParams,
  GetSellerProductsResponse,
  MenuItem,
  ProductResponse,
  RecommerceProduct,
  SearchQuery,
  SearchResultResponse,
} from './recommerce-feed.types';
import { getWithSSRSupport } from '../helpers';
import axios, { ResponseBody } from '../http-client';
export const DEFAULT_FEED_ITEMS_PER_PAGE = 12;
export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_ITEMS = 0;
export const DEFAULT_TOTAL_PAGES = 1;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_MIN = 0;
export const DEFAULT_MAX = 10000;

type Query = {
  [key: string]: string | number | undefined;
};

const serviceName = 'recommerce-feed';

/**
 * client for `recommerce-feed` service
 * @see https://gw.yad2.io/recommerce-feed/api-docs/#
 */
// eslint-disable-next-line max-lines-per-function
export const recommerceFeedClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
    paramsSerializer: (params) => qs.stringify(params),
  });

  return {
    search: (query: SearchQuery, scrollSessionId: string) =>
      client
        .get<ResponseBody<SearchResultResponse>>('/search', {
          params: {
            ...query,
            scrollSessionId,
          },
        })
        .then((res) => res.data.data),
    searchByCollection: (
      query: Query,
      collection: string,
      scrollSessionId: string,
    ) =>
      client
        .get<ResponseBody<SearchResultResponse>>(
          `/search/${encodeURIComponent(collection)}`,
          {
            params: {
              ...query,
              scrollSessionId,
            },
          },
        )
        .then((res) => res.data.data),
    getFilters: (params: SearchQuery) =>
      client
        .get<ResponseBody<Filters>>('/filters', {
          params: params,
        })
        .then((res) => res.data.data),
    filterByCollection: (query: Query, collection: string) =>
      client
        .get<ResponseBody<Filters>>(
          `/filters/${encodeURIComponent(collection)}`,
          {
            params: query,
          },
        )
        .then((res) => res.data.data),
    getProduct: (params: {
      shopifyId?: string;
      urlIdentifier?: string;
    }): Promise<ProductResponse> =>
      client
        .get<GetProductResponse>('/product', {
          params,
        })
        .then((res) => res.data.data.data),
    getAutocomplete: (query: string) =>
      client
        .get<
          ResponseBody<AutocompleteResults>
        >(`/search/autocomplete`, { params: { searchTerm: query } })
        .then((res) => res.data.data),
    getSellerProducts: (
      params: GetSellerProductsParams,
    ): Promise<RecommerceProduct[]> =>
      client
        .get<GetSellerProductsResponse>(`/product/seller/${params.custId}`, {
          params: {
            itemsCount: params.itemsCount,
            excludeProducts: params.excludeProducts,
          },
        })
        .then((res) => res.data.data),
    getMenuItems: (): Promise<MenuItem[]> =>
      client.get<MenuItem[]>('/menu-items').then((res) => res.data),
    getProductsByShopifyIds: (
      shopifyProductIds: number[],
    ): Promise<RecommerceProduct[]> =>
      client
        .post<GetProductsByShopifyIdsResponse>(`/products`, {
          shopifyProductIds,
        })
        .then((res) => res.data.data),
    createCart: (
      requestBody: CartCreateRequestBody,
    ): Promise<CartCreateResponse> =>
      client
        .post<{ data: CartCreateResponse }>('/product/cart', requestBody)
        .then((res) => res.data.data),
  };
};
