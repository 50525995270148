import * as React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.3 8.05a.458.458 0 01.65.65l-3.802 3.8 3.801 3.8a.458.458 0 01.059.577l-.059.072a.458.458 0 01-.648 0L12.5 13.148l-3.8 3.801a.458.458 0 01-.577.059l-.072-.059a.458.458 0 010-.648l3.8-3.801-3.8-3.8a.458.458 0 01-.059-.577l.059-.072a.458.458 0 01.648 0l3.8 3.8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;
