/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';
const SvgAgent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#agent_svg__clip0_2030_569)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.692 11.325a18.717 18.717 0 006.543 1.187h9.502c2.224 0 4.426-.4 6.544-1.187l.157-.052a.85.85 0 00.493-.447.778.778 0 00.012-.613l-.006-.014a.839.839 0 00-.77-.525c-.1 0-.199.018-.295.055l-.004.001-.153.051c-1.07.385-1.975.638-2.846.798l-.166.03-1.376-5.031A4.195 4.195 0 0014.278 2.5H9.719c-1.867 0-3.532 1.267-4.05 3.078L4.295 10.61l-.166-.03c-1.013-.192-1.97-.46-2.846-.799l-.154-.051-.005-.002a.757.757 0 00-.282-.054.858.858 0 00-.787.539.776.776 0 00.022.62c.092.2.26.36.461.44l.155.052zm6.572-5.333a2.555 2.555 0 012.455-1.868h4.61c1.142 0 2.152.768 2.456 1.868l1.313 4.803-.23.016c-.353.025-.718.051-1.08.051H7.26c-.368 0-.735-.026-1.089-.051l-.222-.016 1.314-4.803zm5.913 12.044a3.317 3.317 0 003.227 2.61c1.82 0 3.3-1.474 3.3-3.288a3.2 3.2 0 00-.935-2.31 3.268 3.268 0 00-2.338-.973c-1.331 0-2.532.807-3.059 2.056l-.059.14-.15-.033a5.274 5.274 0 00-1.15-.13c-.382 0-.769.044-1.15.13l-.153.034-.058-.144a3.273 3.273 0 00-3.057-2.053c-1.82 0-3.3 1.473-3.3 3.285a3.296 3.296 0 003.3 3.285 3.308 3.308 0 003.227-2.609l.021-.105.103-.031a3.619 3.619 0 011.063-.161c.356 0 .708.054 1.045.161l.1.033.023.103zm1.61-.65a1.64 1.64 0 011.643-1.636 1.64 1.64 0 011.642 1.635 1.64 1.64 0 01-1.642 1.636 1.64 1.64 0 01-1.643-1.636zm-8.81 0A1.64 1.64 0 017.62 15.75a1.64 1.64 0 011.642 1.635 1.64 1.64 0 01-1.642 1.636 1.64 1.64 0 01-1.643-1.636z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="agent_svg__clip0_2030_569">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgAgent = React.memo(SvgAgent);
export default MemoSvgAgent;
