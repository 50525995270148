import * as React from 'react';

const SvgMagazine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.167 5h-7.5c-.641 0-1.224.238-1.667.626A2.523 2.523 0 0010.333 5h-7.5A.823.823 0 002 5.813v12.375c0 .448.373.812.833.812h18.334c.46 0 .833-.364.833-.813V5.813A.823.823 0 0021.167 5zm-17.5 1.625h6.666c.46 0 .834.364.834.813v9.937h-7.5V6.625zm16.666 10.75h-7.5V7.437c0-.448.374-.812.834-.812h6.666v10.75zM18 8h-3a1 1 0 100 2h3a1 1 0 100-2zm-3 3h3a1 1 0 110 2h-3a1 1 0 110-2zm3 3h-3a1 1 0 100 2h3a1 1 0 100-2zM6 8h3a1 1 0 010 2H6a1 1 0 010-2zm3 3H6a1 1 0 100 2h3a1 1 0 100-2zm-3 3h3a1 1 0 110 2H6a1 1 0 110-2z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMagazine = React.memo(SvgMagazine);
export default MemoSvgMagazine;
