import * as React from 'react';

const SvgAlarm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.25 19.56a.75.75 0 100 1.5v-1.5zm17.5 1.5a.75.75 0 100-1.5v1.5zM9.071 4.972a.75.75 0 101.3-.75l-1.3.75zm-.2-3.348a.75.75 0 00-1.3.75l1.3-.75zm-3.917 6.25a.75.75 0 10.964-1.15l-.964 1.15zM3.62 4.797a.75.75 0 00-.964 1.15l.964-1.15zm14.215 1.929a.75.75 0 00.964 1.149l-.964-1.15zm3.263-.78a.75.75 0 10-.965-1.149l.965 1.15zM13.57 4.223a.75.75 0 101.3.75l-1.3-.75zm2.8-1.848a.75.75 0 10-1.3-.75l1.3.75zM6.5 21.05h11v-1.5h-11v1.5zm12.75-1.75v-5.5h-1.5v5.5h1.5zm-14.5-5.5v5.5h1.5v-5.5h-1.5zM12 6.55a7.25 7.25 0 00-7.25 7.25h1.5A5.75 5.75 0 0112 8.05v-1.5zm7.25 7.25A7.25 7.25 0 0012 6.55v1.5a5.75 5.75 0 015.75 5.75h1.5zm-1.75 7.25a1.75 1.75 0 001.75-1.75h-1.5a.25.25 0 01-.25.25v1.5zm-11-1.5a.25.25 0 01-.25-.25h-1.5c0 .966.783 1.75 1.75 1.75v-1.5zm-3.25 1.51h17.5v-1.5H3.25v1.5zm7.12-16.837l-1.5-2.598-1.3.75 1.5 2.598 1.3-.75zM5.917 6.726L3.62 4.797l-.964 1.15 2.298 1.928.964-1.15zm12.881 1.149l2.299-1.929-.965-1.149-2.298 1.929.964 1.149zM14.87 4.973l1.5-2.598-1.299-.75-1.5 2.598 1.3.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAlarm = React.memo(SvgAlarm);
export default MemoSvgAlarm;
