import * as React from 'react';

const SvgCheckcircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25 2.25c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm4.727 5.24a.75.75 0 01.114.973l-.07.087-4.72 5.131a1.75 1.75 0 01-2.564.012l-.104-.121-1.775-2.278a.75.75 0 011.11-1.005l.074.083 1.774 2.278a.25.25 0 00.351.044l.03-.028 4.72-5.131a.75.75 0 011.06-.045z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCheckcircle = React.memo(SvgCheckcircle);
export default MemoSvgCheckcircle;
