import React from 'react';
import styles from './footer.module.scss';
import { Category } from '../categories-list/types';
import { BOTTOM_LINKS_LIST } from '../bottom-links/bottom-links.consts';
import { SocialButtons, BottomLinks, Link, CategoriesList } from '../index';
import cn from 'classnames';
import texts from './footer.texts.json';

interface Props {
  env: string;
  categoriesList: Category[];
  aboutText?: string;
  termText?: string;
  bottomLinks?: Link[];
  className?: string;
}
export const Footer: React.VFC<Props> = ({
  env,
  categoriesList,
  aboutText = texts.aboutYad2,
  termText = texts.termSite,
  bottomLinks = BOTTOM_LINKS_LIST,
  className,
}) => {
  return (
    <footer className={cn(styles.footer, className)}>
      <div className={styles.container}>
        <p className={styles.aboutText}>{aboutText}</p>
        <CategoriesList categoriesList={categoriesList} />
        <SocialButtons />
        <p className={styles.terms}>{termText}</p>
        <hr className={styles.divider} />
        <BottomLinks linkList={bottomLinks} />
        <span className={styles.environments}>
          {texts.environment}:{env}
        </span>
      </div>
    </footer>
  );
};
