/* eslint-disable custom-rules/enforce-catch-err-handling */
import { isbot } from 'isbot';

export const getDeviceId = () => {
  const deviceId = sessionStorage.getItem('brazeDeviceID');

  return deviceId || undefined;
};

declare global {
  interface Window {
    chrome?: unknown;
  }
}

export const isHeadlessBrowser = (userAgent: string) => {
  const isHeadlessUserAgent = userAgent.toLowerCase().includes('headless');
  if (isHeadlessUserAgent) {
    return true;
  }
  const signs = [
    'callPhantom' in window,
    '_phantom' in window,
    'chrome' in window && !window.chrome,
    'domAutomation' in window || 'domAutomationController' in window,
  ];

  return signs.some((sign) => sign);
};

export const isCookieEnabled = () => {
  const key = 'isbotTestFlag';
  document.cookie = `${key}=1; max-age=5; path=/`;
  const cookies = document.cookie
    .split('; ')
    .map((cookie) => cookie.split('=')[0]);

  return cookies.includes(key);
};

export const isLocalStorageEnabled = () => {
  const key = 'isbotTestFlag';
  try {
    localStorage.setItem(key, '1');
    const testValue = localStorage.getItem(key);
    localStorage.removeItem(key);

    return testValue === '1';
  } catch (err) {
    return false;
  }
};

export const isPossibleBot = () => {
  const { userAgent } = navigator;

  return (
    isbot(userAgent) ||
    isHeadlessBrowser(userAgent) ||
    !isCookieEnabled() ||
    !isLocalStorageEnabled()
  );
};
