import { categories } from '@y2/categories';
import { replaceSpacesWithUnderscoresWithDefault } from '@y2/analytics-common-events/analytics.utils';
import texts from './categories-list.texts.json';
import type { Category } from './types';
const { vehicles, realEstate, products, pets } = categories;

const drushimUrl = 'https://www.drushim.co.il';

export const CATEGORIES_LIST: Category[] = [
  {
    title: realEstate.titleHeb,
    subCategories: [
      {
        url: `/${realEstate.title}/${realEstate.subCategories.forSale.title}`,
        title: realEstate.subCategories.forSale.titleHeb,
      },
      {
        url: `/${realEstate.title}/${realEstate.subCategories.rent.title}`,
        title: realEstate.subCategories.rent.titleHeb,
      },
      {
        url: `/${realEstate.title}/${realEstate.subCategories.commercial.title}`,
        title: realEstate.subCategories.commercial.titleHeb,
      },
      {
        url: `/${realEstate.subCategories.kones.title}/${realEstate.title}`,
        title: realEstate.subCategories.kones.titleHeb,
      },
      {
        url: `/${realEstate.title}/${realEstate.subCategories.yad1Listings.title}`,
        title: realEstate.subCategories.yad1Listings.titleHeb,
      },
      {
        url: `/yzer`,
        title: texts.yzer,
      },
      {
        url: `https://yadata.yad2.co.il/?yad2_source=Footer`,
        title: texts.yadata,
        isTargetBlank: true,
      },
    ],
  },
  {
    title: vehicles.titleHeb,
    subCategories: [
      {
        url: `/${vehicles.title}/${vehicles.subCategories.cars.title}`,
        title: texts.allCars,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.trucks.title}`,
        title: vehicles.subCategories.trucks.titleHeb,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.motorcycles.title}`,
        title: vehicles.subCategories.motorcycles.titleHeb,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.scooters.title}`,
        title: vehicles.subCategories.scooters.titleHeb,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.watercraft.title}`,
        title: vehicles.subCategories.watercraft.titleHeb,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.other.title}`,
        title: vehicles.subCategories.other.titleHeb,
      },
      {
        url: `/${vehicles.title}/${vehicles.subCategories.carAccessories.title}`,
        title: vehicles.subCategories.carAccessories.titleHeb,
      },
      {
        url: `https://konesy2.co.il/cars`,
        title: texts.konesVehicles,
        isTargetBlank: true,
        analyticsProperties: {
          EventName: 'navigation',
          EventDetail: 'footer',
          EventText: replaceSpacesWithUnderscoresWithDefault(
            texts.konesVehicles,
          ),
          EventGroup: 'general_interactions',
        },
      },
      {
        url: `/price-list`,
        title: texts.priceList,
      },
      {
        url: 'https://land.5555.co.il/yad2?utm_source=YAD2&utm_medium=Native&utm_campaign=Direct-Mimun-Yashir_AWO-LP_Leads_BOF_footer',
        title: texts.carFinance,
        isTargetBlank: true,
        analyticsProperties: {
          EventName: 'navigation',
          EventDetail: 'footer',
          EventText: 'מימון_רכב',
          EventGroup: 'general_interactions',
        },
      },
    ],
  },
  {
    title: products.titleHeb,
    subCategories: [
      {
        url: `/lobby/${products.title}`,
        title: texts.allProducts,
      },
      {
        url: `/${products.title}/${products.subCategories.furniture.title}?category=2`,
        title: products.subCategories.furniture.titleHeb,
      },
      {
        url: `/${products.title}/${products.subCategories.cellular.title}?category=5`,
        title: products.subCategories.cellular.titleHeb,
      },
      {
        url: `/${products.title}/${products.subCategories.electricalAppliances.title}?category=1`,
        title: products.subCategories.electricalAppliances.titleHeb,
      },
      {
        url: `/${products.title}/${products.subCategories.bicycles.title}?category=14`,
        title: products.subCategories.bicycles.titleHeb,
      },
    ],
  },
  {
    title: texts.drushim,
    subCategories: [
      {
        url: `${drushimUrl}/categories.aspx`,
        title: texts.allCategories,
      },
      {
        url: `${drushimUrl}/companies.aspx`,
        title: texts.bestCompanies,
      },
      {
        url: `${drushimUrl}/דרושים-הייטק`,
        title: texts.drushimHitec,
      },
      {
        url: `${drushimUrl}/קורות-חיים`,
        title: texts.resume,
      },
      {
        url: `${drushimUrl}/articles`,
        title: texts.careerMagazine,
      },
      {
        url: `${drushimUrl}/smartalerts.aspx`,
        title: texts.smartAgent,
      },
    ],
  },
  {
    title: texts.more,
    subCategories: [
      {
        url: `/${pets.title}/all`,
        title: pets.titleHeb,
      },
      {
        url: `/b144`,
        title: texts.professional,
      },
      {
        url: `https://magazine.yad2.co.il`,
        title: texts.yad2Magazine,
      },
    ],
  },
];
