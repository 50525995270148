import styles from './sub-category-item.module.scss';
import React from 'react';
import { SubCategory } from '../types';
import { getLinkWithHost } from '../../helpers';
import { pushGeneralAnalyticsEvent } from '../../../services/analytics';

const SubCategoryItem: React.VFC<SubCategory> = ({
  url,
  isTargetBlank,
  title,
  analyticsProperties,
}) => {
  const handleClickLink = () => {
    analyticsProperties && pushGeneralAnalyticsEvent(analyticsProperties);
  };

  return (
    <li
      className={styles.subCategoryItem}
      data-nagish="footer-sub-category-item"
    >
      <a
        href={getLinkWithHost(url)}
        target={isTargetBlank ? '_blank' : '_self'}
        rel="noreferrer"
        onClick={handleClickLink}
        data-nagish="footer-sub-category-item-link"
      >
        {title}
      </a>
    </li>
  );
};

export default SubCategoryItem;
