import * as React from 'react';

const SvgJeep = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 9a1.36 1.36 0 00-.36.05v-.6a3 3 0 00-3-3h-6.52A3 3 0 009 6a3.07 3.07 0 00-1.16 1.32l-1 2.54a1 1 0 01-.72.6L3.45 11a2 2 0 00-1.14.69A2.07 2.07 0 001.87 13v2.48a2 2 0 002 2h.43a2.73 2.73 0 005.4 0h3.6a2.73 2.73 0 005.41-.09 2.001 2.001 0 001.43-1.91V15c.117.033.238.05.36.05A1.5 1.5 0 0022 13.5v-3A1.5 1.5 0 0020.5 9zM7.93 17.81c-.105.11-.227.205-.36.28l-.13.07a1.12 1.12 0 01-.88 0l-.13-.07a.861.861 0 01-.22-.15.865.865 0 01-.14-.13 1.275 1.275 0 01-.13-.19 1.19 1.19 0 01.68-1.79.94.94 0 01.76 0 1.19 1.19 0 01.68 1.79c-.038.067-.081.13-.13.19zm8.64.28c.133-.075.255-.17.36-.28.049-.06.092-.123.13-.19a1.19 1.19 0 00-.68-1.79.94.94 0 00-.76 0 1.19 1.19 0 00-.68 1.79c.037.067.08.13.13.19.105.11.227.205.36.28l.13.07c.281.12.599.12.88 0l.13-.07zm2.07-2.64a.51.51 0 01-.15.35 2.74 2.74 0 00-5 .13H9.54a2.75 2.75 0 00-5.08 0h-.59a.49.49 0 01-.36-.15.5.5 0 01-.14-.35V13a.49.49 0 01.39-.48l2.64-.56a2.52 2.52 0 001.81-1.54l1-2.53a1.54 1.54 0 01.55-.68 1.49 1.49 0 01.86-.21h6.52a1.47 1.47 0 011.06.44 1.5 1.5 0 01.44 1.06v6.95zm-8.98-3.79a1.1 1.1 0 00.79.18h6.05a1 1 0 00.71-.29 1 1 0 00.29-.73l-.08-2.43-.12-.26a1.17 1.17 0 00-.8-.24h-5.31c-.202.001-.4.06-.57.17a1.07 1.07 0 00-.37.48l-.83 2.39v.31c.052.154.133.297.24.42zm1.88-2.27H16v.95h-4.79l.33-.95z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgJeep = React.memo(SvgJeep);
export default MemoSvgJeep;
