import * as React from 'react';
const SvgLightBlueTriangle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.671 2.127c.703-1.587 2.955-1.587 3.658 0L26.544 27.44a2 2 0 01-1.828 2.81H2.284a2 2 0 01-1.828-2.81L11.672 2.127z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgLightBlueTriangle = React.memo(SvgLightBlueTriangle);
export default MemoSvgLightBlueTriangle;
