import * as React from 'react';
const SvgCustomerSupportPortal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 9.8a7.95 7.95 0 017.95-7.95h.6a7.95 7.95 0 017.95 7.95v.701a.8.8 0 01.75.799v3.9a.8.8 0 01-.75.799v.201a5.55 5.55 0 01-5.55 5.55h-.638a.799.799 0 01-.662.35h-1.6a.8.8 0 01-.8-.8v-.9a.8.8 0 01.8-.8h1.6a.8.8 0 01.786.65h.514a4.05 4.05 0 004.05-4.05V9.8a6.45 6.45 0 00-6.45-6.45h-.6A6.45 6.45 0 005.25 9.8V16h-1.5v-.002A.8.8 0 013 15.2v-3.9a.8.8 0 01.75-.799V9.8zm13.05 0a.8.8 0 00-.8.8v5.6a.8.8 0 00.8.8h.65a.8.8 0 00.8-.8v-5.6a.8.8 0 00-.8-.8h-.65zm-11.05.8a.8.8 0 01.8-.8h.65a.8.8 0 01.8.8v5.6a.8.8 0 01-.8.8h-.65a.8.8 0 01-.8-.8v-5.6z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgCustomerSupportPortal = React.memo(SvgCustomerSupportPortal);
export default MemoSvgCustomerSupportPortal;
