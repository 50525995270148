import * as React from 'react';

const SvgWalkingman = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.063 5.83c-.99 0-1.799-.81-1.799-1.8 0-.989.81-1.798 1.799-1.798.989 0 1.798.81 1.798 1.799 0 .989-.81 1.798-1.798 1.798zm-4.368 6.136c-.54-.178-.81-.483-.81-.915 0-.433.27-.712.81-.839 1.609 0 3-.876 3.783-2.147l.826-1.403c.304-.526.87-.833 1.478-.833.218 0 .435.044.652.132L16.2 7.102C17.29 7.552 18 8.615 18 9.794v2.172c-.177.428-.467.642-.87.642-.402 0-.692-.214-.87-.642V9.029L14.74 8.37l2.392 12.36c-.19.376-.48.564-.87.564s-.709-.188-.957-.563l-1.522-7.013-.03.027a5.824 5.824 0 00-1.84 4.249v2.737c-.24.375-.539.563-.896.563s-.638-.188-.843-.563v-4.22c0-1.537.607-3.01 1.69-4.102l.093-.094-.522-2.63a6.05 6.05 0 01-4.74 2.28z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgWalkingman = React.memo(SvgWalkingman);
export default MemoSvgWalkingman;
