import * as React from 'react';

const SvgMagazinsidemenuCopy21 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.398 4.176a.75.75 0 01.595.63l.007.106v.072h1.25a.75.75 0 01.743.649l.007.101v12.75a.75.75 0 01-.942.725c-2.57-.681-5.506-1.253-8.806-1.715-3.303.462-6.239 1.034-8.81 1.715a.75.75 0 01-.942-.725V5.734l.007-.101a.75.75 0 01.743-.649H4.5v-.072l.007-.106a.75.75 0 01.595-.63c1.05-.21 2.175-.232 3.373-.066l.401.062.555.103c1.098.216 2.127.507 3.086.873l-.267-.097.218-.08a19.337 19.337 0 012.601-.696l.555-.103.401-.062c1.198-.166 2.323-.145 3.373.066zm1.101 2.308L20 6.483v9.429a.75.75 0 01-.755.75l-.103-.008c-.836-.122-1.863-.146-3.08-.072 1.456.26 2.832.548 4.126.86l.311.077V6.484zM4.5 6.483h-.499V17.52l.31-.077c1.296-.312 2.67-.6 4.128-.86-1.218-.074-2.245-.05-3.08.072l-.104.008a.75.75 0 01-.755-.75V6.483zm4.124-.832c-.913-.156-1.765-.19-2.56-.106l-.063.008v9.526l.073-.006.334-.018c1.25-.053 2.743.035 4.483.264l.61.084V6.375l-.42-.138-.469-.14a19.218 19.218 0 00-1.988-.446zm7.253 0c-.694.119-1.357.267-1.989.446l-.468.14-.42.137v9.028l.61-.083c1.74-.229 3.232-.317 4.482-.264l.334.018.073.006V5.553l-.063-.008c-.794-.085-1.647-.05-2.56.106z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMagazinsidemenuCopy21 = React.memo(SvgMagazinsidemenuCopy21);
export default MemoSvgMagazinsidemenuCopy21;
