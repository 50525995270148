import * as React from 'react';

const SvgCopy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.92 1.25h-11A2.75 2.75 0 006.17 4v11a2.75 2.75 0 002.75 2.75h11A2.75 2.75 0 0022.67 15V4a2.75 2.75 0 00-2.75-2.75zm-11 1.5h11c.69 0 1.25.56 1.25 1.25v11c0 .69-.56 1.25-1.25 1.25h-11c-.69 0-1.25-.56-1.25-1.25V4c0-.69.56-1.25 1.25-1.25zM3.743 8.915a.75.75 0 00-1.493.102v9l.005.199A3.75 3.75 0 006 21.766h9l.102-.006A.75.75 0 0015 20.267H6l-.154-.006a2.25 2.25 0 01-2.096-2.244v-9l-.007-.102z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCopy = React.memo(SvgCopy);
export default MemoSvgCopy;
