import { useEffect, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';

export const getLazyLoadedLottie = async (): Promise<LottiePlayer> => {
  const lottieModule = await import('lottie-web');

  return lottieModule.default;
};

export const useLazyLottieModule = () => {
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    const loadLottieModule = async () => {
      const lottiePlayer = await getLazyLoadedLottie();
      setLottie(lottiePlayer);
    };

    loadLottieModule();
  }, []);

  return lottie;
};
