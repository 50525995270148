import cn from 'classnames';
import { Icon } from '@y2/deprecated/ui/icons/types';
import { ChevronLeftIcon } from '@y2/mango/components/icons';
import {
  sentAnalyticsNavEvent,
  getLinkWithHost,
  LinkBehaviorProps,
} from '../helpers';
import styles from './menu-category-item.module.scss';
import { Link } from '../link/link';
import noop from 'lodash/noop';

type Props = {
  url: string;
  text: string;
  icon?: Icon;
  isNewTab?: boolean;
  disableAnalyticsEvents?: boolean;
  hasLinkBehaviourInMobile?: boolean;
} & LinkBehaviorProps;

export const CategoryLink = ({
  url,
  text,
  icon: Icon,
  isNewTab,
  hasLinkBehaviourInMobile = false,
  disableAnalyticsEvents = false,
  isInSubDomain = false,
  isNextLink,
  onClick = noop,
}: Props) => {
  const handleClick = () => {
    if (!disableAnalyticsEvents) {
      sentAnalyticsNavEvent(text);
    }
    onClick(text);
  };

  return (
    <Link
      className={cn(
        styles.navButton,
        hasLinkBehaviourInMobile && styles.hasLinkBehaviourInMobile,
      )}
      href={isInSubDomain ? getLinkWithHost(url) : url}
      onClick={handleClick}
      {...(isNewTab ? { target: '_blank' } : {})}
      isNextLink={isNextLink}
      data-nagish="header-menu-category-item-link"
    >
      {Icon && (
        <span className={cn(styles.mobileOnly, styles.iconWrapper)}>
          <Icon className="icon" />
        </span>
      )}
      <span className={styles.text}>
        {text}
        {!hasLinkBehaviourInMobile && (
          <ChevronLeftIcon className={styles.arrowIcon} />
        )}
      </span>
    </Link>
  );
};
