import * as React from 'react';

const SvgMap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.682 3.336l4.985 2.492 4.998-2.499A.75.75 0 0120.75 4v13.333a.75.75 0 01-.414.671l-5.334 2.667a.749.749 0 01-.671 0l-4.998-2.5-4.998 2.5A.75.75 0 013.25 20V6.667a.75.75 0 01.415-.671l5.32-2.66a.75.75 0 01.697 0zM8.583 5.214V16.87L4.75 18.786V7.13l3.833-1.916zm1.5 11.656l3.833 1.916V7.13l-3.833-1.916V16.87zm5.334-9.74v11.657l3.833-1.917V5.214L15.417 7.13z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMap = React.memo(SvgMap);
export default MemoSvgMap;
