import * as React from 'react';
const SvgResizeScreen = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M25.904 13.439a.84.84 0 00-.498.244l-3.352 3.352a.837.837 0 101.188 1.178l1.92-1.92v6.361c-.006.444.395.856.838.856.442 0 .844-.412.838-.855v-6.361l1.92 1.92a.839.839 0 101.187-1.178l-3.351-3.352a.84.84 0 00-.69-.245zm-8.379 8.37a.832.832 0 00-.496.253l-3.352 3.351a.837.837 0 000 1.178l3.352 3.353a.837.837 0 101.187-1.18l-1.921-1.919h6.354c.443.008.848-.395.848-.838 0-.441-.404-.844-.848-.838h-6.354l1.921-1.928a.84.84 0 00.015-1.186.836.836 0 00-.706-.246zm16.758 0a.838.838 0 00-.498 1.432l1.919 1.928h-6.44c-.439.023-.818.443-.795.882.022.438.443.816.881.794h6.354l-1.919 1.919a.838.838 0 101.186 1.18l3.353-3.353a.839.839 0 000-1.178l-3.353-3.351a.83.83 0 00-.688-.253zm-8.291 6.693c-.442.005-.839.413-.83.855v6.353l-1.92-1.919a.838.838 0 00-.689-.254.84.84 0 00-.498 1.432l3.352 3.352a.84.84 0 001.186.001l.002-.001 3.351-3.352a.837.837 0 10-1.187-1.178l-1.92 1.919v-6.353c.009-.447-.401-.859-.847-.855z"
      />
    </svg>
  );
};
const MemoSvgResizeScreen = React.memo(SvgResizeScreen);
export default MemoSvgResizeScreen;
