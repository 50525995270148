import * as React from 'react';

const SvgFilterCancel = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.632 18.232a.75.75 0 001.153.954L19.952 3.22l.065-.079a.75.75 0 00-1.152-.954L16.904 4.25H5.329a1.75 1.75 0 00-1.75 1.754l.005.295c.104 3.328 1.7 5.696 4.702 7.023l-4.589 4.831-.065.079zm5.762-6.076l6.085-6.406H5.329a.25.25 0 00-.244.193L5.08 6c.014 3.051 1.416 5.068 4.315 6.156z"
        fill="currentColor"
      />
      <path
        d="M20.505 6.251c.035-.24.02-.475-.038-.695l-1.701 1.792c-.32 1.267-.81 2.276-1.466 3.031l-.21.231c-.932.995-1.974 1.59-3.14 1.807l-.828.87v5.862a.25.25 0 01-.097.198c-.45.344-.921.686-1.413 1.025l-.369.251-.338.224-.001-5.226-1.5 1.58v4.106a1.25 1.25 0 001.922 1.054 36.602 36.602 0 002.611-1.824l.124-.104c.356-.33.561-.793.562-1.283v-5.346l.24-.063c1.337-.385 2.53-1.183 3.569-2.378 1.076-1.239 1.762-2.947 2.073-5.112z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgFilterCancel = React.memo(SvgFilterCancel);
export default MemoSvgFilterCancel;
