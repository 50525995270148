import * as React from 'react';

const SvgScooter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.2998 8.75C12.8856 8.75 12.5498 9.08579 12.5498 9.5C12.5498 9.91421 12.8856 10.25 13.2998 10.25H20.0999C20.5141 10.25 20.8499 9.91421 20.8499 9.5C20.8499 9.08579 20.5141 8.75 20.0999 8.75H13.2998Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <ellipse
        cx="19.2387"
        cy="15.4001"
        rx="2.60002"
        ry="2.60002"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <path
        d="M7.5385 6.625H9.16351L8.31696 9.72902C8.2335 10.035 8.2499 10.3597 8.36376 10.6557L9.4495 13.4786C9.66915 14.0497 10.2157 14.4284 10.8275 14.4334L11.8866 14.4422C12.7141 14.449 13.3885 13.7801 13.3885 12.9526V11.6896C13.3885 10.8669 14.0555 10.2 14.8781 10.2H15.3386H17.2886H19.2386C19.2386 10.2 20.7521 10.6433 21.5136 11.5C21.7544 11.771 22.0054 12.1793 22.2361 12.611C22.7294 13.5343 22.0115 14.5876 20.9647 14.5876H18.1282C17.3055 14.5876 16.6386 15.2545 16.6386 16.0772V16.5H7.5385C7.5385 16.5 7.07117 14.6635 6.56349 13.7751C6.30482 13.3224 5.88788 12.7404 5.5411 12.2784C5.18264 11.8008 5.12087 11.1581 5.40188 10.6312L7.5385 6.625Z"
        // eslint-disable-next-line custom-rules/use-current-color
        fill="white"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <circle
        cx="4.39983"
        cy="15.4001"
        r="2.60002"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <path
        d="M7.2002 6.20515C7.2002 6.61649 6.86674 6.94995 6.4554 6.94995L6.083 6.94995C5.87733 6.94995 5.7106 6.78322 5.7106 6.57755L5.7106 5.83276C5.7106 5.62709 5.87733 5.46036 6.083 5.46036L6.4554 5.46036C6.86674 5.46036 7.2002 5.79381 7.2002 6.20515Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M9.1084 5.7448C9.1084 5.33346 9.44186 5 9.8532 5H10.9636C11.375 5 11.7084 5.33346 11.7084 5.7448C11.7084 6.15614 11.375 6.48959 10.9636 6.48959H9.8532C9.44186 6.48959 9.1084 6.15614 9.1084 5.7448Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgScooter = React.memo(SvgScooter);
export default MemoSvgScooter;
