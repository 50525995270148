import * as React from 'react';
const SvgAccessibilityHandicap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.832 1.533c0 .846-.68 1.531-1.517 1.531a1.526 1.526 0 01-1.519-1.531C3.796.683 4.476 0 5.315 0c.837 0 1.517.684 1.517 1.533zm.851 11.494a.549.549 0 01.743-.226.56.56 0 01.226.75A4.594 4.594 0 014.597 16C2.057 15.999 0 13.923 0 11.36a4.64 4.64 0 011.875-3.736.545.545 0 01.767.116.557.557 0 01-.117.773 3.539 3.539 0 00-1.428 2.848c0 .977.39 1.857 1.024 2.497a3.468 3.468 0 002.475 1.034 3.498 3.498 0 003.087-1.865zm1-3.536a.694.694 0 00-.585-.32H5.862V5.918l.903.911c.107.107.253.17.404.17h2.234a.572.572 0 00.568-.576.572.572 0 00-.568-.575h-2l-1.715-1.73a1.262 1.262 0 00-1.091-.63 1.27 1.27 0 00-1.265 1.276V9.32a1.27 1.27 0 001.508 1.255h2.88l2.154 3.38a.69.69 0 00.962.212.708.708 0 00.208-.972L8.683 9.491zm2.794-4.12a.274.274 0 01.364-.131.277.277 0 01.134.365l-1.223 2.64a.274.274 0 01-.25.16h-3.46a.276.276 0 01-.274-.278c0-.154.124-.278.274-.278h3.286l1.149-2.477z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgAccessibilityHandicap = React.memo(SvgAccessibilityHandicap);
export default MemoSvgAccessibilityHandicap;
