import { format } from 'date-fns';
import type { ComponentTypeDynamic } from './ad-page-event';
import type { ParsedUrlQuery } from 'querystring';

export const stringifyValueWithDefault = (value?: string | number) =>
  value?.toString() ?? 'null';

export const replaceSpacesWithUnderscoresWithDefault = (
  value?: string,
  defaultAnalyticsValue = 'null',
): string => value?.replace(/\s+/g, '_') ?? defaultAnalyticsValue;

export const formatDateWithDefault = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : 'null';

export const getDynamicComponentType = (
  componentType: ComponentTypeDynamic,
  headerText: string,
) => `${componentType}|${replaceSpacesWithUnderscoresWithDefault(headerText)}`;

export const shouldShowItemTags = (query: ParsedUrlQuery) => {
  return (
    query?.['component-type'] === 'main_feed' ||
    query?.['component-type'] === 'minisite_feed'
  );
};
