import * as React from 'react';
const SvgHouse = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.515 2.644a.75.75 0 01.97 0L23.04 11.6a.75.75 0 01-.97 1.144l-1.857-1.575v9.04a.75.75 0 01-.75.75H4.537a.75.75 0 01-.75-.75v-9.04L1.93 12.744A.75.75 0 11.96 11.6l10.555-8.956zM5.286 9.896v9.564H8.38v-6.354a2.258 2.258 0 012.258-2.258h2.726a2.258 2.258 0 012.257 2.258v6.354h3.093V9.896L12 4.2 5.287 9.896zm8.834 9.564v-6.354a.758.758 0 00-.757-.758h-2.726a.758.758 0 00-.758.758v6.354h4.241z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgHouse = React.memo(SvgHouse);
export default MemoSvgHouse;
