import styles from './yad1-link.module.scss';
import texts from './yad1-link.texts.json';
import { sentAnalyticsNavEvent } from '../../helpers';
import { Link } from '../../link/link';
import cn from 'classnames';
import { BusinessReIcon } from '@y2/mango/components/icons';

type Props = {
  desktopColumnPlacement?: string;
};

export const Yad1SubCategoryLink = ({ desktopColumnPlacement }: Props) => (
  <li
    className={styles.listItem}
    style={
      {
        '--desktop-column-placement': desktopColumnPlacement,
      } as React.CSSProperties
    }
  >
    <Link
      className={cn(styles.link)}
      href="/realestate/newprojects"
      onClick={() => sentAnalyticsNavEvent(texts.newProjects)}
      data-nagish="header-menu-subcategory-link"
    >
      <span className={styles.iconWrapper}>
        <BusinessReIcon className="icon" />
      </span>
      {texts.newProjects}
    </Link>
  </li>
);
