import * as React from 'react';

const SvgLeather = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.228 3h-4.117a.889.889 0 00-.856.683c-.356 1.393-.97 1.663-2.393.99a.862.862 0 00-1.104.337L4.143 7.603c-.327.524-.067 1.228.512 1.39 1.522.424 2.292 1.45 2.415 3.268.122 1.788-.669 3.006-2.526 3.81-.447.194-.66.728-.479 1.196l.066.157c.167.381.497 1.03 1.005 1.978.138.259.387.43.665.465l.105.007.567.003c2.719.038 3.776.38 4.835 1.774a.861.861 0 001.42-.052c.803-1.226 2.637-1.84 5.589-1.726a.883.883 0 00.86-.6l.767-2.16c.187-.525-.116-1.1-.64-1.218-1.482-.336-2.191-1.447-2.191-3.622 0-2.142.666-3.104 2.093-3.254.629-.066.989-.78.684-1.358a38.505 38.505 0 00-1.529-2.658.86.86 0 00-1.174-.29c-1.248.747-1.796.342-2.1-1.004A.89.89 0 0014.229 3z"
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
      />
    </svg>
  );
};

const MemoSvgLeather = React.memo(SvgLeather);
export default MemoSvgLeather;
