import { Popover } from '../../../popover';
import { ItemBackButton } from '../../../item';
import { recommerceCollectionUrl } from '../../const';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';
import styles from './mobile-slide-menu.module.scss';
import { SubMenuItem } from '../item';
import { useIsOpenContext } from '../open-context';
import { ListHeader } from '../../list-header';
import { getCollectionLink } from '../../find-collection';
import { useState, useEffect } from 'react';
import texts from './mobile-menu.text.json';

type Props = {
  item: MenuItem;
  baseUrl: string;
  header: string;
  withImages: boolean;
};

const animationDuration = 500;

export const MobileSlideMenu = ({
  item,
  baseUrl,
  header,
  withImages,
}: Props) => {
  const { state, setValue } = useIsOpenContext();
  const [shouldCreateMenuOnClick, setShouldCreateMenuOnClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(Boolean(state[item.id]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(state[item.id])]);

  const handleClose = () => {
    setTimeout(() => setShouldCreateMenuOnClick(false), animationDuration);
    setValue(item.id, false);
  };

  const handleOpen = (subItem: MenuItem) => {
    setValue(subItem.id, true);
    setShouldCreateMenuOnClick(true);
  };

  const items = Array.isArray(item.items) ? item.items : [];

  return (
    <Popover open={isOpen} className={styles.mobileOnly}>
      <ItemBackButton onClick={handleClose} text={String(item.title)} />
      <ul className={styles.list}>
        <ListHeader title={header} route={item.route} baseUrl={baseUrl} />
        {items.map((subItem, index) => {
          const hasItems = Boolean(subItem.items?.length);

          return (
            <li key={subItem.id}>
              <SubMenuItem
                text={String(subItem.title)}
                url={getCollectionLink(subItem)}
                onSubMenuClick={() => handleOpen(subItem)}
                shouldShowImage={withImages}
                image={subItem.imageUrl}
                subItems={subItem.items}
                hasBorder={index < items.length - 1}
              />
              {hasItems && shouldCreateMenuOnClick && (
                <MobileSlideMenu
                  item={subItem}
                  baseUrl={recommerceCollectionUrl}
                  header={`${texts.forAll} ${subItem.title}`}
                  withImages={false}
                />
              )}
            </li>
          );
        })}
      </ul>
    </Popover>
  );
};
