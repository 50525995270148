import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './popover.module.scss';

type Props = {
  open: boolean;
  children: ReactNode;
  className?: string;
};

export const Popover = ({ open, className, children }: Props) => (
  <div className={cn(styles.popover, open && styles.open, className)}>
    {children}
  </div>
);
