import * as React from 'react';

const SvgKitchenette = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.374 2.51a.736.736 0 00-.12-.01H4.936a.73.73 0 00-.685.984l1.337 3.624.128-.253C.99 16.163 1 21.308 6.371 21.5h8.796c5.207-.186 5.4-4.982 1.161-13.68l-.343-.694V4.059c2.855.565 4.297 3.442 4.297 8.915a.73.73 0 001.462 0c0-6.433-2.014-10.024-6.078-10.44l-.292-.024zm1.741 10.952v-.02c1.459 4.403.72 6.5-1.975 6.597H6.397l-.156-.009c-2.548-.17-3.224-2.271-1.794-6.587l.001.019h12.667zm-2.398-5.603h-7.87l-.16.32A49.225 49.225 0 004.973 12h11.62a46.286 46.286 0 00-1.365-3.105l-.35-.717-.16-.32zM5.984 3.962h8.538v2.435H6.884l-.9-2.435z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgKitchenette = React.memo(SvgKitchenette);
export default MemoSvgKitchenette;
