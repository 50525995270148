import * as React from 'react';

const SvgCamera = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.037 5.25c.818 0 1.542.528 1.792 1.307l.146.455h2.882c.992 0 1.812.769 1.887 1.74l.006.148v7.962c0 1.04-.851 1.888-1.893 1.888H5.143a1.894 1.894 0 01-1.893-1.888V8.9c0-1.04.85-1.888 1.893-1.888h2.881l.147-.455c.235-.73.886-1.24 1.64-1.3l.152-.007h4.074zm0 1.5H9.963a.383.383 0 00-.364.265l-.314.977a.75.75 0 01-.714.52H5.143a.394.394 0 00-.393.388v7.962c0 .21.178.388.393.388h13.714a.394.394 0 00.393-.388V8.9a.394.394 0 00-.393-.388H15.43a.75.75 0 01-.714-.52l-.314-.977a.382.382 0 00-.364-.265zM12 9.25a3.25 3.25 0 110 6.5 3.25 3.25 0 010-6.5zm0 1.5a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCamera = React.memo(SvgCamera);
export default MemoSvgCamera;
