import * as React from 'react';
const SvgChat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81 17.093V19.95a.02.02 0 00.036.012l.01-.012.931-1.134.524-.637 1.389-1.692a1 1 0 01.773-.365h3.018a4 4 0 004-4V8.956a4 4 0 00-4-4H7.956a4 4 0 00-4 4v3.282a3.884 3.884 0 003.884 3.884.97.97 0 01.97.97zm6.68-13.637H7.957a5.5 5.5 0 00-5.5 5.5v3.282a5.384 5.384 0 004.855 5.358v2.354c0 1.427 1.789 2.067 2.694.964l2.704-3.292h2.782a5.5 5.5 0 005.5-5.5V8.956a5.5 5.5 0 00-5.5-5.5zM6.605 9.093a.75.75 0 01.75-.75h7.865a.75.75 0 010 1.5H7.354a.75.75 0 01-.75-.75zm0 3.277a.75.75 0 01.75-.75h4.806a.75.75 0 010 1.5H7.354a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgChat = React.memo(SvgChat);
export default MemoSvgChat;
