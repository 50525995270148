import { Environment } from '../schema';

const baseUrl = process.env['NEXT_PUBLIC_BASE_URL'];
const webSocketBaseUrl = process.env['NEXT_PUBLIC_SOCKET_BASE_URL'];

const isJestRun = Boolean(process.env['JEST_RUN']);

/**
 * returns full URL to resource using reverse-proxy host
 * @description
 * use this in SSR to supply full url for requests (SSR cannot use relative paths)
 * @param path relative path to resource
 * @returns full path
 */
const getFullURL = (path: string) => [baseUrl, path].join('');

/**
 * returns relative / absolute url according to the client
 * @param path relative path to resource
 * @returns
 * * full url for server client
 * * relative url for browser client
 */
function isomorphicURL(path: string) {
  return typeof window === 'undefined' ? getFullURL(path) : path;
}

const commit = process.env['NEXT_PUBLIC_COMMIT_HASH'] || 'undefined';

const config: Environment = {
  name: 'local',
  analyticsEnv: 'dev',
  routes: {
    mainSite: 'https://www.yad2.io',
    marketYad2: 'https://market.yad2.io',
    yadataSite: 'https://yadata.yad2.io',
    myYad2: 'https://my.yad2.io',
    plusYad2: 'https://plus.yad2.io',
    personal: 'https://personal.yad2.io',
    oktaLogin: 'https://yad2.io/okta-login',
    generalPages: 'https://www.public.yad2.io/general-pages',
    contactUs: 'https://service.yad2.co.il',
    treedis: 'https://stage.yad2.treedis.com',
    api: {
      gw: isomorphicURL('/gw'),
      gwInClusterForSSR: isomorphicURL('/gw'),
      gwDrushim: isomorphicURL('/gw-drushim'),
      old: isomorphicURL('/api'),
      campaigns: isomorphicURL('/campaigns-api'),
      mipoCampaigns: isomorphicURL('/mipo-campaigns-api'),
      analyticsData: 'https://analytics.data.yad2.io',
      rentPlus: isomorphicURL('/rent-plus-api'),
    },
    websocket: {
      gw: {
        host: String(webSocketBaseUrl),
        baseParam: '/gw-websocket',
      },
    },
  },
  assets: {
    basePath: 'https://dev-assets.yad2.co.il',
    path: 'https://visuals.yad2.co.il',
    drushimPath: 'http://assets.drushim.io',
    imagesPath: 'https://img.yad2.co.il/Pic',
    userPublishImages: isomorphicURL('/user-publish-images'),
  },
  commit,
  isJestRun,
  amplitudeAnalyticsId: 'acf309e91c511c392febbc4168c81b58',
};

export default config;
