import * as React from 'react';
const SvgPlant = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.995 20.98a.767.767 0 01-.565-.228.768.768 0 01-.228-.566v-7.61c-1.062 0-2.08-.2-3.053-.603a7.853 7.853 0 01-2.58-1.722 7.684 7.684 0 01-1.71-2.58 8.077 8.077 0 01-.589-3.053v-.651c0-.268.091-.493.273-.675a.916.916 0 01.674-.272h.652c1.038 0 2.044.203 3.016.608a7.917 7.917 0 012.58 1.728 7.5 7.5 0 011.363 1.856c.355.683.609 1.41.762 2.178.122-.212.258-.413.409-.602.15-.19.317-.377.502-.561A7.894 7.894 0 0116.08 6.5a7.87 7.87 0 013.052-.609h.652c.268 0 .493.09.674.273a.915.915 0 01.272.674v.651a7.87 7.87 0 01-.609 3.052 7.893 7.893 0 01-1.726 2.58 7.715 7.715 0 01-2.567 1.71 7.976 7.976 0 01-3.04.59v4.765a.768.768 0 01-.228.566.768.768 0 01-.566.228zm-.783-10.019c0-.847-.163-1.654-.49-2.422a6.447 6.447 0 00-1.39-2.051 6.446 6.446 0 00-2.051-1.39 6.126 6.126 0 00-2.422-.49c0 .847.159 1.66.476 2.436a6.23 6.23 0 001.377 2.065c.6.6 1.288 1.058 2.065 1.376a6.37 6.37 0 002.435.476zm1.578 2.872a6.273 6.273 0 004.474-1.853c.6-.6 1.063-1.289 1.39-2.065.326-.777.49-1.588.49-2.436-.848 0-1.66.164-2.436.49a6.405 6.405 0 00-2.065 1.39 6.266 6.266 0 00-1.853 4.474z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgPlant = React.memo(SvgPlant);
export default MemoSvgPlant;
