import * as React from 'react';
const SvgDuplex = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.63v15.505h15V5.63h-15zM4 4.13a1 1 0 00-1 1v16.505a1 1 0 001 1h16a1 1 0 001-1V5.13a1 1 0 00-1-1H4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 13.507a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75zM1.25 2.885c0-.967.784-1.75 1.75-1.75h18c.966 0 1.75.783 1.75 1.75v1A1.75 1.75 0 0121 5.635H3a1.75 1.75 0 01-1.75-1.75v-1zM3 2.635a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h18a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.756 8.507c0-.69.56-1.25 1.25-1.25h3c.69 0 1.25.56 1.25 1.25v4.5h-1.5v-4.25h-2.5v4.25h-1.5v-4.5zM6.756 17.129c0-.69.56-1.25 1.25-1.25h3c.69 0 1.25.56 1.25 1.25v4.5h-1.5v-4.25h-2.5v4.25h-1.5v-4.5zM14.27 8.007a.75.75 0 01.75-.75h2.556a.75.75 0 010 1.5H15.02a.75.75 0 01-.75-.75zM14.27 16.629a.75.75 0 01.75-.75h2.556a.75.75 0 010 1.5H15.02a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDuplex = React.memo(SvgDuplex);
export default MemoSvgDuplex;
