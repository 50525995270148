import {
  LazyLoadLottie,
  Props as LazyLottieProps,
} from '../conditional-lazy-loader';

type Props = Omit<LazyLottieProps, 'fetchAnimationData'>;

const fetchAnimationData = () => import('./spinner-white.json');

export const SpinnerWhite = (props: Props) => (
  <LazyLoadLottie fetchAnimationData={fetchAnimationData} {...props} />
);
