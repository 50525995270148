import * as React from 'react';
const SvgLightEye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.795 9a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.047 13.495c-2.59-.09-4.758-1.588-6.502-4.495 1.8-3 4.05-4.5 6.75-4.5s4.95 1.5 6.75 4.5M14.545 16.875a3.562 3.562 0 012.625-2.625 3.563 3.563 0 01-2.625-2.625 3.562 3.562 0 01-2.625 2.625 3.563 3.563 0 012.625 2.625z"
        stroke="currentColor"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const MemoSvgLightEye = React.memo(SvgLightEye);
export default MemoSvgLightEye;
