import * as React from 'react';

const SvgExternal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.48 3.6a.636.636 0 00-.456 1.079l1.336 1.377-4.693 4.674a.75.75 0 001.058 1.063l4.68-4.66 1.44 1.483a.636.636 0 001.091-.443V4.236A.636.636 0 0020.3 3.6h-3.82z"
        fill="currentColor"
      />
      <path
        d="M14.303 5.355a.75.75 0 00-.75-.75H6.25l-.144.006A1.75 1.75 0 004.5 6.355v12l.006.144a1.75 1.75 0 001.744 1.607h12l.143-.006A1.75 1.75 0 0020 18.355v-7.081l-.007-.102a.75.75 0 00-1.493.102v7.082l-.007.057a.25.25 0 01-.243.192h-12l-.057-.006A.25.25 0 016 18.355v-12l.007-.057a.25.25 0 01.243-.193h7.303l.101-.006a.75.75 0 00.649-.744z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgExternal = React.memo(SvgExternal);
export default MemoSvgExternal;
