import * as React from 'react';

const SvgLocation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 9a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM12 7.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.25c-.485.572-.485.572-.486.571l-.001-.001-.005-.004-.016-.014a10.867 10.867 0 01-.266-.237 26.393 26.393 0 01-2.944-3.202C6.692 15.309 5 12.43 5 9.403c0-1.834.74-3.591 2.054-4.885A7.05 7.05 0 0112 2.5a7.05 7.05 0 014.946 2.018 6.856 6.856 0 012.055 4.886c0 3.026-1.693 5.905-3.282 7.959a26.404 26.404 0 01-2.944 3.202 14.758 14.758 0 01-.267.237l-.015.014-.005.004-.001.001L12 20.25zM12 4a5.55 5.55 0 00-3.893 1.587A5.356 5.356 0 006.5 9.404c0 2.512 1.433 5.056 2.968 7.04A24.884 24.884 0 0012 19.238a24.887 24.887 0 002.532-2.792c1.536-1.985 2.969-4.529 2.969-7.041 0-1.43-.577-2.803-1.607-3.817A5.55 5.55 0 0012 4z"
        fill="currentColor"
      />
      <path
        d="M12 20.25l.486.572a.75.75 0 01-.972 0L12 20.25z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLocation = React.memo(SvgLocation);
export default MemoSvgLocation;
