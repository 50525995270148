import * as React from 'react';
const SvgBump = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.566a6.432 6.432 0 100 12.865 6.432 6.432 0 000-12.865zM2.318 10a7.682 7.682 0 1115.364 0 7.682 7.682 0 01-15.364 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.404 9.964a.625.625 0 01-.884 0L10.027 7.47l-2.534 2.533a.625.625 0 11-.884-.884l2.976-2.975a.625.625 0 01.884 0l2.935 2.935a.625.625 0 010 .884z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.027 6.321c.345 0 .625.28.625.625v6.099a.625.625 0 11-1.25 0V6.946c0-.345.28-.625.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBump = React.memo(SvgBump);
export default MemoSvgBump;
