import { ComponentClass } from 'react';
import ReactModalModule, { Props } from 'react-modal';
import env from '@y2/environments';

type SetAppElementType = (typeof ReactModalModule)['setAppElement'];

/**
 * this fixes an issue with react-18 types and the types of `react-modal`
 */
type ReactModalComp = ComponentClass<Props> & {
  setAppElement: SetAppElementType;
};

export const ReactModal = ReactModalModule as ReactModalComp;

const isBrowserEnv = typeof window !== 'undefined' && !env.isJestRun;
if (isBrowserEnv) {
  const storybookRoot = 'body';
  const isNextApp = document.querySelector('body > #__next');

  ReactModal.setAppElement(isNextApp ? '#__next' : storybookRoot);
}
