import * as React from 'react';
const SvgStopwatch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 12.748c0 4.86 4.11 8.808 9.167 8.808 5.057 0 9.166-3.949 9.166-8.808 0-2.413-1.018-4.623-2.669-6.21l.72-.691.334.32c.14.136.316.203.509.203a.718.718 0 00.51-.202.674.674 0 000-.979l-1.669-1.603a.742.742 0 00-1.019 0 .673.673 0 000 .979l.334.32-.79.76a9.298 9.298 0 00-4.689-1.67v-.591h1.598c.404 0 .72-.304.72-.692 0-.388-.316-.692-.72-.692H9.761c-.404 0-.72.304-.72.692 0 .388.316.692.72.692h1.703v.59C6.723 4.328 3 8.124 3 12.748zm9.167-7.424c4.267 0 7.744 3.34 7.744 7.44 0 4.1-3.477 7.442-7.744 7.442-4.268 0-7.745-3.341-7.745-7.441 0-4.117 3.477-7.441 7.745-7.441z"
        fill="currentColor"
      />
      <path
        d="M11.558 14.889h3.329c.437 0 .78-.33.78-.75 0-.421-.343-.75-.78-.75h-2.55V9.527c0-.42-.342-.75-.78-.75-.437 0-.78.33-.78.75v4.629c0 .402.343.732.78.732z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgStopwatch = React.memo(SvgStopwatch);
export default MemoSvgStopwatch;
