export const SENTRY_LOG_PREFIX = '(@y2/log report) - ';

export const UNEXPECTED_SERVER_ERROR = 500;

export const SERVER_COLORS = {
  black: '\\033[30;1m',
  red: '\\033[31;1m',
  green: '\\033[32;1m',
  yellow: '\\033[33;1m',
  blue: '\\033[34;1m',
  cyan: '\\033[36;1m',
  white: '\\033[37;1m',
} as const;

export type Color = keyof typeof SERVER_COLORS;
