import * as React from 'react';

const SvgUser = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm7 0a2.75 2.75 0 10-5.5 0 2.75 2.75 0 005.5 0zm6 9.5a4 4 0 00-4-4H7a4 4 0 000 8h10a4 4 0 004-4zM7 14.5h10l.164.005A2.5 2.5 0 0117 19.5H7l-.164-.005A2.5 2.5 0 017 14.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgUser = React.memo(SvgUser);
export default MemoSvgUser;
