import * as React from 'react';

const SvgAdd = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4.75a.75.75 0 01.743.648l.007.102v5.75h5.75a.75.75 0 01.102 1.493l-.102.007h-5.75v5.75a.75.75 0 01-1.493.102l-.007-.102v-5.75H5.5a.75.75 0 01-.102-1.493l.102-.007h5.75V5.5a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAdd = React.memo(SvgAdd);
export default MemoSvgAdd;
