import * as React from 'react';

const SvgUserCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.025 2.25c5.399 0 9.775 4.376 9.775 9.775S17.424 21.8 12.025 21.8 2.25 17.424 2.25 12.025 6.626 2.25 12.025 2.25zm-5.51 15.455l-.377.136a8.25 8.25 0 005.887 2.459 8.25 8.25 0 005.874-2.446c-3.8-1.42-7.586-1.47-11.385-.15zm5.51-13.955A8.275 8.275 0 005.14 16.618c4.58-1.829 9.18-1.824 13.759.015A8.275 8.275 0 0012.025 3.75zM12 6.55a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm0 1.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgUserCircle = React.memo(SvgUserCircle);
export default MemoSvgUserCircle;
