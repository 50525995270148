import * as React from 'react';
const SvgProperty = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.526 6.183H15.74V5.65c0-.882-.71-1.6-1.582-1.6H9.94c-.872 0-1.582.718-1.582 1.6v.533h-4.78a.532.532 0 00-.528.534V18.45c0 .882.71 1.6 1.582 1.6h14.836c.872 0 1.582-.718 1.582-1.6V6.726c-.02-.35-.233-.54-.524-.543zM9.413 5.65c0-.294.237-.533.527-.533h4.22c.29 0 .527.239.527.533v.533H9.413V5.65zm10.378 1.6l-1.638 4.969a.527.527 0 01-.5.364h-2.966v-.533a.53.53 0 00-.528-.533H9.94a.53.53 0 00-.527.533v.533H6.447a.527.527 0 01-.5-.364L4.308 7.25H19.79zm-6.16 5.333v2.134h-3.163v-2.134h3.164zm6.364 5.867a.531.531 0 01-.527.533H4.632a.531.531 0 01-.528-.533v-8.447l.842 2.553a1.581 1.581 0 001.5 1.094h2.967v1.6a.53.53 0 00.527.533h4.22a.53.53 0 00.527-.533v-1.6h2.966c.682 0 1.285-.44 1.5-1.094l.842-2.553v8.447z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.108 5.65c0-1.018.82-1.85 1.832-1.85h4.22c1.012 0 1.831.832 1.831 1.85v.283h4.542c.199.003.389.07.532.214a.847.847 0 01.234.564V18.45c0 1.018-.818 1.85-1.831 1.85H4.632A1.843 1.843 0 012.8 18.45V6.717c0-.424.34-.784.777-.784h4.531V5.65zM9.94 4.3c-.731 0-1.332.603-1.332 1.35v.783h-5.03a.282.282 0 00-.278.284V18.45c0 .747.6 1.35 1.332 1.35h14.836c.731 0 1.332-.603 1.332-1.35V6.734c-.009-.123-.048-.194-.087-.233a.259.259 0 00-.189-.068h-5.033V5.65c0-.747-.6-1.35-1.332-1.35H9.94zm0 1.067a.281.281 0 00-.277.283v.283h4.774V5.65a.281.281 0 00-.278-.283H9.94zm-.777.283c0-.43.346-.783.777-.783h4.22c.43 0 .777.354.777.783v.783H9.163V5.65zM3.963 7h16.173l-1.745 5.297a.777.777 0 01-.738.536h-3.216v-.783a.28.28 0 00-.278-.283H9.94a.28.28 0 00-.277.283v.783H6.447a.777.777 0 01-.738-.536L3.963 7zm.691.5l1.53 4.64a.277.277 0 00.263.193h2.716v-.283a.78.78 0 01.777-.783h4.22a.78.78 0 01.777.783v.283h2.716a.277.277 0 00.263-.193l1.53-4.64H4.653zm-.8 2.503l.488-.078.841 2.553c.183.553.691.922 1.264.922h3.216v1.85c0 .16.127.283.277.283h4.22a.28.28 0 00.277-.283V13.4h3.216c.573 0 1.081-.369 1.263-.922l.842-2.553.487.078v8.447c0 .43-.346.783-.777.783H4.632a.781.781 0 01-.778-.783v-8.447zm.5 1.557v6.89c0 .159.128.283.278.283h14.836c.15 0 .277-.124.277-.283v-6.89l-.354 1.074a1.831 1.831 0 01-1.738 1.266h-2.716v1.35a.78.78 0 01-.778.783H9.94a.78.78 0 01-.777-.783V13.9H6.447c-.792 0-1.49-.51-1.739-1.266l-.354-1.074zm5.864.773h3.664v2.634h-3.664v-2.634zm.5.5v1.634h2.664v-1.634h-2.664z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgProperty = React.memo(SvgProperty);
export default MemoSvgProperty;
