import * as React from 'react';
const SvgBalcony = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6.75A2.75 2.75 0 019 4h6a2.75 2.75 0 012.75 2.75v4.5H20a.75.75 0 010 1.5h-1.25v5.5H20a.75.75 0 010 1.5H4a.75.75 0 010-1.5h1.25v-5.5H4a.75.75 0 010-1.5h2.25v-4.5zm.5 6v5.5h2.5v-5.5h-2.5zm4 0v5.5h2.5v-5.5h-2.5zm4 0v5.5h2.5v-5.5h-2.5zM9 5.5c-.69 0-1.25.56-1.25 1.25V11h8.5V6.75c0-.69-.56-1.25-1.25-1.25H9z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBalcony = React.memo(SvgBalcony);
export default MemoSvgBalcony;
