/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useRouter } from 'next/router';

const clean = (fullHash: string) => String(fullHash).replace(/^#/, '');

type Router = ReturnType<typeof useRouter>;

const addToHash = (id = '', hash: string, router: Router) => {
  const hashChunks = hash.split(',').filter(Boolean);
  hashChunks.push(id);
  const withoutDuplicates = Array.from(new Set(hashChunks));
  router.push(`#${withoutDuplicates.join(',')}`, undefined, {
    shallow: true,
    scroll: false,
  });
};

const removeFromHash = (id = '') => {
  const hash = clean(window.location.hash);
  const inHash = hash.split(',').find((chunk) => chunk === id);
  if (inHash) {
    window.history.back();
  }
};

const getUniqueId = () => {
  const hash = clean(typeof window !== 'undefined' ? window.location.hash : '');
  const hashChunks = hash.split(',');
  const id = uniqueId(`panel-`);
  const isAlreadyInHash = hashChunks.includes(id);

  return isAlreadyInHash ? `${id}-1` : id;
};

const preventScrollOnHashChange = (event: HashChangeEvent) =>
  event?.preventDefault?.();

// eslint-disable-next-line max-lines-per-function
export const useBrowserBack = (
  onClose: () => void,
  open: boolean,
  useBrowserBackButton?: boolean,
) => {
  const [id] = useState<string>(getUniqueId());
  const isByHandleCloseMethod = useRef(false);
  const router = useRouter();

  useEffect(() => {
    if (!useBrowserBackButton) return;

    const changeByAddressBar = (event: HashChangeEvent) => {
      const hash = clean(window.location.hash);
      const notInHash = !hash.split(',').find((chunk) => chunk === id);
      const wasClosedByBrowser = notInHash && open;
      if (wasClosedByBrowser) {
        preventScrollOnHashChange(event);
        onClose();
      }
    };
    window.addEventListener('hashchange', changeByAddressBar);

    if (open) {
      addToHash(id, clean(window.location.hash), router);
    } else if (isByHandleCloseMethod.current) {
      isByHandleCloseMethod.current = false;
    } else {
      removeFromHash(id);
    }

    return () => {
      window.removeEventListener('hashchange', changeByAddressBar);
    };
  }, [open]);

  return {
    handleClose: () => {
      if (useBrowserBackButton) {
        isByHandleCloseMethod.current = true;
        removeFromHash(id);
      }

      onClose();
    },
    id,
  };
};
