import * as React from 'react';

const SvgRealestate = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4c.53 0 1.061.195 1.478.585l.035.034 7.261 7.1a.736.736 0 01.17.814.72.72 0 01-.652.464h-2.111v6.259a.745.745 0 01-.61.733l-.033.005-.095.006h-3.101a.745.745 0 01-.737-.713v-.031l-.001-3.907h-3.052v3.907a.745.745 0 01-.611.733l-.032.005-.096.006H6.478a.744.744 0 01-.737-.713v-6.29H3.738a.726.726 0 01-.663-.417.764.764 0 01-.072-.411.723.723 0 01.135-.349l.02-.027.068-.074 7.26-7.1A2.158 2.158 0 0112 4zm0 1.488a.694.694 0 00-.487.199L5.56 11.509h.918l.033.002a.745.745 0 01.699.648l.006.096v6.257h1.861v-3.907l.001-.033a.744.744 0 01.643-.705l.095-.006h4.526l.03.001c.192.008.365.09.492.217.133.135.215.321.215.526v3.907h1.627v-6.258l.001-.033a.745.745 0 01.643-.705l.096-.006.993-.001-5.952-5.822-.023-.021A.693.693 0 0012 5.488z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRealestate = React.memo(SvgRealestate);
export default MemoSvgRealestate;
