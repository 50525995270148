import * as React from 'react';
const SvgExport = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 19.436l.001.042c.027.46.261.865.608 1.138.339.267.766.396 1.19.383H18.2c.424.013.851-.117 1.19-.383a1.566 1.566 0 00.609-1.18V9.1l-.001-.042a1.566 1.566 0 00-.607-1.138 1.837 1.837 0 00-1.19-.384h-2.095a.749.749 0 100 1.496h2.137a.338.338 0 01.218.062.134.134 0 01.038.045v10.257l-.003.006a.135.135 0 01-.035.04.339.339 0 01-.218.062l-.03-.001H5.756a.338.338 0 01-.218-.061.134.134 0 01-.038-.045V9.139l.003-.006a.133.133 0 01.035-.039.338.338 0 01.218-.062h2.137a.749.749 0 100-1.496H5.799a1.837 1.837 0 00-1.19.384A1.566 1.566 0 004 9.1v10.336zM9.07 5.704a.747.747 0 010-1.058l2.433-2.427a.748.748 0 011.062.002l2.432 2.425a.747.747 0 010 1.058.751.751 0 01-1.061 0l-1.153-1.15v8.387a.749.749 0 01-1.5 0V4.553l-1.153 1.15a.751.751 0 01-1.06 0z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgExport = React.memo(SvgExport);
export default MemoSvgExport;
