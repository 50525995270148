/**
 * Note* when adding make sure to add a story in this file:
 * libs/mango/src/components/animation/stories/all.story.tsx
 * Also add your animation to the mocks file here:
 * libs/mango/src/components/animation/__mocks__/index.ts
 */
export * from './lottie/balloon';
export * from './lottie/balloons-party';
export * from './lottie/yadata';
export * from './lottie/spinner';
export * from './lottie/spinner-white';
export * from './lottie/spinner-black';
export * from './lottie/chat-loader';
export * from './lottie/rising-graph';
export * from './lottie/heart-black';
export * from './lottie/heart-white';
export * from './lottie/heart-grey';
export * from './lottie/price-list-gauge';
export * from './lottie/dots-loader';
export * from './lottie/yad2-check-white';
export * from './lottie/calculator';
export * from './lottie/guarantee-lock';
export * from './lottie/arrow-badge-animation-blue-bg';
