import * as React from 'react';

const SvgMorehorizontal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.41 11.556a1.556 1.556 0 10-3.11 0 1.556 1.556 0 003.11 0zm6.112 0a1.555 1.555 0 10-3.111 0 1.555 1.555 0 003.111 0zm6.111 0a1.555 1.555 0 10-3.11 0 1.555 1.555 0 003.11 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMorehorizontal = React.memo(SvgMorehorizontal);
export default MemoSvgMorehorizontal;
