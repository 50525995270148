import { useState } from 'react';
import styles from './desktop-drop-menu.module.scss';
import { recommerceBaseUrl, recommerceSubTitle } from '../../const';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';
import { CategoryItem } from '../category-item';
import { CategoryContent } from '../category-content';
import { Popover } from '../../../popover';
import { ListHeader } from '../../list-header';
import { getCollectionLink } from '../../find-collection';

type Props = {
  items: MenuItem[];
};

const defaultIndex = 0;

export const DesktopMenu = ({ items }: Props) => {
  const [openIndex, setOpenIndex] = useState<number>(defaultIndex);

  return (
    <div className={styles.container}>
      <ul className={styles.categories}>
        <ListHeader
          baseUrl={recommerceBaseUrl}
          title={recommerceSubTitle}
          route={''}
        />
        {items.map((categoryItem, index) => (
          <CategoryItem
            key={categoryItem.id}
            url={getCollectionLink(categoryItem)}
            text={categoryItem.title || ''}
            image={categoryItem.imageUrl}
            onMouseEnter={() => setOpenIndex(index)}
          />
        ))}
      </ul>
      <div className={styles.content}>
        {items.map((categoryItem, index) => (
          <CategoryContent
            key={categoryItem.id}
            headerUrl={getCollectionLink(categoryItem)}
            title={categoryItem.title || ''}
            isVisible={openIndex === index}
            subItems={categoryItem.items}
          />
        ))}
      </div>
    </div>
  );
};

export const DesktopDropMenu = ({
  items,
  isOpen,
}: Props & {
  isOpen: boolean;
}) => {
  return (
    <Popover open={isOpen} className={styles.desktopOnly}>
      <DesktopMenu items={items} />
    </Popover>
  );
};
