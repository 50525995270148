import { analytics } from '@y2/google-tag-manager/v2';
import env from '@y2/environments';
import { replaceSpacesWithUnderscoresWithDefault } from '@y2/analytics-common-events/analytics.utils';

export const sentAnalyticsNavEvent = (label: string) => {
  analytics.pushEvent({
    EventName: 'navigation',
    EventDetail: 'header',
    EventText: replaceSpacesWithUnderscoresWithDefault(label),
    EventGroup: 'browsing_interactions',
  });
};

export const isRelativeLink = (linkPath: string) =>
  String(linkPath).startsWith('/');

export const getLinkWithHost = (linkPath: string) =>
  isRelativeLink(linkPath) ? `${env.routes.mainSite}${linkPath}` : linkPath;

export type LinkBehaviorProps = {
  onClick?: (text: string) => void;
  isInSubDomain?: boolean;
  isNextLink?: (url: string) => boolean;
};
