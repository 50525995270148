import React from 'react';

const SvgLightRailIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.76068 6.36502C4.08423 3.45303 6.5456 1.25 9.47551 1.25H14.5251C17.455 1.25 19.9164 3.45303 20.2399 6.36502L21.1288 14.365C21.4604 17.3491 19.4549 20.0053 16.6483 20.6177L17.1143 21.5674C17.2967 21.9393 17.1431 22.3887 16.7712 22.5711C16.3994 22.7535 15.95 22.6 15.7676 22.2281L15.0425 20.75H8.95814L8.23304 22.2281C8.05061 22.6 7.60125 22.7535 7.22938 22.5711C6.8575 22.3887 6.70392 21.9393 6.88635 21.5674L7.35227 20.6177C4.54566 20.0053 2.54022 17.3491 2.87179 14.365L3.76068 6.36502ZM5.2515 6.53067C5.44373 4.80063 6.65685 3.40915 8.25013 2.92971V3V9.5C8.25013 10.7426 9.25748 11.75 10.5001 11.75H13.5001C14.7428 11.75 15.7501 10.7426 15.7501 9.5V3V2.92961C17.3436 3.40895 18.5568 4.80051 18.7491 6.53067L19.638 14.5307C19.9177 17.0482 17.947 19.25 15.414 19.25H8.58662C6.05357 19.25 4.08288 17.0482 4.36261 14.5307L5.2515 6.53067ZM9.75013 2.75H14.2501V3V9.5C14.2501 9.91421 13.9143 10.25 13.5001 10.25H10.5001C10.0859 10.25 9.75013 9.91421 9.75013 9.5V3V2.75ZM8.00029 15.25C7.58608 15.25 7.25029 15.5858 7.25029 16C7.25029 16.4142 7.58608 16.75 8.00029 16.75H10.0003C10.4145 16.75 10.7503 16.4142 10.7503 16C10.7503 15.5858 10.4145 15.25 10.0003 15.25H8.00029ZM14.0003 15.25C13.5861 15.25 13.2503 15.5858 13.2503 16C13.2503 16.4142 13.5861 16.75 14.0003 16.75H16.0003C16.4145 16.75 16.7503 16.4142 16.7503 16C16.7503 15.5858 16.4145 15.25 16.0003 15.25H14.0003Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLightRailIcon = React.memo(SvgLightRailIcon);
export default MemoSvgLightRailIcon;
