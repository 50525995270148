import * as React from 'react';

const SvgRoof = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.72 13.71a2.08 2.08 0 00-1.3-1H19l.42-.64a2.76 2.76 0 00-.49-3.58l-3.4-3a.754.754 0 10-1 1.13l3.39 3a1.26 1.26 0 01.22 1.63l-.86 1.31a.34.34 0 00-.05.14H13a2 2 0 01-1.38-.53 2 2 0 01-.68-1.32L10.8 9.6l-3.68 2.54a2 2 0 01-1 .46l-1.71.3a2.84 2.84 0 00-1.64.93 2.9 2.9 0 00-.69 1.75v1.35A2 2 0 004 19h.42a2.74 2.74 0 005.41 0h4.59a2.74 2.74 0 005.41 0h.43a2 2 0 002-2v-1.63a2.74 2.74 0 00-.54-1.66zm-12-1.57c.108.226.239.44.39.64H8.83l.89-.64zM7.93 19.5l.13-.14a.639.639 0 00.13-.19c.12-.185.185-.4.19-.62a1.24 1.24 0 00-.87-1.17 1.001 1.001 0 00-.38-.08c-.13.002-.26.029-.38.08a1.24 1.24 0 00-.84 1.17c.004.154.034.306.09.45l.07.17.13.2.14.12c.067.059.14.11.22.15l.14.07a1 1 0 00.43.09c.15-.004.3-.034.44-.09l.14-.07c.077-.04.15-.088.22-.14zm10.13-.14a.8.8 0 01-.13.13c-.067.059-.14.11-.22.15l-.14.07a1.269 1.269 0 01-.43.09 1 1 0 01-.43-.09l-.14-.07a1.067 1.067 0 01-.22-.15l-.14-.12a1.362 1.362 0 01-.13-.2L16 19a1.339 1.339 0 01-.09-.45 1.24 1.24 0 01.62-1.05c.078-.05.162-.09.25-.12.12-.051.25-.078.38-.08.13.002.26.029.38.08.513.16.864.633.87 1.17a1.32 1.32 0 01-.18.62.671.671 0 01-.17.19zm2.21-1.86a.51.51 0 00.5-.5v-1.61a1.33 1.33 0 00-.25-.77 1.18 1.18 0 00-.38-.32H5.53l-.87.1a1.27 1.27 0 00-.76.43 1.32 1.32 0 00-.33.82V17a.47.47 0 00.13.34.52.52 0 00.35.16h.54a2.75 2.75 0 015.08 0h4.92a2.75 2.75 0 015.08 0h.6z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgRoof = React.memo(SvgRoof);
export default MemoSvgRoof;
