import * as React from 'react';

const SvgHighCeiling = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 3A1.75 1.75 0 0015 1.25H9A1.75 1.75 0 007.25 3v18c0 .966.784 1.75 1.75 1.75h6A1.75 1.75 0 0016.75 21V3zM9 2.75h6l.057.007A.25.25 0 0115.25 3v1.25H11.5a.75.75 0 000 1.5h3.75v2H12.5a.75.75 0 000 1.5h2.75v2H11.5a.75.75 0 000 1.5h3.75v2H12.5a.75.75 0 000 1.5h2.75v2H11.5a.75.75 0 000 1.5h3.75V21a.25.25 0 01-.25.25H9a.25.25 0 01-.25-.25V3A.25.25 0 019 2.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgHighCeiling = React.memo(SvgHighCeiling);
export default MemoSvgHighCeiling;
