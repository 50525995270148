import * as React from 'react';

const SvgNewad = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 7A2.25 2.25 0 015 4.75h14A2.25 2.25 0 0121.25 7v10A2.25 2.25 0 0119 19.25H5A2.25 2.25 0 012.75 17V7zM5 3.25A3.75 3.75 0 001.25 7v10A3.75 3.75 0 005 20.75h14A3.75 3.75 0 0022.75 17V7A3.75 3.75 0 0019 3.25H5zM13.75 9a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v6a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V9zM14 7.25A1.75 1.75 0 0012.25 9v6c0 .966.784 1.75 1.75 1.75h3A1.75 1.75 0 0018.75 15V9A1.75 1.75 0 0017 7.25h-3zM6.25 10A.75.75 0 017 9.25h3a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6 12.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H6z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgNewad = React.memo(SvgNewad);
export default MemoSvgNewad;
