/* eslint-disable no-restricted-globals */
import { analytics } from '@y2/google-tag-manager/v2';
import text from './helpers.text.json';

export const DEFAULT_ANALYTICS_LOGIN_PARAM = 'Site organic';

export const goToLogin = (
  autoSendAnalytics = true,
  analyticsParam = DEFAULT_ANALYTICS_LOGIN_PARAM,
  customContinue?: string,
  appName?: string,
) => {
  const params = new URLSearchParams({
    continue: customContinue || location.href,
    analytics: analyticsParam,
    ...(appName && { service: appName }),
  });
  if (autoSendAnalytics) {
    analytics.pushEvent({
      EventName: 'navigation',
      EventDetail: 'header',
      EventText: text.login,
      EventGroup: 'general_interactions',
    });
  }
  location.assign(`${location.origin}/auth/login?${params.toString()}`);
};
