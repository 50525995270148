import {
  LazyLoadLottie,
  Props as LazyLottieProps,
} from '../conditional-lazy-loader';

type Props = Omit<LazyLottieProps, 'fetchAnimationData'>;

const fetchAnimationData = () => import('./rising-graph.json');

export const RisingGraph = (props: Props) => (
  <LazyLoadLottie fetchAnimationData={fetchAnimationData} {...props} />
);
