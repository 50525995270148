/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';
const SvgColoredWhatsapp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#colored-whatsapp_svg__clip0_7069_1801)">
        <path
          d="M0 24l1.696-6.16a11.778 11.778 0 01-1.593-5.948C.103 5.332 5.467 0 12.052 0c3.197 0 6.2 1.238 8.452 3.485A11.764 11.764 0 0124 11.897c0 6.56-5.364 11.892-11.954 11.892h-.006c-2 0-3.966-.502-5.713-1.449L0 24zm6.63-3.81l.361.217a9.995 9.995 0 005.055 1.38h.006c5.472 0 9.93-4.431 9.93-9.884a9.813 9.813 0 00-2.905-6.992 9.88 9.88 0 00-7.025-2.898C6.579 2.008 2.12 6.44 2.12 11.892c0 1.865.522 3.684 1.519 5.258l.235.377L2.87 21.17l3.76-.98z"
          fill="#fff"
        />
        <path
          d="M.418 23.584l1.64-5.95A11.386 11.386 0 01.51 11.898C.516 5.567 5.69.417 12.052.417c3.088 0 5.983 1.197 8.16 3.364a11.383 11.383 0 013.375 8.122c0 6.33-5.18 11.48-11.535 11.48h-.006c-1.931 0-3.828-.484-5.513-1.396L.418 23.584z"
          fill="url(#colored-whatsapp_svg__paint0_linear_7069_1801)"
        />
        <path
          d="M0 24l1.696-6.16a11.778 11.778 0 01-1.593-5.948C.103 5.332 5.467 0 12.052 0c3.197 0 6.2 1.238 8.452 3.485A11.764 11.764 0 0124 11.897c0 6.56-5.364 11.892-11.954 11.892h-.006c-2 0-3.966-.502-5.713-1.449L0 24zm6.63-3.81l.361.217a9.995 9.995 0 005.055 1.38h.006c5.472 0 9.93-4.431 9.93-9.884a9.813 9.813 0 00-2.905-6.992 9.88 9.88 0 00-7.025-2.898C6.579 2.008 2.12 6.44 2.12 11.892c0 1.865.522 3.684 1.519 5.258l.235.377L2.87 21.17l3.76-.98z"
          fill="url(#colored-whatsapp_svg__paint1_linear_7069_1801)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.066 6.918c-.224-.496-.459-.507-.671-.513-.172-.006-.372-.006-.573-.006-.2 0-.522.074-.797.371S5.982 7.785 5.982 9.251c0 1.46 1.072 2.875 1.221 3.074.149.2 2.069 3.297 5.1 4.489 2.522.992 3.038.793 3.582.741.544-.051 1.765-.718 2.017-1.414.247-.696.247-1.29.172-1.415-.074-.125-.275-.2-.573-.348-.298-.148-1.765-.867-2.04-.97-.275-.096-.476-.147-.67.15-.201.296-.774.963-.946 1.163-.172.2-.35.222-.648.074-.298-.149-1.26-.462-2.4-1.477-.89-.787-1.49-1.763-1.663-2.06-.172-.296-.017-.456.132-.604.132-.131.298-.348.447-.519.15-.171.2-.297.298-.496.098-.2.052-.37-.023-.52-.074-.142-.659-1.613-.922-2.2z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="colored-whatsapp_svg__paint0_linear_7069_1801"
          x1={12.002}
          y1={23.582}
          x2={12.002}
          y2={0.415}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.195} stopColor="#1ECA3B" />
          <stop offset={0.57} stopColor="#60D66A" />
          <stop offset={1} stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="colored-whatsapp_svg__paint1_linear_7069_1801"
          x1={12.002}
          y1={23.998}
          x2={12.002}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="colored-whatsapp_svg__clip0_7069_1801">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const MemoSvgColoredWhatsapp = React.memo(SvgColoredWhatsapp);
export default MemoSvgColoredWhatsapp;
