import cn from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';
import { IconButton } from './with-icon';
import { ButtonProps } from '../base';
import styles from './button-with-icon.module.scss';

export const arrows = ['right', 'left'] as const;

export type ArrowButtonProps = {
  arrow: (typeof arrows)[number];
} & ButtonProps;

export const ArrowButton = ({
  arrow,
  className,
  children,
  ...buttonProps
}: ArrowButtonProps): JSX.Element => (
  <IconButton
    className={cn(
      className,
      arrow === 'left' ? styles.leftArrow : styles.rightArrow,
    )}
    {...buttonProps}
    iconPosition={arrow}
    iconSlot={
      arrow === 'left' ? (
        <ChevronLeftIcon data-testid="left-arrow-icon" />
      ) : (
        <ChevronRightIcon data-testid="right-arrow-icon" />
      )
    }
  >
    {children}
  </IconButton>
);
