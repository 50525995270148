import * as React from 'react';

const SvgColdRoom = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.751 6.111c0-2.126-1.673-3.861-3.75-3.861s-3.75 1.735-3.75 3.861v5.57c-1.41 1.381-1.868 2.33-1.998 4.269L6.25 16a5.75 5.75 0 0011.498 0v-.05c-.13-1.94-.588-2.887-1.997-4.268l-.001-5.57zm-6 0c0-1.31 1.014-2.361 2.25-2.361s2.25 1.051 2.25 2.361V12l.005.085.014.081.023.083.038.088.022.042c.035.06.079.114.132.166 1.337 1.264 1.658 1.868 1.768 3.505l-.002-.022a4.25 4.25 0 01-8.495.178l-.005-.178.016-.205c.125-1.47.477-2.074 1.754-3.282l.01-.01a.767.767 0 00.121-.158l.05-.107.025-.079.02-.113.004-.074V6.111zm2.25 1.616a.75.75 0 01.75.75v5.117a2.52 2.52 0 11-1.5 0V8.477a.75.75 0 01.75-.75zM10.98 16a1.02 1.02 0 112.04 0 1.02 1.02 0 01-2.04 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgColdRoom = React.memo(SvgColdRoom);
export default MemoSvgColdRoom;
