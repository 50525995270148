import {
  analytics,
  type AnalyticsEventBaseData,
} from '@y2/google-tag-manager/v2';

export const pushGeneralAnalyticsEvent = (
  analyticsProperties: AnalyticsEventBaseData,
) => {
  analytics.pushEvent(analyticsProperties);
};
