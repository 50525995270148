import * as React from 'react';
const SvgFilter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.329 3.25h13.44a1.749 1.749 0 011.736 2.001c-.311 2.165-.997 3.873-2.073 5.112-1.038 1.195-2.232 1.993-3.57 2.378l-.24.063.001 5.346c0 .49-.206.953-.562 1.283l-.124.104a36.602 36.602 0 01-2.611 1.824 1.25 1.25 0 01-1.92-1.054l-.001-7.561-.048-.014C5.663 11.517 3.7 9.002 3.584 5.299l-.005-.295c0-.97.782-1.754 1.75-1.754zm13.441 1.5H5.33a.25.25 0 00-.25.25c.015 3.371 1.725 5.48 5.276 6.468a.75.75 0 01.549.722v7.657l.339-.224.368-.25c.492-.34.963-.682 1.413-1.026a.25.25 0 00.098-.198V12.19a.75.75 0 01.64-.742c1.33-.197 2.502-.878 3.537-2.069.868-1 1.447-2.442 1.72-4.343a.25.25 0 00-.25-.286z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgFilter = React.memo(SvgFilter);
export default MemoSvgFilter;
