import * as React from 'react';

const SvgAirCon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.5 9a.5.5 0 000 1h9a.5.5 0 000-1h-9z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 3A.75.75 0 014 2.25h16a.75.75 0 01.75.75v9a.75.75 0 01-.75.75H4a.75.75 0 01-.75-.75V3zm16 .75H4.75v7.5h14.5v-7.5z"
        fill="currentColor"
      />
      <path
        d="M17.915 15.28a.75.75 0 01.511.93c-.078.27-.094.689.078 1.04.147.3.493.675 1.41.805a.75.75 0 01-.21 1.485c-1.322-.187-2.144-.81-2.547-1.63-.377-.769-.325-1.59-.171-2.12a.75.75 0 01.93-.51zM14.223 15.98a.75.75 0 00-1.44-.419c-.31 1.068-.138 2.434.407 3.697.552 1.28 1.521 2.543 2.924 3.385a.75.75 0 00.772-1.286c-1.097-.658-1.873-1.659-2.319-2.693-.453-1.05-.528-2.05-.344-2.685zM9.866 15.05a.75.75 0 00-.511.93c.184.635.109 1.633-.344 2.684-.446 1.034-1.222 2.035-2.32 2.693a.75.75 0 10.772 1.286c1.404-.842 2.373-2.105 2.925-3.385.544-1.263.717-2.63.407-3.697a.75.75 0 00-.929-.511zM4.002 18.046a.75.75 0 00-.004 1.5c.552 0 1.355-.176 1.99-.752.675-.613 1.047-1.576.86-2.899a.75.75 0 00-1.485.21c.13.917-.136 1.354-.383 1.578-.29.263-.696.363-.978.363z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAirCon = React.memo(SvgAirCon);
export default MemoSvgAirCon;
