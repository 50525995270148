import styles from './not-supported-modal.module.scss';
import texts from './not-supported-modal.texts.json';
import Modal from '@y2/mango/components/modal';
import { AnnouncementSpotIllustration } from '@y2/mango/components/illustrations/spot';
import { Button } from '@y2/mango/components/button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const downloadUrl = `https://app-download.yad2.co.il`;

export const NotSupportedModal = ({ isOpen, onClose }: Props) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    label={texts.warning}
    description={texts.warning}
    data-nagish="supported-modal-dialog"
  >
    <div className={styles.modal} data-testid="unsupported-modal">
      <div className={styles.imageBox}>
        <AnnouncementSpotIllustration height={120} width={120} />
      </div>
      <h3 className={styles.title}>{texts.title}</h3>
      <p className={styles.content}>{texts.subTitle}</p>
      <div className={styles.divider} />
      <p className={styles.content}>{texts.details}</p>
      <div className={styles.buttonRows}>
        <Button
          variant="primary"
          height="medium"
          className={styles.button}
          asElement="a"
          href={downloadUrl}
        >
          {texts.downloadApp}
        </Button>
        <button className={styles.closeButton} onClick={onClose}>
          {texts.continue}
        </button>
      </div>
    </div>
  </Modal>
);
