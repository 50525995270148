import { envState } from '../helpers';

export const isClientSide = () => typeof window !== 'undefined';

export const isServerSide = () => typeof window === 'undefined';

export const getEnvironmentEventData = () =>
  envState.environment
    ? {
        Environment: envState.environment,
      }
    : {};

export const getReactPageIndicator = () => ({
  SiteType: 'react',
});
