import * as React from 'react';
const SvgSharedComputers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.192871 13C0.192871 12.5858 0.528658 12.25 0.942871 12.25H23.0572C23.4714 12.25 23.8072 12.5858 23.8072 13C23.8072 13.4142 23.4714 13.75 23.0572 13.75H0.942871C0.528658 13.75 0.192871 13.4142 0.192871 13Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.1929 4.00002C0.1929 2.48123 1.42412 1.25002 2.9429 1.25002H8.67002C10.1888 1.25002 11.42 2.48123 11.42 4.00002V8.00002C11.42 9.5188 10.1888 10.75 8.67002 10.75H2.9429C1.42412 10.75 0.1929 9.5188 0.1929 8.00002V4.00002ZM2.9429 2.75002C2.25254 2.75002 1.6929 3.30966 1.6929 4.00002V8.00002C1.6929 8.69037 2.25254 9.25002 2.9429 9.25002H8.67002C9.36038 9.25002 9.92002 8.69037 9.92002 8.00002V4.00002C9.92002 3.30966 9.36038 2.75002 8.67002 2.75002H2.9429Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.6929 9.25002H8.1929V13.75H3.6929V9.25002ZM5.1929 10.75V12.25H6.6929V10.75H5.1929Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5936 4C12.5936 2.48122 13.8248 1.25 15.3436 1.25L21.0572 1.25C22.5759 1.25 23.8072 2.48122 23.8072 4V8C23.8072 9.51878 22.5759 10.75 21.0572 10.75H15.3436C13.8248 10.75 12.5936 9.51878 12.5936 8V4ZM15.3436 2.75C14.6532 2.75 14.0936 3.30964 14.0936 4V8C14.0936 8.69036 14.6532 9.25 15.3436 9.25H21.0572C21.7475 9.25 22.3072 8.69036 22.3072 8V4C22.3072 3.30964 21.7475 2.75 21.0572 2.75H15.3436Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2678 18.7178C18.682 18.7178 19.0178 19.0536 19.0178 19.4678V21.7178H20.0178C20.432 21.7178 20.7678 22.0536 20.7678 22.4678C20.7678 22.8821 20.432 23.2178 20.0178 23.2178H16.5178C16.1036 23.2178 15.7678 22.8821 15.7678 22.4678C15.7678 22.0536 16.1036 21.7178 16.5178 21.7178H17.5178V19.4678C17.5178 19.0536 17.8536 18.7178 18.2678 18.7178Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7678 18.4678C13.7678 16.9491 14.999 15.7178 16.5178 15.7178H20.5178C22.0366 15.7178 23.2678 16.9491 23.2678 18.4678C23.2678 19.4343 22.4843 20.2178 21.5178 20.2178H15.5178C14.5513 20.2178 13.7678 19.4343 13.7678 18.4678ZM16.5178 17.2178C15.8274 17.2178 15.2678 17.7775 15.2678 18.4678C15.2678 18.6059 15.3797 18.7178 15.5178 18.7178H21.5178C21.6559 18.7178 21.7678 18.6059 21.7678 18.4678C21.7678 17.7775 21.2081 17.2178 20.5178 17.2178H16.5178Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.6531 18.7178C6.06732 18.7178 6.4031 19.0536 6.4031 19.4678V21.7178H7.4031C7.81732 21.7178 8.1531 22.0536 8.1531 22.4678C8.1531 22.8821 7.81732 23.2178 7.4031 23.2178H3.9031C3.48889 23.2178 3.1531 22.8821 3.1531 22.4678C3.1531 22.0536 3.48889 21.7178 3.9031 21.7178H4.9031V19.4678C4.9031 19.0536 5.23889 18.7178 5.6531 18.7178Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.1531 18.4678C1.1531 16.9491 2.38432 15.7178 3.9031 15.7178H7.9031C9.42189 15.7178 10.6531 16.9491 10.6531 18.4678C10.6531 19.4343 9.8696 20.2178 8.9031 20.2178H2.9031C1.9366 20.2178 1.1531 19.4343 1.1531 18.4678ZM3.9031 17.2178C3.21275 17.2178 2.6531 17.7775 2.6531 18.4678C2.6531 18.6059 2.76503 18.7178 2.9031 18.7178H8.9031C9.04117 18.7178 9.1531 18.6059 9.1531 18.4678C9.1531 17.7775 8.59346 17.2178 7.9031 17.2178H3.9031Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        d="M16.2768 10.0161H17.7689V12.4382H16.2768V10.0161Z"
        fill="currentColor"
      />
      <path
        clip-rule="evenodd"
        d="M19.2666 10.0161H20.7588V12.4382H19.2666V10.0161Z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgSharedComputers = React.memo(SvgSharedComputers);
export default MemoSvgSharedComputers;
