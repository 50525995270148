import * as React from 'react';

const SvgVessel = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6 19C6.40076 19.4509 7.48996 20.0715 8.78825 19.1842C8.92966 19.0875 9.12266 19.1027 9.25703 19.2089C9.7579 19.6048 10.6812 19.9409 11.696 19.175C11.8218 19.08 11.9967 19.0748 12.1284 19.1615C12.7451 19.5676 13.8325 19.9546 14.7107 19.2242C14.8469 19.1109 15.048 19.0975 15.1948 19.1966C15.8296 19.6252 16.9783 19.9967 17.975 19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.0086 16.0325L5.15303 11.5991C5.07367 11.1879 5.38876 10.8062 5.80762 10.8062H19.8958C20.3974 10.8062 20.7194 11.3394 20.4857 11.7833L18.2493 16.0325"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.38395 4.66667V5.90067H11.7617V4.66667C11.7617 4.29848 11.4632 4 11.0951 4H10.0506C9.68243 4 9.38395 4.29848 9.38395 4.66667Z"
        fill="currentColor"
      />
      <path
        d="M7 10.7603V6.56733C7 6.19914 7.29848 5.90067 7.66667 5.90067H9.38395M18.7364 10.7603L15.5428 6.18572C15.4181 6.0071 15.214 5.90067 14.9962 5.90067H11.7617M9.38395 5.90067V4.66667C9.38395 4.29848 9.68243 4 10.0506 4H11.0951C11.4632 4 11.7617 4.29848 11.7617 4.66667V5.90067M9.38395 5.90067H11.7617"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const MemoSvgVessel = React.memo(SvgVessel);
export default MemoSvgVessel;
