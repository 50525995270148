import * as React from 'react';

const SvgReload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.512 14.037c.274.289.642.448 1.037.448.395 0 .764-.159 1.038-.448l2.3-2.428a.785.785 0 00-.03-1.107.789.789 0 00-1.107.03l-1.421 1.5v-.222a6.254 6.254 0 016.245-6.245c1.47 0 2.899.522 4.02 1.467a.79.79 0 00.507.184c.232 0 .45-.101.598-.278.135-.16.2-.363.182-.571a.776.776 0 00-.276-.532A7.811 7.811 0 0011.574 4c-4.306 0-7.81 3.504-7.81 7.81v.216l-1.413-1.49a.788.788 0 00-1.108-.03.787.787 0 00-.03 1.108l2.299 2.423zm2.929 3.962a7.806 7.806 0 005.03 1.835c4.307 0 7.81-3.503 7.81-7.81v-.098l1.414 1.49a.783.783 0 101.136-1.077l-2.297-2.424a1.433 1.433 0 00-1.037-.435c-.396 0-.775.158-1.038.435l-2.301 2.429a.783.783 0 001.137 1.077l1.42-1.5v.103a6.252 6.252 0 01-6.244 6.245 6.256 6.256 0 01-4.021-1.466.78.78 0 00-1.103.093.778.778 0 00.094 1.103z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgReload = React.memo(SvgReload);
export default MemoSvgReload;
