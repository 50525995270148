import * as React from 'react';
const SvgDoor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 20.67a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H4.75a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.975 20.107V5.362c0-.698.56-1.264 1.25-1.264l7.55.007c.69 0 1.25.566 1.25 1.264v14.745h1.5V5.369c0-1.536-1.231-2.781-2.75-2.781l-7.55-.007c-1.52 0-2.75 1.245-2.75 2.78v14.746h1.5z"
        fill="currentColor"
      />
      <path
        d="M15.75 13.448a1.184 1.184 0 11-2.367 0 1.184 1.184 0 012.367 0z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDoor = React.memo(SvgDoor);
export default MemoSvgDoor;
