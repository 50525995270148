import * as React from 'react';

const SvgDrivingcar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.15 5.232c1.502.016 2.37.614 2.89 2.002.182.485.344.978.51 1.469l.398 1.15c.218.622.458 1.23 1.01 1.691.235.198.413.56.458.869.28 1.952.19 3.92.058 5.873-.045.668-.699 1.054-1.43.956-.669-.09-1.144-.501-1.162-1.134l.003-.14c.054-.745-.228-.883-.73-.88l-.21.007-.115.008a75.2 75.2 0 01-9.082.065l-1.338-.08c-.45-.016-.732.056-.737.638l.003.133c.047.873-.495 1.421-1.388 1.381-.896-.04-1.29-.638-1.288-1.506.004-1.52.005-3.041.002-4.562 0-.496.072-1.015.414-1.353.753-.742 1.121-1.654 1.432-2.59l.272-.844c.091-.282.186-.561.293-.835.708-1.81 1.374-2.316 3.33-2.32l3.147-.001c1.087 0 2.173-.01 3.26.003zm2.443 7.364c-.61-.043-1.011.295-1.068.886-.063.655.316 1.064.972 1.091.61.025.935-.36 1.052-1.005-.08-.507-.342-.928-.956-.972zm-11.57-.002c-.605.012-.985.38-.983.977.003.652.406 1.036 1.068 1 .605-.035.905-.437.95-1.035-.101-.576-.423-.954-1.034-.942zm7.758-.001l-.636-.01-1.273.003c-.674-.001-1.347-.007-2.02.001-.431.006-.74.212-.747.654-.007.443.296.667.727.67 1.31.01 2.62.015 3.93-.003.43-.006.716-.26.691-.703-.021-.38-.292-.6-.672-.612zm1.027-6.041l-.775.002-1.5.01-3.37.003-.352.006-.311.012-.273.022c-.767.083-1.08.332-1.406 1.082l-.11.27-.175.48-.2.578-.127.307c-.216.7-.029.984.68.921l.138-.015a34.246 34.246 0 017.58-.207l1.492.147.26.02.227.009c.635.014.848-.123.79-.598l-.03-.172-.048-.198-.066-.226-.129-.395-.221-.64c-.36-1.031-1.022-1.418-2.074-1.418z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDrivingcar = React.memo(SvgDrivingcar);
export default MemoSvgDrivingcar;
