import * as React from 'react';

const SvgYoutubeWhite = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        // fillRule="evenodd"
        clipRule="evenodd"
        d="M16.294 8.843V3.031s0-2.801-2.782-2.801H2.93S.15.23.15 3.03v5.812s0 2.801 2.781 2.801h10.582s2.782 0 2.782-2.8zm-4.938-2.898L6.07 9.071V2.819l5.286 3.126z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgYoutubeWhite = React.memo(SvgYoutubeWhite);
export default MemoSvgYoutubeWhite;
