import * as React from 'react';

const SvgLogout = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.569 4a1.57 1.57 0 00-1.183.609A1.833 1.833 0 004 5.799V18.2c-.013.424.117.851.384 1.19A1.57 1.57 0 005.57 20h7.362l.043-.001a1.571 1.571 0 001.14-.607c.268-.34.398-.767.385-1.19v-2.095a.75.75 0 00-1.5 0v2.137a.337.337 0 01-.062.218.134.134 0 01-.04.035l-.005.003H5.608l-.006-.003a.135.135 0 01-.04-.035.338.338 0 01-.062-.218V5.786v-.03a.337.337 0 01.063-.218.134.134 0 01.039-.035l.005-.003h7.285l.006.003a.133.133 0 01.04.035.337.337 0 01.062.218v2.137a.75.75 0 001.5 0V5.799a1.834 1.834 0 00-.386-1.19A1.571 1.571 0 0012.931 4H5.569zm10.768 5.07a.75.75 0 011.06 0l2.433 2.433a.748.748 0 01-.002 1.062l-2.43 2.432a.75.75 0 01-1.061-1.061l1.152-1.153H9.081a.75.75 0 010-1.5h8.408l-1.152-1.153a.75.75 0 010-1.06z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLogout = React.memo(SvgLogout);
export default MemoSvgLogout;
