import * as React from 'react';

const SvgDoubleArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.785 5.356a.75.75 0 01.137.97l-.067.088-5.21 5.952 5.989 5.242a.75.75 0 01-.9 1.195l-.088-.066L4.093 13a.75.75 0 01-.137-.97l.067-.088 5.704-6.516a.75.75 0 011.058-.07zm7 0a.75.75 0 01.137.97l-.067.088-5.21 5.952 5.989 5.242a.75.75 0 01-.9 1.195l-.089-.066L11.093 13a.75.75 0 01-.137-.97l.067-.088 5.704-6.516a.75.75 0 011.058-.07z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgDoubleArrow = React.memo(SvgDoubleArrow);
export default MemoSvgDoubleArrow;
