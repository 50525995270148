import styles from './template.module.scss';
import React from 'react';
import env from '@y2/environments';
import { goToLogin } from '@y2/auth-common/helpers';

export type Props = {
  title: string;
  subTitle?: string;
  illustration: React.ReactNode;
  info?: string;
  buttonText?: string;
  statusCode?: number;
  action?: ButtonActions;
  customAction?: () => void;
};

const getWithLines = (text: string) =>
  text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

const getContinueParam = () =>
  new URLSearchParams(window.location.search).get('continue');

const getRedirectURL = () => getContinueParam() || env.routes.mainSite;

const navigateToLogin = () => {
  goToLogin(false, '', encodeURIComponent(getRedirectURL()));
};

type ButtonActions = 'refreshOnClick' | 'loginOnClick' | 'redirectOnClick';

const onButtonClick = (action?: ButtonActions): void => {
  switch (action) {
    case 'refreshOnClick':
      return window.location.reload();
    case 'loginOnClick':
      return navigateToLogin();
    case 'redirectOnClick':
      return window.location.assign(getRedirectURL());
    default:
      return window.location.assign(env.routes.mainSite);
  }
};

export const ErrorSection: React.VFC<Props> = ({
  title,
  subTitle,
  illustration,
  info,
  buttonText,
  statusCode,
  action,
  customAction,
}) => (
  <section className={styles.section} data-testid="error-section">
    <div className={styles.content}>
      {illustration}
      <h2 className={styles.title} data-testid="error-title">
        {title}
      </h2>
      {subTitle && (
        <p className={styles.subTitle} data-testid="error-subtitle">
          {getWithLines(subTitle)}
        </p>
      )}
      {buttonText && (
        <button
          onClick={() =>
            customAction ? customAction() : onButtonClick(action)
          }
          className={styles.button}
          data-testid="error-button"
        >
          {buttonText}
        </button>
      )}
      {info && (
        <p className={styles.info} data-testid="error-info">
          {getWithLines(info)}
        </p>
      )}
    </div>
    {statusCode && (
      <span className={styles.statusCode} data-testid="error-code">
        {statusCode}
      </span>
    )}
  </section>
);
