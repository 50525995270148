import React from 'react';
import cn from 'classnames';
import styles from './logo.module.scss';
import texts from './logo.text';

type Props = {
  imageToUse:
    | 'yad2'
    | 'yad2white'
    | 'yad1'
    | 'inverted'
    | 'yad2Plus'
    | 'drushim';
  linkAddress?: string;
  isSmall?: boolean;
  className?: string;
  openLinkInNewTab?: boolean;
};

const defaultLogosBasePath = 'https://assets.yad2.co.il';
const bigLogoWidth = 74;
const smallLogoWidth = 50;

const logoImages: {
  [key in Props['imageToUse']]: { src: string; altText: string };
} = {
  yad2: {
    src: `${defaultLogosBasePath}/common/logo-default.svg`,
    altText: texts.LOGO_IMG_ALT_Y2,
  },
  yad2white: {
    src: `${defaultLogosBasePath}/common/logo-white.svg`,
    altText: texts.LOGO_IMG_ALT_Y2,
  },
  inverted: {
    src: `${defaultLogosBasePath}/common/logo-orange.svg`,
    altText: texts.LOGO_IMG_ALT_Y2,
  },
  yad1: {
    src: `${defaultLogosBasePath}/common/logo-yad1.svg`,
    altText: texts.LOGO_IMG_ALT_Y1,
  },
  yad2Plus: {
    src: 'https://plus.yad2.co.il/assets/images/logo-yad2+.svg',
    altText: texts.LOGO_IMG_ALT_Y2,
  },
  drushim: {
    // temp
    src: 'https://www.drushim.co.il/images/logo.png',
    altText: texts.LOGO_IMG_ALT_Y2,
  },
};

const getImgWidth = (isSmall: boolean) =>
  isSmall ? smallLogoWidth : bigLogoWidth;

const Logo: React.VFC<Props> = ({
  className,
  imageToUse = 'yad2',
  linkAddress = '/',
  isSmall = false,
  openLinkInNewTab,
}) => {
  const logoImage = logoImages[imageToUse];

  return (
    <a
      href={linkAddress}
      className={cn(styles['logo'], className)}
      target={openLinkInNewTab ? '_blank' : '_self'}
    >
      <img
        src={logoImage.src}
        alt={logoImage.altText}
        width={getImgWidth(isSmall)}
      />
    </a>
  );
};

export default Logo;
