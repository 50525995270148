import * as React from 'react';
const SvgShield = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.265 3.712l-5.012-1.671a.8.8 0 00-.506 0L6.735 3.712A3.994 3.994 0 004 7.507V11.6c0 6.05 7.36 9.392 7.675 9.531a.799.799 0 00.65 0c.315-.139 7.675-3.48 7.675-9.53V7.506a3.994 3.994 0 00-2.735-3.795zM18.4 11.6c0 4.364-5.055 7.227-6.4 7.912-1.346-.683-6.4-3.537-6.4-7.912V7.507A2.4 2.4 0 017.242 5.23L12 3.643l4.758 1.587A2.4 2.4 0 0118.4 7.507V11.6z"
        fill="currentColor"
      />
      <path
        d="M14.64 8.64L11.29 12l-1.796-1.872a.801.801 0 10-1.152 1.11l1.844 1.92a1.498 1.498 0 001.076.48h.027a1.498 1.498 0 001.068-.443l3.417-3.418a.801.801 0 00-.259-1.31.803.803 0 00-.875.173z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgShield = React.memo(SvgShield);
export default MemoSvgShield;
