import styles from './category-content.module.scss';
import { ChevronLeftIcon } from '@y2/mango/components/icons';
import texts from './category-content.text.json';
import { getCollectionLink } from '../../find-collection';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';
import { SubCategoryList } from '../sub-category-list';
import cn from 'classnames';

type Props = {
  headerUrl: string;
  title: string;
  isVisible: boolean;
  subItems?: MenuItem[];
};

export const CategoryContent = ({
  headerUrl,
  title,
  subItems,
  isVisible,
}: Props) => {
  const items = Array.isArray(subItems) ? subItems : [];

  return (
    <ul className={cn(styles.content, isVisible && styles.visible)}>
      <li className={styles.headerWrapper}>
        <a href={headerUrl} className={styles.header}>
          <strong>
            {texts.forAll} {title}
          </strong>
          <ChevronLeftIcon className={styles.icon} />
        </a>
      </li>
      {items.map((subItem) => (
        <li key={subItem.id} className={styles.subCategoryItem}>
          <SubCategoryList
            url={getCollectionLink(subItem)}
            title={subItem.title || ''}
            subItems={subItem.items}
          />
        </li>
      ))}
    </ul>
  );
};
