import * as React from 'react';

const SvgArrowPointer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M0.404306 1.44636C6.81117 4.51051 9.96468 12.6518 7.3315 19.2374C6.95308 20.1782 8.47727 20.5881 8.85044 19.6578C11.0894 14.0551 9.42858 7.32764 5.28698 3.04413C4.11493 1.83003 2.72739 0.815657 1.19794 0.0850955C0.288677 -0.351139 -0.51021 1.01012 0.404306 1.44636Z"
        fill="currentColor"
      />
      <path
        d="M3.65222 13.5137L7.55205 21.7654C7.75178 22.1859 8.49285 22.312 8.79243 21.9231C10.8632 19.2531 12.934 16.5779 15.0048 13.9079C15.2676 13.5716 15.3307 13.1248 15.0048 12.7937C14.7368 12.5204 14.1481 12.4573 13.8906 12.7937C11.8198 15.4637 9.749 18.1389 7.67819 20.8088L8.91857 20.9665C7.62038 18.2177 6.32219 15.4637 5.01874 12.7149C4.58776 11.8003 3.2265 12.5992 3.65748 13.5085L3.65222 13.5137Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgArrowPointer = React.memo(SvgArrowPointer);
export default MemoSvgArrowPointer;
