import * as React from 'react';

const SvgEnlarge = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.743 3.102a.75.75 0 00-.75-.75H4.047l-.143.006a1.75 1.75 0 00-1.607 1.744v9.94l.007.102a.75.75 0 001.493-.101v-9.94l.007-.058a.25.25 0 01.243-.193h9.946l.102-.006a.75.75 0 00.648-.744zM9.297 21.043c0 .414.336.75.75.75h9.946l.144-.006a1.75 1.75 0 001.606-1.744v-9.94L21.736 10a.75.75 0 00-1.493.101v9.94l-.006.058a.25.25 0 01-.244.192h-9.946l-.101.007a.75.75 0 00-.649.744z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgEnlarge = React.memo(SvgEnlarge);
export default MemoSvgEnlarge;
