import * as React from 'react';
const SvgPosition = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.485 4.81c-.44-1.427.897-2.764 2.325-2.325l15.477 4.762c1.604.494 1.785 2.691.285 3.442a.887.887 0 01-.163.062l-7.592 2.066-2.066 7.592a.884.884 0 01-.063.163c-.75 1.5-2.948 1.319-3.441-.285L2.485 4.81zm1.68-.571a.09.09 0 00.004.053l4.762 15.476c.01.034.023.045.03.05a.11.11 0 00.056.02.108.108 0 00.058-.01.058.058 0 00.01-.006l2.07-7.61a.883.883 0 01.062-.163c.18-.36.472-.652.832-.832a.883.883 0 01.163-.062l7.61-2.07a.06.06 0 00.005-.01.108.108 0 00.01-.058.108.108 0 00-.02-.056c-.004-.007-.015-.02-.049-.03L4.291 4.17a.09.09 0 00-.052-.003.105.105 0 00-.046.028.105.105 0 00-.027.045z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgPosition = React.memo(SvgPosition);
export default MemoSvgPosition;
