import * as React from 'react';
const SvgSignature = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.852 12.552l-.141.141-.052.194-.854 3.163c-.352 1.303.842 2.497 2.145 2.146l3.164-.854.193-.052.142-.142L17.627 6.97a3.25 3.25 0 10-4.597-4.596L2.852 12.552zm.4 3.889l.803-2.97.319-.32 2.474 2.476-.319.319-2.97.801a.25.25 0 01-.306-.306zm4.657-1.875l-2.475-2.475 6.94-6.94 2.474 2.476-6.939 6.94zm8-8l.657-.657a1.75 1.75 0 00-2.475-2.475l-.657.657 2.475 2.475zM12.03 18.867c.982-.982 1.574-1.288 1.896-1.345a.41.41 0 01.27.027c.075.036.172.113.294.267.199.254.36.571.562.967.072.14.148.292.234.453.285.54.68 1.218 1.31 1.62.693.442 1.411.502 2.078.328.635-.166 1.201-.537 1.673-.918.457-.37.877-.795 1.222-1.143l.042-.042c.388-.392.627-.623.78-.717a.75.75 0 00-.783-1.28c-.335.206-.708.583-1.032.91l-.03.031-.023.023c-.359.362-.728.735-1.12 1.052-.403.326-.774.545-1.108.633a1.051 1.051 0 01-.892-.14c-.263-.169-.503-.512-.791-1.058-.054-.101-.11-.213-.17-.33-.217-.428-.474-.934-.773-1.315a2.381 2.381 0 00-.812-.688 1.904 1.904 0 00-1.192-.157c-.801.142-1.67.738-2.681 1.747-1.21 1.088-2.081 1.746-2.891 1.963-.71.19-1.494.064-2.624-.84a.75.75 0 00-.938 1.171c1.37 1.096 2.636 1.47 3.95 1.118 1.21-.324 2.336-1.243 3.52-2.31l.015-.013.014-.014z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgSignature = React.memo(SvgSignature);
export default MemoSvgSignature;
