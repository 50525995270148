import * as React from 'react';

const SvgUnmute = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 20v-7.95l-1.5 1.58v5.004l-2.962-1.886-1.053 1.108 4.362 2.777.09.049A.75.75 0 0017.75 20zm0-16l-.007-.102a.75.75 0 00-1.145-.531l-6.567 4.165a1.63 1.63 0 00-.92-.282H5.889c-.905 0-1.638.733-1.638 1.638v5.224c0 .905.733 1.638 1.638 1.638h3.038l-4.019 4.23a.75.75 0 001.088 1.034L21.162 5.046a.75.75 0 00-1.087-1.033L17.75 6.46V4zm-1.5 4.04V5.362l-5.5 3.489v4.977l5.5-5.79zm-10.362.71h3.224c.076 0 .138.062.138.138v5.224a.138.138 0 01-.138.138H5.888a.138.138 0 01-.138-.138V8.888c0-.076.062-.138.138-.138z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgUnmute = React.memo(SvgUnmute);
export default MemoSvgUnmute;
