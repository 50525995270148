import * as React from 'react';

const SvgStats = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-.575 2.855a2.997 2.997 0 01-1.85 0l-2.814 4.173a3 3 0 11-1.237-.848l2.785-4.13a3 3 0 114.382 0l2.785 4.13a2.993 2.993 0 012.048 0l2.785-4.13a3 3 0 111.266.805l-2.814 4.173a3 3 0 11-4.521 0l-2.815-4.173zM3 18.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm12 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6-9a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgStats = React.memo(SvgStats);
export default MemoSvgStats;
