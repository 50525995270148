import env from '@y2/environments';
import * as portsData from './ssr-services-ports.json';

type ServicesPorts = { [key: string]: number };

const ports: ServicesPorts = portsData as ServicesPorts;
const isLocal = env.name === 'local';
const isBrowser = typeof window !== 'undefined';

const getLastChar = (str: string) =>
  str ? String(str).charAt(str.length - 1) : '';

const joinServicePath = (basePath: string, serviceName: string) =>
  getLastChar(basePath) !== '/'
    ? `${basePath}/${serviceName}`
    : `${basePath}${serviceName}`;

const getClusterPath = () => {
  if (!env.routes.api.gwInClusterForSSR) {
    throw Error(
      'missing NEXT_PUBLIC_CLUSTER_GW in the environment, needed to fetch requests in cluster for SSR phase',
    );
  }

  return env.routes.api.gwInClusterForSSR;
};

const getPort = (serviceName: string) => {
  const port = ports[serviceName];
  if (!port) {
    throw Error(
      `missing port for the service: ${serviceName}, check ssr-services-ports.json & the service port in y2 repo`,
    );
  }

  return port;
};

const isDrushim = (basePath: string) => basePath === env.routes.api.gwDrushim;

export const getWithSSRSupport = (basePath: string, serviceName: string) => {
  const directToDrushim = isDrushim(basePath);
  const port = getPort(
    directToDrushim ? `drushim-${serviceName}` : serviceName,
  );
  if (isBrowser || isLocal || directToDrushim) {
    return joinServicePath(basePath, serviceName);
  }

  const cluster = getClusterPath();

  return `http://${serviceName}-svc.${cluster}:${port}`;
};
