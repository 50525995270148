import * as React from 'react';

const SvgExclusive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.486 1.75h-.472a.77.77 0 00-.027 0H6.98a1 1 0 00-.806.408L1.697 8.249a1 1 0 00.032 1.227l9.498 11.581a1 1 0 001.546 0L22.27 9.476a1 1 0 00.033-1.227l-4.477-6.091a1 1 0 00-.806-.408h-4.507a.77.77 0 00-.027 0zm-.236 1.81L9.66 8h5.18l-2.59-4.44zm2.662 5.94H9.588l2.662 8.745L14.912 9.5zm-.694 7.429L16.48 9.5h3.832l-6.093 7.429zM16.576 8l-2.77-4.75h2.961L20.258 8h-3.682zM3.741 8h4.182l2.771-4.75H7.233L3.74 8zm6.835 9.898L8.02 9.5H3.69l6.886 8.398z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgExclusive = React.memo(SvgExclusive);
export default MemoSvgExclusive;
