import * as React from 'react';

const SvgBluetooth = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 7.5l10.531 8.617a.5.5 0 01.004.77l-4.715 3.93a.5.5 0 01-.82-.384V4a.5.5 0 01.8-.4l4.703 3.527a.5.5 0 01.027.779L6 17"
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};

const MemoSvgBluetooth = React.memo(SvgBluetooth);
export default MemoSvgBluetooth;
