import * as React from 'react';

const SvgExclusiveCar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6 2L15 4.1l-1.4 2.1-1.5-2.1L13.6 2zM19 3.9l1.5 2.2L19 8.2l-1.6-2.1L19 3.9zm-11.1 4L5.8 5.1l-2 2.8 2 2.8 2.1-2.8zM21.7 14c-.3-.3-.6-.6-1-.7l-.6-.2-.8-1.9c-.2-.5-.6-1-1.1-1.3-.5-.3-1.1-.5-1.7-.5h-4.9c-.7 0-1.4.3-2 .7L7 12.6c-.3.2-.6.4-1 .4l-2.4.4c-.5.1-.9.3-1.2.7-.2.4-.4.8-.4 1.3l-.1 2c0 .5.2 1 .5 1.4.4.4.8.6 1.4.6h1.4c.3 1.3 1.4 2.2 2.7 2.2 1.3 0 2.4-.9 2.7-2.2H14c.3 1.3 1.4 2.2 2.7 2.2 1.3 0 2.4-.9 2.7-2.2h.7c.5 0 .9-.2 1.3-.6.4-.4.6-.9.6-1.4v-2.2c0-.5 0-.8-.3-1.2zm-6-3.1h.9c.3 0 .6.1.8.3.2.2.4.4.6.7l.6 1.4h-2.9v-2.4zm-4 0c-.4 0-.7.2-1 .4l-2.2 1.9h5.7v-2.3h-2.5zm-3.8 9.2c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zm7.5-1.3c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3zm5.15-1.2a.773.773 0 00-.05.2c0 .1-.2.1-.4.1h-.8c-.4-1-1.4-1.8-2.6-1.8-1.2 0-2.2.7-2.6 1.8h-3.7c-.4-1-1.4-1.8-2.6-1.8-1.2 0-2.2.7-2.6 1.8H3.9c-.1 0-.3-.1-.3-.2-.1-.1-.1-.2-.1-.4l.1-2c0-.05.025-.1.05-.15.025-.05.05-.1.05-.15 0 0 .1 0 .2-.1l1.5-.2h14.8c.1 0 .2.1.3.2.1.1.1.2.1.3v2.2c0 .05-.025.125-.05.2z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgExclusiveCar = React.memo(SvgExclusiveCar);
export default MemoSvgExclusiveCar;
