import * as React from 'react';

const SvgLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.923 6.363a.85.85 0 010 1.7h-.567a3.719 3.719 0 000 7.437h.567a.85.85 0 010 1.7h-.567a5.419 5.419 0 110-10.837h.567zm5.277.85c0-.47.38-.85.85-.85h.531a5.419 5.419 0 010 10.837h-.531a.85.85 0 110-1.7h.531a3.719 3.719 0 000-7.437h-.531a.85.85 0 01-.85-.85zm-6.925 4.552c0-.47.38-.85.85-.85h7.652a.85.85 0 010 1.7H8.125a.85.85 0 01-.85-.85z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgLink = React.memo(SvgLink);
export default MemoSvgLink;
