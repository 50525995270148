import {
  ResponsiveHeaderGrid,
  MenuListItems,
  Logo,
  menuData,
} from '@y2/header';
import env from '@y2/environments';
import { ReactNode } from 'react';

type Props = { userDrop?: ReactNode };

export const Header = ({ userDrop = null }: Props) => {
  return (
    <ResponsiveHeaderGrid
      data-testid="my-header"
      logoSlot={<Logo imageToUse={'yad2'} linkAddress={env.routes.mainSite} />}
      mobileLogoSlot={
        <Logo imageToUse={'inverted'} linkAddress={env.routes.mainSite} />
      }
      userDropSlot={userDrop}
      mainMenuSlot={<MenuListItems list={menuData} isInSubDomain />}
    />
  );
};
