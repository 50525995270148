/* eslint-disable custom-rules/user-agent */
import './types';

export const UA_PREFIXES = {
  REACT_NATIVE_APP: 'RN_APP',
  LEGACY_APP: 'RESPONSIVE_MOBILE_APP_',
  ANDROID_TEMP_APP: 'RESPONSIVE_MOBILE_APP_ANDROID',
} as const;

type UaPrefix = keyof typeof UA_PREFIXES;

const getUserAgentByWindow = () =>
  typeof window !== 'undefined' ? window.navigator.userAgent : '';

export const isWebView = (
  appType: UaPrefix = 'REACT_NATIVE_APP',
  customUserAgent?: string,
): boolean => {
  const userAgent = customUserAgent || getUserAgentByWindow();
  const userAgentPrefix = UA_PREFIXES[appType];

  return userAgent.includes(userAgentPrefix);
};
