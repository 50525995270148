/* eslint-disable @typescript-eslint/no-explicit-any, no-param-reassign, no-console */
import { captureException } from '@sentry/nextjs';
import {
  SENTRY_LOG_PREFIX,
  SERVER_COLORS,
  UNEXPECTED_SERVER_ERROR,
  Color,
} from './constants';
// eslint-disable-next-line custom-rules/api-clients-lib
import axios from 'axios';
import env from '@y2/environments';

const isDev = env.name !== 'prod';

export const safeConsole = {
  log: function (...args: any) {
    if (isDev) {
      console.log(...args);
    }
  },
  error: function (...args: any) {
    if (isDev) {
      console.error(...args);
    }
  },
};

export const prepareErrorForSentry = (
  errorArgs: any,
  prefixMessage: string,
) => {
  const prefix = `${SENTRY_LOG_PREFIX}${prefixMessage} `;
  if (errorArgs instanceof Error) {
    errorArgs.message = `${prefix}${errorArgs.message}`;
  } else if (errorArgs?.message) {
    errorArgs.message = `${prefix}${errorArgs.message}`;
  }

  return errorArgs;
};

export const isWhiteListedError = (error: any) => {
  if (axios.isAxiosError(error)) {
    return error?.response?.status === UNEXPECTED_SERVER_ERROR;
  }

  return true;
};

export const log = {
  error: (error: Error | any, prefixMessage: string) => {
    const preparedError = prepareErrorForSentry(error, prefixMessage);
    const isWhiteListed = isWhiteListedError(error);
    if (preparedError && isWhiteListed) {
      captureException(preparedError);
    }

    safeConsole.error(error);
  },
  info: (msg: string, color?: Color) => {
    if (!color) {
      return safeConsole.log(msg);
    }

    if (typeof window !== 'undefined') {
      safeConsole.log(msg, `color: ${color};`);
    } else {
      safeConsole.log(SERVER_COLORS[color], msg);
    }
  },
};
