import styles from './mobile-item.module.scss';
import { ChevronLeftIcon } from '@y2/mango/components/icons';
import texts from './mobile-item.text.json';
import { recommerceCollectionUrl } from '../../const';
import { MenuItem } from '@y2/api-clients/recommerce-feed.types';
import cn from 'classnames';

type Props = {
  text: string;
  url: string;
  onSubMenuClick: () => void;
  shouldShowImage: boolean;
  image?: string;
  subItems?: MenuItem[];
  hasBorder?: boolean;
};

type ButtonAttributes = React.HTMLAttributes<HTMLButtonElement> &
  React.HTMLAttributes<HTMLAnchorElement> & { href?: string };

const ConvertibleButton = (props: ButtonAttributes) => {
  return props.href ? (
    <a {...props}>{props.children}</a>
  ) : (
    <button {...props}>{props.children}</button>
  );
};

export const SubMenuItem = ({
  shouldShowImage,
  image,
  text,
  subItems,
  url,
  onSubMenuClick,
  hasBorder,
}: Props) => {
  const isLink = !subItems?.length;
  const buttonAttribute = isLink
    ? {
        href: url,
        'aria-label': `${texts.openCategoryLink} ${text}`,
      }
    : {
        onClick: onSubMenuClick,
        'aria-label': texts.openMenu,
      };

  return (
    <ConvertibleButton
      className={cn(styles.item, hasBorder && styles.border)}
      {...buttonAttribute}
    >
      {image && shouldShowImage && (
        <span
          className={styles.image}
          role="img"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      )}
      <span className={styles.text}>{text}</span>
      {!isLink ? <ChevronLeftIcon className={styles.icon} /> : null}
    </ConvertibleButton>
  );
};
