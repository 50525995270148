import * as React from 'react';
const SvgCheckedGreenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.874 15.196l7.93-8.733a1.25 1.25 0 111.85 1.68l-8.306 9.148a2 2 0 01-2.985-.026l-4.032-4.602a1.25 1.25 0 111.88-1.648l3.663 4.181z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgCheckedGreenIcon = React.memo(SvgCheckedGreenIcon);
export default MemoSvgCheckedGreenIcon;
