import React from 'react';
import Script from 'next/script';
import { TEST_AD_BLOCK_SCRIPT_PATH } from './constants';

export const getAdBlockStatus = () => ({
  AdBlockStatus: window?.isBlockerDetected ? 'true' : 'false',
});

export const AdBlockStatusScript: React.VFC = () => {
  const antiCacheChunk = new Date().getMinutes();

  return (
    <Script
      id="ad-block-status-test"
      src={`${TEST_AD_BLOCK_SCRIPT_PATH}${antiCacheChunk}`}
      onError={() => (window.isBlockerDetected = true)}
    />
  );
};
