export const getInlineScriptSnippet = (
  sitekey: string,
  integrityKey: string,
) => `
window.interdeal = {
    "sitekey": '${sitekey}',
    "Position": "left",
    "domains": {
        "js": "https://js.nagich.co.il/",
        "acc": "https://access.nagich.co.il/"
    },
    "Menulang": "HE",
    "btnStyle": {
        "vPosition": [
            "20%",
            "80%"
        ],
        "scale": [
            "0.4",
            "0.5"
        ],
        "color": {
            "main": "#ff7002",
            "second": "#ffffff"
        },
        "icon": {
            "outline": true,
            "type": 11,
            "shape": "circle"
        }
    }
};
(function(doc, head, body){
    var coreCall             = doc.createElement('script');
    coreCall.src             = interdeal.domains.js + 'core/5.0.10/accessibility.js';
    coreCall.defer           = true;
    coreCall.integrity       = '${integrityKey}';
    coreCall.crossOrigin     = 'anonymous';
    coreCall.setAttribute('data-cfasync', true );
    body? body.appendChild(coreCall) : head.appendChild(coreCall);
})(document, document.head, document.body);
`;
