import * as React from 'react';

const SvgBusinessRe = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2 4.31v16.11h2v-3.5a1 1 0 011-1h4a1 1 0 011 1v3.5h2V3.54l-10 .77zm6.5 13.11h-3v3h3v-3zm-6.5 4.5h17V10.787a1 1 0 00-.858-.99L15.7 9.135V3a1 1 0 00-1.076-.997l-11 .846a1 1 0 00-.924.997V21.92h1.5zm11.5-1.5v-9.77l4 .571v9.2h-4zM6.2 11.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-2zm.5-5.58a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-.5zm4 5.58a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-2zm.5-5.58a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgBusinessRe = React.memo(SvgBusinessRe);
export default MemoSvgBusinessRe;
