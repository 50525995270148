import * as React from 'react';

const SvgTarget = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.926 1.25a.75.75 0 01.743.648l.007.102v2.028a8.003 8.003 0 017.265 6.994H22a.75.75 0 01.102 1.493l-.102.007h-2.017a8.001 8.001 0 01-7.306 7.45v1.7a.75.75 0 01-1.494.102l-.007-.102v-1.714a8.002 8.002 0 01-7.16-7.435H2a.75.75 0 01-.102-1.494L2 11.022h2.06a8.003 8.003 0 017.116-6.98V2a.75.75 0 01.75-.75zm.75 4.285V8a.75.75 0 01-1.493.102L11.176 8V5.552a6.504 6.504 0 00-5.603 5.47H8a.75.75 0 01.102 1.493L8 12.522H5.52a6.502 6.502 0 005.656 5.926v-2.776a.75.75 0 011.493-.102l.007.102v2.793a6.502 6.502 0 005.803-5.943H16a.75.75 0 01-.102-1.493l.102-.007h2.427a6.503 6.503 0 00-5.75-5.487z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgTarget = React.memo(SvgTarget);
export default MemoSvgTarget;
