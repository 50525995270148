import * as React from 'react';

const SvgBoiler = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.642 1.981a.75.75 0 01.654.739l-.006.101-.1.775a.75.75 0 01-1.495-.09l.007-.102.1-.775a.75.75 0 01.738-.654l.102.006zm4.307 2.283a.75.75 0 00-1.06.006l-.422.427-.072.084a.75.75 0 001.138.97l.422-.426.072-.085a.75.75 0 00-.078-.976zm-7.688 1.7c2.025-1.523 5.205-.845 6.08 1.247a.75.75 0 11-1.384.579c-.48-1.15-2.52-1.585-3.794-.627-1.1.827-1.243 2.22-.648 3.127l.082.115a.75.75 0 11-1.192.911c-1.17-1.53-.993-3.96.856-5.351zm13.905 4.716A2 2 0 0018.192 9H9.778a2 2 0 00-1.975 1.685l-1.434 9A2 2 0 008.344 22H19.65a2 2 0 001.974-2.32l-1.458-9zm-5.416-.18h3.442l.09.008a.5.5 0 01.403.412l.54 3.33H14.75V10.5zm-1.5 0H9.778a.5.5 0 00-.472.334l-.022.087-.53 3.33h4.496V10.5zm0 5.25H8.515l-.665 4.171a.5.5 0 00.404.571l.09.008h4.906v-4.75zm1.5 4.75h4.9a.499.499 0 00.5-.49l-.007-.09-.675-4.17H14.75v4.75zM4.455 2.954a.75.75 0 011.037.223l.392.607.054.098a.75.75 0 01-1.314.716l-.392-.607-.054-.098a.75.75 0 01.277-.94zM2.659 7.983a.75.75 0 01-.102-1.493l.102-.007h.707a.75.75 0 01.102 1.493l-.102.007h-.707zm-.34 3.646a.75.75 0 00.98.407l.6-.248.09-.045a.75.75 0 00-.664-1.34l-.599.247-.091.045a.75.75 0 00-.316.934z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgBoiler = React.memo(SvgBoiler);
export default MemoSvgBoiler;
