import * as React from 'react';

const SvgVideo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.05 7.25c.966 0 1.75.784 1.75 1.75v1.347l2.756-2.411a.75.75 0 011.237.46l.007.104v7a.75.75 0 01-1.244.564L16.8 13.653V15a1.75 1.75 0 01-1.607 1.744l-.143.006h-10A1.75 1.75 0 013.3 15V9c0-.966.783-1.75 1.75-1.75h10zm0 1.5h-10A.25.25 0 004.8 9v6c0 .138.112.25.25.25h10a.25.25 0 00.25-.25V9a.25.25 0 00-.25-.25zm4.25 1.403L17.188 12l2.112 1.848v-3.695z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgVideo = React.memo(SvgVideo);
export default MemoSvgVideo;
