import React from 'react';
import styles from './categories-list.module.scss';
import CategoryItem from '../category-item/category-item';
import { Category } from '../types';

export interface Props {
  categoriesList: Category[];
}

export const CategoriesList: React.VFC<Props> = ({ categoriesList }) => {
  return (
    <nav className={styles.nav} data-nagish="footer-categories-links-menu">
      <ul
        className={styles.categoriesList}
        data-nagish="footer-categories-list"
      >
        {categoriesList.map(({ title, subCategories }) => (
          <CategoryItem
            key={title}
            title={title}
            subCategories={subCategories}
          />
        ))}
      </ul>
    </nav>
  );
};
