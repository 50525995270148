/* eslint-disable custom-rules/use-current-color */
import * as React from 'react';
const SvgBitLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.09 40.256c-2.243 0-3.78-.743-4.702-2.273a6.146 6.146 0 01-.812-2.55v-6.2h4.878c1.055 0 1.91-.84 1.91-1.873 0-1.034-.855-1.872-1.91-1.872h-4.878v-5.616c0-1.034-.855-1.872-1.91-1.872-1.054 0-1.908.838-1.908 1.872v5.616h-.849c-1.055 0-1.909.838-1.909 1.872 0 1.034.854 1.872 1.91 1.872h.848v6.292c.006.217.094 2.183 1.242 4.192C39.118 41.67 41.429 44 46.09 44c1.056 0 1.91-.838 1.91-1.872 0-1.034-.854-1.872-1.91-1.872zM29 42.132c0 .513.147.954.44 1.319.294.366.647.549 1.06.549.412 0 .766-.183 1.06-.55.293-.364.44-.805.44-1.318V26.868c0-.514-.147-.953-.44-1.32-.294-.365-.648-.548-1.06-.548-.413 0-.766.183-1.06.549-.293.366-.44.805-.44 1.32v15.263zM25.342 28.173c-1.763-1.8-3.846-2.684-6.414-2.684h-5.45v-5.617c0-1.034-.82-1.872-1.829-1.872-1.01 0-1.828.838-1.828 1.872v5.617H7.803c-.496 0-.92.184-1.273.55-.353.366-.53.806-.53 1.32s.177.954.53 1.32c.353.366.777.55 1.274.55H9.82V35.522c.007.218.09 2.184 1.19 4.193C12.08 41.67 14.293 44 18.758 44c.039 0 .077-.004.116-.006h.054c2.493 0 4.651-.912 6.414-2.712C27.106 39.48 28 37.275 28 34.727c0-2.547-.894-4.753-2.658-6.554zm-2.586 10.463c-1.065 1.087-2.316 1.616-3.828 1.616-1.511 0-2.764-.529-3.829-1.617-1.064-1.087-1.582-2.365-1.582-3.908l-.04-1.17V29.229H19.49c.033.003.064.01.097.013 1.221.135 2.265.653 3.17 1.578 1.065 1.087 1.582 2.366 1.582 3.908 0 1.543-.517 2.822-1.582 3.909z"
        fill="currentColor"
      />
      <path
        d="M49.222 41.077l-1.22-2.773c-.041-.436.456-.317.881-.17l7.696 2.731c.425.147.59.548.201.768l-7.393 4.137c-.698.457-.838.184-.782-.39l.884-2.705c.066-.272.09-.398.887-.504l2.431-.414c.44-.095.431-.183-.02-.198l-2.996-.116c-.45-.014-.53-.052-.57-.366zM31.5 20a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
        fill="#E01A33"
      />
    </svg>
  );
};
const MemoSvgBitLogo = React.memo(SvgBitLogo);
export default MemoSvgBitLogo;
