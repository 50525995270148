import * as React from 'react';
const SvgRocket = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.676 15.606h.001l.309-.172.003.048a.94.94 0 01.005.078v.772c.001.945.002 1.89-.001 2.835-.002.326.12.571.42.708.272.125.551.057.798-.19l.505-.502.001-.002c.562-.56 1.124-1.119 1.68-1.683.939-.953 1.404-2.103 1.408-3.439v-.096a92.19 92.19 0 00-.002-1.45.268.268 0 01.124-.247c.21-.155.415-.318.62-.481l.14-.113c1.485-1.174 2.572-2.625 3.133-4.453.657-2.146.79-4.353.792-6.576 0-.359-.31-.642-.677-.642-1.979.001-3.948.113-5.875.606-1.387.354-2.666.93-3.752 1.889-.724.638-1.338 1.372-1.915 2.14-.093.124-.184.184-.35.18-.529-.013-1.059-.013-1.588-.003-1.263.023-2.361.464-3.269 1.344-.49.474-.971.958-1.453 1.442-.266.267-.531.535-.799.8-.23.23-.323.493-.193.799.128.303.378.427.704.425.485-.003.97-.002 1.455-.002h2.281l-.043.083-.066.122a75.796 75.796 0 00-1.517 2.83c-.202.4-.162.646.157.964 1.098 1.1 2.196 2.198 3.295 3.295.305.305.56.348.947.146.696-.363 1.39-.732 2.082-1.102.213-.114.424-.232.64-.353zm8.039-9.525c.302-1.294.444-2.607.52-3.932l-.003-.176c-.003-.135-.006-.27.007-.402.016-.168-.04-.2-.2-.194-1.374.046-2.743.14-4.092.43-1.196.258-2.334.66-3.33 1.4-.854.634-1.529 1.441-2.156 2.288-1.702 2.298-3.099 4.781-4.437 7.3-.062.118-.022.175.056.252.832.828 1.663 1.657 2.49 2.492.092.094.157.103.272.04.334-.186.67-.368 1.006-.55.5-.27.998-.54 1.491-.82 1.87-1.066 3.696-2.198 5.397-3.527 1.53-1.195 2.537-2.708 2.979-4.601zm-4.307 7.27c.14 1.31-.17 2.453-1.164 3.364-.2.183-.389.377-.577.572-.085.087-.17.175-.256.26-.056-.021-.05-.061-.044-.098a.2.2 0 00.003-.03l-.001-1.317V16.1c0-.44 0-.879-.002-1.318 0-.096.008-.167.107-.227.495-.303.986-.61 1.482-.92l.001-.001.45-.282zM3.413 7.868l-.374.38.678-.001c.74 0 1.463 0 2.186.002.09 0 .12-.05.153-.106l.016-.026a1428.557 1428.557 0 00.977-1.56c.048-.076.098-.152.153-.233l.092-.139h-.289a19.577 19.577 0 00-.54 0 3.35 3.35 0 00-2.304.938c-.252.239-.495.487-.748.745z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.492 6.887a2.755 2.755 0 00-2.745-2.764 2.755 2.755 0 00-2.753 2.734A2.756 2.756 0 0013.74 9.62a2.756 2.756 0 002.753-2.734zm-1.375-.01a1.377 1.377 0 00-1.374-1.379c-.758 0-1.376.62-1.374 1.38a1.378 1.378 0 001.363 1.368 1.379 1.379 0 001.385-1.369zM.91 16.478c-.625 1.004-.832 2.127-.907 3.185-.017.7.251.98.85.951.969-.045 1.913-.221 2.798-.64.815-.385 1.458-.944 1.784-1.814.115-.309.06-.574-.17-.805a1931.89 1931.89 0 00-2.003-2.003c-.231-.23-.495-.285-.804-.17-.67.249-1.174.698-1.547 1.296zm.62 2.693l.03-.005c.36-.055.715-.134 1.06-.253.457-.157.878-.371 1.22-.727.114-.12.134-.195-.001-.323-.25-.235-.493-.48-.735-.724l-.285-.288c-.234-.234-.24-.24-.469.01-.578.627-.772 1.413-.904 2.224-.016.102.032.094.085.086z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgRocket = React.memo(SvgRocket);
export default MemoSvgRocket;
