import * as React from 'react';

const Svg360 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.791 4.254c0 1.244.992 2.256 2.209 2.256s2.209-1.012 2.209-2.256C14.209 3.012 13.217 2 12 2S9.791 3.012 9.791 4.254zm1.223.001A.996.996 0 0112 3.251c.545 0 .986.451.986 1.004A.996.996 0 0112 5.261a.996.996 0 01-.986-1.006zm2.372 14.864v-5.08c0-.346.273-.626.611-.626h.587V9.177c0-.063-.027-.124-.066-.15-.923-.612-1.505-.885-2.518-.885s-1.595.273-2.518.884c-.04.027-.066.088-.066.15v4.237h.587c.338 0 .611.28.611.625v5.081h2.772zm-1.997 2.256A.62.62 0 0012 22c4.335 0 9-.962 9-3.071 0-2.136-4.658-2.782-6.39-2.947v-1.319h.586c.337 0 .612-.28.612-.625V9.177c0-.493-.235-.941-.624-1.2C14.097 7.257 13.306 6.89 12 6.89c-1.307 0-2.098.366-3.184 1.086a1.43 1.43 0 00-.624 1.2v4.861c0 .345.275.625.612.625h.587v1.319C7.658 16.147 3 16.793 3 18.929c0 .582.361 1.09 1.074 1.516a.637.637 0 00-.259.674.615.615 0 00.595.474l.123-.012 1.623-.414c.051-.014.087-.04.076-.04H6.23l.082-.022.036-.025a.67.67 0 00.124-.123l.023-.026c.068-.093.107-.207.091-.241l.027-.095v-.06a.625.625 0 00-.097-.322l-.799-1.224a.611.611 0 00-.51-.277.595.595 0 00-.336.104.628.628 0 00-.272.495c-.3-.19-.376-.332-.376-.382 0-.357 1.49-1.336 5.168-1.691v2.507a.62.62 0 00.61.625h3.996a.62.62 0 00.612-.625v-2.507c3.678.355 5.168 1.334 5.168 1.69 0 .53-2.725 1.822-7.777 1.822a.62.62 0 00-.611.625z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvg360 = React.memo(Svg360);
export default MemoSvg360;
