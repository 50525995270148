import * as React from 'react';

const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M8.29 25.333c-.91 0-1.623-.713-1.623-1.624s.713-1.622 1.623-1.622h15.391c.886 0 1.611.704 1.652 1.601 0 .931-.713 1.645-1.623 1.645H8.29zm0-7.723c-.91 0-1.623-.713-1.623-1.624s.713-1.624 1.623-1.624h15.391c.886 0 1.611.704 1.652 1.604 0 .93-.713 1.643-1.623 1.643H8.29zm0-7.696c-.91 0-1.623-.713-1.623-1.624S7.38 6.666 8.29 6.666h15.391c.886 0 1.611.704 1.652 1.604 0 .93-.713 1.643-1.623 1.643H8.29z" />
    </svg>
  );
};

const MemoSvgMenu = React.memo(SvgMenu);
export default MemoSvgMenu;
