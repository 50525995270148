/* eslint-disable max-lines */
import { BASE_PATH } from '../constants';
import altTexts from './alt-texts.json';
import Image, { ImageProps } from 'next/image';

type Props = Omit<ImageProps, 'src' | 'alt'>;

const PATH = `${BASE_PATH}/hero/`;

const DEFAULT_SIZE = {
  width: 360,
  height: 240,
};

const ImageComponent = (props: ImageProps) => <Image {...props} />;

export const AbroadHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Abroad.svg`}
    alt={altTexts.Abroad}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AntibotHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Antibot.svg`}
    alt={altTexts.Antibot}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AtWorkHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}At_work.svg`}
    alt={altTexts.At_work}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CarHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Car.svg`}
    alt={altTexts.Car}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CouchHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Couch.svg`}
    alt={altTexts.Couch}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ErrorHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Error.svg`}
    alt={altTexts.Error}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const HomeHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Home.svg`}
    alt={altTexts.Home}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const HouseSaleHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}House_sale.svg`}
    alt={altTexts.House_sale}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NoConnectionHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}No_connection.svg`}
    alt={altTexts.No_connection}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RocketHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Rocket.svg`}
    alt={altTexts.Rocket}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PageCrushHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}error-page.svg`}
    alt={altTexts.CRUSH}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PageFailedHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}no-explorer.svg`}
    alt={altTexts.Wrong}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PageUnauthorizedHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}unauthorized-page.svg`}
    alt={altTexts.Unauthorized}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PageForbiddenHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}forbidden-page.svg`}
    alt={altTexts.Forbidden}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UnsubscribeHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}unsubscribe.svg`}
    alt={altTexts.Unsubscribe}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BusinessAdHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Business-ad.svg`}
    alt={altTexts.Business_ad}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const EmptyMailBoxHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}empty-mailbox.svg`}
    alt={altTexts.Business_ad}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BillHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}bill.svg`}
    alt={altTexts.Business_ad}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BuildingsHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}building.svg`}
    alt={altTexts.Business_ad}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BlueHouseHeroIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}blue-house.svg`}
    alt={altTexts.rent}
    {...DEFAULT_SIZE}
    {...args}
  />
);
