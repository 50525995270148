import * as React from 'react';
const SvgSupportCenter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5a7.5 7.5 0 00-7.47 6.832A1.75 1.75 0 015.75 13v4A1.75 1.75 0 014 18.75 2.75 2.75 0 011.25 16v-2c0-1.173.734-2.174 1.768-2.57a9 9 0 0117.964 0A2.751 2.751 0 0122.75 14v2a2.751 2.751 0 01-1.771 2.57.756.756 0 01.021.18v1.5a.75.75 0 01-.75.75h-7.5a.75.75 0 010-1.5h6.75v-.75l.003-.072A1.75 1.75 0 0118.25 17v-4a1.75 1.75 0 011.22-1.668A7.502 7.502 0 0012 4.5zm-8 8.25c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25a.25.25 0 00.25-.25v-4a.25.25 0 00-.25-.25zm16 0a.25.25 0 00-.25.25v4c0 .138.112.25.25.25.69 0 1.25-.56 1.25-1.25v-2c0-.69-.56-1.25-1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgSupportCenter = React.memo(SvgSupportCenter);
export default MemoSvgSupportCenter;
