import * as React from 'react';

const SvgWrench = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.744 4.67c.165 0 .33.009.496.026l-2.55 2.55.872 4.192 4.192.872L19.3 9.763c.127 1.286-.254 2.527-1.119 3.393-.739.738-1.767 1.145-2.897 1.145-.521 0-1.048-.084-1.567-.25l-.97-.31-.72.72-4.354 4.353a1.748 1.748 0 01-1.244.515c-.47 0-.912-.183-1.244-.515a1.748 1.748 0 01-.515-1.244c0-.47.183-.911.515-1.244l4.354-4.354.72-.72-.31-.97c-.543-1.693-.208-3.362.894-4.465.74-.74 1.77-1.146 2.9-1.146zm0-1.67c-1.52 0-2.983.539-4.08 1.637-1.595 1.594-2.008 3.96-1.305 6.154l-4.354 4.354a3.43 3.43 0 104.85 4.85l4.354-4.353c.684.219 1.386.33 2.075.33 1.52 0 2.982-.538 4.08-1.635 1.963-1.964 2.139-5.097.638-7.638l-3.792 3.792-2.236-.465-.465-2.236L17.3 3.998A7.025 7.025 0 0013.744 3z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgWrench = React.memo(SvgWrench);
export default MemoSvgWrench;
