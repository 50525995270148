import * as React from 'react';
const SvgDecrease = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.17 14.859a.777.777 0 01.998-.186l.095.065 3.96 3.174v-13.4a.777.777 0 011.547-.105l.007.106v13.336l3.966-3.116a.777.777 0 011.013.046l.078.085a.777.777 0 01-.046 1.014l-.085.077-5.273 4.143a.777.777 0 01-.868.063l-.098-.067-5.173-4.143a.777.777 0 01-.12-1.092z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDecrease = React.memo(SvgDecrease);
export default MemoSvgDecrease;
