/* eslint-disable no-magic-numbers */
export const recommerce = {
  title: 'recommerce' as const,
  titleHeb: 'ריקומרס',
  id: 20 as const,
  subCategories: {
    recommerce: {
      id: 0 as const,
    },
  },
};
