import texts from './bottom-links.texts.json';
import env from '@y2/environments';

const SPECIAL_BASE_PATH = 'https://www.2.yad2.co.il';

export const BOTTOM_LINKS_LIST = [
  {
    title: texts.eula,
    url: `${SPECIAL_BASE_PATH}/eula`,
  },
  {
    title: texts.accessibilityStatement,
    url: `${SPECIAL_BASE_PATH}/accessibility`,
  },
  {
    title: texts.privacyPolicy,
    url: `${SPECIAL_BASE_PATH}/privacy`,
  },
  {
    title: texts.siteMap,
    url: '/sitemap',
  },
  {
    title: texts.contactUs,
    url: 'https://service.yad2.co.il',
  },
  {
    title: texts.transactionCancellation,
    url: `${env.routes.generalPages}/transaction-cancellation`,
  },
  {
    title: texts.careersYad2,
    url: 'https://www.career.yad2.co.il',
  },
  {
    title: texts.nadlanationProjects,
    url: `${env.assets.basePath}/y2_frontend/footer/NadlanationProjects.pdf`,
  },
];
