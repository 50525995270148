import * as React from 'react';

const SvgPrint = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.645 4.624a.424.424 0 01.3-.124h8.216a.424.424 0 01.424.424v2.771H7.521V4.924c0-.113.045-.22.124-.3zM6.021 7.695V4.924A1.924 1.924 0 017.945 3h8.216a1.923 1.923 0 011.924 1.924v2.771h1.598a1.924 1.924 0 011.923 1.924v5.869a1.924 1.924 0 01-1.924 1.923h-1.978v3.352a.75.75 0 01-.75.75H7.152a.75.75 0 01-.75-.75v-3.352H4.424A1.924 1.924 0 012.5 15.487V9.62a1.924 1.924 0 011.924-1.924H6.02zm.744 1.5H4.424A.424.424 0 004 9.619v5.869a.424.424 0 00.424.423h1.978V13.14a.75.75 0 01.75-.75h9.802a.75.75 0 01.75.75v2.771h1.979a.423.423 0 00.423-.423v-5.87a.424.424 0 00-.424-.423H6.766zm1.137 4.695v6.123h8.302V13.89H7.902z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPrint = React.memo(SvgPrint);
export default MemoSvgPrint;
