import * as React from 'react';
const SvgKeys = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.205 4.097a3.75 3.75 0 016.983 2.652 4.728 4.728 0 00-2.25-1.646 4.73 4.73 0 00-3.066-.017 4.736 4.736 0 00-1.65-.983l-.017-.006zm-1.524-.258a5.251 5.251 0 119.334 4.708 4.747 4.747 0 01-3.59 5.677c.03.255.004.52-.086.778l-2.311 6.66a1.748 1.748 0 01-.347.591l-.748.84a1.75 1.75 0 01-2.944-.55l-.478-1.27a1.75 1.75 0 01.264-1.699l.517-.656a.25.25 0 00.054-.155V17.71c0-.393.133-.774.376-1.082l.552-.701a.25.25 0 00.045-.222L12 14.56a1.75 1.75 0 01.032-1.043l.069-.197a4.716 4.716 0 01-.907-.003c-.07.123-.157.24-.26.346L6.042 18.74c-.16.166-.351.299-.563.39l-1.03.448a1.75 1.75 0 01-2.443-1.734l.1-1.354a1.75 1.75 0 01.952-1.43l.745-.38a.25.25 0 00.114-.117l.443-.956a1.75 1.75 0 01.795-.825l.795-.404a.25.25 0 00.134-.183l.19-1.172a1.75 1.75 0 01.468-.933l.234-.243a1.78 1.78 0 01.086-.084 4.752 4.752 0 014.62-5.925zM8.594 7.525a3.25 3.25 0 014.84-1.662 4.729 4.729 0 00-1.541 2.17 4.737 4.737 0 00.283 3.767 3.221 3.221 0 01-.867.021 1.745 1.745 0 00-.42-.631l-.72-.694-.72-.694a1.742 1.742 0 00-.95-.47 3.232 3.232 0 01.095-1.807zm-.54 3.363a.25.25 0 01.354-.007l.72.694.72.695a.25.25 0 01.007.353L4.96 17.699a.25.25 0 01-.08.056l-1.031.448a.25.25 0 01-.35-.248l.1-1.354a.25.25 0 01.137-.205l.745-.378c.35-.178.63-.469.795-.825l.443-.956a.25.25 0 01.113-.118l.796-.404a1.75 1.75 0 00.934-1.279l.19-1.172a.25.25 0 01.068-.133l.234-.243zm5.256-2.363a3.25 3.25 0 113.113 4.316 1.76 1.76 0 00-.163-.066l-.414-.144-.531-.184-.57-.197-.375-.13a1.64 1.64 0 00-.054-.019 3.25 3.25 0 01-1.006-3.577zm.418 5.007l.015.01.011-.016a.25.25 0 01.124.01l.376.13.569.198.531.184.414.144c.13.045.2.188.154.318l-2.311 6.661a.25.25 0 01-.05.084l-.748.84a.25.25 0 01-.42-.079l-.478-1.27a.25.25 0 01.038-.243l.517-.657a1.75 1.75 0 00.376-1.082v-1.053a.25.25 0 01.054-.155l.552-.7a1.75 1.75 0 00.312-1.554l-.32-1.143a.25.25 0 01.005-.15l.111-.318a.25.25 0 01.168-.159z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgKeys = React.memo(SvgKeys);
export default MemoSvgKeys;
