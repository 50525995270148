import * as React from 'react';

const SvgErrorcircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.059 8.641l-.084-.072-.103-.064a.75.75 0 00-.874 1.197l2.298 2.297-2.298 2.3-.073.083a.75.75 0 001.134.977l2.297-2.299 2.299 2.299.084.072a.75.75 0 00.976-1.133L13.418 12l2.299-2.298.072-.084a.75.75 0 00-1.133-.977l-2.3 2.298-2.296-2.298z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12.25c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10zm18.5 0a8.5 8.5 0 10-17 0 8.5 8.5 0 0017 0z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgErrorcircle = React.memo(SvgErrorcircle);
export default MemoSvgErrorcircle;
