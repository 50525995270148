import styles from './bottom-links.module.scss';
import { Link as Y2Link } from '@y2/mango/components/link';
import { getLinkWithHost } from '../helpers';
import { BOTTOM_LINKS_LIST } from './bottom-links.consts';

interface Props {
  linkList?: Link[];
}

export type Link = {
  title: string;
  url: string;
};

export const BottomLinks: React.VFC<Props> = ({
  linkList = BOTTOM_LINKS_LIST,
}) => (
  <ul className={styles.aboutLinks} data-nagish="footer-links-list">
    {linkList.map(({ title, url }) => (
      <li
        className={styles.linkItem}
        key={title}
        data-nagish="footer-links-item"
      >
        <Y2Link
          variant={'tertiary'}
          href={getLinkWithHost(url)}
          data-nagish="footer-links-link"
        >
          {title}
        </Y2Link>
      </li>
    ))}
  </ul>
);
