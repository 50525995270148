import type { Environment } from './schema';
import prod from './configs/production';
import staging from './configs/staging';
import local from './configs/local';

// class MissingNxBuildEnvError extends Error {}
class InvalidNxBuildEnvError extends Error {}

type NEXT_PUBLIC_BUILD_ENV = 'production' | 'staging' | 'local';

const envName = process.env['NEXT_PUBLIC_BUILD_ENV'] || 'staging';

// prettier-ignore
const envMapping: Record<NEXT_PUBLIC_BUILD_ENV, Environment> = {
  'production': prod,
  'staging': staging,
  'local': local,
}

const envConfig = envMapping[envName as NEXT_PUBLIC_BUILD_ENV];

if (typeof envConfig === 'undefined') {
  throw new InvalidNxBuildEnvError(
    `[@y2/environment] supplied NEXT_PUBLIC_BUILD_ENV "${envName}" is not supported! use one of the following (${Object.keys(
      envMapping,
    ).join(',')})`,
  );
}

export default envConfig;
