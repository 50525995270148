import * as React from 'react';

const SvgAttachment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.94 3.092c2.626-.323 5.01 1.626 5.343 4.331.157 1.282-.17 2.546-.854 3.482l-.032.056-.112.153-.021.023-5.88 7.843a.75.75 0 01-.956.21l-.093-.06a.75.75 0 01-.21-.956l.06-.093 5.84-7.782.053-.081.095-.127.115-.17a3.522 3.522 0 00.506-2.315C18.56 5.709 16.91 4.36 15.123 4.58a3.166 3.166 0 00-2.028 1.1l-.138.172-7.169 9.591a2.31 2.31 0 00-.436 1.67c.153 1.24 1.227 2.117 2.383 1.975.481-.059.916-.287 1.25-.645l.122-.14 5.707-7.614c.136-.182.211-.406.212-.641l-.008-.143c-.072-.584-.567-.988-1.096-.923a.927.927 0 00-.553.279l-.085.1-4.497 5.97a.75.75 0 01-1.258-.808l.06-.093 4.497-5.972c.399-.53.989-.883 1.653-.965 1.366-.168 2.596.836 2.767 2.23a2.59 2.59 0 01-.38 1.702l-.11.162-5.61 7.482-.026.042-.08.106-.054.059-.104.125a3.56 3.56 0 01-2.009 1.143l-.215.033c-1.993.245-3.803-1.233-4.054-3.28a3.816 3.816 0 01.595-2.57l.128-.182 7.17-9.592a4.68 4.68 0 013.183-1.862z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAttachment = React.memo(SvgAttachment);
export default MemoSvgAttachment;
