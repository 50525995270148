import styles from './social-buttons.module.scss';
import { ICON_LISTS } from './icon-list';
import React from 'react';
import { Link } from '@y2/mango/components/link';

export const SocialButtons = () => {
  return (
    <ul
      className={styles.socialButtonsContainer}
      data-nagish="footer-social-buttons-list"
    >
      {ICON_LISTS.map(({ url, getIcon, ariaLabel }) => {
        const IconComponent = getIcon();
        return (
          <li
            className={styles.iconItem}
            key={ariaLabel}
            data-nagish="social-buttons-item"
          >
            <Link
              href={url}
              target={'_blank'}
              className={styles.iconButton}
              aria-label={ariaLabel}
              variant={'none'}
              data-nagish="social-buttons-link"
            >
              {IconComponent}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
