/* eslint-disable max-lines */

import { Icon } from '@y2/deprecated/ui/icons/types';
import {
  RealEstateIcon,
  CalculatorIcon,
  VehicleIcon,
  CarPriceIcon,
  SecondHandIcon,
  WantedIcon,
  BusinessIcon,
  PetsIcon,
  ProfessionsIcon,
  BusinessReIcon,
  ClipBoardIcon,
} from '@y2/mango/components/icons';
import env from '@y2/environments';

export type MenuSubCategory = {
  text: string;
  url: string;
  icon?: Icon;
  newTab?: boolean;
  desktopColumnPlacement?: string;
  isNewTag?: boolean;
};

export type MenuCategory = {
  categoryText: string;
  url: string;
  icon?: Icon;
  newTab?: boolean;
  subCategories?: MenuSubCategory[];
  rowsInDesktop?: number;
  isRecommerce?: boolean;
  isRealestate?: boolean;
  isMobileOnly?: boolean;
};

export const menuData: MenuCategory[] = [
  {
    categoryText: 'נדל״ן',
    url: '/realestate/forsale',
    icon: RealEstateIcon,
    isRealestate: true,
  },
  {
    categoryText: 'דירות חדשות',
    url: '/yad1',
    icon: BusinessReIcon,
    newTab: true,
  },
  {
    categoryText: 'רכב',
    url: '/vehicles/cars',
    icon: VehicleIcon,
    rowsInDesktop: 7,
    subCategories: [
      { text: 'פרטיים ומסחריים', url: '/vehicles/cars' },
      { text: 'אופנועים', url: '/vehicles/motorcycles' },
      { text: 'קטנועים', url: '/vehicles/scooters' },
      { text: 'מיוחדים', url: '/vehicles/other' },
      {
        text: 'אביזרים',
        url: `${env.routes.mainSite}/vehicles/car-accessories`,
      },
      { text: 'משאיות', url: '/vehicles/trucks' },
      { text: 'כלי שייט', url: '/vehicles/watercraft' },
      {
        text: 'מחירון רכב',
        url: '/price-list',
        icon: CarPriceIcon,
      },
      {
        text: 'מימון רכב',
        url: 'https://land.5555.co.il/yad2?utm_source=YAD2&utm_medium=Native&utm_campaign=Direct-Mimun-Yashir_AWO-LP_Leads_BOF_header',
        newTab: true,
        icon: CalculatorIcon,
      },
      {
        text: 'רכבים מכונס נכסים',
        url: 'https://konesy2.co.il/cars',
        newTab: true,
        icon: VehicleIcon,
      },
      {
        text: 'בדיקת רכב לפני קנייה',
        url: 'https://lp.yad2.co.il/car_test/?utm_source=yad2&utm_medium=uplinks&utm_campaign=technotest',
        newTab: true,
        icon: ClipBoardIcon,
      },
    ],
  },
  {
    categoryText: 'יד שנייה',
    url: '/lobby/products',
    icon: SecondHandIcon,
    isRecommerce: true,
  },
  {
    categoryText: 'דרושים IL',
    url: 'https://www.drushim.co.il/?utm_source=yad2&utm_medium=hp',
    icon: WantedIcon,
    rowsInDesktop: 10,
    newTab: true,
    subCategories: [
      {
        text: 'חיפוש עבודה',
        url: 'https://www.drushim.co.il/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'פרסום משרות',
        url: 'https://www.drushim.co.il/employers',
        newTab: true,
      },
      {
        text: 'כתיבת קורות חיים',
        url: 'https://www.drushim.co.il/cvwizard/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'אודות החברות',
        url: 'https://www.drushim.co.il/companies.aspx/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'דרושים הייטק',
        url: 'https://www.drushim.co.il/%D7%93%D7%A8%D7%95%D7%A9%D7%99%D7%9D-%D7%94%D7%99%D7%99%D7%98%D7%A7/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'דרושים סטודנטים',
        url: 'https://www.drushim.co.il/jobs/subcat/88/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'מגזין הקריירה',
        url: 'https://www.drushim.co.il/articles/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'כספים',
        url: 'https://www.drushim.co.il/jobs/cat9/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'מכירות',
        url: 'https://www.drushim.co.il/jobs/cat17/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'שירות לקוחות',
        url: 'https://www.drushim.co.il/jobs/cat11/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'אדמיניסטרציה',
        url: 'https://www.drushim.co.il/jobs/cat1/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'מהנדסים',
        url: 'https://www.drushim.co.il/jobs/cat10/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'תחבורה',
        url: 'https://www.drushim.co.il/jobs/cat29/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'מסעדנות/תיירות',
        url: 'https://www.drushim.co.il/jobs/cat12/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'אבטחה',
        url: 'https://www.drushim.co.il/jobs/cat25/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'בריאות',
        url: 'https://www.drushim.co.il/jobs/cat16/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'בעלי מקצוע',
        url: 'https://www.drushim.co.il/jobs/cat3/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'הדרכה/הוראה',
        url: 'https://www.drushim.co.il/jobs/cat7/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'שיווק',
        url: 'https://www.drushim.co.il/jobs/cat27/?utm_source=yad2top',
        newTab: true,
      },
      {
        text: 'לתחומים נוספים',
        url: 'https://drushim.co.il/categories.aspx?utm_source=yad2top',
        newTab: true,
      },
    ],
  },
  {
    categoryText: 'עסקים למכירה',
    url: '/products/businesses-for-sale?category=37',
    icon: BusinessIcon,
    rowsInDesktop: 7,
    subCategories: [
      {
        text: 'בתי קפה ומסעדות',
        url: '/products/businesses-for-sale?category=37&item=302',
      },
      {
        text: 'זכיינות',
        url: '/products/businesses-for-sale?category=37&item=290',
      },
      {
        text: 'קווי חלוקה',
        url: '/products/businesses-for-sale?category=37&item=304',
      },
      {
        text: 'הזדמנויות עסקיות',
        url: '/products/businesses-for-sale?category=37&item=289',
      },
      {
        text: 'מינימרקטים וסופרמרקטים',
        url: '/products/businesses-for-sale?category=37&item=883',
      },
      {
        text: 'קיוסקים ופיצוציות',
        url: '/products/businesses-for-sale?category=37&item=882',
      },
      {
        text: 'לכל העסקים',
        url: '/products/businesses-for-sale?category=37',
      },
    ],
  },
  {
    categoryText: 'חיות מחמד',
    url: '/pets/all',
    icon: PetsIcon,
    rowsInDesktop: 10,
    subCategories: [
      { text: 'כלבים', url: '/pets/dogs?species=1' },
      { text: 'חתולים', url: '/pets/cats?species=2' },
      { text: 'תוכים ובעלי כנף', url: '/pets/parrots?species=5' },
      { text: 'דגים', url: '/pets/fish?species=3' },
      { text: 'זוחלים', url: '/pets/reptiles?species=10' },
      { text: 'מכרסמים', url: '/pets/rodents?species=13' },
      { text: 'סוסים', url: '/pets/horses?species=8' },
      { text: 'תרנגולים', url: '/pets/chickens?species=22' },
      { text: 'חיות משק', url: '/pets/livestock?species=21' },
      { text: 'חמוסים', url: '/pets/ferrets?species=20' },
    ],
  },
  {
    categoryText: 'בעלי מקצוע',
    url: 'https://www.yad2.co.il/b144',
    icon: ProfessionsIcon,
    rowsInDesktop: 11,
    newTab: true,
    subCategories: [
      {
        text: 'מכוני בדיקה ורישוי לרכב',
        url: 'https://www.yad2.co.il/b144/search?category=1060',
        newTab: true,
      },
      {
        text: 'רחיצת רכב',
        url: 'https://www.yad2.co.il/b144/search?category=764',
        newTab: true,
      },
      {
        text: 'חומרי בניין',
        url: 'https://www.yad2.co.il/b144/search?category=1246',
        newTab: true,
      },
      {
        text: 'אינסטלטורים',
        url: 'https://www.yad2.co.il/b144/search?category=1416',
        newTab: true,
      },
      {
        text: 'חשמלאים',
        url: 'https://www.yad2.co.il/b144/search?category=1225',
        newTab: true,
      },
      {
        text: 'שמאי מקרקעין',
        url: 'https://www.yad2.co.il/b144/search?category=718',
        newTab: true,
      },
      {
        text: 'שיפוצים',
        url: 'https://www.yad2.co.il/b144/search?category=729',
        newTab: true,
      },
      {
        text: 'הובלות',
        url: 'https://www.yad2.co.il/b144/search?category=1290',
        newTab: true,
      },
      {
        text: 'רהיטים',
        url: 'https://www.yad2.co.il/b144/search?category=788',
        newTab: true,
      },
      {
        text: 'חברות ניקיון ואחזקה',
        url: 'https://www.yad2.co.il/b144/search?category=525',
        newTab: true,
      },
      {
        text: 'לכל בעלי המקצוע',
        url: 'https://www.yad2.co.il/b144',
        newTab: true,
      },
    ],
  },
  // {
  //   categoryText: 'מגזין יד2',
  //   url: 'https://magazine.yad2.co.il/?yad2_source=header_more',
  //   rowsInDesktop: 0,
  //   newTab: true,
  //   subCategories: [],
  //   icon: MagazineIcon,
  // },
];
