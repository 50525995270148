import * as React from 'react';

const SvgCalculate = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.743 3.898A.75.75 0 0017.25 4v2.25H15l-.102.007A.75.75 0 0015 7.75h2.25V10l.007.102A.75.75 0 0018.75 10V7.75H21l.102-.007A.75.75 0 0021 6.25h-2.25V4l-.007-.102zM20.991 15.193a.75.75 0 00-1.145-.964l-2.024 2.096-2.024-2.096a.75.75 0 00-1.145.964l.066.078 2.06 2.133-2.175 2.254-.066.078a.75.75 0 001.145.964l2.139-2.217 2.14 2.217a.75.75 0 001.144-.964l-.065-.078-2.176-2.254 2.06-2.133.066-.078zM9 6.25a.75.75 0 01.102 1.493L9 7.75H3a.75.75 0 01-.102-1.493L3 6.25h6zM9.75 15a.75.75 0 00-.75-.75H3l-.102.007A.75.75 0 003 15.75h6l.102-.007A.75.75 0 009.75 15zM9.75 19a.75.75 0 00-.75-.75H3l-.102.007A.75.75 0 003 19.75h6l.102-.007A.75.75 0 009.75 19z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgCalculate = React.memo(SvgCalculate);
export default MemoSvgCalculate;
