import React from 'react';

const SvgBusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.83594 4.62214C3.83594 3.69606 4.58668 2.94531 5.51277 2.94531H18.4884C19.4145 2.94531 20.1652 3.69605 20.1652 4.62214V17.5978C20.1652 18.5238 19.4145 19.2746 18.4884 19.2746H18.3115V20.3782C18.3115 21.3043 17.5608 22.0551 16.6347 22.0551H15.7079C14.7818 22.0551 14.0311 21.3043 14.0311 20.3782V19.2746H9.97008V20.3782C9.97008 21.3043 9.21934 22.0551 8.29325 22.0551H7.36643C6.44034 22.0551 5.6896 21.3043 5.6896 20.3782V19.2746H5.51277C4.58668 19.2746 3.83594 18.5238 3.83594 17.5978V4.62214ZM6.4396 17.7746H9.22008H14.7811H17.5615H18.4884C18.586 17.7746 18.6652 17.6954 18.6652 17.5978V13.427H5.33594V17.5978C5.33594 17.6954 5.41511 17.7746 5.51277 17.7746H6.4396ZM5.33594 12.0368H18.6652V7.86604H5.33594V12.0368ZM5.33594 6.4758V4.62214C5.33594 4.52448 5.41511 4.44531 5.51277 4.44531H18.4884C18.586 4.44531 18.6652 4.52448 18.6652 4.62214V6.4758H5.33594ZM7.1896 19.2746V20.3782C7.1896 20.4759 7.26877 20.5551 7.36643 20.5551H8.29325C8.39091 20.5551 8.47008 20.4759 8.47008 20.3782V19.2746H7.1896ZM15.5311 19.2746H16.8115V20.3782C16.8115 20.4759 16.7324 20.5551 16.6347 20.5551H15.7079C15.6102 20.5551 15.5311 20.4759 15.5311 20.3782V19.2746ZM17.0983 15.573C17.0983 16.2128 16.5796 16.7315 15.9397 16.7315C15.2999 16.7315 14.7812 16.2128 14.7812 15.573C14.7812 14.9331 15.2999 14.4144 15.9397 14.4144C16.5796 14.4144 17.0983 14.9331 17.0983 15.573ZM8.52496 16.7315C9.1648 16.7315 9.6835 16.2128 9.6835 15.573C9.6835 14.9331 9.1648 14.4144 8.52496 14.4144C7.88512 14.4144 7.36643 14.9331 7.36643 15.573C7.36643 16.2128 7.88512 16.7315 8.52496 16.7315Z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgBusIcon = React.memo(SvgBusIcon);
export default MemoSvgBusIcon;
