import * as React from 'react';

const SvgReverse = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.08 5.35a.75.75 0 10-.9-1.2C8.61 5.329 6.716 7.565 6.16 10.484c-.566 2.974.282 6.51 3.94 10.168a.75.75 0 001.06-1.06c-3.368-3.37-3.984-6.421-3.526-8.827.469-2.461 2.09-4.398 3.446-5.415zm2.03.95a.75.75 0 01-.15 1.05c-.979.734-2.147 2.133-2.484 3.9-.326 1.713.101 3.916 2.564 6.38a.75.75 0 01-1.06 1.06c-2.753-2.752-3.412-5.44-2.978-7.72.424-2.226 1.864-3.924 3.058-4.82a.75.75 0 011.05.15zm1.97 3.05a.75.75 0 10-.9-1.2c-.817.613-1.803 1.773-2.095 3.306-.303 1.587.168 3.425 2.015 5.273a.75.75 0 001.06-1.061c-1.557-1.558-1.796-2.912-1.602-3.931.205-1.075.92-1.936 1.522-2.387zm2.244 2a.75.75 0 10-.9-1.2c-.441.33-.976.956-1.137 1.797-.17.897.112 1.889 1.057 2.833a.75.75 0 101.06-1.06c-.655-.655-.706-1.164-.643-1.492.073-.383.337-.709.563-.878z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgReverse = React.memo(SvgReverse);
export default MemoSvgReverse;
