import styles from './category-item.module.scss';
import SubCategoryList from '../sub-category-list/sub-category-list';
import React, { useState } from 'react';
import { Category } from '../types';
import { ArrowIcon } from '@y2/deprecated/ui/icons';
import cn from 'classnames';

const CategoryItem: React.VFC<Category> = ({ title, subCategories }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  return (
    <li
      className={cn(styles.categoryItem, isCollapsed && styles.isCollapsed)}
      data-nagish="footer-category-item"
    >
      <div
        className={styles.categoryNameContainer}
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        <h6
          className={styles.categoryItemName}
          data-nagish="footer-item-least-title"
        >
          {title}
        </h6>
        <ArrowIcon className={styles.arrowIcon} />
      </div>
      <SubCategoryList
        subCategories={subCategories}
        isCollapsed={isCollapsed}
      />
    </li>
  );
};

export default CategoryItem;
