import * as React from 'react';

const SvgVehicle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.706 9.88c.399.1.697.399.995.697.299.398.299.697.299 1.194v2.19c0 .497-.199.994-.597 1.392-.398.398-.796.597-1.293.597h-.697c-.299 1.294-1.393 2.19-2.687 2.19-1.293 0-2.388-.896-2.686-2.19h-3.383c-.299 1.294-1.393 2.19-2.687 2.19-1.293 0-2.388-.896-2.686-2.19H3.89c-.597 0-.995-.199-1.393-.597C2.199 14.955 2 14.458 2 13.96l.1-1.99c0-.497.199-.895.398-1.293.298-.398.696-.597 1.194-.697l2.388-.398c.398 0 .696-.199.995-.398l2.587-2.487c.597-.398 1.293-.697 1.99-.697h4.875c.597 0 1.194.199 1.692.498.497.298.895.796 1.094 1.293l.797 1.89.596.2zm-4.08-2.387h-.895V9.88h2.886l-.597-1.393c-.2-.299-.398-.498-.597-.697-.2-.199-.498-.298-.796-.298zm-5.87.398c.299-.2.597-.398.995-.398h2.488V9.78H8.567l2.19-1.89zm-4.08 7.462c0 .697.598 1.294 1.294 1.294.697 0 1.294-.597 1.294-1.294 0-.696-.597-1.293-1.294-1.293-.696 0-1.293.597-1.293 1.293zm10.05 1.294a1.317 1.317 0 01-1.293-1.294c0-.696.597-1.293 1.293-1.293.697 0 1.294.597 1.294 1.293 0 .697-.597 1.294-1.294 1.294zm3.831-2.488c.025-.074.05-.149.05-.199v-2.189c0-.1 0-.199-.1-.298-.099-.1-.198-.2-.298-.2H5.483l-1.493.2c-.1.1-.199.1-.199.1 0 .049-.025.099-.05.148-.024.05-.05.1-.05.15l-.099 1.99c0 .199 0 .298.1.398 0 .1.199.199.298.199h1.294c.398-1.095 1.393-1.791 2.587-1.791 1.194 0 2.189.796 2.587 1.79h3.681c.398-1.094 1.393-1.79 2.588-1.79 1.194 0 2.189.796 2.587 1.79h.796c.199 0 .398 0 .398-.099 0-.05.024-.124.05-.199z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgVehicle = React.memo(SvgVehicle);
export default MemoSvgVehicle;
