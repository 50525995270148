import { EventData, PageEventData } from './types';
import { defaultEventName, defaultPageEventName } from './const';
import {
  isClientSide,
  getEnvironmentEventData,
  getReactPageIndicator,
} from './helpers';
import { getRversionEventData, getIsBotIndication } from '../helpers';
import { addBrazeCampaignIndication } from '../campaigns/braze';
import { triggerPostPageEvent } from '../triggers';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, unknown>[];
    isBlockerDetected?: boolean;
  }
}
const createEventPusher = (eventName: string) => (eventData: EventData) => {
  if (!isClientSide()) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push({
    event: eventName,
    ...getIsBotIndication(),
    ...addBrazeCampaignIndication(),
    ...eventData,
  });
};

const createPageEventPusher =
  (eventName: string) => (eventData: PageEventData) => {
    if (!isClientSide()) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push({
      event: eventName,
      ...eventData,
      ...getEnvironmentEventData(),
      ...getRversionEventData(),
      ...getReactPageIndicator(),
      ...getIsBotIndication(),
      ...addBrazeCampaignIndication(),
    });

    triggerPostPageEvent();
  };

export const analytics = {
  pushEvent: createEventPusher(defaultEventName),
  pushPageEvent: createPageEventPusher(defaultPageEventName),
};
