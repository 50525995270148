/* eslint-disable no-magic-numbers */
export const pets = {
  title: 'pets' as const,
  titleHeb: 'חיות מחמד',
  id: 5 as const,
  subCategories: {
    dogs: {
      id: 1 as const,
      title: 'dogs' as const,
      titleHeb: 'כלבים',
    },
    cats: {
      id: 2 as const,
      title: 'cats' as const,
      titleHeb: 'חתולים',
    },
    fish: {
      id: 3 as const,
      title: 'fish' as const,
      titleHeb: 'דגים',
    },
    parrots: {
      id: 5 as const,
      title: 'parrots' as const,
      titleHeb: 'תוכים ובעלי כנף',
    },
    horses: {
      id: 8 as const,
      title: 'horses' as const,
      titleHeb: 'סוסים',
    },
    reptiles: {
      id: 10 as const,
      title: 'reptiles' as const,
      titleHeb: 'זוחלים',
    },
    rodents: {
      id: 13 as const,
      title: 'rodents' as const,
      titleHeb: 'מכרסמים',
    },
    ferrets: {
      id: 20 as const,
      title: 'ferrets' as const,
      titleHeb: 'חמוסים',
    },
    livestock: {
      id: 21 as const,
      title: 'livestock' as const,
      titleHeb: 'חיות משק',
    },
    chickens: {
      id: 22 as const,
      title: 'chickens' as const,
      titleHeb: 'תרנגולים',
    },
  },
};
