import * as React from 'react';

const SvgLoader = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 12a7 7 0 10-7 7"
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};

const MemoSvgLoader = React.memo(SvgLoader);
export default MemoSvgLoader;
