/* eslint-disable no-param-reassign */
import type { AnimationItem } from 'lottie-web';

export const DIRECTIONS = [
  'loop',
  'reverseLoop',
  'freezeAtFirst',
  'freezeAtLast',
  'playToLast',
  'playToFirst',
] as const;

export type Direction = (typeof DIRECTIONS)[number];

export const setDirection = (
  animation: AnimationItem,
  direction: Direction,
) => {
  animation.loop = direction === 'loop' || direction === 'reverseLoop';
  animation.setDirection(direction === 'reverseLoop' ? -1 : 1);
  const lastFrame = animation.getDuration(true);

  switch (direction) {
    case 'freezeAtFirst':
      return animation.goToAndStop(0, true);
    case 'freezeAtLast':
      return animation.goToAndStop(lastFrame - 1, true);
    case 'playToFirst':
      animation.setDirection(-1);
      return animation.goToAndPlay(lastFrame - 1, true);
    case 'playToLast':
      animation.setDirection(1);
      return animation.goToAndPlay(0, true);
    default:
      break;
  }
};
