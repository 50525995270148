import * as React from 'react';

const SvgMamad = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.642 6.676v-.44l-.384-.215-8.583-4.798a1.745 1.745 0 00-1.705-.001l-8.586 4.8L2 6.235v10.309c0 .614.322 1.177.843 1.493l8.07 4.894a1.75 1.75 0 001.815 0l8.071-4.894a1.75 1.75 0 00.843-1.496V6.676zm-9.699-4.144l7.412 4.144-7.412 4.144a.245.245 0 01-.241.001L4.287 6.676l7.415-4.145a.245.245 0 01.241.002zm.732 9.597l7.467-4.174v8.587a.25.25 0 01-.12.214l-8.071 4.893a.25.25 0 01-.26 0L8.5 19.714v-4.826c0-.439-.23-.846-.607-1.072l-1.5-.9A1.25 1.25 0 004.5 13.988v3.3l-.88-.532a.245.245 0 01-.12-.211v-8.59l7.47 4.175a1.745 1.745 0 001.705 0zM6 18.2l1 .606V15.03l-1-.6v3.768z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMamad = React.memo(SvgMamad);
export default MemoSvgMamad;
