import React from 'react';
import cn from 'classnames';
import { variants, sizes, possibleElements } from './consts';
import style from './button.module.scss';

type Variants = (typeof variants)[number];
export type Size = (typeof sizes)[number];

type PossibleElements = (typeof possibleElements)[number];

type AllButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonProps = AllButtonAttributes & {
  variant?: Variants;
  asElement?: PossibleElements;
  mobileOnlyVariant?: Variants;
  desktopOnlyVariant?: Variants;
  height?: Size;
  mobileOnlySize?: Size;
  desktopOnlySize?: Size;
  children?: React.ReactNode;
  isFlexedStyle?: boolean;
  className?: string;
};

const validateLinkElement = (
  element: ButtonProps['asElement'],
  href: ButtonProps['href'],
) => {
  if (element === 'a' && !href) {
    throw Error('mango button used as anchor <a .. /> is missing href');
  }
};

const getVariantClasses = (
  variant?: Variants,
  mobileOnlyVariant?: Variants,
  desktopOnlyVariant?: Variants,
) => {
  const noStylePerDevice = !mobileOnlyVariant && !desktopOnlyVariant;

  return [
    mobileOnlyVariant && style[`mobile-${mobileOnlyVariant}`],
    desktopOnlyVariant && style[`desktop-${desktopOnlyVariant}`],
    noStylePerDevice && style[`default-${variant}`],
  ];
};

const getSizeClasses = (
  size?: Size,
  mobileOnlySize?: Size,
  desktopOnlySize?: Size,
) => {
  const noStylePerDevice = !mobileOnlySize && !desktopOnlySize;

  return [
    mobileOnlySize && style[`mobile-size-${mobileOnlySize}`],
    desktopOnlySize && style[`desktop-size-${desktopOnlySize}`],
    noStylePerDevice && style[`default-size-${size}`],
  ];
};

export const Button = ({
  children,
  mobileOnlyVariant,
  desktopOnlyVariant,
  asElement: HtmlElement = 'button',
  variant = 'primary',
  className = '',
  height = 'small',
  mobileOnlySize,
  desktopOnlySize,
  isFlexedStyle = true,
  ...buttonAttributes
}: ButtonProps): JSX.Element => {
  validateLinkElement(HtmlElement, buttonAttributes.href);

  return (
    <HtmlElement
      className={cn(
        style.button,
        isFlexedStyle && style.flexed,
        ...getVariantClasses(variant, mobileOnlyVariant, desktopOnlyVariant),
        ...getSizeClasses(height, mobileOnlySize, desktopOnlySize),
        className,
      )}
      aria-disabled={buttonAttributes.disabled}
      {...buttonAttributes}
    >
      {children}
    </HtmlElement>
  );
};
