import * as React from 'react';
const SvgRoom = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3 4.136l-6.46 3.73v7.98l6.46-3.73v-7.98zm-5.21 12.72L12 20.268l6.035-3.485-5.91-3.412-6.035 3.485zm13.07-1.155V7.866L12.8 4.194v7.835l6.36 3.672zM11.5 2.289a1 1 0 011 0l7.66 4.422a1 1 0 01.5.866v8.846a1 1 0 01-.5.866L12.5 21.71a1 1 0 01-1 0L3.84 17.29a1 1 0 01-.5-.866V7.577a1 1 0 01.5-.866L11.5 2.29z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgRoom = React.memo(SvgRoom);
export default MemoSvgRoom;
