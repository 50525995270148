import * as React from 'react';
const SvgSale = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm0 1.5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.78 6.97a.75.75 0 010 1.06l-9 9a.75.75 0 01-1.06-1.06l9-9a.75.75 0 011.06 0zM16.25 16.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM9.25 9.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      />
    </svg>
  );
};
const MemoSvgSale = React.memo(SvgSale);
export default MemoSvgSale;
