import * as React from 'react';

const SvgPhone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.593 15.288c2.83 3.255 5.719 5.086 9.026 5.276a.75.75 0 00.277-.036c1.61-.529 2.387-1.206 3.133-2.726a.75.75 0 00.012-.635 7.747 7.747 0 00-4.098-3.999.75.75 0 00-.854.205l-.14.163c-.26.297-.394.432-.629.6l-.191.13-.06-.02c-1.402-.557-4.186-3.76-4.544-5.228l-.011-.059.052-.06a2.83 2.83 0 01.548-.492l.256-.172.162-.104a.75.75 0 00.322-.817 7.758 7.758 0 00-3.389-4.616.75.75 0 00-.632-.077c-1.609.53-2.385 1.205-3.134 2.724a.75.75 0 00-.075.27c-.272 3.301 1.14 6.418 3.969 9.673zm.869-1.291C6.137 11.237 4.978 8.659 5.1 6.03l.01-.151.047-.093c.447-.849.862-1.245 1.692-1.578l.113-.042.195.142a6.282 6.282 0 012.069 2.815l.02.061-.09.062a4.213 4.213 0 00-.706.65l-.283.323a.75.75 0 00-.18.474c-.041 2.131 4.074 6.865 6.19 7.12a.75.75 0 00.492-.112l.216-.14c.4-.26.62-.43.884-.702l.078-.082.056.028a6.274 6.274 0 012.499 2.44l.114.214-.058.106c-.444.775-.894 1.131-1.796 1.456l-.098.035.14.01c-2.833-.163-5.391-1.784-7.98-4.763l-.263-.307z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPhone = React.memo(SvgPhone);
export default MemoSvgPhone;
