import { useState, useEffect } from 'react';
import {
  minBrowserVersions,
  minOperatingSystems,
  excludedBrowsers,
} from './list';
import UAParser from 'ua-parser-js';

const isUnsupportedBrowserVersion = (
  browserName: string,
  browserVersion: number,
) =>
  minBrowserVersions[browserName]
    ? browserVersion < minBrowserVersions[browserName]
    : false;

const isUnsupportedOSVersion = (osName: string, osVersion: number) =>
  minOperatingSystems[osName] ? osVersion < minOperatingSystems[osName] : false;

export const useBrowserSupport = () => {
  const [isUnsupported, setIsUnsupported] = useState(false);

  useEffect(() => {
    const userAgent = new UAParser().getResult();
    const browser = userAgent.browser.name || '';
    const browserVersion = parseFloat(String(userAgent.browser.version));
    const os = userAgent.os.name || '';
    const osVersion = parseFloat(String(userAgent.os.version));
    const isExcludedBrowser = browser && excludedBrowsers.includes(browser);

    if (
      isUnsupportedBrowserVersion(browser, browserVersion) ||
      isUnsupportedOSVersion(os, osVersion) ||
      isExcludedBrowser
    ) {
      setIsUnsupported(true);
    }
  }, []);

  return {
    isUnsupported,
  };
};
