import * as React from 'react';
const SvgLightbulb = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.97 10.5c-4.032 0-6.944 2.977-7.406 6.174-.288 2.132.408 4.21 1.853 5.782a7.277 7.277 0 011.967 4.995l-.006 2.917c0 1.18.984 2.132 2.2 2.132h2.837c1.217 0 2.2-.953 2.2-2.132l.006-2.917c0-1.906.635-3.65 1.852-4.883a6.902 6.902 0 002.027-4.882c-.005-3.989-3.423-7.186-7.53-7.186zm2.542 19.868c0 .56-.462 1.012-1.043 1.012h-2.836c-.581 0-1.043-.446-1.043-1.012v-1.291h4.917v1.291h.005zm2.152-8.585c-1.445 1.458-2.2 3.477-2.2 5.668v.506h-4.918v-.506c0-2.078-.869-4.15-2.314-5.722-1.272-1.346-1.793-3.085-1.565-4.883.407-2.691 2.934-5.221 6.308-5.221 3.532 0 6.368 2.75 6.368 6.061a5.837 5.837 0 01-1.68 4.097z"
        fill="currentColor"
        stroke="#3578E5"
        strokeWidth={0.5}
      />
      <path
        d="M9.287 18.5h-2M19.97 6.5v-2M10.91 9.607L9.494 8.192M30.713 18.5h2M29.09 9.607l1.415-1.415"
        stroke="#3578E5"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
};
const MemoSvgLightbulb = React.memo(SvgLightbulb);
export default MemoSvgLightbulb;
