import * as React from 'react';

const SvgArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.511 6.372a.75.75 0 01.18.963l-.063.091-3.064 3.824H19.5a.75.75 0 01.102 1.493l-.102.007H6.624l3.008 3.83a.75.75 0 01-.044.977l-.082.075a.75.75 0 01-.978-.044l-.075-.082-4-5.091a.75.75 0 01-.06-.838l.064-.095 4-4.993a.75.75 0 011.054-.117z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgArrow = React.memo(SvgArrow);
export default MemoSvgArrow;
