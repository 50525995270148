/* eslint-disable custom-rules/use-current-color */

import * as React from 'react';

const SvgWhatsappFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15" cy="15.4297" r="15" fill="#2BD249" />
      <path
        d="M19.5909 16.535C19.3583 16.4202 18.1949 15.8745 17.9913 15.7883C17.7877 15.7022 17.6132 15.6735 17.4677 15.9032C17.3223 16.133 16.8569 16.6499 16.7406 16.7935C16.5952 16.9371 16.4788 16.9658 16.2462 16.8509C16.0135 16.7361 15.2573 16.5063 14.3556 15.7309C13.6576 15.1278 13.1923 14.3811 13.0468 14.1514C12.9014 13.9216 13.0468 13.8068 13.1632 13.6919C13.2795 13.577 13.3958 13.4334 13.5122 13.2898C13.6285 13.1463 13.6576 13.0601 13.7449 12.9165C13.8321 12.7729 13.774 12.6293 13.7158 12.5144C13.6576 12.3996 13.1923 11.2796 12.9887 10.8201C12.7851 10.3893 12.6106 10.4467 12.4651 10.418C12.3197 10.418 12.1743 10.418 12.0289 10.418C11.8834 10.418 11.6217 10.4754 11.4181 10.7052C11.2145 10.9349 10.6037 11.4806 10.6037 12.6006C10.6037 13.7206 11.4472 14.7832 11.5635 14.9555C11.6798 15.0991 13.2213 17.3966 15.5481 18.373C16.1007 18.6027 16.537 18.7463 16.886 18.8612C17.4386 19.0335 17.9622 19.0048 18.3694 18.9474C18.8056 18.8899 19.7654 18.4017 19.9399 17.8848C20.1435 17.3679 20.1435 16.9084 20.0854 16.8222C19.969 16.7073 19.8236 16.6499 19.5909 16.535ZM15.1409 22.451C13.7449 22.451 12.407 22.0777 11.2145 21.3884L10.9236 21.2161L8.01514 21.9628L8.80043 19.1771L8.62592 18.8899C7.86972 17.6838 7.46253 16.3053 7.46253 14.8694C7.46253 10.7052 10.9236 7.31642 15.17 7.31642C17.1769 7.31642 19.1256 8.09182 20.5798 9.52774C22.0341 10.9637 22.8194 12.8591 22.8194 14.8694C22.8194 19.0335 19.3583 22.451 15.1409 22.451ZM21.685 8.43644C19.9399 6.71333 17.6132 5.76562 15.1409 5.76562C10.0511 5.76562 5.89194 9.84364 5.89194 14.8694C5.89194 16.4776 6.32822 18.0284 7.11351 19.4069L5.80469 24.1167L10.691 22.8531C12.0289 23.571 13.5704 23.9731 15.1119 23.9731C20.2017 23.9731 24.3609 19.8951 24.3609 14.8694C24.3899 12.457 23.4301 10.1595 21.685 8.43644Z"
        fill="white"
      />
    </svg>
  );
};

const MemoSvgWhatsappFilled = React.memo(SvgWhatsappFilled);
export default MemoSvgWhatsappFilled;
