import * as React from 'react';
const SvgDrawers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.898 5.5c-.772 0-1.398.626-1.398 1.398V19.25a.75.75 0 01-1.5 0V6.898C4 5.298 5.298 4 6.898 4h9.667c1.6 0 2.898 1.298 2.898 2.898V19.25a.75.75 0 01-1.5 0V6.898c0-.772-.626-1.398-1.398-1.398H6.898z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.287 10.444H19.25v1.5H5.287v-1.5zM9.907 7.972a.75.75 0 01.75-.75h2.149a.75.75 0 010 1.5h-2.149a.75.75 0 01-.75-.75zM9.907 14.417a.75.75 0 01.75-.75h2.149a.75.75 0 010 1.5h-2.149a.75.75 0 01-.75-.75zM5.287 16.889H19.25v1.5H5.287v-1.5z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgDrawers = React.memo(SvgDrawers);
export default MemoSvgDrawers;
