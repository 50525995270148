import * as React from 'react';
const SvgPause = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.706 6c.357 0 .653.302.7.695l.006.109v10.392c0 .444-.316.804-.706.804-.357 0-.653-.302-.7-.695L8 17.196V6.804C8 6.36 8.316 6 8.706 6zm6.588 0c.357 0 .653.302.7.695l.006.109v10.392c0 .444-.316.804-.706.804-.357 0-.653-.302-.7-.695l-.006-.109V6.804c0-.444.316-.804.706-.804z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgPause = React.memo(SvgPause);
export default MemoSvgPause;
