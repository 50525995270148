import * as React from 'react';

const SvgShekel = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.054 6c1.625 0 2.83.395 3.618 1.185.787.79 1.181 1.974 1.181 3.554v2.581c0 .129-.04.235-.123.318a.418.418 0 01-.311.126h-.794a.418.418 0 01-.312-.126.435.435 0 01-.123-.318v-2.523c0-2.21-1.077-3.314-3.23-3.314H7.162v9.074c0 .128-.041.234-.123.318a.418.418 0 01-.312.125h-.793a.418.418 0 01-.312-.125.435.435 0 01-.123-.318V6.443c0-.128.04-.234.123-.318A.418.418 0 015.935 6h4.119zm8.011 0c.126 0 .23.042.312.125.082.084.123.19.123.318v5.818c0 1.58-.397 2.764-1.19 3.554-.794.79-2.003 1.185-3.628 1.185h-4.1a.418.418 0 01-.312-.125.435.435 0 01-.123-.318V9.66c0-.128.04-.234.123-.318a.418.418 0 01.311-.125h.794c.126 0 .23.042.312.125.082.084.123.19.123.318v5.837h2.777c2.167 0 3.25-1.098 3.25-3.294v-5.76c0-.141.041-.25.123-.327A.437.437 0 0117.272 6h.793z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgShekel = React.memo(SvgShekel);
export default MemoSvgShekel;
