import * as React from 'react';

const SvgAlertactive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.904 4c.423 0 .767.336.767.75v.81a6.016 6.016 0 013.544 1.67 5.764 5.764 0 011.746 4.122c0 2.339.256 3.653.484 4.356.113.35.218.54.274.629.02.03.034.048.04.056a.738.738 0 01.198.795.766.766 0 01-.724.5h-3.31a.731.731 0 01-.03.197 2.944 2.944 0 01-1.083 1.527 3.05 3.05 0 01-1.803.588 3.05 3.05 0 01-1.802-.588c-.52-.381-.9-.917-1.083-1.527a.735.735 0 01-.03-.196H5.766a.769.769 0 01-.69-.422.736.736 0 01.087-.793c.015-.023.043-.07.08-.143.076-.146.19-.4.307-.79.235-.779.488-2.102.488-4.189 0-1.546.628-3.028 1.746-4.121a6.012 6.012 0 013.352-1.646V4.75c0-.414.343-.75.767-.75zm-1.22 13.689c.102.205.253.385.441.523a1.493 1.493 0 001.764 0c.189-.138.34-.318.442-.523h-2.647zM8.87 8.29A4.479 4.479 0 0112 7.024c1.174 0 2.3.456 3.13 1.267a4.28 4.28 0 011.297 3.061c0 2.433.264 3.912.556 4.81l.008.027H6.951c.024-.07.048-.145.071-.223.284-.941.551-2.41.551-4.614a4.28 4.28 0 011.297-3.06zm6.8-3.64a.75.75 0 00.315 1.013c1.548.813 2.655 2.17 3.302 4.152a.75.75 0 001.426-.465c-.75-2.297-2.087-3.995-4.03-5.015a.75.75 0 00-1.014.315zM8.014 5.664a.75.75 0 10-.697-1.328c-1.943 1.02-3.281 2.718-4.03 5.015a.75.75 0 001.425.465c.647-1.981 1.754-3.34 3.302-4.152z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgAlertactive = React.memo(SvgAlertactive);
export default MemoSvgAlertactive;
