import * as React from 'react';
const SvgPlayVideo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.284 6.375a1.686 1.686 0 00-2.26 1.586v8.078a1.686 1.686 0 002.358 1.546L8.04 15.13a.75.75 0 00.451-.688V9.147a.75.75 0 00-.495-.705L2.284 6.375zm-.76 1.586c0-.13.128-.219.25-.175l5.218 1.888v4.275l-5.207 2.26a.186.186 0 01-.26-.17V7.96z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.976 7.576c0-1.83-1.506-3.294-3.337-3.294h-10.31c-1.83 0-3.336 1.463-3.337 3.294v8.847c0 1.832 1.506 3.295 3.338 3.295h10.309c1.831 0 3.337-1.464 3.337-3.295V7.576zm-3.337-1.794c1.027 0 1.837.815 1.837 1.794v8.847c0 .98-.81 1.794-1.837 1.795h-10.31c-1.026 0-1.837-.816-1.837-1.795V7.576c0-.979.811-1.794 1.838-1.794h10.309z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.962 7.79a1.15 1.15 0 011.788.957v6.506a1.15 1.15 0 01-1.788.956l-4.879-3.252a1.15 1.15 0 010-1.914l4.88-3.252zm.288 1.611L13.352 12l3.898 2.599V9.4z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgPlayVideo = React.memo(SvgPlayVideo);
export default MemoSvgPlayVideo;
