import * as React from 'react';
const SvgHand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.146 22c-1.737 0-3.378-.782-4.51-2.14l-4.87-4.673a2.548 2.548 0 01-.211-3.407 2.333 2.333 0 011.816-.881c.51 0 .992.162 1.395.476l.44.333v-5.6c0-1.178.938-2.14 2.088-2.14.272 0 .535.054.78.153.01-1.168.949-2.121 2.09-2.121.816 0 1.553.485 1.895 1.231.298-.161.632-.251.983-.251 1.15 0 2.088.962 2.088 2.14v.96c.246-.098.518-.152.781-.152 1.15 0 2.089.962 2.089 2.14v7.856c0 3.343-2.659 6.067-5.924 6.067h-.956l.026.009zm-7.775-9.762a1.04 1.04 0 00-.807.396 1.175 1.175 0 00.096 1.573l4.8 4.62.088.08c.913 1.125 2.203 1.745 3.59 1.745h.956c2.545 0 4.616-2.122 4.616-4.728V8.067c0-.44-.351-.8-.781-.8-.43 0-.781.36-.781.8v3.128c-.088.78-1.22.78-1.308 0V5.128c0-.44-.35-.8-.78-.8-.43 0-.782.36-.782.8v6.067c-.087.78-1.22.78-1.307 0V4.14c0-.44-.351-.8-.781-.8-.43 0-.781.36-.781.8v7.056c-.088.78-1.22.78-1.308 0V6.108c0-.44-.35-.8-.78-.8-.43 0-.782.36-.782.8v8.252l-2.527-1.915a1.049 1.049 0 00-.623-.216l-.018.01z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgHand = React.memo(SvgHand);
export default MemoSvgHand;
