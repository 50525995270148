/* eslint-disable no-magic-numbers */
export const commercial = {
  title: 'commercial' as const,
  titleHeb: 'הכל לעסק',
  id: 15 as const,
  subCategories: {
    businesses: {
      id: 1 as const,
      title: 'businesses' as const,
      titleHeb: 'עסקים למכירה/מסירה',
    },
    realEstate: {
      id: 2 as const,
      title: 'realestate' as const,
      titleHeb: 'נדלן מסחרי',
    },
    equipment: {
      id: 3 as const,
      title: 'equipment' as const,
      titleHeb: 'ציוד לעסק',
    },
  },
};
