/* istanbul ignore file */

import { isWebView } from '@y2/react-native-utils';
import { useEffect, useState } from 'react';
import { AccessibilityScript } from '.';
import { AccessibilityButton } from './button/accessibility-button';

type Props = {
  siteKey?: string;
  integrityKey?: string;
  shouldShowAccessibilityButton?: boolean;
};

export const Accessibility = ({
  shouldShowAccessibilityButton = true,
  siteKey,
  integrityKey,
}: Props) => {
  const [isNativeApp, setIsNativeApp] = useState(true);

  useEffect(() => {
    const isNative = isWebView();
    setIsNativeApp(isNative);
  }, []);

  return isNativeApp ? null : (
    <>
      <AccessibilityButton shouldHide={!shouldShowAccessibilityButton} />
      <AccessibilityScript sitekey={siteKey} integrityKey={integrityKey} />
    </>
  );
};

export default Accessibility;
