import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgCalender = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M11.25 8a.75.75 0 0 0 1.5 0v-.8h2.5V8a.75.75 0 0 0 1.5 0v-.8H19l.09.008a.5.5 0 0 1 .41.492v2.75h-15V7.7l.008-.09A.5.5 0 0 1 5 7.2h2.25V8a.75.75 0 0 0 1.5 0v-.8h2.5V8Zm-2.5-2.3h2.5V4a.75.75 0 0 1 1.5 0v1.7h2.5V4a.75.75 0 0 1 1.5 0v1.7H19a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h2.25V4a.75.75 0 0 1 1.5 0v1.7Zm-4.25 13v-6.75h15v6.75l-.008.09a.5.5 0 0 1-.492.41H5l-.09-.008a.5.5 0 0 1-.41-.492Z"
      />
    </svg>
  );
};

const Memo = memo(SvgCalender);
export default Memo;
