import * as React from 'react';

const SvgNoentry = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25 2.25c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zM5.354 7.28a8.5 8.5 0 0012.401 11.447L5.354 7.28zm6.896-3.53a8.472 8.472 0 00-5.914 2.395l12.472 11.512A8.5 8.5 0 0012.25 3.75z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgNoentry = React.memo(SvgNoentry);
export default MemoSvgNoentry;
