import * as React from 'react';

const SvgMagnesium = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17zm0 1.5c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm3.237-6.874a4.485 4.485 0 001.236-2.626H13.46l1.776 2.626zm-1.208.892c-.61.308-1.299.482-2.029.482a4.49 4.49 0 01-1.541-.27l1.71-2.961 1.86 2.749zm-1.959-5.574l1.532-2.65A4.49 4.49 0 0012 7.5c-.6 0-1.173.118-1.697.33l1.767 2.614zM9.027 8.622L10.636 11H7.612a4.497 4.497 0 011.415-2.378zM13.481 11h2.908a4.499 4.499 0 00-1.494-2.445L13.48 11zm-4.326 4.487A4.494 4.494 0 017.527 12.5h3.355l-1.727 2.987zM12 18a6 6 0 100-12 6 6 0 000 12z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgMagnesium = React.memo(SvgMagnesium);
export default MemoSvgMagnesium;
