import * as React from 'react';

const SvgPets = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26 3a2.482 2.482 0 00-2.02 1.034l-5.054 6.084a.807.807 0 00-.186.516 3.19 3.19 0 003.178 3.194c.576 0 1.117-.155 1.583-.426v2.013a3.535 3.535 0 003.03 3.501A3.516 3.516 0 0012 21a3.515 3.515 0 003.208-2.084 3.534 3.534 0 003.03-3.5v-2.014c.467.27 1.008.426 1.584.426A3.19 3.19 0 0023 10.634a.807.807 0 00-.186-.516L17.76 4.033a2.483 2.483 0 00-.29-.332A2.483 2.483 0 0015.742 3H8.259zM7.5 5.02l.137-.164a.899.899 0 01.621-.25h7.482c.241 0 .46.096.622.251l.134.161a.905.905 0 01.147.495v9.902c0 1.061-.86 1.93-1.923 1.93a1.928 1.928 0 01-1.86-1.437c.178-.14.333-.323.46-.542l.68-1.184c.304-.53.345-1.11.075-1.58-.27-.469-.79-.722-1.396-.722h-1.36c-.607 0-1.126.253-1.396.722-.27.47-.23 1.05.075 1.58l.68 1.184c.127.219.282.402.46.542a1.928 1.928 0 01-1.86 1.436 1.928 1.928 0 01-1.923-1.929V5.511a.9.9 0 01.145-.49zm10.738 5.614v-3.52l3.144 3.785a1.587 1.587 0 01-1.561 1.322 1.587 1.587 0 01-1.583-1.587zm-14.06 1.587A1.587 1.587 0 012.616 10.9l3.144-3.784v3.519c0 .873-.708 1.587-1.583 1.587zM12 14.456l-.558-.97h1.116l-.558.97zm0 4.937a1.92 1.92 0 01-1.463-.676A3.532 3.532 0 0012 17.655c.385.47.888.84 1.463 1.062a1.92 1.92 0 01-1.463.676zM9.832 9.791c.599 0 1.084-.494 1.084-1.104 0-.61-.485-1.104-1.084-1.104-.598 0-1.083.494-1.083 1.104 0 .61.485 1.104 1.083 1.104zm5.42-1.104c0 .61-.486 1.104-1.085 1.104a1.094 1.094 0 01-1.083-1.104c0-.61.485-1.104 1.083-1.104.599 0 1.084.494 1.084 1.104z"
        fill="currentColor"
      />
    </svg>
  );
};

const MemoSvgPets = React.memo(SvgPets);
export default MemoSvgPets;
